module.exports = {
  "enus": {
      "P_YS_FI_CM_0000026181": "Yes",
      "P_YS_PF_PROCENTER_0000027341": "App",
      "P_YS_PF_WORKBENCH-FE_0001133355": "Product Module Customization",
      "P_YS_PF_WORKBENCH-FE_0001133356": " days before expiration",
      "P_YS_PF_WORKBENCH-FE_0001133353": "so I can manage and keep track of business operations anytime.",
      "P_YS_PF_WORKBENCH-FE_0001133354": "Modify Business Date",
      "P_YS_PF_WORKBENCH-FE_0001133359": "Preferences",
      "P_YS_PF_WORKBENCH-FE_0001228612": "Page Property Settings",
      "P_YS_PF_WORKBENCH-FE_0001228611": "Picture Ambiguity",
      "P_YS_PF_WORKBENCH-FE_0001133357": "Homepage Settings",
      "P_YS_PF_WORKBENCH-FE_0001228610": "Picture Mask",
      "P_YS_PF_WORKBENCH-FE_0001133358": "Portal",
      "P_YS_PF_WORKBENCH-FE_0001133362": "You can open up to 20 pages. Please close the pages you don't need.",
      "P_YS_PF_WORKBENCH-FE_0001228609": "Card Spacing",
      "P_YS_PF_GZTSYS_0000012650": "App Mgmt",
      "P_YS_PF_WORKBENCH-FE_0001133363": "Now I have the energy to pay more attention to each employee, empowering and activating the team.",
      "P_YS_PF_WORKBENCH-FE_0001228608": "Page Width",
      "P_YS_PF_WORKBENCH-FE_0001133360": "After quitting from a team, the Apps under the team will no longer be available, and the relevant data will be deleted. Please confirm that the data has been backed up.",
      "P_YS_PF_WORKBENCH-FE_0001228607": "Fixed Width",
      "P_YS_PF_WORKBENCH-FE_0001133361": "No result found",
      "P_YS_PF_WORKBENCH-FE_1420197920737591296": "If you switch the layout type, the existing components in the canvas will be lost. Are you sure you want to switch?",
      "P_YS_FED_UCFBASEDOC_0000025292": "Not Added",
      "P_YS_PF_WORKBENCH-FE_0001133366": "Yonyou Network Technology Co., Ltd ? Copyright 2018 Beijing ICP No. 5007539-24 Beijing ICP No. 100714",
      "P_YS_PF_WORKBENCH-FE_0001133367": "Search for functions,  contacts, etc.",
      "P_YS_PF_WORKBENCH-FE_0001133364": " Starry Blue",
      "P_YS_PF_WORKBENCH-FE_0001133365": "Exclusive Customizations",
      "P_YS_PF_WORKBENCH-FE_0001228600": "Welcome to YouZone",
      "P_YS_PF_WORKBENCH-FE_0001133368": "Service Function Release",
      "P_YS_PF_WORKBENCH-FE_0001133369": "Email",
      "P_YS_PF_WORKBENCH-FE_0001133370": "Telephone Communication",
      "P_YS_FED_FW_0000021848": "Greater than",
      "P_YS_PF_WORKBENCH-FE_0001133373": "Enterprise-level uniform basic file and data control",
      "P_YS_FED_IMP_IOT0000005567": "Confirm",
      "P_YS_PF_WORKBENCH-FE_0001133374": "Exit",
      "P_YS_FED_EXAMP_0000020762": "Enterprise",
      "P_YS_PF_WORKBENCH-FE_0001133371": "View the records of your usage",
      "P_YS_OA_app_xtywlj_0000037365": "Ignore",
      "P_YS_PF_WORKBENCH-FE_0001133372": "I am a research and development engineer.",
      "P_YS_PF_WORKBENCH-FE_0001663982": "Layout scale",
      "P_YS_PF_WORKBENCH-FE_0001663983": "Role workbench preview status, close the browser tab to end the preview.",
      "P_YS_PF_WORKBENCH-FE_0001663984": "LOGO bar setting",
      "P_YS_PF_WORKBENCH-FE_0001663985": "Component settings",
      "P_YS_FED_UCFBASEDOC_0000025043": "Add Shortcut to Homepage",
      "P_YS_PF_WORKBENCH-FE_0001133377": "Expert teams provide product solutions according to enterprise requirements",
      "country66": "Thailand",
      "P_YS_PF_WORKBENCH-FE_0001133378": "Hand over Team",
      "country65": "Singapore",
      "P_YS_PF_WORKBENCH-FE_0001133375": "Free Consultation",
      "P_YS_PF_WORKBENCH-FE_0001133376": "Enterprise-level App market provides all-round digital service entrance",
      "country63": "Philippines",
      "country62": "Indonesia",
      "country61": "Australia",
      "P_YS_PF_WORKBENCH-FE_0001133379": "Please enter menu name first",
      "country60": "Malaysia",
      "P_YS_PF_WORKBENCH-FE_0001133380": "Light Blue",
      "P_YS_PF_WORKBENCH-FE_0001133381": "Click OK to refresh the page. Continue?",
      "P_YS_FED_FW_0000020965": " items",
      "P_YS_PF_WORKBENCH-FE_0001196918": "Copyright©2021",
      "P_YS_FED_FW_0000021813": "Not equal",
      "P_YS_PF_WORKBENCH-FE_0001133384": "I am an HR manager.",
      "P_YS_PF_WORKBENCH-FE_0001133385": "Provide product deployment solutions, that you‘re free to choose the products you need",
      "P_YS_PF_WORKBENCH-FE_0001133382": "You need to switch to the corresponding enterprise to view details. Switch?",
      "P_YS_PF_WORKBENCH-FE_0001133383": "Management: visible to enterprise administrators by default. Can be authorized to other users",
      "P_YS_PF_YS-LOGIN_0001079475": "No data returned",
      "P_YS_PF_WORKBENCH-FE_0001245170": "\"Portal Mgmt\" was changed to \"Workbench Mgmt\", and the entry was moved from \"Collaboration\" to \"System Mgmt\"",
      "P_YS_FED_FW_0000020962": "Simpl. Chinese",
      "P_YS_FED_FW_0000020961": "Saved successfully",
      "P_YS_PF_WORKBENCH-FE_0001237311": "Multi-device Login Prompt",
      "P_YS_PF_WORKBENCH-FE_0001237312": "Enter service name",
      "P_YS_PF_WORKBENCH-FE_0001237313": "Log in and log out from other devices",
      "P_YS_PF_WORKBENCH-FE_0001237314": "Relevant result not found",
      "P_YS_PF_WORKBENCH-FE_0001237315": "Your account has logged in on another device or browser. Log in anyway?",
      "P_YS_PF_WORKBENCH-FE_0001237316": "Do not log in",
      "P_YS_PF_WORKBENCH-FE_0001237317": "Log in and keep in other devices",
      "P_YS_PF_WORKBENCH-FE_0001237318": "Your account has logged in on another device or browser. Continue to log in?",
      "P_YS_PF_WORKBENCH-FE_0001237319": "Login Prompt",
      "P_YS_HR_HRPXF_0000055966": "Please enter team name",
      "P_YS_PF_WORKBENCH-FE_0001133388": "Product modules can be deployed in your exclusive computer room, eliminating your security concerns",
      "P_YS_PF_MANAGER-FE_0001087904": "Forbid users to apply",
      "P_YS_PF_WORKBENCH-FE_0001133389": "Paid Consultation",
      "P_YS_PF_WORKBENCH-FE_0001133386": "PC Client",
      "P_YS_PF_WORKBENCH-FE_0001203344": "Close Others",
      "P_YS_PF_WORKBENCH-FE_0001133387": "After a team is dissolved, the Apps under the team will no longer be available, and the corresponding data will be deleted. Please confirm that the data has been backed up.",
      "P_YS_PF_WORKBENCH-FE_0001228620": "Enterprise Component",
      "P_YS_PF_WORKBENCH-FE_0001133391": "I‘m an entrepreneur.",
      "P_YS_PF_WORKBENCH-FE_0001133392": "Null",
      "P_YS_PF_WORKBENCH-FE_0001271682": "Questionnaire",
      "P_YS_PF_WORKBENCH-FE_0001133390": "Please search for the user to hand over to.",
      "P_YS_PF_WORKBENCH-FE_0001228613": "Mask Transparency",
      "P_YS_PF_WORKBENCH-FE_0001271683": "[Tips] Click here to fill in the user questionnaire. We look forward for your input",
      "P_YS_PF_WORKBENCH-FE_0001133395": "You've not joined any enterprise",
      "P_YS_PF_WORKBENCH-FE_0001133396": "The page contains unsaved data. Clicking OK will re-open the page. Are you sure to continue?",
      "P_YS_PF_WORKBENCH-FE_0001133393": "Service Application",
      "P_YS_PF_WORKBENCH-FE_0001133394": "Pre-sales staff will get in touch with you to discuss your customization needs",
      "P_YS_HR_HRXCF_0000056202": "Email format error",
      "P_YS_FI_CM_0000029891": "Please enter verification code",
      "P_YS_PF_WORKBENCH-FE_0001133319": "Quit from Team",
      "P_YS_PF_WORKBENCH-FE_0001133317": "my work efficiency, and facilitates team communication and collaboration.",
      "P_YS_PF_WORKBENCH-FE_0001133318": "One-on-one solutions provided by expert teams",
      "P_YS_PF_YS-LOGIN_0001196671": "Beijing ICP Certificate No. 05007539-24",
      "P_YS_PF_GZTTMP_0000075890": "Mobile",
      "P_YS_PF_WORKBENCH-FE_0001133311": "Basic attendance and salary query services",
      "P_YS_OA_XTMENHU_0000046660": "Initiating Time:",
      "P_YS_PF_MANAGER-FE_0001087915": "Fill in Mobile Homepage or summerId. ",
      "P_YS_PF_WORKBENCH-FE_0001133312": "Customize",
      "P_YS_PF_WORKBENCH-FE_0001133310": "Address: Yonyou Industry Park, No.68 Beiqing Road, Haidian District, Beijing",
      "P_YS_PF_MANAGER-FE_0001087912": "Runtime Env. Ver",
      "P_YS_PF_WORKBENCH-FE_0001133315": "History",
      "P_YS_PF_WORKBENCH-FE_0001133316": "The HR shared services of Yonyou Cloud",
      "P_YS_PF_WORKBENCH-FE_0001133313": "Do not switch",
      "P_YS_PF_WORKBENCH-FE_0001133314": "Scan to Download Client",
      "P_YS_PF_WORKBENCH-FE_0001274545": "No new members",
      "P_YS_PF_GZTSYS_0000015967": "Settings",
      "P_YS_PF_WORKBENCH-FE_0001274544": "Unknown Dept",
      "P_YS_PF_WORKBENCH-FE_0001274543": "Please enter the component link",
      "P_YS_PF_WORKBENCH-FE_0001274542": "快捷應用",
      "P_YS_PF_MANAGER-FE_0001087908": "Confirm to remove the selected user?",
      "P_YS_IPAAS_UCG_0001063993": "Water, Environment and Public Facilities Management Industry",
      "P_YS_IPAAS_UCG_0001063994": "Info Transmission, Computer Service and Software Industry",
      "P_YS_OA_XTMENHUCMS_0000045307": "Theme Name",
      "P_YS_FED_EXAMP_0000020713": "Mobile No.",
      "P_YS_PF_WORKBENCH-FE_0001274549": "Content Name",
      "P_YS_OA_LCGL_0000037233": "User Name",
      "P_YS_PF_WORKBENCH-FE_0001274548": "Earliest by Submission Time",
      "P_YS_FED_UCFBASEDOC_0000025257": "Search...",
      "P_YS_FED_FW_0000021874": "Type",
      "P_YS_PF_WORKBENCH-FE_0001274547": "Doc Type/Submitted At",
      "P_YS_PF_WORKBENCH-FE_0001274546": "Max Display Qty",
      "P_YS_FED_UCFBASEDOC_0000025253": "Sign Up",
      "P_YS_PF_WORKBENCH-FE_0001133328": "Elegant Grey",
      "P_YS_PF_MANAGER-FE_0001087920": "Free of Login",
      "P_YS_PF_WORKBENCH-FE_0001133329": "Expand",
      "P_YS_PF_PROCENTER_0000027376": "Advanced Settings",
      "P_YS_FED_UCFBASEDOC_0000025251": "Please enter mobile number",
      "P_YS_PF_WORKBENCH-FE_0001133322": "Stricter enterprise member management",
      "P_YS_PF_MANAGER-FE_0001087926": "Upload Local Icon",
      "P_YS_PF_WORKBENCH-FE_0001133323": "Member Permission",
      "P_YS_PF_WORKBENCH-FE_0001133320": "I am a financial manager",
      "P_YS_PF_WORKBENCH-FE_0001133321": "Business: by role authorization only",
      "P_YS_PF_MANAGER-FE_0001087923": "Web",
      "P_YS_PF_WORKBENCH-FE_0001133326": "Switch Enterprise",
      "P_YS_PF_MANAGER-FE_0001087922": "Please enter service name.",
      "P_YS_PF_WORKBENCH-FE_0001133327": "Hotline: 010-62438888",
      "P_YS_PF_MANAGER-FE_0001087925": "Icon size: 120*120; format: JPG, GIF or PNG; no more than 5M",
      "P_YS_PF_WORKBENCH-FE_0001133324": "Group name; no more than 12 chars",
      "P_YS_PF_WORKBENCH-FE_0001133325": "Loading data...",
      "P_YS_PF_WORKBENCH-FE_0001133330": "Role-based application permission management",
      "P_YS_FI_FCWEB_0001047535": "Full Screen",
      "P_YS_PF_WORKBENCH-FE_0001324054": "Permission restricted. Cannot view",
      "P_YS_OA_LCGL_0000037221": "User Code",
      "P_YS_PF_WORKBENCH-FE_0001133339": "After upgrading to an enterprise, you will get more privileges",
      "P_YS_FED_UCFBASEDOC_0000025483": "Added",
      "P_YS_HR_HRYGF_0000058856": "Personalizations",
      "P_YS_PF_WORKBENCH-FE_0001220704": "Moments",
      "P_YS_PF_GZTSYS_0000109035": "Favorites",
      "P_YS_PF_WORKBENCH-FE_0001133333": "Send the link to your friends",
      "P_YS_PF_MANAGER-FE_0001087937": "Please choose scale",
      "P_YS_PF_WORKBENCH-FE_0001133334": "Profile",
      "P_YS_PF_WORKBENCH-FE_0001133331": "Close All Pages",
      "P_YS_PF_WORKBENCH-FE_0001528930": "Upgrade Reminder",
      "P_YS_PF_WORKBENCH-FE_0001133332": "Upgrade to Enterprise",
      "P_YS_PF_WORKBENCH-FE_0001133337": "Morandi Yellow",
      "P_YS_PF_WORKBENCH-FE_0001528932": "Upgrade",
      "P_YS_PF_MANAGER-FE_0001087933": "Invite by Admin",
      "P_YS_PF_WORKBENCH-FE_0001133338": "Your enterprise has ",
      "P_YS_PF_WORKBENCH-FE_0001528931": "The tenant product form has been updated. Please upgrade the product",
      "P_YS_PF_WORKBENCH-FE_0001133335": "Renew",
      "P_YS_PF_WORKBENCH-FE_0001133336": "Please enter graphic verification code",
      "P_YS_PF_GZTSYS_0000012679": "Manager Self-services",
      "P_YS_PF_WORKBENCH-FE_0001133340": "Team App",
      "P_YS_OA_XTMENHU_0000046686": "Submitted By:",
      "P_YS_PF_WORKBENCH-FE_0001133341": "According to your needs, we can provide expert team on-site solutions",
      "P_YS_YYGJFW_YBUC_0001335998": "Dark Color",
      "P_YS_FED_IMP_IOT0000005514": "Next",
      "P_YS_IPAAS_UCG_0001063976": "Mining Industry",
      "P_YS_FED_FW_0000021612": "Failed to load",
      "P_YS_HR_HRXCF_0000072788": "Please select data source",
      "P_YS_FED_FW_0000021852": "Refresh",
      "P_YS_FED_EXAMP_0000020736": "Hide",
      "P_YS_PF_MANAGER-FE_0001087941": "After quitting from an enterprise, the Apps under the enterprise will no longer be available, and the corresponding data will be deleted. Please confirm that the data has been backed up.",
      "P_YS_SD_SDMAF_0000137211": "Restore Default Settings",
      "P_YS_PF_MANAGER-FE_0001087943": "Wrong mobile No. format",
      "P_YS_PF_WORKBENCH-FE_0001497250": "分钟】，您已超时，请重新登录。",
      "P_YS_OA_XTRIZHI_0000036826": "Favorites",
      "P_YS_PF_WORKBENCH-FE_0001133344": "Hotline: 010-62438888",
      "P_YS_PF_WORKBENCH-FE_0001133345": "Service Process",
      "P_YS_PF_WORKBENCH-FE_0001133342": "Yonyou Cloud enables us to track the company‘s financial data in real-time every day, really making finance a part of the business.",
      "P_YS_FI_CM_0000026177": "Approved",
      "P_YS_PF_WORKBENCH-FE_0001133343": "Service icon",
      "P_YS_PF_MANAGER-FE_0001087945": "Enter the redirect URL from YouZone PC version",
      "P_YS_PF_WORKBENCH-FE_0001133348": "Enterprise-level Org structure management",
      "P_YS_PF_WORKBENCH-FE_0001133349": "Please enter graphic verification code",
      "P_YS_PF_WORKBENCH-FE_0001133346": "Log Out",
      "P_YS_PF_WORKBENCH-FE_0001133347": "Menu Path",
      "P_YS_PF_WORKBENCH-FE_0001133351": "Confirm to set the current user as an administrator?",
      "P_YS_OA_XTMENHU_0000046675": "Greetings",
      "P_YS_PF_WORKBENCH-FE_0001133352": "My Portal",
      "P_YS_PF_WORKBENCH-FE_0001133350": "Yonyou Group",
      "P_YS_FED_IMP_IOT0000005503": "Query",
      "P_YS_PF_WORKBENCH-FE_0001497249": "温馨提示：您所在的企业帐号",
      "P_YS_PF_WORKBENCH-FE_0001497248": "）启用了无操作时长限制【",
      "OA_web_statistics_000000106": "Product Name",
      "P_YS_PF_WORKBENCH-FE_0001312270": "Pagination Settings",
      "P_YS_PF_WORKBENCH-FE_1422621673865084940": "1.Updated Personal Center into banner to provide more quick accesses ",
      "P_YS_FED_FW_0000022099": "Less than",
      "P_YS_PF_WORKBENCH-FE_1420197852018638911": "Suggested image size is 750 * 88, the size is not more than 2M, and jpg and png are supported.",
      "P_YS_PF_WORKBENCH-FE_1422621673865084946": "4.Optimized workbench designer and adjusted setting buttons of personal workbench ",
      "P_YS_PF_WORKBENCH-FE_1422621673865084943": "5. Optimized interaction experiences in shortcut menu of role workbench",
      "P_YS_PF_WORKBENCH-FE_0001301136": "Select Color",
      "P_YS_PF_WORKBENCH-FE_1422621673865084949": "Sorting by dragging is supported. You can drag three commonly used workstations above the dotted line and display them permanently to realize quick switching.",
      "P_YS_PF_WORKBENCH-FE_0001301137": "Red -#FF5735",
      "P_YS_PF_WORKBENCH-FE_0001301138": "Blue -#588CE9",
      "P_YS_PF_WORKBENCH-FE_0001301139": "Grouping Condition Settings",
      "P_YS_PF_WORKBENCH-FE_0001312273": "/Doc Summary",
      "P_YS_PF_WORKBENCH-FE_0001312272": "Latest Received",
      "P_YS_PF_WORKBENCH-FE_0001312271": "Earliest Received",
      "P_YS_PF_WORKBENCH-FE_0001301140": "Report Source",
      "P_YS_OA_XTMENHUCMS_0000045100": "Background Setting",
      "P_YS_PF_WORKBENCH-FE_0001301141": "Ind. Card Settings",
      "P_YS_OA_XTMENHUCMS_0000045340": "Top",
      "P_YS_PF_WORKBENCH-FE_0001301142": "No indicator",
      "P_YS_PF_WORKBENCH-FE_0001301143": "Confirm to delete the indicator card",
      "P_YS_FED_EXAMP_0000019931": "Mailbox",
      "P_YS_PF_WORKBENCH-FE_0001312269": "/Doc Type",
      "P_YS_PF_WORKBENCH-FE_0001312268": "Select Filter Criteria",
      "P_YS_IPAAS_UCG_0001064402": "Real Estate Industry",
      "P_YS_PF_WORKBENCH-FE_1420197852018638914": "Theme color setting",
      "P_YS_PF_GZTSYS_0000012812": "To-dos",
      "P_YS_PF_WORKBENCH-FE_1420197852018638917": "Suggested image size 750 * 88",
      "P_YS_OA_XTMENHUCMS_0000045344": "IOS Redirection Parameter",
      "P_YS_PF_GZTSYS_0000012814": "Team",
      "000322": "Please enter the URL",
      "P_YS_PF_WORKBENCH-FE_1422621673865084931": "2.Drag to sort workbenches. First three are displayed constantly and the first is the default workbench",
      "P_YS_PF_WORKBENCH-FE_1422621673865084934": "3.Adds various Cloud Applications to global navigation and supports five-level navigation and drawer layout",
      "P_YS_PF_WORKBENCH-FE_1420197852018638920": "Small decoration",
      "P_YS_YYGJFW_YBUC_0001337063": "Display Icon",
      "P_YS_PF_WORKBENCH-FE_1422621673865084937": "Define Enterprise Customization",
      "P_YS_FED_EXAMP_0000019939": "OK",
      "P_YS_IPAAS_UCG_0001064652": "Manufacturing",
      "P_YS_PF_WORKBENCH-FE_1420197852018638926": "Collection and history",
      "P_YS_IPAAS_UCG_0001064416": "Environment and Public Administration, Social Insurance, and Social Org",
      "P_YS_PF_WORKBENCH-FE_1420197852018638923": "Allow users to modify",
      "P_YS_PF_WORKBENCH-FE_1420197852018638929": "Business blue",
      "P_YS_PF_WORKBENCH-FE_1420197852018638932": "Column-default logo",
      "000357": "Delete Files",
      "P_YS_PF_WORKBENCH-FE_1422621673865084928": "Self-built sub-domains are temporarily integrated into \"enterprise customization\". Administrators can find this sub-domain in \"menu management\" and independently classify it into a specific domain cloud.",
      "P_YS_PF_ANALYTIC-FE_0001652738": "Edit theme",
      "P_YS_OA_LCSJ_0001130313": "Before",
      "P_YS_PF_WORKBENCH-FE_0001301366": "User Community",
      "P_YS_PF_WORKBENCH-FE_1420197852018638935": "Longitudinal dividing line",
      "P_YS_FI_IA_0000054929": "Doc Type",
      "P_YS_PF_WORKBENCH-FE_1420197852018638938": "Head background",
      "P_YS_HR_HRYGF_0000058823": "Upgrade",
      "P_YS_PF_WORKBENCH-FE_1420197852018638944": "Icon selected",
      "P_YS_PF_WORKBENCH-FE_1420197852018638941": "Selected title",
      "P_YS_PF_WORKBENCH-FE_0001312267": "/Submitted By",
      "P_YS_PF_WORKBENCH-FE_0001301129": "Green -#18B681",
      "P_YS_PF_PC-UI_0000167466": "Filtering Region",
      "P_YS_PF_WORKBENCH-FE_0001274556": "Failed to call; DomID:",
      "P_YS_SD_UDHWN_0000122417": "White",
      "P_YS_PF_WORKBENCH-FE_0001274555": "Rendering Function of Widget",
      "P_YS_PF_WORKBENCH-FE_0001274554": "Latest by Submission Time",
      "P_YS_PF_WORKBENCH-FE_0001301130": "Please enter the statistic name",
      "P_YS_PF_WORKBENCH-FE_0001274553": "Failed to render the properties of the widget",
      "P_YS_PF_WORKBENCH-FE_0001301131": "Select Doc",
      "P_YS_PF_AUTHF_0000096078": "20 /page",
      "P_YS_PF_WORKBENCH-FE_0001274552": "Please enter the max display Qty",
      "P_YS_PF_WORKBENCH-FE_0001301132": "Dark Grey -#333333",
      "P_YS_PF_WORKBENCH-FE_0001274551": "Please enter the requested address",
      "P_YS_PF_WORKBENCH-FE_0001301133": "Statistics Name",
      "P_YS_IPAAS_UCG_0001064631": "Scientific Research, Technical Services and Geological Prospecting",
      "P_YS_PF_WORKBENCH-FE_0001274550": "By Doc Type",
      "P_YS_PF_WORKBENCH-FE_0001301134": "Yellow -#FFA600",
      "P_YS_PF_WORKBENCH-FE_0001301135": "Historical Announcements",
      "P_YS_SD_SDSD-FE_0001301389": "Enterprise-defined",
      "P_YS_PF_WORKBENCH-FE_1420197852018638947": "Logo replacement",
      "P_YS_PF_WORKBENCH-FE_0001238259": "To-do Notice",
      "P_YS_PF_WORKBENCH-FE_0001274558": "Interface Returned Format",
      "P_YS_OA_XTMENHUCMS_0000045311": "Approval Center",
      "P_YS_PF_WORKBENCH-FE_0001274557": "Please enter the content name",
      "P_YS_PF_WORKBENCH-FE_0001133399": "After handing over a team, you'll no longer have the administrator permissions.",
      "P_YS_PF_WORKBENCH-FE_0001133397": "Link copied. Send it to your friends now!",
      "P_YS_PF_WORKBENCH-FE_0001133398": "Digital Portal",
      "country84": "Vietnam",
      "P_YS_OA_XTTMCENTER_0000035122": "Administrator",
      "P_YS_OA_XTMENHUCMS_0000045143": "Favorite Apps",
      "P_YS_OA_XTMENHUCMS_0000045382": "Android Download Link",
      "P_YS_OA_XTMENHUCMS_0000045140": "Flow Layout",
      "P_YS_FED_IMP_IOT0000011425": "Close",
      "P_YS_SD_SDMA_0000060355": "Help Center",
      "P_YS_IPAAS_UCG_0001064207": "Resident Services and Other Services",
      "P_YS_FI_CM_0000029021": "Go to ",
      "P_YS_FED_UCFBASEDOC_0000025096": "Confirm to delete the service?",
      "country95": "Burma",
      "P_YS_SCM_PU_0000028725": "Regular",
      "P_YS_OA_XTMENHUCMS_0000045376": "Server Address",
      "P_YS_FED_FW_0000020955": "Disable",
      "P_YS_OA_XTMENHUCMS_0000045375": "Mobile Version Homepage",
      "P_YS_PF_GZTSYS_0000012860": "Dynamic",
      "P_YS_PF_WORKBENCH-FE_0001663980": "Switch the layout type, the existing tiles in the canvas will be lost. Are you sure you want to switch?",
      "P_YS_PF_WORKBENCH-FE_0001663981": "Tab bar setting",
      "P_YS_FI_CM_0000030025": "Expiration Time",
      "P_YS_FED_EXAMP_0000019906": "More",
      "P_YS_PF_WORKBENCH-FE_0001217140": "Please select at least one client",
      "P_YS_OA_XTMENHUCMS_0000045121": "No results",
      "P_YS_OA_XTMENHUCMS_0000045362": "Android Redirection Parameter",
      "P_YS_PF_WORKBENCH-FE_0001663979": "The current page is",
      "P_YS_OA_XTTMCENTER_0000035140": "Quit",
      "P_YS_PF_WORKBENCH-FE_0001625479": "Cancel setting",
      "P_YS_PF_WORKBENCH-FE_0001663975": "Fixed width (1400px)",
      "P_YS_PF_WORKBENCH-FE_0001663976": "Component spacing",
      "P_YS_PF_WORKBENCH-FE_0001663977": "Set canvas",
      "P_YS_PF_WORKBENCH-FE_0001663978": "Component head setting",
      "P_YS_HR_HRJQ_0000031030": "Null",
      "P_YS_OA_XTMENHUCMS_0000045366": "In Current Page",
      "P_YS_FED_UCFBASEDOC_0000025079": "Failed to publish",
      "P_YS_PF_WORKBENCH-FE_1422621673865084952": "6.Supports enterprises to custom scenario-based APPs and bind them to specified workbenches",
      "P_YS_SCM_PU_0000028707": "Merchant",
      "P_YS_SD_UDHWN_0000122693": "Last Week",
      "P_YS_PF_WORKBENCH-FE_0001565488": "Tsingtao Beer Green",
      "P_YS_PF_WORKBENCH-FE_0001625482": "Workbench version",
      "P_YS_OA_XTMENHUCMS_0000045353": "IOS Download Link",
      "P_YS_PF_WORKBENCH-FE_0001625481": "Support personal workbench",
      "P_YS_OA_XTMENHUCMS_0000045352": "Publish Client",
      "P_YS_PF_WORKBENCH-FE_0001625484": "| editing:",
      "P_YS_OA_XTMENHUCMS_0000045351": "Please enter App name",
      "P_YS_PF_WORKBENCH-FE_0001625483": "Workbench designer",
      "P_YS_PF_GZTSYS_0000011990": "Portal Homepage",
      "P_YS_PF_WORKBENCH-FE_0001625480": "Default business unit",
      "P_YS_PF_WORKBENCH-FE_1420197852018638902": "Drag to the first position to be the default workbench.",
      "P_YS_OA_XTMENHUCMS_0000045359": "In New Page",
      "P_YS_PF_WORKBENCH-FE_1420197852018638908": "The selected theme cannot be deactivated.",
      "P_YS_PF_WORKBENCH-FE_0001625485": "Search component name",
      "P_YS_PF_WORKBENCH-FE_1420197852018638905": "Background type",
      "P_YS_PF_WORKBENCH-FE_0001229381": "Select Widget",
      "P_YS_PF_WORKBENCH-FE_0001229380": "Sort by Dragging",
      "P_YS_PF_WORKBENCH-FE_0001229385": "The recommended value should be an integer multiple of 87",
      "P_YS_PF_WORKBENCH-FE_0001229384": "User-defined Height",
      "P_YS_PF_WORKBENCH-FE_0001229383": "Content Settings",
      "P_YS_PF_WORKBENCH-FE_0001229382": "Rich Text Content Settings",
      "P_YS_OA_XTLCZX_0000030376": "Change",
      "P_YS_PF_WORKBENCH-FE_0001229388": "Display Name",
      "P_YS_PF_WORKBENCH-FE_0001229387": "Display Notification",
      "P_YS_PF_WORKBENCH-FE_0001229386": "Component Height",
      "P_YS_PF_GZTSYS_0000013194": "Workbench",
      "P_YS_PF_GZTSYS_0001025942": "Portal Ads Space",
      "P_YS_IPAAS_UCG_0001064361": "Agriculture Forestry Animal Husbandry and Fishery Industries",
      "P_YS_OA_XTTXL_0000045990": "Management",
      "P_YS_OA_XTSPZX_0000034652": "Sort",
      "P_YS_FED_UCFBASEDOC_0000025619": "Add Folder",
      "P_YS_IPAAS_UCG_0001064369": "International Organization",
      "P_YS_FED_FW_0000022488": "This Month",
      "P_YS_FED_UCFBASEDOC_0000025614": "Mobile Phone",
      "P_YS_FED_UCFORG_0000066189": "Display Content",
      "P_YS_OA_XTLCZX_0000030141": "Select layout",
      "P_YS_PF_WORKBENCH-FE_0001229374": "Ads space resources can be set in Portal Ads Space",
      "P_YS_PF_WORKBENCH-FE_0001229373": "Set the picture carousel interval when there are multiple ads pictures",
      "P_YS_PF_WORKBENCH-FE_0001229377": "Enable Customization",
      "P_YS_PF_WORKBENCH-FE_0001229376": "Associated Ads",
      "P_YS_PF_WORKBENCH-FE_0001229375": "Picture Carousel Interval",
      "P_YS_PF_WORKBENCH-FE_0001229379": "Search App Name",
      "P_YS_OA_XTMENHUCMS_0000045174": "Ads Space",
      "P_YS_IPAAS_UCG_0001064138": "Electricity/Heating Power/Gas/Water Production and Supply Industry",
      "P_YS_FED_UCFBASEDOC_0000024755": "Delete",
      "P_YS_FED_UCFBASEDOC_0000025601": "Please enter e-mail",
      "P_YS_FED_UCFBASEDOC_0000024752": "Confirm to delete the service?",
      "P_YS_SD_SDMAUF_0000152315": "After",
      "P_YS_PF_GZTLOG_0000025988": "Unlock",
      "P_YS_FED_UCFBASEDOC_0000024748": "Confirm to batch delete?",
      "P_YS_FED_FW_0000022466": "Default",
      "P_YS_FED_FW_0000021376": "China",
      "P_YS_OA_XTSPZX_0000034639": "Field",
      "P_YS_FED_FW_0000021379": "Beijing",
      "P_YS_FED_UCFBASEDOC_0000024982": "Discard",
      "P_YS_OA_XTMENHUCMS_0000045168": "Message Center",
      "P_YS_SCM_STOCK_0000034455": "Unread",
      "P_YS_PF_WORKBENCH-FE_0001241019": "Workbench",
      "P_YS_PF_ANALYTIC-FE_0001651354": "Table Settings",
      "P_YS_FED_FW_0000022483": ":",
      "P_YS_OA_XTTMCENTER_0000035108": "Yonyou Cloud Market",
      "P_YS_PF_MANAGER-FE_0001120439": "Wrong parameter: applicationId",
      "P_YS_PF_WORKBENCH-FE_0001302733": "Failed to render the filtering area",
      "P_YS_PF_GZTLOG_0000025976": "Lock",
      "P_YS_PF_GZTSYS_0000012092": "Tenant Upgrade",
      "P_YS_PF_GZTLOG_0000025975": "Custom",
      "P_YS_FED_FW_0000022475": "Industry",
      "P_YS_OA_XTMENHUCMS_0000045158": "Work Portal",
      "P_YS_FI_CM_0000029097": "Today",
      "P_YS_PF_WORKBENCH-FE_1420197852018638950": "More personalized settings",
      "P_YS_FI_FA_0000056249": "Add Cards",
      "P_YS_OA_XTLCZX_0000030333": "Received At",
      "P_YS_PF_WORKBENCH-FE_1420197852018638953": "Text selection",
      "P_YS_FED_FW_0000022290": "Less than or equal to",
      "P_YS_YYGJFW_YYSJQ-FE_0001661128": "Color background",
      "P_YS_PF_WORKBENCH-FE_1420197852018638956": "Logo replacement",
      "P_YS_FED_UCFBASEDOC_0000024960": "Publish",
      "P_YS_FED_FW_0000022064": "No data",
      "P_YS_PF_WORKBENCH-FE_0001202943": "Fixed Navigation",
      "P_YS_PF_WORKBENCH-FE_0001202944": "Lock Menu",
      "P_YS_PF_WORKBENCH-FE_0001202945": "Switch Navigation",
      "P_YS_PF_WORKBENCH-FE_0001202946": "Unfix",
      "P_YS_PF_WORKBENCH-FE_0001202941": "Quick Navigation",
      "P_YS_PF_WORKBENCH-FE_0001202942": "Global Navigation",
      "P_YS_SCM_USTOCK-UI_0000173476": "Business Settings",
      "country886": "Taiwan, China",
      "P_YS_FI_FCWEB_0001048065": "Workbench setting",
      "P_YS_OA_XTSPZX_0000034846": "Expand",
      "P_YS_SD_SDMB_0000146348": "Service Support",
      "P_YS_PF_WORKBENCH-FE_0001202947": "Quick Navigation Settings",
      "P_YS_FED_UCFBASEDOC_0000024951": "The groups without content are invisible on the homepage",
      "P_YS_PF_GZTSYS_0000012902": "Others",
      "P_YS_PF_PROCENTER_0000022591": "Verification failed",
      "P_YS_PF_WORKBENCH-FE_0001212762": "Quick Search",
      "P_YS_OA_XTLCZX_0000030351": "Display",
      "P_YS_FED_FW_0000022274": "R&D Center",
      "P_YS_SD_SDMAUF_0000152518": "Remaining",
      "P_YS_PF_WORKBENCH-FE_0001153504": "No more reminders",
      "P_YS_SD_SDMB_0000138761": "Classic Blue",
      "P_YS_PF_WORKBENCH-FE_0001137268": "Ignored. Do not remind again",
      "P_YS_FED_UCFBASEDOC_0000024702": "Please select Doc",
      "P_YS_FED_UCFBASEDOC_0000024942": "Add",
      "P_YS_OA_XTLCZX_0000030589": "Color",
      "P_YS_OA_app_xtyyjm_0000035755": "Pending Approval",
      "P_YS_OA_XTSPZX_0000034819": "Confirm",
      "P_YS_PF_YHTT_0001204706": "My Language",
      "P_YS_FED_EXAMP_0000020075": "Operation succeeded",
      "P_YS_FED_UCFBASEDOC_0000024938": "Delete Service",
      "P_YS_FED_UCFBASEDOC_0000024930": "Please enter contact name",
      "P_YS_OA_app_xtyyjm_0000035750": "Members",
      "P_YS_PF_WORKBENCH-FE_0001212760": "” related result not found. Please re-enter",
      "P_YS_PF_WORKBENCH-FE_0001212761": "Failed to search for“",
      "P_YS_SD_SDMB_0000139191": "Message Details",
      "P_YS_PF_MANAGER-FE_0001087832": "Allow to Apply",
      "P_YS_OA_XTMENHUCMS_0000044935": "Posts",
      "P_YS_PF_GZTSYS_0000109255": "Failed to get Verif. code",
      "P_YS_OA_XTMENHUCMS_0000044934": "Long Ads Space",
      "P_YS_OA_YBMOB_0000036991": "Approval Details",
      "P_YS_SD_SDMA_0000123136": "Homepage",
      "P_YS_PF_MANAGER-FE_0001087834": "Enter the redirect URL from YouZone mobile version",
      "P_YS_PF_MANAGER-FE_0001087836": "Please enter IOS download link",
      "P_YS_FED_EXAMP_0000020236": "Copy Link",
      "P_YS_OA_XTMENHU_0000046620": "Link Name",
      "P_YS_OA_app_xtywlj_0000037874": "Property Settings",
      "P_YS_SD_UDHMC_0000051143": "Please enter enterprise name",
      "P_YS_OA_XTMENHUCMS_0000044932": "Vibrant Orange",
      "P_YS_FED_UCFBASEDOC_0000025215": "Log In",
      "P_YS_OA_app_xtywlj_0000037879": "Category",
      "P_YS_PF_GZTSYS_0001127465": "Dashboard",
      "P_YS_PF_MANAGER-FE_0001087842": "Redirection Parameters",
      "P_YS_PF_MANAGER-FE_0001087841": "Invite All",
      "P_YS_OA_XTMENHUCMS_0000044929": "Theme Settings",
      "P_YS_PF_MANAGER-FE_0001087844": "Please select if it is a native App",
      "P_YS_PF_MANAGER-FE_0001087843": "Enter the installation pack ID to upload to iuap",
      "P_YS_FED_FW_0000055346": "Please select data",
      "P_YS_PF_MANAGER-FE_0001087840": "Web Homepage",
      "P_YS_FI_CM_0000029500": " ",
      "P_YS_FI_FP_0000034914": "This Year",
      "P_YS_PF_MANAGER-FE_0001087846": "Offline App",
      "P_YS_PF_MANAGER-FE_0001087848": "Invitation Rules",
      "P_YS_PF_MANAGER-FE_0001087847": "Enter the redirect URL from a Web browser",
      "P_YS_FED_EXAMP_0000020005": "Template",
      "P_YS_FED_EXAMP_0000020004": "Total ",
      "P_YS_FI_CM_0000028899": "Log Out",
      "P_YS_OA_XTLCZX_0000030776": "Normal",
      "P_YS_FED_UCFBASEDOC_0000025203": "Get Verification Code",
      "P_YS_PF_WORKBENCH-FE_0001233465": "Start",
      "P_YS_OA_XTMENHU_0000046618": "My Project",
      "P_YS_FED_IMP_IOT_0000724713": "test",
      "P_YS_FED_UCFBASEDOC_0000025200": "Mobile",
      "P_YS_PF_MANAGER-FE_0001087852": "Please upload the Service icon.",
      "P_YS_HR_HRSBF_0001183870": "Personal Settings",
      "P_YS_OA_XTLCZX_0000030555": "Tile",
      "P_YS_PF_WORKBENCH-FE_0001232171": "Remaining Qty",
      "P_YS_HR_HRJQ_0000030940": "Process",
      "P_YS_PF_WORKBENCH-FE_0001232170": "Earlier",
      "P_YS_PF_MANAGER-FE_0001087858": "Priority at Top",
      "P_YS_FED_EXAMP_0000020016": "Homepage",
      "P_YS_OA_XTMENHU_0000046646": "Invitation Link",
      "P_YS_SD_SDMA_0000098308": "Page Settings",
      "P_YS_PF_WORKBENCH-FE_0001245211": "Left-Right",
      "P_YS_PF_GZTSYS_0000013325": "Label",
      "P_YS_OA_XTMENHU_0000046644": "Space Management",
      "P_YS_PF_METADATA_0000085782": "Product Code",
      "P_YS_OA_app_xtyyjm_0000035782": "Select Report",
      "P_YS_PF_WORKBENCH-FE_0001232168": "Exception in obtaining the data. Please refresh",
      "P_YS_FED_FW_0000021777": "Exclude",
      "P_YS_OA_app_xtywlj_0000037618": "Business",
      "P_YS_PF_WORKBENCH-FE_0001232169": "Authorization Usage",
      "P_YS_PF_WORKBENCH-FE_0001232166": "Authorization Status Details",
      "P_YS_IMP_DFM-UI_0001273087": "Non-Strict",
      "P_YS_PF_WORKBENCH-FE_0001232167": "No. of Authorizations",
      "P_YS_PF_WORKBENCH-FE_0001232164": "Message Category",
      "P_YS_PF_MANAGER-FE_0001087863": "Operations",
      "P_YS_PF_WORKBENCH-FE_0001133309": "System: visible to enterprise administrators only",
      "P_YS_PF_WORKBENCH-FE_0001232165": "Last 3 Days",
      "P_YS_PF_MANAGER-FE_0001087865": "In PC Client",
      "P_YS_PF_WORKBENCH-FE_0001232163": "Deployment Fee",
      "P_YS_SD_SDMAUF_0000151876": "Please fill in",
      "P_YS_PF_MANAGER-FE_0001087868": "Please choose an industry",
      "P_YS_PF_MANAGER-FE_0001087867": "Icon size must be 120*120 and cannot exceed 5M!",
      "P_YS_PF_WORKBENCH-FE_0001245212": "Top-Bottom",
      "P_YS_OA_app_xtywlj_0001081532": "Source Tenant",
      "P_YS_SD_SDMB-UI_0001160783": "Display Profile Photo",
      "P_YS_PF_WORKBENCH-FE_0001145762": "I know. Do not prompt again",
      "P_YS_FED_FW_0000021785": "Include",
      "P_YS_PF_WORKBENCH-FE_0001145764": "The product you bought will expire soon",
      "P_YS_PF_WORKBENCH-FE_0001145763": "The product you bought has expired",
      "P_YS_SD_SDMB_0000146962": "Top",
      "P_YS_PF_WORKBENCH-FE_0001145765": "View",
      "P_YS_PF_MANAGER-FE_0001087877": "Hong Kong Special Administrative Region of China",
      "P_YS_PF_MANAGER-FE_0001087870": "Staff",
      "P_YS_PF_MANAGER-FE_0001087873": "Invite not Allowed",
      "P_YS_FI_CM_0000029930": "OK",
      "P_YS_PF_MANAGER-FE_0001087872": "Cannot be more than 6 Chinese chars or 12 letters.",
      "P_YS_PF_PROCENTER_0000023062": "Other",
      "P_YS_HR_HRPXF_0000055999": "Award Honor",
      "P_YS_OA_XTSPZX_0000034691": "Submitted By",
      "OA_web_footer_000000023": "Beijing CIN 11010802021935",
      "P_YS_FED_UCFBASEDOC_0000025417": "Rename",
      "P_YS_FED_FW_0000022200": "Current",
      "P_YS_PF_PROCENTER_0000026339": "Contact",
      "P_YS_HR_HRJQF_0000054222": "Date Range",
      "P_YS_FED_FW_0000022205": "Move Down",
      "P_YS_PF_MANAGER-FE_0001087885": "Photo",
      "P_YS_PF_MANAGER-FE_0001087882": "Download Link",
      "P_YS_PF_MANAGER-FE_0001087884": "Forbidden",
      "P_YS_PF_MANAGER-FE_0001087883": "Add to Mobile Homepage",
      "P_YS_SD_SDMAUF_0000151808": "∨",
      "P_YS_PF_METADATA_0000084287": "Authorizations Used",
      "P_YS_PF_WORKBENCH-FE_0001371281": "Binding Service",
      "P_YS_OA_XTSPZX_0000034688": "Data Source",
      "P_YS_FED_EXAMP_0000020207": "Approved",
      "P_YS_FED_FW_0000022215": "Group",
      "P_YS_FED_UCFBASEDOC_0000025400": "Confirm to delete the item?",
      "P_YS_PF_MANAGER-FE_0001087899": "Failed to upload icon",
      "P_YS_PF_MANAGER-FE_0001087893": "No more than 60 chars",
      "P_YS_PF_MANAGER-FE_0001087895": "Free of Login",
      "P_YS_OA_XTMENHU_0000046601": "You can add up to 19 Apps",
      "P_YS_OA_XTSPZX_0000034674": "New App",
      "P_YS_OA_XTMENHU_0000046840": "No records.  Check in on your mobile phone~",
      "P_YS_PF_GZTLOG_0000028817": "Request failed",
      "P_YS_OA_XTSPZX_0000034678": "Enterprise Account",
      "P_YS_PF_MANAGER-FE_0001087891": "Based on Org manager",
      "P_YS_OA_XTRIZHI_0000036793": "Cancel Editing",
      "P_YS_OA_XTMENHU_0000046605": "Pending Approval",
      "P_YS_PF_GZTTMP_0000079164": "Message Status",
      "P_YS_YYGJFW_YYSJQ-FE_0001661175": "Picture background",
      "P_YS_PF_METADATA_0000088627": "Feedback",
      "P_YS_OA_XTSPZX_0000034661": "Mobile",
      "P_YS_PF_WORKBENCH-FE_0001204986": "Please enter the service name (up to 15 Chinese characters)",
      "P_YS_FED_EXAMP_0000020462": "Clear",
      "P_YS_FI_GL_0000088728": "Allowed",
      "P_YS_FED_FW_0001097161": "Clicking OK will close the page and all the editing will be discarded. Continue?",
      "P_YS_PF_WORKBENCH-FE_0001620597": "Please check the page for unsaved data.",
      "P_YS_PF_WORKBENCH-FE_0001620596": "Click OK to close the page. Continue?",
      "P_YS_SD_SDMAF_0000136493": "Send Email",
      "P_YS_PF_MES-FE_0001269113": "Doc Title",
      "P_YS_SD_SDMAUF_0000153186": "Enterprise Addr.",
      "P_YS_OA_XTMENHU_0000046582": "Latest Check-in:",
      "P_YS_SD_SDMA-UI_0001140473": "Component Link",
      "P_YS_FI_ETL_0000111653": "Local",
      "P_YS_PF_WORKBENCH-FE_0001233073": "The menu structure has changed",
      "P_YS_PF_WORKBENCH-FE_0001233072": "Switch to Old Version",
      "P_YS_PF_WORKBENCH-FE_0001233071": "New Menu",
      "P_YS_PF_WORKBENCH-FE_0001233070": "Switch Personalized Config. and Tenants",
      "P_YS_FED_FW_0000021969": "Upload Picture",
      "P_YS_FED_IMP_IOT0000005442": "Deleted successfully",
      "P_YS_OA_XTMENHU_0000046575": "Component Address",
      "P_YS_PF_PRINT_0000072986": "No related content",
      "P_YS_PF_GUIDE_0001071542": "It must be a picture",
      "P_YS_PF_YS-LOGIN_0001079584": "You clicked this icon; parameter called:",
      "P_YS_PF_WORKBENCH-FE_0001233069": "You can switch menus here",
      "P_YS_PF_WORKBENCH-FE_0001233068": "Switch Tenant",
      "P_YS_FED_UCFBASEDOC_0000025165": "Add Group",
      "P_YS_PF_WORKBENCH-FE_1421570875756380169": "The navigation menu adds the concept of domain cloud, and the original level is changed into sub-domains, which are placed under the domain cloud respectively; There are multiple sub-domains under the cloud, which can be folded in the form of drawers.",
      "P_YS_FED_UCFBASEDOC_0000025164": "Drag the widgets below to the right",
      "P_YS_PES_PES-FE_0001108409": "Yonyou Red",
      "P_YS_SD_SDMAF_0000137369": "Self-adaptive",
      "P_YS_PF_WORKBENCH-FE_1421570875756380163": "Personal center experience promotion",
      "P_YS_PF_WORKBENCH-FE_1421570875756380166": "Define \"ungrouped\"",
      "P_YS_OA_XTLCZX_0000030707": "Agree",
      "P_YS_PF_WORKBENCH-FE_1421570875756380160": "Optimization of workbench switching experience",
      "P_YS_FED_IMP_IOT0000008702": "Copy",
      "P_YS_FED_IMP_IOT_0000129791": "Function Name",
      "P_YS_PF_GZTTMP_0000079071": "Doc Time",
      "P_YS_PF_MES-FE_0001269123": "Doc Summary",
      "P_YS_PF_MES-FE_0001269122": "Interface error",
      "P_YS_PF_WORKBENCH-FE_1421570875756380175": "Function position adjustment",
      "P_YS_PF_WORKBENCH-FE_1421570875756380172": "Define the global menu level",
      "P_YS_PF_WORKBENCH-FE_1421570875756380178": "The personal center has been redesigned, which is in the form of top-to-top, adding more configuration items for individual users.",
      "P_YS_HR_HRCOREF_0000058490": "Download File",
      "P_YS_PF_GZTSYS_0000012764": "Approval Center",
      "P_YS_PF_PFRPT_0001631363": "Title text",
      "P_YS_FED_IMP_IOT0000005424": "Edit",
      "P_YS_FED_EXAMP_0000020660": "Me",
      "P_YS_PF_AUTHF_0000096113": "5 entries/Page",
      "P_YS_FED_FW_0000021948": "Category",
      "P_YS_FED_IMP_IOT0000006999": "Filter by Default",
      "P_YS_FED_FW_0000177597": "Upgrade",
      "P_YS_OA_XTMENHUCMS_0000044985": "Component",
      "P_YS_PF_WORKBENCH-FE_0001133438": "Yonyou Cloud improves",
      "P_YS_PF_WORKBENCH-FE_0001133439": "Portal of Menus",
      "P_YS_PF_WORKBENCH-FE_0001133432": "I am an IT operation and maintenance manager.",
      "P_YS_FI_FCWEB_0001048519": "Recently used",
      "P_YS_PF_WORKBENCH-FE_0001133433": "You've not purchased any service",
      "P_YS_OA_XTMENHU_0000046780": "No approval task",
      "P_YS_PF_WORKBENCH-FE_0001133430": "Add to Homepage",
      "P_YS_PF_WORKBENCH-FE_0001133431": "Missing required parameter: serviceCode",
      "P_YS_OA_XTMENHU_0000046540": "Text Mode",
      "P_YS_PF_WORKBENCH-FE_0001133436": "Loading mdf",
      "P_YS_PF_WORKBENCH-FE_0001133437": "Expert Team On-site Services",
      "P_YS_PF_WORKBENCH-FE_0001133434": "Intelligent IM",
      "P_YS_PF_WORKBENCH-FE_0001133435": "Upgrading to enterprise…",
      "P_YS_PF_GZTSYS_0000012336": "Open Platform",
      "P_YS_FED_FW_0000021759": "Title",
      "P_YS_OA_XTTMCENTER_0000035088": "Yonyou's Official Website",
      "P_YS_SD_SDMB_0000146914": "Beijing Headquarters",
      "P_YS_PF_WORKBENCH-FE_0001133440": "Dissolve Team",
      "P_YS_OA_XTMENHU_0000046542": "Template Selection",
      "P_YS_FED_FW_0000021994": "Set as Default",
      "P_YS_PF_MANAGER-FE_0001087800": "Remove",
      "P_YS_PF_WORKBENCH-FE_0001133449": "Hide",
      "P_YS_PF_MANAGER-FE_0001087806": "In New Browser Page",
      "P_YS_PF_WORKBENCH-FE_0001133443": "Click Discard and your editing will be discarded",
      "P_YS_PF_WORKBENCH-FE_0001133444": "At the end of each month and each year, we always have to work overtime on various financial statements.",
      "P_YS_PF_WORKBENCH-FE_0001133441": "Enter your personal information so that we can get in touch with you",
      "P_YS_PF_MANAGER-FE_0001087807": "Allow Users to Log Out ",
      "P_YS_PF_WORKBENCH-FE_0001133442": "Return to Homepage",
      "P_YS_PF_WORKBENCH-FE_0001133447": "Removed successfully",
      "P_YS_PF_WORKBENCH-FE_0001133448": "Homepage",
      "P_YS_PF_MANAGER-FE_0001087804": "Service name cannot be more than 6 Chinese chars or 12 letters.",
      "P_YS_PF_WORKBENCH-FE_0001133445": "Could not access Help Center from your network. Please switch to Internet and try again.",
      "P_YS_PF_WORKBENCH-FE_0001133446": "You can find all the functions here.",
      "P_YS_PF_GZTSYS_0000012589": "Approval",
      "P_YS_FED_EXAMP_0000020600": "Yes",
      "P_YS_PF_WORKBENCH-FE_0001133450": "Photo",
      "P_YS_PF_WORKBENCH-FE_0001133451": "Confirm to remove the selected user?",
      "P_YS_OA_XTMENHU_0000046775": "Select Friends",
      "P_YS_OA_XTMENHU_0000046539": "Birthday Wishes",
      "P_YS_PF_MANAGER-FE_0001087811": ">2000",
      "P_YS_HR_HRJXF_0000059541": "Verification failed",
      "P_YS_PF_MANAGER-FE_0001087817": "Please enter enterprise address.",
      "P_YS_PF_WORKBENCH-FE_0001133454": "On-site Demonstration",
      "P_YS_PF_WORKBENCH-FE_0001133452": " Apps will expire soon. ",
      "P_YS_PF_GZTPRE_0000071076": "Owning Application",
      "P_YS_PF_WORKBENCH-FE_0001133453": "Running the business no longer makes me anxious.",
      "P_YS_SD_UDHWS_0000070064": "Enterprise Name",
      "P_YS_PF_MANAGER-FE_0001087814": "Use App Navigation",
      "P_YS_OA_XTMENHU_0000046569": "Notifications",
      "P_YS_PF_MANAGER-FE_0001087809": "Macau Special Administrative Region of China",
      "P_YS_OA_XTWENKU_0000035585": "No data",
      "P_YS_FED_FW_0000021973": "Filter Criteria",
      "P_YS_PF_MANAGER-FE_0001087820": "1001-2000",
      "P_YS_PF_MANAGER-FE_0001087827": "Native App",
      "P_YS_PF_MANAGER-FE_0001087829": "App Native Navigation",
      "P_YS_PF_MANAGER-FE_0001087823": "Select default icon",
      "P_YS_PF_GZTSYS_0001010812": "Enterprise Certification",
      "P_YS_OA_XTMENHU_0000046557": "Unread",
      "P_YS_PF_WORKBENCH-FE_0001291199": "Happy birthday!",
      "P_YS_PF_PRINT_0000106816": "Middle",
      "P_YS_OA_XTMENHU_0000046556": "Please enter the network address of the custom component",
      "P_YS_FED_UCFBASEDOC_0000025589": "Published successfully",
      "P_YS_FED_FW_0000021744": "Greater than or equal to",
      "P_YS_FED_UCFBASEDOC_0000025345": "Move to",
      "P_YS_SD_SDMBF_0000141689": "Expiration time:",
      "P_YS_PF_ECON-FE_0001309288": "顯示",
      "P_YS_SCM_USTOCK-UI_0000173932": "This Quarter",
      "P_YS_PF_WORKBENCH-FE_0001208723": "Zoom In",
      "P_YS_PF_WORKBENCH-FE_0001228574": "You Secretary",
      "P_YS_PF_WORKBENCH-FE_0001208722": "Extra Large",
      "P_YS_SD_SDMAUF_0000153108": "Customer Service",
      "P_YS_OA_XTLCZX_0000030093": "Submitted On",
      "P_YS_OA_XTTMCENTER_0000035047": "YouZone",
      "P_YS_OA_XTTMCENTER_0000035043": "Link",
      "P_YS_SD_SDMBF_0000144965": "Display Method",
      "P_YS_SD_SDMBF_0000142527": "Please click.",
      "P_YS_HR_HRJQ_0000031105": "Unprocessed",
      "P_YS_PF_WORKBENCH-FE_0001228566": "Ad Details",
      "P_YS_PF_WORKBENCH-FE_0001228565": "Digital Contents",
      "P_YS_OA_XTTMCENTER_0000035058": "Yonyou Cloud Official Website",
      "P_YS_PF_WORKBENCH-FE_0001274451": "sed execution error",
      "P_YS_PF_WORKBENCH-FE_0001274450": "Temp Folder Location",
      "P_YS_HR_HRRCPDF_0000071164": "Standards",
      "P_YS_FED_FW_0000022192": "Move Up",
      "P_YS_PF_WORKBENCH-FE_0001228592": "Large",
      "P_YS_FED_FW_0000022197": "Expand",
      "P_YS_PF_WORKBENCH-FE_0001228597": "Confirm to restore the default settings?",
      "P_YS_PF_WORKBENCH-FE_0001228599": "Edit Card",
      "P_YS_PF_WORKBENCH-FE_0001228598": "Restoration Prompt",
      "P_YS_OA_XTTMCENTER_0000035064": "Download",
      "P_YS_FED_EXAMP_0000019832": ", ",
      "P_YS_OA_XTTMCENTER_0000035062": "Download Client",
      "P_YS_OA_XTMENHUCMS_0000045206": "Header Height",
      "P_YS_PF_WORKBENCH-FE_0001274449": "Delete Directory",
      "P_YS_PF_WORKBENCH-FE_0001274448": "Capture Multilingual Addr.",
      "P_YS_PF_WORKBENCH-FE_0001243713": "Function Guide",
      "P_YS_PF_MANAGER-FE_0001116216": "Visible to All Staff",
      "P_YS_PF_METADATA_0000087187": "Field",
      "P_YS_PF_METADATA_0000086098": "User Info",
      "P_YS_PF_WORKBENCH-FE_0001228589": "Card Property Settings",
      "P_YS_PF_SOCD-S_0000183662": "Enterprise Mgmt",
      "P_YS_SD_UDHMC_0000050938": "Skip",
      "P_YS_SD_UDHWN_0000121225": "Last Month",
      "P_YS_IPAAS_UCG_0001064325": "Construction Industry",
      "P_YS_PF_GZTSYS_0000011889": "Honor",
      "P_YS_PF_WORKBENCH-FE_0001421901": "Search person, service, or others",
      "P_YS_HR_HRJQ_0000031143": "None",
      "P_YS_OA_XTTXL_0000045969": "Message",
      "P_YS_IPAAS_UCG_0001064571": "Leasing and Business Services",
      "P_YS_FED_FW_0000021923": "OK",
      "P_YS_SD_UDHWN_0000120386": "Apply Now",
      "P_YS_IPAAS_UCG_0001064578": "Finance and Insurance Industries",
      "P_YS_PF_GZTSYS_0000012505": "Basic Settings",
      "P_YS_IPAAS_UCG_0001009417": "DingTalk",
      "P_YS_PF_WORKBENCH-FE_0001232883": "Switch to New Version",
      "P_YS_PF_WORKBENCH-FE_0001232882": "My Workbench",
      "P_YS_PF_WORKBENCH-FE_0001469084": "Close Right",
      "P_YS_PF_WORKBENCH-FE_0001473568": "Error in the operation of widget %c:",
      "P_YS_OA_XTTMCENTER_0000035034": "Contact Us",
      "P_YS_HR_HRRCPDF_0000071186": "Yonyou Network Tech Co., Ltd.",
      "P_YS_HR_HRPXF_0000056053": "Team Name",
      "P_YS_PF_MANAGER-FE_0001087797": "Allow all users to apply",
      "P_YS_PF_WORKBENCH-FE_1420197852018638872": "pedestrian crossing",
      "P_YS_PF_MANAGER-FE_0001087794": "Name, Mobile No., E-mail Address",
      "P_YS_PF_WORKBENCH-FE_1420197852018638878": "Workbench editor",
      "P_YS_PF_MANAGER-FE_0001087793": "Display Watermark in Contacts",
      "P_YS_PF_MANAGER-FE_0001087796": "PC Client",
      "P_YS_OA_XTLCZX_0000030256": "CC",
      "P_YS_PF_MANAGER-FE_0001087795": "Based on imported reporting relationship",
      "P_YS_PF_WORKBENCH-FE_1420197852018638875": "Suggested picture size is 50-100PX",
      "P_YS_SD_SDMAF_0001008026": "Created successfully.",
      "P_YS_FED_FW_0000021036": "Filter",
      "P_YS_FED_IMP_IOT0000008165": "Delete",
      "P_YS_IPAAS_UCG_0001064009": "Accommodation and Catering Industry",
      "P_YS_FED_FW_0000022366": "Modified successfully",
      "P_YS_DMP_DATAD-FE_0001665639": "New theme",
      "P_YS_PF_WORKBENCH-FE_1420197852018638884": "Recommended height is 22px, and the default is png format.",
      "P_YS_PF_WORKBENCH-FE_1420197852018638887": "Mulv",
      "P_YS_PF_WORKBENCH-FE_1420197852018638881": "Development and debugging",
      "P_YS_IPAAS_UCG_0001064490": "Culture, Sports and Recreation",
      "P_YS_IPAAS_UCG_0001064251": "Health, Social Security and Social Service Industry",
      "P_YS_OA_XTMENHUCMS_0000045299": "Gradient",
      "P_YS_OA_XTMENHUCMS_0000045298": "Picture Style",
      "P_YS_OA_XTMENHUCMS_0001314169": "More Colors",
      "P_YS_PF_WORKBENCH-FE_1420197852018638896": "Tile setting",
      "P_YS_FED_UCFBASEDOC_0000024870": "View Details",
      "P_YS_PF_WORKBENCH-FE_1420197852018638893": "#008c8c_Mars Green",
      "P_YS_PF_AUTHF_0000080113": "10 /page",
      "P_YS_PF_WORKBENCH-FE_1420197852018638899": "Please enter the query criteria to support initials and pinyin.",
      "P_YS_PF_WORKBENCH-FE_0001473777": "Select Analysis Card",
      "P_YS_FED_FW_0000021020": "New",
      "P_YS_OA_XTMENHUCMS_0000045290": "Background Color",
      "P_YS_PF_WORKBENCH-FE_0001413028": "Wrong widget style; widget:",
      "P_YS_PF_WORKBENCH-FE_0001413029": "Vertical Arrangement",
      "P_YS_PF_WORKBENCH-FE_1420197852018638890": "Current background color",
      "P_YS_PF_WORKBENCH-FE_0001413035": "Use the default style",
      "P_YS_OA_XTMENHUCMS_0000045287": "Favorite Apps",
      "P_YS_PF_WORKBENCH-FE_0001413036": "Do not display icon",
      "P_YS_PF_WORKBENCH-FE_0001413037": "Link - New",
      "P_YS_PF_WORKBENCH-FE_0001413038": "Filling Method",
      "P_YS_OA_XTMENHUCMS_0000045285": "Text Color",
      "P_YS_OA_XTMENHUCMS_0000045284": "Icon Size",
      "P_YS_PF_WORKBENCH-FE_0001413031": "Table Arrangement",
      "P_YS_OA_XTMENHUCMS_0000045041": "Component Name",
      "P_YS_PF_WORKBENCH-FE_0001413032": "Set Website LOGO",
      "P_YS_PF_WORKBENCH-FE_0001413033": "User-defined Picture Style",
      "P_YS_PF_WORKBENCH-FE_0001413034": "User-defined Color",
      "P_YS_PF_GZTLOG_0000080270": "Web",
      "P_YS_FED_UCFBASEDOC_0000024866": "Save the latest modification?",
      "P_YS_FED_FW_0000021014": "Enabled",
      "P_YS_PF_WORKBENCH-FE_0001413030": "Picture Transparency",
      "P_YS_PLM_GDS-FE_0001246638": "Uploading file",
      "P_YS_PF_WORKBENCH-FE_0001537178": "March 15, 2017",
      "P_YS_PF_WORKBENCH-FE_0001537177": "Help Center (question mark)",
      "P_YS_PF_WORKBENCH-FE_0001537176": "The inverted triangle next to the question mark",
      "P_YS_SD_SDMBF_0000144334": "Please upload picture",
      "P_YS_FED_EXAMP_0000020397": "Name",
      "P_YS_OA_XTLCZX_0000030206": "Hide",
      "P_YS_FED_FW_0000022168": "Please enter keywords",
      "P_YS_PF_ECON-FE_0001309239": "條",
      "P_YS_PF_AUTHF_0000080120": "15 /page",
      "P_YS_OA_XTLCZX_0000030221": "System",
      "P_YS_IPAAS_UCG_0001064293": "Wholesale and Retail Trade",
      "P_YS_OA_XTMENHUCMS_0001320647": "Preview -",
      "P_YS_PF_WORKBENCH-FE_1420197852018638848": "compact",
      "P_YS_PF_GZTSYS_0000011939": "My Portal",
      "P_YS_FED_FW_0000022179": "Alignment",
      "P_YS_PF_ECON-FE_0001309228": "頁",
      "P_YS_OA_XTLCZX_0000030219": "Search Result",
      "P_YS_SD_SDMAUF_0000152873": "History",
      "P_YS_PF_WORKBENCH-FE_1420197852018638851": "Column-highlight logo",
      "P_YS_SCM_STOCK_0000034523": "Read",
      "P_YS_PF_WORKBENCH-FE_1420197852018638854": "Drag onto the dotted line to fix it to the title area.",
      "P_YS_FED_EXAMP_0000020183": "Close",
      "P_YS_OA_XTMENHUCMS_0001314180": "You are currently in preview status",
      "P_YS_PF_WORKBENCH-FE_0001480260": "Historical Upgrades",
      "P_YS_FED_UCFBASEDOC_0000024820": "To",
      "P_YS_PF_WORKBENCH-FE_1420197852018638857": "oppo green",
      "P_YS_PF_WORKBENCH-FE_1420197852018638863": "Shu Yan LV",
      "P_YS_PF_WORKBENCH-FE_1420197852018638860": "Style setting",
      "P_YS_PF_WORKBENCH-FE_1420197852018638866": "Suggested picture size 0-10",
      "P_YS_OA_XTLCZX_0000030480": "Small",
      "P_YS_OA_XTMENHUCMS_0000045079": "Free Layout",
      "P_YS_FED_EXAMP_0000020188": "Reject",
      "P_YS_OA_XTLCZX_0000030238": "Background Picture",
      "P_YS_PF_WORKBENCH-FE_0001561051": "URL Addr",
      "P_YS_PF_WORKBENCH-FE_1420197852018638869": "Label area setting",
      "P_YS_PF_WORKBENCH-FE_0001561052": "Please enter the independent APPurl",
      "P_YS_PF_WORKBENCH-FE_0001561050": "Independent APP",
      "P_YS_PF_WORKBENCH-FE_0001233364": "Enhanced Personalization Capabilities of Workbench",
      "P_YS_PF_WORKBENCH-FE_0001300847": "To-do Task Display Method",
      "P_YS_PF_WORKBENCH-FE_0001233363": "Support more widgets, more flexible layout, and richer display effects",
      "P_YS_PF_WORKBENCH-FE_0001300848": "Approval Center Button (Agree)",
      "P_YS_PF_WORKBENCH-FE_0001233362": "Portals have been consolidated into workbenches, enabling users to switch between multiple workbenches; the role-based workbenches can help you better complete your work",
      "P_YS_PF_WORKBENCH-FE_0001300849": "Alert and Notification",
      "P_YS_PF_WORKBENCH-FE_0001233361": "Enterprise Account Relocated",
      "P_YS_SD_UDHWN_0000049711": "Theme Color",
      "P_YS_FED_UCFBASEDOC_0000025581": "Rename",
      "P_YS_PF_WORKBENCH-FE_0001233360": "Upgraded Digital Portal",
      "P_YS_PLM_BASE-FE_0001160596": "Top",
      "P_YS_PF_MANAGER-FE_0001087957": "Please enter Android download link",
      "P_YS_FED_EXAMP_0000020115": "Switch",
      "P_YS_OA_XTMENHU_0000046746": "Alerts",
      "P_YS_OA_XTMENHU_0000046742": "Attendance Approval",
      "P_YS_PF_WORKBENCH-FE_0001233359": "Optimized Menu Navigation",
      "P_YS_PF_WORKBENCH-FE_0001233358": "Enterprise Account has been moved to the user drop-down panel",
      "P_YS_PF_WORKBENCH-FE_0001470672": "Add Favorite App",
      "P_YS_PF_WORKBENCH-FE_0001233357": "More powerful workbench configuration capabilities and more efficient menu navigation",
      "P_YS_PF_WORKBENCH-FE_0001233356": "Support Multi-workbench",
      "P_YS_PF_PTDESIGN-FE_0001184496": "Border Radius",
      "P_YS_PF_WORKBENCH-FE_0001233355": "Brand new menu panel with the new search function, supporting quickly adding into Favorites and viewing recently used services",
      "P_YS_PF_WORKBENCH-FE_0001133407": "All History",
      "P_YS_PF_WORKBENCH-FE_0001133408": "Yonyou Cloud, a completely publicized enterprise cloud service, frees me from the traditional operation and maintenance work, so that I can become a builder of the company‘s data assets.",
      "P_YS_PF_WORKBENCH-FE_0001133405": "Start Recording",
      "P_YS_PF_MANAGER-FE_0001087964": "PC Client Homepage",
      "P_YS_PF_WORKBENCH-FE_0001133406": "Person, service, or others",
      "P_YS_OA_XTWEIYOU_0000046270": "Send E-mail at the same time",
      "P_YS_FED_FW_0000021451": "(",
      "P_YS_PF_MANAGER-FE_0001087961": "Favorite App",
      "P_YS_PF_WORKBENCH-FE_0001133409": "Remove from Home",
      "P_YS_PF_WORKBENCH-FE_0001300850": "List Content Config",
      "P_YS_PF_WORKBENCH-FE_0001133400": " Apps have expired. ",
      "P_YS_PF_WORKBENCH-FE_0001300851": "Approval Center Button (Reject)",
      "P_YS_PF_WORKBENCH-FE_0001300852": "By Field App",
      "P_YS_PF_WORKBENCH-FE_0001133403": "Team Settings",
      "P_YS_PF_MANAGER-FE_0001087966": "Display Supr./Sub.",
      "P_YS_PF_WORKBENCH-FE_0001133404": "free me from tedious and repetitive routine tasks.",
      "P_YS_PF_WORKBENCH-FE_0001133401": "Background Color",
      "P_YS_PF_WORKBENCH-FE_0001133402": "More Results",
      "P_YS_PF_WORKBENCH-FE_0001242095": "Not yet added to favorites. Add now",
      "P_YS_PF_WORKBENCH-FE_0001242096": "Don't know",
      "P_YS_PF_GZTSYS_0000013477": "Save",
      "P_YS_PF_METADATA_0000084364": "Request Address",
      "P_YS_PF_GZTSYS_0000015896": "Team Member",
      "P_YS_OA_XTMENHU_0000046739": "My Tasks",
      "P_YS_OA_XTNEIBUQUN_0000046122": "Set Administrator",
      "P_YS_PF_WORKBENCH-FE_0001133418": "Maintaining the company‘s hundreds of servers every day makes me really anxious, may affect the company‘s business development.",
      "P_YS_PF_WORKBENCH-FE_0001133419": "Fill in Information",
      "P_YS_PF_WORKBENCH-FE_0001133416": "Publish to Menu",
      "P_YS_PF_WORKBENCH-FE_0001133417": "Email: diwork@yonyou.com",
      "OA_web_ad_000000095": "Ungrouped",
      "P_YS_PF_WORKBENCH-FE_0001133410": "Trust us, and start digital working right away",
      "P_YS_PF_WORKBENCH-FE_0001133411": "Stop Recording",
      "P_YS_PF_WORKBENCH-FE_0001133414": " ",
      "P_YS_PF_WORKBENCH-FE_0001133415": "The current tenant has turned on the login control policy. The control mode is Verification Code",
      "P_YS_PF_WORKBENCH-FE_0001133412": "Account Mgmt",
      "P_YS_OA_YBMOB_0000036890": "File",
      "P_YS_PF_WORKBENCH-FE_0001133413": "If you want to buy or know more about the product, please call the hotline:",
      "P_YS_OA_XTMENHU_0000046524": "To-dos",
      "P_YS_FED_FW_0000022505": "This Week",
      "P_YS_OA_XTMENHU_0000046523": "Graphic Mode",
      "P_YS_FED_EXAMP_0000020377": "Equal",
      "P_YS_FED_UCFBASEDOC_0000025319": "Verification Code",
      "P_YS_HR_HRJQF_0000054364": "Import finished",
      "P_YS_HR_HRCOREF_0000058092": "Current Person Qty",
      "P_YS_PF_WORKBENCH-FE_0001133429": "Scan QR code to enter the",
      "P_YS_PF_WORKBENCH-FE_0001133427": "Upgrade to Enterprise",
      "P_YS_PF_WORKBENCH-FE_0001133428": "Menu Name",
      "P_YS_OA_XTLCZX_0000030680": "I Initiated",
      "P_YS_OA_XTLCZX_0000030202": "Big",
      "P_YS_PF_WORKBENCH-FE_0001133421": "Yonyou Cloud provides all kinds of digital services that my company needs,",
      "P_YS_PF_WORKBENCH-FE_0001133422": "Global Search",
      "P_YS_FED_EXAMP_0000020395": "Search",
      "P_YS_OA_XTMENHU_0000046750": "Earliest Check-in:",
      "P_YS_PF_WORKBENCH-FE_0001133420": "Email:diwork@yonyou.com",
      "P_YS_IPAAS_UCG_0001064071": "Transportation, Warehousing and Postal Services",
      "P_YS_PF_WORKBENCH-FE_0001133425": "Customization Services",
      "P_YS_PF_WORKBENCH-FE_0001133426": "Professional pre-sales team across the country, ready to go anytime",
      "P_YS_FED_EXAMP_0000020391": "Loading...",
      "P_YS_PF_WORKBENCH-FE_0001133423": "Menu Position",
      "P_YS_PF_WORKBENCH-FE_0001133424": " new App(s).",
      "P_YS_PF_YHTTNT-FE_0001045676": "Display Admin Only",
      "P_YS_PF_WORKBENCH-FE_0001309411": "No summary item",
      "P_YS_FED_EXAMP_0000020385": "Cancel",
      "P_YS_PF_WORKBENCH-FE_0001309412": "Summary Item",
      "P_YS_PF_WORKBENCH-FE_0001309413": "Save Filter Criteria",
      "P_YS_FED_FW_0000022510": "Restore Default",
      "P_YS_OA_XTLCZX_0000030679": "Medium",
      "P_YS_FED_FW_0000022512": "No search result",
      "P_YS_PF_WORKBENCH-FE_0001233366": "Support switching between the old and new version of the workbench at any time",
      "P_YS_PF_WORKBENCH-FE_0001233365": "Switch to Old Version of Workbench",
      "P_YS_PF_WORKBENCH-FE_0001291208": "Happy birthday! ",
      "P_YS_PF_WORKBENCH-FE_0001291209": "Happy birthday! ",
      "P_YS_FED_UCFBASEDOC_0000024690": "Group Name (no more than 4 chars)",
      "P_YS_PF_WORKBENCH-FE_1421570875756380184": "Adjust my language, default business unit, modified business date and workbench version switch here.",
      "P_YS_PF_ANALYTIC-FE_0001651904": "Tab Settings",
      "P_YS_PF_WORKBENCH-FE_1421570875756380187": "Adjust the help center, function guidance, last review and user community here.",
      "P_YS_FED_IMP_IOT0000005499": "All",
      "P_YS_PF_WORKBENCH-FE_1421570875756380181": "Customers' self-built sub-domains are temporarily and uniformly classified into \"ungrouped\", and support will be freely classified into other domain clouds later.",
      "P_YS_SCM_PU_0000028165": "Processing",
      "P_YS_PF_WORKBENCH-FE_0001291202": "Dear friends, happy birthday! We wish you happy every day, good luck every year, and happy forever",
      "P_YS_PF_WORKBENCH-FE_0001291203": "Happy birthday!",
      "P_YS_PF_WORKBENCH-FE_0001291200": "Happy birthday!",
      "P_YS_PF_WORKBENCH-FE_0001291201": "Happy birthday!",
      "P_YS_OA_XTLCZX_0000030607": "Stretch",
      "P_YS_PF_WORKBENCH-FE_0001291206": "Happy birthday!",
      "P_YS_PF_WORKBENCH-FE_0001291207": "Display Dept",
      "P_YS_PF_WORKBENCH-FE_0001291204": "Happy birthday!",
      "P_YS_OA_XTLCZX_0000030605": "Arrange By",
      "P_YS_PF_WORKBENCH-FE_0001291205": "Happy birthday!",
      "P_YS_FED_IMP-DFM-FE_0000020888": "No.",
      "P_YS_FED_IMP-DFM-FE_0000020886": "Copy",
      "P_YS_PF_GZTTMP_0000073996": "Education",
      "P_YS_OA_XTNEIBUQUN_0000046136": "Invite Member",
      "P_YS_FED_EXAMP_0000020566": "WeChat",
      "P_YS_FED_FW_0001515909": "Picture display",
      "P_YS_PF_WORKBENCH-FE_1421570875756380190": "Sorting by dragging is supported. You can drag and drop each common workbench above the dotted line and display it permanently, so as to realize quick switching.",
      "P_YS_FED_UCFBASEDOC_0000025523": "Group name already exists",
      "P_YS_PF_WORKBENCH-FE_0001155883": "Some Apps in the product you purchased will expire soon. Please renew in time",
      "P_YS_PF_WORKBENCH-FE_0001155882": "Some Apps in the product you purchased have expired. Please renew as soon as possible",
      "P_YS_PF_GZTSYS_0000013012": "App Settings",
      "P_YS_FED_IMP-DFM-FE_0000020898": "Cut",
      "P_YS_YYGJFW_YBUC_0001336566": "Help Info",
      "P_YS_OA_XTMENHU_0000046725": "Add Link",
      "P_YS_SD_SDMA_0000041132": "Expired",
      "P_YS_PF_AUTHF_0000112877": "Enter service name",
      "P_YS_OA_XTMENHU_0000046721": "Happy birthday!",
      "P_YS_PF_GZTSYS_0000013245": "Service Type",
      "P_YS_FED_IMP_IOT0000005471": "Prompt",
      "P_YS_PF_MANAGER-FE_0001087788": "Quit from Enterprise",
      "P_YS_OA_XTMENHU_0000046711": "QR Code Invitation",
      "P_YS_FED_EXAMP_0000020345": "Please select",
      "P_YS_FI_CM_0000029607": "Error",
      "P_YS_PF_WORKBENCH-FE_0001470623": "Upload Local Picture",
      "P_YS_FED_EXAMP_0000020344": "No",
      "P_YS_OA_XTMENHU_0000046710": " persons' birthday!",
      "P_YS_PF_WORKBENCH-FE_0001470622": "Select the default picture",
      "P_YS_PF_AUTHF_0000112885": "No result",
      "P_YS_PF_WORKBENCH-FE_0001582371": "Single Sign On",
      "P_YS_PF_GZTSYS_0000109539": "Trad. Chinese",
      "P_YS_FED_UCFBASEDOC_0000025061": "Role Code",
      "P_YS_FED_UIMG_0000157462": "[Hint] If Enterprise Language is empty, then current language will be used",
      "P_YS_FED_UIMG_0000157460": "Current Language",
      "P_YS_FED_UIMG_0000157461": "Enterprise Language",
      "P_YS_PF_GZTSYS_0001155401": "Asset Stocktaking Mobile App",
      "P_YS_FED_FW_0000020988": "Details",
      "message.common.lang0000522": "Deployed successfully",
      "P_YS_OA_XTLCZX_0000030807": "Template",
      "P_YS_HR_HRCM-UI_0001380153": "Page Modeling",
      "P_YS_HR_HRXCF_0000056236": "Re-upload",
      "P_YS_FED_FW_0000020984": "Text",
      "P_YS_FED_UIMG_0000082816": "It's read-only and cannot be deleted",
      "P_YS_FED_UCFBASEDOC_0000025290": "Dept Leave",
      "P_YS_PF_GZTSYS_0000012671": "UOM",
      "000723": "Please enter the URL",
      "P_YS_PF_PRINT_0000057241": "Unit Price in FC Excl. Tax",
      "P_YS_PF_GZTSYS_0000012661": "Attnd App Delegation",
      "P_YS_PF_YS-LOGIN_0001079461": "Sandbox",
      "P_YS_FED_FW_0000020994": "Integer Type",
      "P_YS_PF_GZTVPA_0001126493": "Robot Name",
      "P_YS_FED_UCFBASEDOC_0000025044": "test",
      "P_YS_SD_UDHWN_0000050202": "Help",
      "P_YS_PF_PRINT_0000057698": "Amt in FC Excl. Tax",
      "P_YS_FED_UIMG_0000101674": "Cannot modify the system field as Optional",
      "P_YS_AM_AMC-UI_0000170775": "Tree",
      "P_YS_FED_FW_0000021815": "Right",
      "P_YS_OA_app_xtywlj_0000037579": "Please select data type",
      "P_YS_HR_HRPXF_0000055973": "Publish to",
      "P_YS_PF_GZTSYS_0000012631": "Transa. Type",
      "P_YS_SD_SDMA_0000062157": "Off-shelves",
      "P_YS_PF_GZTSYS_0000015903": "Store",
      "P_YS_PF_TRS-FE_0000103851": "Daily Env.",
      "P_YS_PF_MANAGER-FE_0001087905": "Label Mode",
      "P_YS_FED_IMP_IOT0000007727": "Export",
      "P_YS_PF_MANAGER-FE_0001087903": "Confirm to enable [",
      "P_YS_FED_EXAMP_0000020302": "Uploaded successfully",
      "P_YS_FED_FW_0000021827": "Delete Line",
      "P_YS_FED_FW_0000020973": "Required",
      "P_YS_FED_FW_0000021821": "Default Language",
      "P_YS_PF_GZTSYS_0000015916": "Analysis",
      "P_YS_OA_XTGONGWEN_0000045558": "Please select type",
      "P_YS_SD_UDHMC_0001070473": "Red Theme",
      "P_YS_PF_GZTSYS_0000109292": "Prod. Env.",
      "P_YS_PF_GZTSYS_0000013547": "Purchased Goods Receipt",
      "P_YS_SD_UDHWN_0000120893": "Again",
      "P_YS_PF_GZTSYS_0000012697": "Project",
      "P_YS_FED_FW_0000021631": "Example: YYYY-MM-DD HH:mm:ss",
      "P_YS_FED_UCFBASEDOC_0000025019": "test",
      "P_YS_FED_UCFBASEDOC_0000025016": "Please enter account number",
      "P_YS_FED_FW_0000021875": "Width",
      "P_YS_OA_app_xtyyjm_0000036018": "Please select form",
      "P_YS_FED_MDF-COMPS_0001573108": "物料名稱",
      "P_YS_FED_MDF-COMPS_0001573109": "表体-0528test",
      "P_YS_FI_FINBD-UI_0000161964": "Table Ref",
      "P_YS_FED_MDF-COMPS_0001573106": "組號",
      "P_YS_FED_MDF-COMPS_0001573107": "Move to",
      "P_YS_FED_FW_0000021890": "Japanese",
      "P_YS_OA_XTWENKU_0000035495": "Modify Time",
      "P_YS_FED_MDF-COMPS_0001573100": "ycx文本",
      "P_YS_FED_MDF-COMPS_0001573101": "表头-0528test",
      "P_YS_SD_SDMA_0000062368": "PC",
      "P_YS_FED_MDF-COMPS_0001573104": "應收件數",
      "P_YS_FED_UCFBASEDOC_0000025006": "test",
      "P_YS_FED_MDF-COMPS_0001573105": "客户分类3",
      "P_YS_FED_MDF-COMPS_0001573102": "仓档案",
      "P_YS_FED_UCFBASEDOC_0000025488": "test",
      "P_YS_FED_MDF-COMPS_0001573103": "主計量",
      "P_YS_FED_UCFBASEDOC_0000025485": "test",
      "P_YS_FED_MDF-COMPS_0001573315": "么?",
      "P_YS_PF_PTDESIGN-FE_0001184798": "Card Settings",
      "P_YS_PF_GZTORG_0000035405": "Modified On",
      "P_YS_SD_SDMAUF_0000153043": "Version List",
      "P_YS_PF_PRINT_0000057017": "Effective Date",
      "P_YS_FI_OMC_0001094475": "Current Environment",
      "P_YS_FED_FW_0000021617": "Qty Precision",
      "P_YS_FED_FW_0000021615": "Draft",
      "P_YS_FED_FW_0000021618": "Formula Editor",
      "P_YS_FED_EXAMP_0000020734": ", ",
      "P_YS_HR_HRJXF_0000059668": "Canceled successfully",
      "P_YS_PF_PROCENTER_0000027111": "Modify",
      "P_YS_SCM_PU_0000028543": "Failed to obtain data",
      "P_YS_PF_GZTBDM_0000036354": "Refreshed successfully",
      "P_YS_PF_GZTSYS_0000012688": "Overtime",
      "P_YS_PF_GZTSYS_0000012687": "Attnd Center",
      "P_YS_FED_FW_0000021868": "No data",
      "P_YS_AM_RMM-UI_0001217391": "Appl. Contents",
      "P_YS_PF_GZTSYS_0000012208": "Cloud Conference",
      "P_YS_FED_MDF-COMPS_0001623284": "沙箱许可",
      "P_YS_FED_MDF-COMPS_0001623283": "移動供應鏈",
      "P_YS_FED_MDF-COMPS_0001623286": "空页面",
      "000572": "Datasets",
      "P_YS_FED_MDF-COMPS_0001623285": "点击条目快速预览",
      "P_YS_FED_MDF-COMPS_0001623288": "请选择发布数据",
      "P_YS_FED_MDF-COMPS_0001623287": "确定发布当前参照吗?",
      "P_YS_FED_FW_0000022097": "Replace",
      "P_YS_FED_MDF-COMPS_0001623289": "独立应用和小程序",
      "P_YS_HR_HRYGF_0000058837": "Enumeration",
      "P_YS_PF_GZTSYS_0000012811": "Collaboration",
      "P_YS_PF_METADATA_0001038547": "Left-Tree and Right-Card",
      "P_YS_FED_MDF-COMPS_0001623291": "请选择系统预置",
      "P_YS_PF_GZTSYS_0000011965": "HR Calendar",
      "P_YS_FED_MDF-COMPS_0001623290": "收件人名称",
      "P_YS_FED_MDF-COMPS_0001623293": "包名必须包含至少两个段，由一个或多个点隔开。例如：com.abc，tencent.qq.mm；每个段的字符必须是小写字母、数字或下划线[a-z0-9_]组成；每个段必须以字母开头；",
      "P_YS_FED_MDF-COMPS_0001623292": "在APP工作台中动态显示应用管理中的应用。",
      "P_YS_FED_MDF-COMPS_0001623295": "错误数",
      "P_YS_FED_MDF-COMPS_0001623294": "企業文化",
      "P_YS_FED_MDF-COMPS_0001623297": "沙箱名称",
      "P_YS_FED_MDF-COMPS_0001623296": "keystore密码",
      "P_YS_FI_CM_0000030058": "Doc No.",
      "P_YS_FED_MDF-COMPS_0001623299": "进入应用构建专业版",
      "P_YS_FED_MDF-COMPS_0001623298": "已选择插件",
      "P_YS_IPAAS_UCG_0001009331": "Developer",
      "P_YS_OA_XTWENKU_0000035693": "Size",
      "P_YS_PF_TRS_0000034730": "Application Code",
      "P_YS_HR_HRRCPDF_0000073224": "Creator:",
      "P_YS_YYGJFW_YBUC_0001333705": "Environment Config",
      "P_YS_FED_MDF-COMPS_0001573117": "提交時間",
      "P_YS_FED_MDF-COMPS_0001573118": "自定義項目測試202008060001",
      "P_YS_FED_MDF-COMPS_0001623266": "应用编码不能以数字或者下划线开头!",
      "P_YS_FED_MDF-COMPS_0001623265": "掃碼入職",
      "P_YS_FI_CM_0000029070": "Function",
      "P_YS_FED_MDF-COMPS_0001623268": "重新打包",
      "P_YS_FED_MDF-COMPS_0001623267": "暂无可用沙箱",
      "P_YS_FED_MDF-COMPS_0001623269": "请选择表单分类",
      "P_YS_HR_HRYGF_0000058815": "Template Type",
      "P_YS_FI_AR_0000058963": "Due Day",
      "P_YS_SD_UDHWN_0000120238": "Not set",
      "P_YS_SD_SDSD-FE_0001486556": "備註",
      "P_YS_SD_SDMA_0000038452": "Operator",
      "P_YS_PF_GZTSYS_0000135330": "T+ Service",
      "P_YS_FED_MDF-COMPS_0001573111": "ycx檔案類型",
      "P_YS_FED_MDF-COMPS_0001573112": "likun-表体自定义项[文本]",
      "P_YS_FED_MDF-COMPS_0001573110": "枚舉",
      "P_YS_SD_SDMA_0000040767": "Order Placement Time",
      "P_YS_FED_MDF-COMPS_0001573115": "上游單據號",
      "P_YS_FI_FCWEB_0001047524": "Drag and drop to adjust the order",
      "P_YS_FED_MDF-COMPS_0001573116": "保質期單位",
      "P_YS_FED_MDF-COMPS_0001573113": "物料編碼",
      "P_YS_FED_MDF-COMPS_0001623271": "我上传的插件",
      "P_YS_FED_MDF-COMPS_0001573114": "倉庫",
      "P_YS_FED_MDF-COMPS_0001623270": "开发沙箱(",
      "P_YS_FED_MDF-COMPS_0001623273": "请上传选中图标",
      "P_YS_FED_MDF-COMPS_0001623272": "普通发布",
      "P_YS_FED_MDF-COMPS_0001623275": "系统自建",
      "P_YS_FED_MDF-COMPS_0001623274": "視頻會議",
      "P_YS_FED_MDF-COMPS_0001623277": "指定页面",
      "P_YS_PF_GZTSYS_0000012809": "Asset Mgmt",
      "P_YS_FED_MDF-COMPS_0001623276": "请输入申请说明",
      "P_YS_FED_MDF-COMPS_0001623279": "请输入打包信息",
      "P_YS_FED_MDF-COMPS_0001623278": "打包序号",
      "P_YS_FED_EXAMP_0000019958": "Amt",
      "P_YS_OA_app_xtyyjm_0000036087": "Import File",
      "P_YS_OA_XTWENKU_0000035676": "Created At",
      "P_YS_FED_MDF-COMPS_0001623280": "青啤",
      "P_YS_PF_GZTSYS_0000012801": "Benefits",
      "P_YS_OA_XTMENHUCMS_0000045312": "Default Icon",
      "P_YS_FED_MDF-COMPS_0001623282": "请选择申请领域",
      "P_YS_FED_EXAMP_0000020703": "Specification",
      "P_YS_FED_MDF-COMPS_0001623281": "新建移动页面",
      "P_YS_FED_MDF-COMPS_0001623482": "该应用已关联",
      "P_YS_FED_MDF-COMPS_0001623481": "扩展行数范围1-10",
      "P_YS_FED_MDF-COMPS_0001623484": "分析模板",
      "P_YS_FED_MDF-COMPS_0001623483": "PC端页面",
      "P_YS_SCM_STOCK_0000034389": "Line",
      "P_YS_FED_MDF-COMPS_0001623486": "适用组织职能",
      "P_YS_FED_MDF-COMPS_0001623485": "描述文件",
      "P_YS_FED_MDF-COMPS_0001623488": "别名(alias)",
      "P_YS_FED_MDF-COMPS_0001623487": "打包需要约30分钟,请稍后刷新页面",
      "P_YS_FED_MDF-COMPS_0001623489": "工作彙報",
      "P_YS_IPAAS_APIC-FE_0001577482": "Permission Description",
      "P_YS_OA_PACK_0001100778": "Client Configuration",
      "P_YS_PF_PRINT_0000065739": "Matl SKU Code",
      "P_YS_PF_GZTSYS_0000012611": "Feedbacks",
      "P_YS_FI_FCWEB_0001047707": "Generate Template",
      "P_YS_FED_FW_0000020949": "Trad. Chinese",
      "P_YS_YYGJFW_YBUC_0001333755": "Environment Address",
      "P_YS_YYGJFW_YBUC_0001335933": "Set as Admin",
      "P_YS_HR_HRJXF_0000059247": "Add Member",
      "P_YS_FED_MDF-COMPS_0001623491": "闪屏",
      "P_YS_FI_YYFI-UI_0000167698": "Other Goods Receipt Doc List Region",
      "P_YS_PF_GZTSYS_0000012858": "Meeting",
      "P_YS_FED_MDF-COMPS_0001623490": "引用已有",
      "P_YS_FED_MDF-COMPS_0001623493": "智能取件櫃",
      "P_YS_FED_MDF-COMPS_0001623492": "入職",
      "P_YS_PF_GZTSYS_0001146583": "Mobile Workbench",
      "P_YS_FED_MDF-COMPS_0001623495": "立即开始",
      "P_YS_HR_HRYGF_0000058801": "Select entity",
      "P_YS_FED_MDF-COMPS_0001623494": "採購入庫",
      "P_YS_FED_MDF-COMPS_0001623497": "管理应用 (删改) | 管理成员 (增删) | 开发应用",
      "P_YS_PF_PTDESIGN-FE_0001184504": "Page name is required",
      "P_YS_FED_MDF-COMPS_0001623496": "友空间扫码",
      "P_YS_FED_MDF-COMPS_0001623499": "请输入自定义链接",
      "P_YS_SD_SDMA-UI_0001101601": "Application Status",
      "P_YS_FED_MDF-COMPS_0001623498": "提供人",
      "P_YS_SD_SDSD-FE_0001491730": "數量",
      "P_YS_PF_GZTSYS_0000012867": "Continuous Performance",
      "P_YS_OA_XTLCZX_0000030199": "Third-Party",
      "P_YS_FED_MDF-COMPS_0001623460": "描述文件名称",
      "P_YS_FED_MDF-COMPS_0001623462": "計劃盤點",
      "P_YS_FED_EXAMP_0000019909": "Business Type",
      "P_YS_FED_MDF-COMPS_0001623461": "下载插件模版",
      "P_YS_SD_SDMB_0000151538": "From",
      "P_YS_FED_MDF-COMPS_0001623464": "请选择应用引擎",
      "P_YS_FED_MDF-COMPS_0001623463": "请选中分组",
      "P_YS_FED_MDF-COMPS_0001623466": "获取当前登陆用户id失败",
      "P_YS_FED_MDF-COMPS_0001623465": "小图卡片",
      "P_YS_FED_MDF-COMPS_0001623468": "请搜索登录名",
      "P_YS_FED_MDF-COMPS_0001623467": "展示时长",
      "P_YS_FED_EXAMP_0000019901": "Update successful",
      "P_YS_FED_MDF-COMPS_0001623469": "确定删除插件吗？",
      "P_YS_FED_FW_0001573430": "! 重复的control对象为:%o",
      "P_YS_SCM_UIT-UI_0000174857": "Enterprises Profiling",
      "P_YS_FED_FW_0001573427": "{\\\"1\\\":\\\"年\\\",\\\"2\\\":\\\"月\\\",\\\"6\\\":\\\"天\\\"}",
      "P_YS_FED_IMP_IOT0000011205": "Please enter description",
      "P_YS_FED_FW_0001573428": "[{\\\"key\\\":\\\"1\\\",\\\"nameType\\\":\\\"text\\\",\\\"value\\\":\\\"年\\\"},{\\\"key\\\":\\\"2\\\",\\\"nameType\\\":\\\"text\\\",\\\"value\\\":\\\"月\\\"},{\\\"key\\\":\\\"6\\\",\\\"nameType\\\":\\\"text\\\",\\\"value\\\":\\\"天\\\"}]",
      "P_YS_FED_FW_0001573429": "获取删除之后的数据",
      "P_YS_FED_MDF-COMPS_0001623471": "沙箱是特殊的企业租户。",
      "P_YS_FED_MDF-COMPS_0001623470": "申请领域",
      "P_YS_FED_MDF-COMPS_0001623473": "列表结构",
      "P_YS_FED_MDF-COMPS_0001623472": "銷售下單",
      "P_YS_FED_MDF-COMPS_0001623475": "添加移动端",
      "P_YS_FED_UIMG_0001071360": "Fields created by calculation cannot be set as Required",
      "P_YS_FED_MDF-COMPS_0001623474": "删除成员后，该成员创建的内容仍将保留",
      "P_YS_FED_MDF-COMPS_0001623477": "请先启用闪屏再进行配置~",
      "P_YS_FED_MDF-COMPS_0001623476": "使用许可",
      "P_YS_FED_MDF-COMPS_0001623479": "应用图标大小不能超过1M",
      "P_YS_FED_MDF-COMPS_0001623478": "发布终端",
      "P_YS_PF_BD-UI_0001049486": "One master and many sons",
      "P_YS_FED_FW_0001573431": "[bill-setting] [BillModal] [updateGridModelDataValueByCRefRetId()] 获取参照返回值ERROR:[",
      "P_YS_FED_FW_0001573432": "[bill-setting-new] [BillModal] [updateGridModelDataValueByCRefRetId()] 获取参照返回值ERROR:[",
      "P_YS_FED_FW_0001573433": "%c[bill-setting] [redux billmodal] [mergeDirtyDataInModalDataOriginal()] 重复的cName",
      "P_YS_PF_GZTSYS_0000012602": "Enterprise Introduction",
      "P_YS_FED_FW_0000020935": "Failed",
      "P_YS_PF_GZTSYS_0000135360": "Assets Stocktaking",
      "P_YS_FED_FW_0001573434": ",阻止执行",
      "P_YS_PF_GZTSYS_0000012601": "Business Travel",
      "P_YS_PF_TRS_0000034719": "Application Name",
      "P_YS_FED_FW_0001573435": "渲染表格方法与组件",
      "P_YS_PF_PROCENTER_0000022745": "Call",
      "P_YS_FED_FW_0001573436": "参照缺少必要配置cRefRetId、cRefType",
      "P_YS_FED_FW_0001573437": "获取下标为",
      "P_YS_PF_GZTORG_0000035493": "Open Status",
      "P_YS_SD_UDHLIB_0000133243": "Enabling",
      "P_YS_FED_MDF-COMPS_0001623480": "抽取开发数据",
      "P_YS_PF_GZTSYS_0000012605": "Dept O/T",
      "P_YS_PF_PRINT_0000056946": "Header User-defined Field 14",
      "P_YS_OA_app_xtyyjm_0000035922": "Page Name",
      "P_YS_FED_MDF-COMPS_0001623561": "应用卡片预览",
      "P_YS_PF_PRINT_0000056943": "Header User-defined Field 10",
      "P_YS_FED_MDF-COMPS_0001623560": "雲直播",
      "P_YS_FED_MDF-COMPS_0001623321": "未选择用户",
      "P_YS_FED_MDF-COMPS_0001623563": "需跳转到证书管理页面",
      "P_YS_FED_MDF-COMPS_0001623320": "基本属性",
      "P_YS_FED_MDF-COMPS_0001623562": "Description plug-in",
      "P_YS_FED_MDF-COMPS_0001623323": "描述文件被使用中，无法删除",
      "P_YS_FED_MDF-COMPS_0001623565": "请输入来源租户",
      "P_YS_FED_MDF-COMPS_0001623322": "帮助你自动完成重复耗时的任务, 提高工作效率。",
      "P_YS_FED_MDF-COMPS_0001623564": "工作台地址",
      "P_YS_FED_MDF-COMPS_0001623325": "可用沙箱",
      "P_YS_FED_MDF-COMPS_0001623567": "关联已有APP或小程序，关联后将采用关联对象配置。",
      "P_YS_FED_MDF-COMPS_0001623324": "请选择创建自",
      "P_YS_FED_MDF-COMPS_0001623566": "应用显示规则",
      "P_YS_FED_MDF-COMPS_0001623327": "在APP工作台中固定显示白名单中的应用。",
      "P_YS_FED_MDF-COMPS_0001623569": "用友云小程序",
      "P_YS_FED_MDF-COMPS_0001623326": "可选服务",
      "P_YS_FED_MDF-COMPS_0001623568": "新建参照",
      "P_YS_FED_MDF-COMPS_0001623329": "已扩展",
      "P_YS_FED_MDF-COMPS_0001623328": "请输入文件名称搜索",
      "P_YS_PF_GZTTMP_0000078208": "Transa. Pcs",
      "P_YS_YYGJFW_YBUC_0001336020": "Please select an app",
      "P_YS_SD_UDHWS_0000066531": "Qty (Pri. UOM)",
      "P_YS_FED_FW_0000022004": "View",
      "P_YS_FED_FW_0000022489": "Today",
      "P_YS_FI_FCWEB_0001324088": "Entries not connected to the multilingual center:",
      "P_YS_FED_FW_0000022003": "Please select lines to move down",
      "P_YS_FI_FCWEB_0001324089": "Not exist. Please check the entry",
      "P_YS_PF_PRINT_0000056949": "Header User-defined Field 12",
      "P_YS_OA_XTWENKU_0000035721": "Project Name",
      "P_YS_PF_PRINT_0000056947": "Header User-defined Field 13",
      "P_YS_HR_HRSSC_0000135821": "Failed to cancel",
      "P_YS_FED_MDF-COMPS_0001623570": "页面发布",
      "P_YS_PF_PRINT_0000056956": "Header User-defined Field 18",
      "P_YS_FED_MDF-COMPS_0001623330": "隐私协议地址",
      "P_YS_FED_MDF-COMPS_0001623572": "启用失败！",
      "P_YS_PF_PRINT_0000057801": "Header User-defined Field 8",
      "P_YS_SCM_STOCK_0000034476": "Doc",
      "P_YS_FED_MDF-COMPS_0001623571": "智能發件櫃",
      "P_YS_PF_PRINT_0000057800": "Header User-defined Field 9",
      "P_YS_FED_MDF-COMPS_0001623332": "調班",
      "P_YS_FED_MDF-COMPS_0001623574": "工作台地址不能为空！",
      "P_YS_FED_MDF-COMPS_0001623331": "不超2MB",
      "P_YS_FED_MDF-COMPS_0001623573": "别名密码",
      "P_YS_FED_MDF-COMPS_0001623334": "今日错误数",
      "P_YS_FED_MDF-COMPS_0001623576": "未找到相关插件",
      "P_YS_PF_PRINT_0000056950": "Header User-defined Field 11",
      "P_YS_FED_MDF-COMPS_0001623333": "PC端页面名称",
      "P_YS_FED_MDF-COMPS_0001623575": "客戶檔案",
      "P_YS_FED_MDF-COMPS_0001623336": "启用状态不允许删除，请停用后删除",
      "P_YS_FED_MDF-COMPS_0001623578": "只查看上架数据",
      "P_YS_FED_MDF-COMPS_0001623335": "没有选中",
      "P_YS_FED_MDF-COMPS_0001623577": "删除pc端",
      "P_YS_PF_METADATA_0000087362": "App Icon",
      "RPA_L27_00050068": "Robot Running Status",
      "P_YS_FED_MDF-COMPS_0001623338": "銷售發貨",
      "P_YS_FED_MDF-COMPS_0001623337": "应用code不存在，请联系管理员！",
      "P_YS_FED_MDF-COMPS_0001623579": "确定要删除服务吗?",
      "P_YS_FED_MDF-COMPS_0001623339": "下载日志",
      "P_YS_PF_GZTSYS_0000134847": "Payslip",
      "P_YS_OA_XTSPZX_0000034640": "Web",
      "Running": "Running",
      "P_YS_SD_SDMA-UI_0001101654": "Terminal",
      "P_YS_FED_FW_0000022255": "Add",
      "P_YS_FED_FW_0000022256": "No line selected",
      "P_YS_FED_UCFBASEDOC_0000024994": "test",
      "P_YS_PF_PRINT_0000056958": "Header User-defined Field 17",
      "P_YS_FED_UCFBASEDOC_0000024990": "Password",
      "P_YS_SCM_USTOCK-UI_0001025485": "Required. Please specify SKU",
      "P_YS_OA_XTLCZX_0000030159": "Approved At",
      "P_YS_FED_MDF-COMPS_0001623541": "请搜素",
      "P_YS_PF_GZTLOG_0000025980": "List",
      "P_YS_OA_app_xtyyjm_0000035947": "New App",
      "P_YS_FED_MDF-COMPS_0001623540": "无限制：",
      "P_YS_FED_MDF-COMPS_0001623301": "社交溝通",
      "P_YS_FED_MDF-COMPS_0001623543": "]将被刷新",
      "P_YS_FED_MDF-COMPS_0001623300": "搜索领域名称",
      "P_YS_FED_MDF-COMPS_0001623542": "说明：上传图片尺寸建议为750*244px，图片支持JPG/PNG格式，大小不超过300KB",
      "P_YS_PF_METADATA_0000085152": "System Version",
      "P_YS_FED_MDF-COMPS_0001623303": "），继续保存将覆盖已有APP。",
      "P_YS_FED_MDF-COMPS_0001623545": "穿梭表参照",
      "P_YS_FED_MDF-COMPS_0001623302": "确定带数据权限发布当前参照吗?",
      "P_YS_FED_MDF-COMPS_0001623544": "长度应小于",
      "P_YS_FED_MDF-COMPS_0001623305": "匿名",
      "P_YS_FED_MDF-COMPS_0001623547": "请选择沙箱许可",
      "P_YS_FED_MDF-COMPS_0001623304": "开屏页面大小不能超过2M",
      "P_YS_FED_MDF-COMPS_0001623546": "生成示例应用",
      "P_YS_FED_MDF-COMPS_0001623307": "应用引擎",
      "P_YS_FED_MDF-COMPS_0001623549": "主子孙",
      "P_YS_FED_MDF-COMPS_0001623306": "新建沙箱",
      "P_YS_FED_MDF-COMPS_0001623548": "开发租户",
      "P_YS_FED_MDF-COMPS_0001623309": "首页地址不能为空！",
      "P_YS_FED_MDF-COMPS_0001623308": "扩展成功",
      "P_YS_OA_XTYUNHUIWU_0000168712": "Copied to Clipboard",
      "P_YS_FED_UCFBASEDOC_0000024747": "test",
      "P_YS_FED_UCFBASEDOC_0000024986": "Import",
      "P_YS_FED_FW_0000022228": "Column Name",
      "P_YS_FED_UCFBASEDOC_0000024742": "test",
      "P_YS_PF_GZTSYS_0001122252": "Mobile Supply Chain",
      "P_YS_FED_MDF-COMPS_0001623550": "刷新沙箱",
      "P_YS_FED_MDF-COMPS_0001623310": "添加证书文件",
      "P_YS_FED_MDF-COMPS_0001623552": "按结构默认生成",
      "P_YS_FED_MDF-COMPS_0001623551": "查企業",
      "P_YS_FED_MDF-COMPS_0001623312": "权限说明：管理沙箱(编辑、删除、导入用户)",
      "P_YS_FED_MDF-COMPS_0001623554": "业务对象（",
      "P_YS_SCM_STOCK_0000034216": "Effective To",
      "P_YS_FED_MDF-COMPS_0001623311": "创建自",
      "P_YS_FED_MDF-COMPS_0001623553": "图片尺寸错误，请上传122*122以内的图片",
      "P_YS_FED_MDF-COMPS_0001623314": "打包日志",
      "P_YS_FED_MDF-COMPS_0001623556": "请选择要写入cookie的环境",
      "P_YS_PF_GZTSYS_0000012096": "Purchase Record",
      "P_YS_FED_MDF-COMPS_0001623313": "颁发者",
      "P_YS_FED_MDF-COMPS_0001623555": "应用显示规则是控制当前租户下应用在APP工作台的显示规则。",
      "P_YS_FED_MDF-COMPS_0001623316": "上架版本",
      "P_YS_FED_MDF-COMPS_0001623558": "(参照)",
      "RPA_L27_00050089": "Cancel Deployment",
      "P_YS_FED_MDF-COMPS_0001623315": "应用编码长度应小于50个字符",
      "P_YS_FED_MDF-COMPS_0001623557": "仅发布",
      "P_YS_PF_GZTTMP_0000078679": "Certificate Password",
      "P_YS_FED_MDF-COMPS_0001623318": "需跳转到证书管理页面，当前页面数据会以草稿态保存到 App 列表中。",
      "P_YS_FED_MDF-COMPS_0001623317": "证书下有描述文件被使用中，无法删除",
      "P_YS_FED_MDF-COMPS_0001623559": "雲會務",
      "P_YS_FED_MDF-COMPS_0001623319": "页面列表",
      "P_YS_FED_UCFBASEDOC_0000024737": "test",
      "P_YS_FED_FW_0000022236": "Navigation",
      "P_YS_FED_FW_0000022234": "Portuguese",
      "P_YS_SD_SDMBF_0000145547": "Trend Chart",
      "P_YS_FED_FW_0000022291": "Inv Org",
      "P_YS_HR_HRPUBF_0001009191": "Please enter required items",
      "P_YS_FED_FW_0000022055": "Please input...",
      "P_YS_FED_FW_0000022298": "Save failed",
      "P_YS_FED_MDF-COMPS_0001623521": "打包中",
      "P_YS_FED_MDF-COMPS_0001623520": "下架状态，将该版本设置为下架状态",
      "P_YS_FED_MDF-COMPS_0001623523": "不带默认坏境",
      "P_YS_FED_MDF-COMPS_0001623522": "工資條",
      "P_YS_FED_MDF-COMPS_0001623525": "当前应用不存在！",
      "P_YS_PF_GZTSYS_0001561352": "Data Modeling",
      "P_YS_FED_MDF-COMPS_0001623524": "确定删除当前证书文件吗?删除后不可找回, 请谨慎操作",
      "P_YS_FED_MDF-COMPS_0001623527": "请输入名称或描述",
      "P_YS_FED_MDF-COMPS_0001623526": "获取到期沙箱失败",
      "P_YS_FED_MDF-COMPS_0001623529": "纯文字卡片",
      "P_YS_FED_MDF-COMPS_0001623528": "本操作相当于重建沙箱，当前数据将被重置且操作无法撤回。",
      "RPA_L27_00050095": "Robot Deployment Details",
      "P_YS_FED_UIMG_0000025893": "Please set the field as Display first",
      "P_YS_OA_XTLCZX_0000030325": "Upload",
      "P_YS_FED_MDF-COMPS_0001623530": "用友云隐私协议",
      "P_YS_FED_MDF-COMPS_0001623532": "更新描述",
      "P_YS_FED_MDF-COMPS_0001623531": "系统要求：",
      "P_YS_FED_MDF-COMPS_0001623534": "访问应用构建服务失败",
      "P_YS_FED_MDF-COMPS_0001623533": "测试沙箱",
      "P_YS_FED_MDF-COMPS_0001623536": "请选择使用许可",
      "P_YS_FED_MDF-COMPS_0001623535": "页面编码不能以数字开头!",
      "P_YS_FED_MDF-COMPS_0001623538": "树形表",
      "P_YS_FED_MDF-COMPS_0001623537": "自主创建和维护的应用程序。",
      "P_YS_FED_MDF-COMPS_0001623539": "选择时间段",
      "P_YS_FED_EXAMP_0000020286": "Code",
      "P_YS_HR_HRXCF_0001135149": "Account Password",
      "P_YS_SD_SDMBF_0000145326": "Purchase Qty",
      "P_YS_FED_FW_0000022030": "Verification succeeded",
      "P_YS_PF_PRINT_0000056963": "Header User-defined Field 15",
      "P_YS_PF_GZTSYS_0000134433": "Workflow",
      "P_YS_FED_MDF-COMPS_0001623741": "移动端页面",
      "P_YS_PF_PRINT_0000056961": "Header User-defined Field 16",
      "P_YS_FED_MDF-COMPS_0001623740": "普通上架",
      "P_YS_FED_MDF-COMPS_0001623501": "错误分析详情",
      "P_YS_FED_MDF-COMPS_0001623743": "更新app异常",
      "P_YS_FED_MDF-COMPS_0001623500": "终端系统",
      "P_YS_FED_MDF-COMPS_0001623742": "名称/手机号/邮箱",
      "P_YS_FED_MDF-COMPS_0001623503": "主题配置",
      "P_YS_FED_MDF-COMPS_0001623745": "链接网址",
      "P_YS_FED_MDF-COMPS_0001623502": "留下",
      "P_YS_FED_MDF-COMPS_0001623744": "添加常用",
      "P_YS_FED_MDF-COMPS_0001623505": "公共插件",
      "P_YS_FED_MDF-COMPS_0001623747": "应用编码只能包含数字字母下划线!",
      "P_YS_FED_MDF-COMPS_0001623504": "页面编码不能为空!",
      "P_YS_FED_MDF-COMPS_0001623746": "打开上次访问的应用",
      "P_YS_FED_MDF-COMPS_0001623507": "菜单/类别",
      "P_YS_FED_MDF-COMPS_0001623506": "建造中",
      "P_YS_FED_MDF-COMPS_0001623509": "首页地址",
      "P_YS_FED_FW_0001486020": "[Hint] If the content in the benchmark language (<%=enterprise%>) is not provided, then system will populate the content in the current language (<%=current%>)",
      "P_YS_FED_MDF-COMPS_0001623508": "iOS 11以上",
      "P_YS_PF_PRINT_0000065438": "A/R Pcs",
      "P_YS_OA_XTSPZX_0000034833": "Time",
      "P_YS_FED_FW_0000022040": "Down",
      "P_YS_FED_FW_0000022041": "Operator",
      "P_YS_OA_app_xtyyjm_0000035998": "Created By",
      "P_YS_PF_GZTSYS_0001206993": "Tracked Sales",
      "P_YS_FED_MDF-COMPS_0001623510": "沙箱总数",
      "P_YS_FED_MDF-COMPS_0001623512": "切换后，当前应用权限为所有人可操作",
      "P_YS_PF_PRINT_0000056970": "Header User-defined Field 19",
      "P_YS_FED_MDF-COMPS_0001623511": "同时生成参照",
      "P_YS_FED_MDF-COMPS_0001623514": "应用显示规则：",
      "P_YS_FED_MDF-COMPS_0001623513": "暂无可用许可，请联系销售人员扩展额外许可",
      "P_YS_FED_MDF-COMPS_0001623516": "APP配置文件",
      "P_YS_FED_MDF-COMPS_0001623515": "对象建模",
      "P_YS_FED_MDF-COMPS_0001623518": "存在重复的包名（",
      "P_YS_FED_MDF-COMPS_0001623517": "友空間投屏",
      "P_YS_FED_MDF-COMPS_0001623519": "多环境",
      "P_YS_FED_FW_0000022279": "Unknown",
      "P_YS_PF_GZTSYS_0000134430": "Social",
      "P_YS_HR_HRPUBF_0001009195": "Please enter code and name",
      "P_YS_FED_UCFBASEDOC_0000025464": "test",
      "P_YS_OA_XTMENHUCMS_0000044938": "Navigation Title",
      "P_YS_PF_PRINT_0000057631": "Detail User-defined Field 7",
      "P_YS_FED_MDF-COMPS_0001623721": "确定要取消部署吗?",
      "P_YS_PF_PRINT_0000057630": "Detail User-defined Field 8",
      "P_YS_FED_MDF-COMPS_0001623720": "说明：闪屏一般用于运营推广；上传图片支持JPG/PNG/GIF格式，尺寸建议为1080*1920px，大小不超过300KB",
      "P_YS_FED_MDF-COMPS_0001623723": "APP配置吗？删除后该租户下的APP配置将失效，请谨慎操作！",
      "P_YS_FED_MDF-COMPS_0001623722": "字节长度应小于8",
      "P_YS_FED_MDF-COMPS_0001623725": "白名单控制：",
      "P_YS_FED_MDF-COMPS_0001623724": "针对已购买/已授权的应用,进行特性增强的应用程序。",
      "P_YS_FED_MDF-COMPS_0001623727": "离线工作台",
      "P_YS_FED_MDF-COMPS_0001623726": "系统：",
      "P_YS_FED_MDF-COMPS_0001623729": "请输入沙箱名称",
      "P_YS_FED_MDF-COMPS_0001623728": "页面编码只能包含数字字母下划线!",
      "P_YS_SD_SDMBF_0000145133": "On-shelf Time",
      "P_YS_PF_GZTSYS_0000012497": "Dept Shift",
      "P_YS_FED_FW_0000021796": "Confirm to delete",
      "P_YS_HR_HRJQF_0000054269": "Select File",
      "P_YS_FED_FW_0000021314": "Reference",
      "P_YS_OA_XTLCZX_0000030527": "Form Category",
      "P_YS_OA_XTLCZX_0000030780": "New",
      "P_YS_PF_HELPCENTER-FE_0001135323": "Please select a field",
      "P_YS_FED_MDF-COMPS_0001623730": "树表参照",
      "P_YS_SCM_USTOCK-UI_0001025096": "Revision Info",
      "P_YS_FED_MDF-COMPS_0001623732": "证书OU",
      "P_YS_PF_MANAGER-FE_0001087849": "Confirm to disable [",
      "P_YS_FED_MDF-COMPS_0001623731": "未知租户",
      "P_YS_FED_MDF-COMPS_0001623734": "点击进入官方文档",
      "P_YS_FED_MDF-COMPS_0001623733": "APP配置名称",
      "P_YS_FED_MDF-COMPS_0001623736": "上架版本：",
      "P_YS_FED_MDF-COMPS_0001623735": "(网页端)",
      "P_YS_FED_MDF-COMPS_0001623738": "生成列表页面",
      "P_YS_FED_MDF-COMPS_0001623737": "数据模型111",
      "P_YS_FED_MDF-COMPS_0001623739": "調動",
      "P_YS_SD_SDMB_0000146708": "Download QR Code",
      "P_YS_FED_UIMG_0000025865": "Configuration",
      "P_YS_FED_IMP_IOT0000006019": "Please search",
      "P_YS_PF_PRINT_0000065262": "Matl Name",
      "P_YS_OA_XTLCZX_0000030779": "Please enter title",
      "P_YS_OA_app_xtyyjm_0000035788": "Published",
      "P_YS_OA_XTLCZX_0000030553": "Process",
      "P_YS_PF_GZTSYS_0000012482": "Certificates",
      "P_YS_SD_SDMA_0000123117": "WeChat Mini Program",
      "P_YS_FED_MDF-COMPS_0001623701": "下载离线包模版",
      "P_YS_FED_MDF-COMPS_0001623700": "APP打包",
      "P_YS_FED_MDF-COMPS_0001623703": "终端系统不能为空！",
      "P_YS_FED_MDF-COMPS_0001623702": "错误分析",
      "P_YS_FED_MDF-COMPS_0001623705": "新建app异常",
      "P_YS_FED_MDF-COMPS_0001623704": "行编辑表",
      "P_YS_FED_MDF-COMPS_0001623707": "上传文件格式错误，支持上传的文件格式为：png、jpeg、jpg、svg",
      "P_YS_FED_MDF-COMPS_0001623706": "員工信息",
      "P_YS_FED_MDF-COMPS_0001623709": "同时生成列表",
      "P_YS_FED_MDF-COMPS_0001623708": "新建机器人",
      "P_YS_PF_METADATA_0000085783": "Product Spec",
      "P_YS_YYGJFW_YBUC_0001568362": "扩",
      "P_YS_FED_FW_0000021774": "French",
      "bpm.datatype.start.success": "enable success",
      "P_YS_PF_PRINT_0000057628": "Detail User-defined Field 3",
      "P_YS_PF_PRINT_0000057627": "Detail User-defined Field 4",
      "P_YS_PF_MANAGER-FE_0001204334": "APP Configuration",
      "P_YS_PF_GZTSYS_0001050715": "My Contract",
      "P_YS_PF_PRINT_0000057624": "Detail User-defined Field 5",
      "P_YS_FED_FW_0000021792": "Preview",
      "P_YS_PF_BD-UI_0001049605": "Doc Card",
      "P_YS_PF_PRINT_0000057622": "Detail User-defined Field 6",
      "P_YS_PF_PRINT_0000057621": "Detail User-defined Field 1",
      "P_YS_PF_MANAGER-FE_0001204332": "APP Name",
      "P_YS_PF_PRINT_0000057620": "Detail User-defined Field 2",
      "P_YS_FED_MDF-COMPS_0001623710": "銷售跟單",
      "P_YS_PF_METADATA_0001040427": "Place of Origin",
      "P_YS_FED_MDF-COMPS_0001623712": "描述文件不能为空！",
      "P_YS_FED_MDF-COMPS_0001623711": "组织模式",
      "P_YS_FED_MDF-COMPS_0001623714": "白名单控制",
      "P_YS_FED_MDF-COMPS_0001623713": "发布应用",
      "P_YS_FED_MDF-COMPS_0001623716": "日常盤點",
      "P_YS_FED_MDF-COMPS_0001623715": "Add plug-in",
      "P_YS_FED_MDF-COMPS_0001623718": "开屏页面",
      "P_YS_PF_GZTSYS_0000012489": "Announcement",
      "P_YS_FED_MDF-COMPS_0001623717": "请输入正确网址",
      "P_YS_FED_MDF-COMPS_0001623719": "环境地址不能为空！",
      "P_YS_SD_SDMAUF_0000152718": "Ref Template",
      "P_YS_SD_SDSD-FE_0001491115": "金額",
      "P_YS_FED_FW_0000022452": "Italian",
      "P_YS_SD_UDHAR_0000053500": "Available",
      "P_YS_PF_GZTSYS_0001085814": "YouZone Mirroring",
      "P_YS_PF_GZTSYS_0000012057": "Announcement Settings",
      "P_YS_FED_UCFBASEDOC_0000025656": "test",
      "P_YS_OA_XTMENHUCMS_0000044972": "Uploaded successfully",
      "P_YS_OA_XTMENHU_0000046825": "Upload File",
      "P_YS_PF_PROCENTER_0000027411": "Warehouse File",
      "P_YS_FED_FW_0000022461": "Column Name and Formula are required. Please fill in completely and try again",
      "P_YS_FED_FW_0000021130": "Online",
      "P_YS_PF_GZTTMP_0000073639": "Unlimited",
      "P_YS_YC_BID-PROJECT_0001575935": "Packed",
      "P_YS_FED_EXAMP_0000020201": "Role",
      "P_YS_PF_METADATA_0000086463": "Association",
      "P_YS_OA_XTMENHU_0000046819": "Import User",
      "P_YS_FED_FW_0000022456": "Design",
      "P_YS_FED_UCFBASEDOC_0000025404": "Modified successfully",
      "P_YS_OA_XTLCZX_0000030733": "Completion Time",
      "P_YS_PF_PROCENTER_0000023057": "Inv Unit",
      "RPA_L23_00050024": "Download Dataset",
      "P_YS_PF_GZTSYS_0000013133": "Shift Adjustment",
      "P_YS_FI_CM_0000026206": "Generated",
      "P_YS_PF_GZTSYS_0000012279": "Sales Shipment",
      "P_YS_FED_UIMG_0000025836": "System Preset",
      "P_YS_PF_GZTSYS_0000013382": "Form Template",
      "P_YS_PF_PRINT_0000065047": "Doc ID",
      "P_YS_PF_PRINT_0000065042": "Matl Code",
      "P_YS_PF_GZTSYS_0001066094": "Analysis Card",
      "P_YS_OA_XTSPZX_0000034668": "Please enter name",
      "OA_web_navsetting_000000021": "Me",
      "P_YS_FED_FW_0000021100": "Table",
      "P_YS_OA_XTRIZHI_0000036771": "Description",
      "P_YS_PF_YHTTNT-FE_0001045014": "Note Name",
      "P_YS_PF_GZTSYS_0000012530": "i-Cabinet",
      "P_YS_PF_GZTSYS_0000013621": "Batch No.",
      "P_YS_PF_GZTSYS_0000012532": "Vote",
      "P_YS_YYGJFW_YBUC_0001336704": "Update Content",
      "P_YS_FED_FW_0000021954": "System items are not allowed to be designed",
      "P_YS_PF_METADATA_0000084513": "Plug-in Name",
      "P_YS_FI_FCWEB_0001047450": "Member Type",
      "OA_web_ad_0000000103": "Expiration Time",
      "P_YS_PF_GZTVPA_0001126369": "Canvas",
      "P_YS_PF_GZTSYS_0000056369": "Business Process",
      "P_YS_SD_SDMA_0000063372": "Disabled successfully",
      "P_YS_PF_GZTSYS_0000012545": "Metadata",
      "P_YS_PF_GZTSYS_0000012786": "Dept Leave",
      "P_YS_OA_app_xtywlj_0000037486": "Self-created",
      "P_YS_PF_MANAGER-FE_0001620641": "Cancel",
      "P_YS_FED_IMP_IOT0000005448": "Template Name",
      "P_YS_FED_FW_0000157504": "Tree Ref",
      "P_YS_PF_GUIDE_0001071547": "Single Org",
      "P_YS_PF_GZTSYS_0000012548": "On-boarding Checklist",
      "P_YS_FED_FW_0000021723": "Enabled",
      "P_YS_FED_FW_0000021962": "Display",
      "P_YS_PF_PROCENTER_0000027456": "Manual Input",
      "P_YS_FED_FW_0000021721": "]?",
      "P_YS_FED_UCFBASEDOC_0000025160": "Permission",
      "P_YS_OA_app_xtywlj_0000037693": "Retries",
      "P_YS_FED_FW_0000021935": "Please select a line and add",
      "P_YS_PF_GZTSYS_0000012753": "Work Email",
      "P_YS_FED_UCFORG_0000031966": "Previous",
      "P_YS_YYGJFW_YBUC_0001336920": "你确定删除",
      "P_YS_SD_UDHWN_0000120990": "File uploaded successfully",
      "P_YS_PF_GZTSYS_0001106345": "Data Middle Platform",
      "P_YS_FED_UCFBASEDOC_0000025158": "test",
      "P_YS_PF_GZTSYS_0000012759": "Customer File",
      "P_YS_PF_GZTSYS_0001106342": "Search Enterprise",
      "P_YS_FED_UIMG_0000111351": "Expression is required",
      "P_YS_PF_GZTPRE_0001118984": "Please select metadata",
      "P_YS_PF_GZTSYS_0000012765": "Team",
      "P_YS_FED_FW_0000021947": "Multi-language",
      "P_YS_FED_EXAMP_0000020662": "Copied successfully",
      "P_YS_OA_XTLCZX_0000030711": "Unit",
      "P_YS_OA_XTRIZHI_0000036745": "Visible to All",
      "P_YS_PF_GZTSYS_0000012529": "Transfer-to-regular",
      "P_YS_FED_FW_0000021941": "German",
      "P_YS_FED_UCFBASEDOC_0000025386": "Void",
      "P_YS_FI_CM_0000029774": "Enabled",
      "P_YS_FED_IMP_IOT_0000129734": "Please enter function name",
      "P_YS_FI_FCWEB_0001048516": "Aggregation",
      "P_YS_PF_GZTSYS_0000012580": "Personal Info",
      "P_YS_PF_PROCENTER_0000022808": "Shelf-life Unit",
      "P_YS_PF_GZTORG_0000174966": "Please confirm",
      "P_YS_FED_IMP_IOT0000005413": "Operation",
      "P_YS_OA_XTTMCENTER_0000035086": "Self-created App",
      "P_YS_PF_METADATA_0000085880": "Available Qty",
      "P_YS_FED_FW_0001100234": "The required item <%=confirmMSGFields%> is hidden for the operation. Please re-confirm",
      "P_YS_FED_UCFBASEDOC_0000025138": "test",
      "P_YS_FED_FW_0001050721": "Confirm to delete \"<%=cShowCaptionName%>\"?",
      "P_YS_FED_UCFBASEDOC_0000025134": "test",
      "P_YS_FED_FW_0001050720": "Edit <%=templateName%>",
      "P_YS_SD_SDMAUF_0000153152": "Remaining",
      "P_YS_PF_PRINT_0000057169": "Template ID",
      "P_YS_PF_GZTSYS_0000012594": "Task",
      "P_YS_PF_GZTSYS_0000012590": "Goals",
      "P_YS_PF_GZTSYS_0001207021": "Sales Order",
      "P_YS_FED_FW_0000021769": "Precision",
      "P_YS_IPAAS_APIC-FE_0001577501": "Provider",
      "P_YS_OA_XTTMCENTER_0000035090": "Created By",
      "P_YS_FED_FW_0000021766": "Back",
      "P_YS_CTM_STCT-BE_0001315265": "No data selected",
      "P_YS_HR_HRJXF_0000059300": "Setting failed",
      "P_YS_PF_GZTSYS_0000012561": "Warehouse",
      "P_YS_FED_UIMG_0001050970": "Hiding associated fields will affect runtime. Please operate with caution",
      "P_YS_FED_FW_0000021735": "Korean",
      "P_YS_FED_FW_0000021971": "Display Name",
      "P_YS_FED_FW_0001050719": "View <%=templateName%>",
      "P_YS_PF_GZTSYS_0000015829": "Report",
      "P_YS_IPAAS_UCG_0001112295": "Deployment",
      "P_YS_HR_HRJQ_0000031322": "Wrong format",
      "RPA_L0_00050021": "Designer",
      "P_YS_HR_HRPUBF_0000053688": "Set successfully",
      "P_YS_FED_UIMG_0001050967": "Template verification failed",
      "P_YS_IPAAS_UCG_0001072575": "API Publishing",
      "P_YS_FED_UIMG_0001050968": "The current search result corresponds to entity metadata property, instead of UI metadata property",
      "P_YS_FED_UIMG_0001050969": "Confirm to delete column",
      "P_YS_SD_UDHMC_0001070581": "Blue Theme",
      "P_YS_FED_FW_0000021981": "Default Value",
      "P_YS_FED_UCFBASEDOC_0000025348": "test",
      "P_YS_OA_XTWENKU_0000035597": "Created successfully",
      "P_YS_OA_LCGL_0000037122": "Created successfully",
      "P_YS_FED_FW_0000021743": "Data Type",
      "P_YS_FI_CM_0000029174": "Remarks",
      "P_YS_OA_XTLCZX_0000032030": "Go to ",
      "P_YS_PF_GZTSYS_0000135427": "App Building",
      "P_YS_SCM_USTOCK_0000064365": "Due",
      "P_YS_SD_SDMA_0000060271": "QR Code",
      "P_YS_FED_MDF-COMPS_0001378486": "Benchmark Language",
      "P_YS_PF_MANAGER-FE_0001470871": "Level-1 Menu",
      "P_YS_FED_UCFORG_0000025917": "Failed to enable",
      "P_YS_FED_EXAMP_0000020808": "Name",
      "000206": "Set Variable",
      "P_YS_FED_MDF-COMPS_0001378485": "[Hint] If the content in the Enterprise Acct language (<%=enterprise%>) is not provided, then system will populate the content in the current language (<%=current%>)",
      "P_YS_FED_MDF-COMPS_0001378484": "The current language will be retrieved by default when the benchmark language is null",
      "P_YS_OA_XTTMCENTER_0000035056": "Update Time",
      "P_YS_PF_GZTSYS_0000012709": "Off-boarding",
      "P_YS_OA_LCGL_0000037147": "Imported successfully",
      "P_YS_FED_MDF-COMPS_0001623383": "文庫",
      "P_YS_FED_FW_0000022195": "Required",
      "P_YS_HR_HRJQF_0000053833": "Failed",
      "P_YS_FED_MDF-COMPS_0001623382": "系统应用管理",
      "P_YS_FED_MDF-COMPS_0001623385": "Application history",
      "P_YS_FED_MDF-COMPS_0001623384": "确定删除当前描述文件吗?删除后不可找回, 请谨慎操作",
      "P_YS_FED_MDF-COMPS_0001623387": "应用引擎:",
      "P_YS_FED_MDF-COMPS_0001623386": "错误率",
      "P_YS_FED_MDF-COMPS_0001623389": "图文卡片",
      "P_YS_FED_MDF-COMPS_0001623388": "当前租户未开通专业版！",
      "P_YS_SD_UDHWN_0000120595": " ",
      "P_YS_FED_MDF-COMPS_0001623390": "流程&自动化",
      "P_YS_FED_MDF-COMPS_0001623392": "指纹信息",
      "P_YS_FED_MDF-COMPS_0001623391": "别名（alias）",
      "P_YS_SD_SDMB_0000146297": "User Name/Mobile No./Email",
      "P_YS_FED_MDF-COMPS_0001623394": "管理成员 (增删) | 开发应用",
      "P_YS_FED_MDF-COMPS_0001623393": "部門排班",
      "P_YS_FED_MDF-COMPS_0001623396": "資產盤點移動應用",
      "P_YS_FED_MDF-COMPS_0001623395": "启动闪屏",
      "P_YS_FED_MDF-COMPS_0001623398": "申请已提交",
      "P_YS_FED_MDF-COMPS_0001623397": "签名证书",
      "P_YS_HR_HRJQ_0000031367": "N/A for the Moment",
      "P_YS_FED_MDF-COMPS_0001623399": "请输入应用编码",
      "P_YS_PF_METADATA_0001162380": "Stack Info",
      "RPA_L27_00050006": "Try to configure again",
      "P_YS_PF_METADATA_0001038878": "Variable Value",
      "P_YS_HR_HRPXF_0000056097": "Scan QR Code",
      "P_YS_HR_HRJXF_0000059361": "Parameter Configuration",
      "000652": "Report Settings",
      "P_YS_FED_MDF-COMPS_0001623361": "包名",
      "P_YS_FED_MDF-COMPS_0001623360": "页面编码长度应小于50个字符",
      "P_YS_FED_MDF-COMPS_0001623363": "占用沙箱",
      "P_YS_FED_MDF-COMPS_0001623362": "证书使用中，不能删除",
      "P_YS_FED_MDF-COMPS_0001623365": "未收集到日志信息，请尝试重新打包",
      "P_YS_FED_MDF-COMPS_0001623364": "系统预置功能，包含工作台、通讯录、我的，需登录后使用",
      "P_YS_FED_MDF-COMPS_0001623367": "导入用户发生错误",
      "P_YS_FED_MDF-COMPS_0001623366": "切换后，当前应用权限为仅成员可操作",
      "P_YS_FED_MDF-COMPS_0001623369": "确定新增PC页面吗?",
      "P_YS_FED_MDF-COMPS_0001623368": "长度应小于100个字符",
      "RPA_L27_00050017": "To",
      "P_YS_SD_SDMB_0000138699": "Group List",
      "P_YS_FED_FW_0000021911": "Replace Picture",
      "P_YS_SD_SDSD-FE_0001486259": "日期時間",
      "P_YS_PF_GZTSYS_0000011886": "Library",
      "P_YS_FED_MDF-COMPS_0001573056": "日期时间测试",
      "P_YS_FED_MDF-COMPS_0001573057": "固定資產檔案導出測試",
      "P_YS_PF_PRINT_0000056908": "Header User-defined Field 24",
      "P_YS_SD_SDSD-FE_0001491840": "單據日期",
      "P_YS_PF_PRINT_0000056906": "Header User-defined Field 20",
      "P_YS_PF_PRINT_0000056905": "Header User-defined Field 21",
      "P_YS_FED_MDF-COMPS_0001573058": "z0客户3255830",
      "P_YS_FED_MDF-COMPS_0001623370": "app打包异常",
      "P_YS_FED_MDF-COMPS_0001573059": "表體-0528test",
      "P_YS_FED_MDF-COMPS_0001623372": "请输入扩展行数",
      "P_YS_FED_MDF-COMPS_0001623371": "请使用友空间扫一扫功能预览页面",
      "P_YS_PF_PRINT_0000056911": "Header User-defined Field 22",
      "P_YS_FED_MDF-COMPS_0001623374": "任务取消重试",
      "P_YS_PF_PRINT_0000056910": "Header User-defined Field 23",
      "P_YS_FED_MDF-COMPS_0001623373": "编码不能是特殊字段!",
      "P_YS_FED_MDF-COMPS_0001623376": "未查询到打包的日志信息！",
      "P_YS_FED_MDF-COMPS_0001623375": "已选择成员(",
      "P_YS_FED_MDF-COMPS_0001623378": "Tip:大小不超过20kb，尺寸122px*122px的PNG图标",
      "P_YS_FED_MDF-COMPS_0001623377": "仅成员可见",
      "RPA_L27_00050026": "Deploy Robot",
      "P_YS_FED_MDF-COMPS_0001623379": "今日设备数",
      "P_YS_PF_GZTSYS_0000135219": "Intelligent Analysis",
      "RPA_L27_00050027": "Client",
      "P_YS_FED_MDF-COMPS_0001573060": "庫存組織",
      "P_YS_PF_GZTSYS_0000012745": "User-defined File",
      "P_YS_PF_GZTSYS_0000012744": "Q&A",
      "P_YS_FED_FW_0000021928": "Amt Precision",
      "P_YS_FED_MDF-COMPS_0001573063": "產地",
      "P_YS_FED_MDF-COMPS_0001573064": "ycx档案类型",
      "P_YS_PF_GZTSYS_0000011891": "Video Meeting",
      "P_YS_FED_MDF-COMPS_0001573061": "庫管員",
      "P_YS_PF_GZTSYS_0000012741": "i-Pickup",
      "P_YS_FED_MDF-COMPS_0001573062": "型號",
      "P_YS_PF_GZTSYS_0000012740": "Work Log",
      "P_YS_FED_MDF-COMPS_0001573067": "您对必输项设置了隐藏，请注意！",
      "P_YS_PF_PROCENTER_0000022885": "Shelf-life",
      "P_YS_FED_MDF-COMPS_0001573068": "交易類型",
      "P_YS_FED_MDF-COMPS_0001573065": "NN表頭數值0630",
      "P_YS_FED_MDF-COMPS_0001573066": "庫存單位",
      "P_YS_FED_MDF-COMPS_0001573069": "表頭-0528test",
      "P_YS_FED_MDF-COMPS_0001623381": "确定新增移动页面吗?",
      "P_YS_PF_GZTSYS_0000012748": "Goals",
      "P_YS_FED_MDF-COMPS_0001623380": "增补成功",
      "P_YS_FED_MDF-COMPS_0001623581": "查看上架应用",
      "P_YS_FED_MDF-COMPS_0001623580": "最多可设置24个常用应用，按住拖拽可进行排序",
      "P_YS_FED_MDF-COMPS_0001623341": "嘟嘟雲總機",
      "P_YS_FED_MDF-COMPS_0001623583": "领域开通耗时10-20分钟，请耐心等待",
      "P_YS_FED_MDF-COMPS_0001623340": "移动列表",
      "P_YS_FED_MDF-COMPS_0001623582": "记住密码框",
      "P_YS_FED_UIMG_0001065827": "Cannot perform Sum on the non-numeric fields",
      "P_YS_FED_MDF-COMPS_0001623343": "编辑沙箱",
      "P_YS_FED_MDF-COMPS_0001623585": "不超1MB",
      "P_YS_FED_MDF-COMPS_0001623342": "未勾选的定时规则，将不予不保存，是否继续？",
      "P_YS_FED_MDF-COMPS_0001623584": "权限发布",
      "P_YS_OA_XTWENKU_0000035509": "File Name",
      "P_YS_FED_MDF-COMPS_0001623345": "Android 6.0以上",
      "P_YS_FED_MDF-COMPS_0001623587": "已存在包名（",
      "P_YS_FED_MDF-COMPS_0001623344": "累计错误数",
      "P_YS_FED_MDF-COMPS_0001623586": "请输入页面编码",
      "P_YS_FED_MDF-COMPS_0001623347": "点击查看插件信息",
      "P_YS_FED_MDF-COMPS_0001623589": "证书签名",
      "P_YS_FED_MDF-COMPS_0001623346": "请输入隐私协议地址！",
      "P_YS_FED_MDF-COMPS_0001623588": "请选择组织模式",
      "P_YS_FED_MDF-COMPS_0001623349": "当前应用内的服务将全部停用，请谨慎操作。",
      "P_YS_OA_XTTMCENTER_0000035019": "User Name",
      "P_YS_FED_MDF-COMPS_0001623348": "已关联APP配置",
      "P_YS_SD_URETAIL-UI_0001150860": "Planned Stocktaking",
      "P_YS_PF_BD-UI_0000157890": "Robot",
      "P_YS_PF_GZTVPA_0001190941": "Updatable",
      "P_YS_OA_XTTMCENTER_0000035020": "Operated On",
      "P_YS_FI_FCWEB_0001047849": "Template generated successfully.",
      "P_YS_PF_METADATA_0000084937": "Certificate Name",
      "P_YS_SD_SDMA_0000061789": "Client",
      "P_YS_SCM_STOCK_0000034250": "Revision No.",
      "P_YS_FED_MDF-COMPS_0001623590": "选择上架，前一个上架版本将自动下架",
      "P_YS_FED_MDF-COMPS_0001623350": "应用主页",
      "P_YS_FED_MDF-COMPS_0001623592": "登录方式",
      "P_YS_FED_UCFBASEDOC_0000025199": "Form",
      "P_YS_FED_MDF-COMPS_0001623591": "图片大小不能超过300KB",
      "P_YS_FED_MDF-COMPS_0001623352": "访问APP设置",
      "P_YS_FED_MDF-COMPS_0001623594": "生成单卡页面",
      "P_YS_PF_PROCENTER_0000027079": "User",
      "P_YS_FED_MDF-COMPS_0001623351": "），请到列表上进行修改",
      "P_YS_FED_MDF-COMPS_0001623593": "今日启动次数",
      "P_YS_FED_MDF-COMPS_0001623354": "请选择管理员",
      "P_YS_FED_MDF-COMPS_0001623596": "请输入更新内容",
      "P_YS_FED_MDF-COMPS_0001623353": "请输入PC端页面名称",
      "P_YS_FED_MDF-COMPS_0001623595": "新建微信小程序",
      "P_YS_FED_MDF-COMPS_0001623356": "选择下架，上一个历史上架版本将恢复上架状态",
      "P_YS_FED_MDF-COMPS_0001623598": "不带默认环境",
      "P_YS_FED_MDF-COMPS_0001623355": "PC端名称",
      "P_YS_FED_MDF-COMPS_0001623597": "请上传配置文件",
      "RPA_L27_00050048": "Deployment Details",
      "P_YS_FED_MDF-COMPS_0001623358": "显示隐藏应用",
      "P_YS_FED_UIMG_0000080080": "It' not allowed to hide related fields",
      "P_YS_OA_app_xtyyjm_0000035918": "Associated Config.",
      "P_YS_FED_MDF-COMPS_0001623357": "请输入移动端页面名称",
      "P_YS_FED_MDF-COMPS_0001623599": "】应用？",
      "RPA_L27_00050045": "Standby",
      "P_YS_FED_MDF-COMPS_0001623359": "测试沙箱(",
      "P_YS_PF_GZTSYS_0000012963": "Warehouse Keeper",
      "P_YS_FED_FW_0000021900": "Confirm to delete column [",
      "P_YS_PF_GZTSYS_0000011876": "Schedule",
      "P_YS_OA_XTTXL_0000045984": "Offline",
      "P_YS_PF_GZTSYS_0000011872": "Live Stream",
      "P_YS_SD_URETAIL-UI_0001150858": "Daily Stocktaking",
      "P_YS_PF_GZTSYS_0000012725": "Emp. Info",
      "P_YS_PF_GZTSYS_0000011877": "Topic",
      "P_YS_PF_GZTSYS_0000011879": "Mini-Mail",
      "P_YS_SCM_STOCK_0000034341": "Unit Price",
      "P_YS_FED_MDF-COMPS_0001623680": "删除沙箱",
      "P_YS_FED_FW_0000022371": "Current Language",
      "P_YS_OA_XTLCZX_0000030494": "Collapse",
      "P_YS_FED_MDF-COMPS_0001623440": "证书",
      "P_YS_FED_MDF-COMPS_0001623682": "请先启用轮播图再进行配置~",
      "P_YS_FED_MDF-COMPS_0001623681": "上下架规则",
      "P_YS_FED_MDF-COMPS_0001623442": "已添加用户",
      "P_YS_FED_MDF-COMPS_0001623684": "友空间rrr",
      "P_YS_FED_MDF-COMPS_0001623441": "建议1080*1920px",
      "P_YS_FED_MDF-COMPS_0001623683": "菜单结构",
      "P_YS_FED_FW_0000022130": "System items are not allowed to be deleted",
      "P_YS_OA_app_xtyyjm_0000035805": "Required",
      "P_YS_FED_MDF-COMPS_0001623444": "小程序名称",
      "P_YS_FED_MDF-COMPS_0001623686": "没有选中的实体",
      "P_YS_FED_FW_0000021042": "Finish",
      "P_YS_FED_MDF-COMPS_0001623443": "上传文件格式错误，支持上传的文件格式为：png、jpeg、jpg",
      "P_YS_FED_MDF-COMPS_0001623685": "包名不能为空！",
      "P_YS_FED_MDF-COMPS_0001623446": "确定要删除这条吗？",
      "P_YS_FED_MDF-COMPS_0001623688": "累计启动次数",
      "P_YS_SD_SDSD-FE_0001486293": "個人信息",
      "P_YS_FED_MDF-COMPS_0001623445": "” 已复制到粘贴板",
      "P_YS_FED_MDF-COMPS_0001623687": "全部显示(",
      "P_YS_FED_MDF-COMPS_0001623448": "(移动端)",
      "P_YS_FED_MDF-COMPS_0001623447": "请确认包名是否存在~",
      "P_YS_FED_MDF-COMPS_0001623689": "你确定删除页面吗?",
      "P_YS_SD_SDSD-FE_0001486298": "創建人",
      "P_YS_PF_GZTTMP_0000077237": "Model",
      "P_YS_FED_MDF-COMPS_0001623449": "隐私协议名称",
      "P_YS_PF_GZTSYS_0000134736": "Reimb. App",
      "P_YS_PF_PRINT_0000065776": "Matl SKU Name",
      "P_YS_FED_MDF-COMPS_0001573092": "Please select the line to operate",
      "P_YS_FED_MDF-COMPS_0001573093": "z0客户3627661",
      "P_YS_FED_MDF-COMPS_0001573090": "單據編號",
      "P_YS_FED_MDF-COMPS_0001573091": "likun-表頭自定義項[文本]",
      "P_YS_FED_MDF-COMPS_0001573096": "likun-表體自定義項[文本]",
      "P_YS_PF_PC-UI_0001072138": "For display. Input not required",
      "P_YS_FED_MDF-COMPS_0001573097": "必輸，請精確到sku",
      "P_YS_FED_MDF-COMPS_0001573094": "保質期",
      "P_YS_YYGJFW_YBUC_0001337239": "Page Code",
      "P_YS_FED_MDF-COMPS_0001573095": "wjl测试自定义项",
      "P_YS_FED_MDF-COMPS_0001573098": "wjl測試自定義項",
      "P_YS_FED_MDF-COMPS_0001573099": "件數",
      "P_YS_FED_UCFBASEDOC_0000024885": "test",
      "P_YS_FED_FW_0000022127": "Russian",
      "P_YS_FED_MDF-COMPS_0001623691": "扩展",
      "P_YS_FED_MDF-COMPS_0001623690": "启动次数",
      "P_YS_FED_MDF-COMPS_0001623451": "主子表列表",
      "P_YS_FED_MDF-COMPS_0001623693": "请输入包名",
      "P_YS_FED_MDF-COMPS_0001623450": "长度应小于30个字符",
      "P_YS_FED_MDF-COMPS_0001623692": "错误次数",
      "P_YS_FED_MDF-COMPS_0001623453": "未限制",
      "P_YS_FED_MDF-COMPS_0001623695": "系统插件",
      "P_YS_OA_app_xtyyjm_0000035815": "Enter page name",
      "P_YS_FED_MDF-COMPS_0001623452": "扩展应用",
      "P_YS_FED_MDF-COMPS_0001623694": "该插件已存在相同版本",
      "P_YS_FED_MDF-COMPS_0001623455": "RPA官网",
      "P_YS_FED_MDF-COMPS_0001623697": "请上传默认图标",
      "P_YS_FED_MDF-COMPS_0001623454": "请输入APP配置名称",
      "P_YS_FED_MDF-COMPS_0001623696": "打包信息不能为空！",
      "P_YS_FED_MDF-COMPS_0001623457": "应用名称不能为空!",
      "P_YS_FED_MDF-COMPS_0001623699": "扩展原厂页面",
      "P_YS_FED_MDF-COMPS_0001623456": "结构/首页",
      "P_YS_FED_MDF-COMPS_0001623698": "通用名称",
      "P_YS_FED_MDF-COMPS_0001623459": "推荐主题",
      "P_YS_FED_MDF-COMPS_0001623458": "调度",
      "P_YS_FED_MDF-COMPS_0001209115": "Use of version rules",
      "P_YS_FED_MDF-COMPS_0001209116": "Version rule",
      "P_YS_FED_MDF-COMPS_0001209114": "Use of version rules",
      "P_YS_PF_PROCENTER_0000022686": "Doc Date",
      "P_YS_FED_FW_0000022137": "Item Name",
      "P_YS_FED_UCFBASEDOC_0000025724": "API Authorization",
      "P_YS_FED_UCFBASEDOC_0000024878": "test",
      "P_YS_FED_UCFBASEDOC_0000024874": "Role Name",
      "P_YS_FED_MDF-COMPS_0001623660": "友報賬",
      "P_YS_FED_MDF-COMPS_0001623420": "复制下载链接",
      "P_YS_FED_MDF-COMPS_0001623662": "主题&导航",
      "P_YS_FED_MDF-COMPS_0001623661": "支持在离线状态下使用工作台",
      "P_YS_FED_MDF-COMPS_0001623422": "正在开发中,请等待。。。。。",
      "P_YS_FED_MDF-COMPS_0001623664": "打包信息",
      "P_YS_FED_MDF-COMPS_0001623421": "选择客户端",
      "P_YS_FED_MDF-COMPS_0001623663": "報賬應用",
      "P_YS_FED_MDF-COMPS_0001623424": "格式错误，正确格式为：AA.BB.CC，第一个A不能为0，且只能包含数字和点，点分割开的数字可以只有一个数字；例如：2.06.9；范围：1.0.0~99.99.99",
      "P_YS_FED_MDF-COMPS_0001623666": "删除移动端",
      "P_YS_FED_MDF-COMPS_0001623423": "我的反饋",
      "P_YS_FED_MDF-COMPS_0001623665": "工作台配置",
      "P_YS_FED_MDF-COMPS_0001623426": "运行分析",
      "P_YS_FED_MDF-COMPS_0001623668": "错误时间",
      "P_YS_FED_MDF-COMPS_0001623425": "普通上架，上架该版本，不强制更新",
      "P_YS_FED_MDF-COMPS_0001623667": "请查看校验项",
      "P_YS_FED_MDF-COMPS_0001623428": "操作系统",
      "P_YS_FED_MDF-COMPS_0001623427": "]将被删除",
      "P_YS_FED_MDF-COMPS_0001623669": "扫码安装APP",
      "P_YS_FED_MDF-COMPS_0001623429": "默认开通数字化建模和应用构建，领域开通需要申请。",
      "P_YS_FED_MDF-COMPS_0001573070": "NN物料查询1",
      "P_YS_FED_MDF-COMPS_0001573071": "The entered number is greater than the max value available. Please select again",
      "P_YS_PF_EVENTCENTER-MD_0001414933": "App Category",
      "P_YS_FED_MDF-COMPS_0001573074": "專案編碼",
      "P_YS_SD_SDMA_0000125259": "Carousel Picture",
      "P_YS_FED_MDF-COMPS_0001573075": "数值型字段",
      "P_YS_FED_MDF-COMPS_0001573072": "數值型字段",
      "P_YS_FED_MDF-COMPS_0001573073": "The entered number is less than the min value available. Please select again",
      "P_YS_FED_MDF-COMPS_0001573078": "物料sku編碼",
      "P_YS_FED_FW_0000021015": "Date",
      "P_YS_FED_MDF-COMPS_0001573079": "自定义项目测试202008060001",
      "P_YS_FED_MDF-COMPS_0001573076": "計量單位",
      "P_YS_FED_MDF-COMPS_0001573077": "固定资产档案导出测试",
      "P_YS_FED_FW_0000022105": "Please select lines to move up",
      "P_YS_PF_ECON-FE_0001152750": "Edit App",
      "P_YS_PF_GZTSYS_0000134745": "HR Apps",
      "P_YS_FED_MDF-COMPS_0001623671": "公共引擎",
      "P_YS_FED_MDF-COMPS_0001623670": "启用沙箱",
      "P_YS_FED_MDF-COMPS_0001623431": "链接目标",
      "P_YS_FED_MDF-COMPS_0001623673": "图片尺寸错误，请上传750*244以内的图片",
      "P_YS_PF_GZTSYS_0000134741": "Enterprise Culture",
      "P_YS_FED_MDF-COMPS_0001623430": "开发沙箱",
      "P_YS_FED_MDF-COMPS_0001623672": "Deployment status",
      "P_YS_FED_FW_0000022361": "Format",
      "P_YS_FED_MDF-COMPS_0001623433": "最后打包时间",
      "P_YS_FED_MDF-COMPS_0001623675": "及其下面的服务吗?",
      "P_YS_FED_FW_0000021031": "Date & Time",
      "P_YS_FED_FW_0000022362": "Editing Area",
      "P_YS_FED_MDF-COMPS_0001623432": "插件配置",
      "P_YS_FED_MDF-COMPS_0001623674": "启动轮播图",
      "P_YS_FED_MDF-COMPS_0001623435": "你确定删除应用么",
      "P_YS_FED_MDF-COMPS_0001623677": "确定切换权限？",
      "P_YS_FED_MDF-COMPS_0001623434": "資產管理",
      "P_YS_FED_MDF-COMPS_0001623676": "主表列表",
      "P_YS_FED_MDF-COMPS_0001623437": "更换证书可能导致应用安装冲突或提交应用市场失败，如果您不了解相关机制请谨慎选择，确定要更换证书吗？",
      "P_YS_FED_MDF-COMPS_0001623679": "移动端页面名称",
      "P_YS_FED_MDF-COMPS_0001623436": "许可详情>",
      "P_YS_FED_MDF-COMPS_0001623678": "机器人可以在流程、页面中, 也可以通过API或定时调度任务启动运行,",
      "P_YS_FED_MDF-COMPS_0001623439": "说明：建议上传700*390图片",
      "P_YS_FED_MDF-COMPS_0001623438": "添加证书",
      "P_YS_FED_MDF-COMPS_0001573081": "業務員",
      "P_YS_SD_SDMA_0000125244": "Other Settings",
      "P_YS_FED_MDF-COMPS_0001573082": "时间H",
      "P_YS_FED_UCFBASEDOC_0000025709": "Project",
      "P_YS_FED_MDF-COMPS_0001573080": "物料sku名稱",
      "P_YS_FED_MDF-COMPS_0001573085": "自定義檔案",
      "P_YS_FED_MDF-COMPS_0001573086": "批次號",
      "P_YS_FED_MDF-COMPS_0001573083": "跟踪号123",
      "P_YS_PF_GZTORG_0000035173": "Modified By",
      "P_YS_FED_MDF-COMPS_0001573084": "likun-表头自定义项[文本]",
      "P_YS_YYGJFW_YBUC_0001315675": "Web Version",
      "P_YS_FED_FW_0000022114": "Accounting Entity",
      "P_YS_FED_MDF-COMPS_0001573089": "門店",
      "P_YS_FED_MDF-COMPS_0001573087": "會計主體",
      "P_YS_FED_MDF-COMPS_0001573088": "NN表头数值0630",
      "P_YS_OA_app_xtyyjm_0000035830": "Application",
      "P_YS_OA_XTLCZX_0000030451": "Approved By",
      "P_YS_OA_XTLCZX_0000030692": "In Progress",
      "P_YS_FED_MDF-COMPS_0001623640": "用于测试更改或新应用程序而不会损坏您的生产数据或配置。",
      "P_YS_FED_MDF-COMPS_0001623400": "导入机器人",
      "P_YS_FED_MDF-COMPS_0001623642": "新建APP",
      "P_YS_FED_MDF-COMPS_0001623641": "沙箱列表",
      "P_YS_FED_MDF-COMPS_0001623402": "张张张",
      "P_YS_FED_MDF-COMPS_0001623644": "沙箱内数据将被完全删除且操作无法撤回。",
      "P_YS_FED_MDF-COMPS_0001623401": "链接地址:",
      "P_YS_FED_MDF-COMPS_0001623643": "累计设备数",
      "P_YS_FED_MDF-COMPS_0001623404": "提交中",
      "P_YS_FED_MDF-COMPS_0001623646": "沙箱历史",
      "P_YS_FED_MDF-COMPS_0001623403": "添加PC端",
      "P_YS_FED_MDF-COMPS_0001623645": "工作電郵",
      "P_YS_PLM_BASE-UI_0001105578": "Tree-Table",
      "P_YS_FED_IMP_IOT_0001069279": "EUC",
      "P_YS_FED_MDF-COMPS_0001623406": "手机型号",
      "P_YS_FED_MDF-COMPS_0001623648": "上次",
      "P_YS_FED_MDF-COMPS_0001623405": "主题&导航配置预览",
      "P_YS_FED_MDF-COMPS_0001623647": "模板示例",
      "P_YS_FED_MDF-COMPS_0001623408": "下载安装包",
      "P_YS_FED_MDF-COMPS_0001623407": "最新版本：",
      "P_YS_FED_MDF-COMPS_0001623649": "人力應用",
      "P_YS_FED_MDF-COMPS_0001623409": "跳过广告",
      "P_YS_SD_SDSD-FE_0001184978": "Page Info",
      "P_YS_FED_FW_0000022167": "No data in the current template",
      "P_YS_FED_FW_0000021093": "Accumulation",
      "P_YS_FED_MDF-COMPS_0001623651": "获取沙箱失败",
      "P_YS_FED_FW_0000022188": "Update",
      "P_YS_FED_MDF-COMPS_0001623650": ") 的配置,是否确认修改该配置？",
      "P_YS_FED_MDF-COMPS_0001623411": "禁用长按",
      "P_YS_FED_MDF-COMPS_0001623653": "同时生成",
      "P_YS_FED_MDF-COMPS_0001623410": "许可到期日",
      "P_YS_FED_MDF-COMPS_0001623652": "建议1024*1024px",
      "P_YS_FED_MDF-COMPS_0001623413": "导出配置文件",
      "P_YS_FED_MDF-COMPS_0001623655": "标题字节长度应小于8",
      "P_YS_FED_MDF-COMPS_0001623412": "请输入文件名称,别名搜索",
      "P_YS_FED_MDF-COMPS_0001623654": "新建证书需跳转到证书管理页面，当前页面数据会以草稿态保存到 App 列表中。",
      "P_YS_FED_MDF-COMPS_0001623415": "保存并关闭",
      "P_YS_FED_MDF-COMPS_0001623657": "上传插件",
      "P_YS_FED_MDF-COMPS_0001623414": "示例页面",
      "P_YS_FED_MDF-COMPS_0001623656": "图标大小不能超过300kb",
      "P_YS_FED_MDF-COMPS_0001623417": "应用编码不能为空!",
      "P_YS_FED_MDF-COMPS_0001623659": "运行分析详情",
      "P_YS_FED_MDF-COMPS_0001623416": "应用隐藏后将在APP工作台中无法查看",
      "P_YS_FED_MDF-COMPS_0001623658": "配置参数",
      "P_YS_FED_MDF-COMPS_0001623419": "台账列表",
      "P_YS_FED_MDF-COMPS_0001623418": "强制上架",
      "P_YS_OA_app_xtyyjm_0000035850": "Not Published",
      "P_YS_FED_UCFORG_0000025923": "Enabled successfully",
      "P_YS_OA_app_xtyyjm_0000035868": "Page",
      "P_YS_FED_MDF-COMPS_0001064728": "[Hint] If the content in the enterprise language is not provided, then system will populate the content in the current language",
      "P_YS_PF_PRINT_0000056601": "Extended Field",
      "P_YS_FED_MDF-COMPS_0001623620": "申请提交失败",
      "P_YS_FED_MDF-COMPS_0001623622": "进入沙箱",
      "P_YS_OA_XTMENHUCMS_0000045092": "Edit Navigation",
      "P_YS_FED_MDF-COMPS_0001623621": "添加描述文件",
      "P_YS_FED_MDF-COMPS_0001623624": "证书文件不能为空！",
      "P_YS_FED_MDF-COMPS_0001623623": "允许跳过",
      "P_YS_FED_MDF-COMPS_0001623626": "图标大小不能超过20kb",
      "P_YS_FED_MDF-COMPS_0001623625": "当前应用内的服务将全部启用，请谨慎操作。",
      "P_YS_FED_MDF-COMPS_0001623628": "请重新设定版本号，并且高于",
      "P_YS_FED_MDF-COMPS_0001623627": "请选择实体字段",
      "P_YS_FED_MDF-COMPS_0001623629": "历史上架",
      "P_YS_SD_SCMSA-UI_0001345888": "Qty Used",
      "P_YS_PF_PC-UI_0000160768": "Calling Method",
      "P_YS_SD_SDSD-FE_0001491421": "我的團隊",
      "P_YS_SCM_USTOCK-UI_0001077879": "For display. Input not required",
      "P_YS_HR_HRRCPDF_0000073196": "Created On:",
      "P_YS_OA_XTLCZX_0000030484": "Please enter the content",
      "P_YS_FED_FW_0000022166": "Total",
      "P_YS_FED_MDF-COMPS_0001623631": "T+服務",
      "P_YS_FED_MDF-COMPS_0001623630": "添加签名文件",
      "P_YS_FED_MDF-COMPS_0001623633": "请输入6位数密码",
      "P_YS_YYGJFW_YBUC_0001336150": "Link Method",
      "P_YS_FED_MDF-COMPS_0001623632": "请选择需要从源环境同步到当前开发沙箱的用户(不含授权信息)",
      "P_YS_FED_MDF-COMPS_0001623635": "文件UUID",
      "P_YS_FED_MDF-COMPS_0001623634": "描述文件类型",
      "P_YS_FED_MDF-COMPS_0001623637": "了解详情, 请移步",
      "P_YS_FED_MDF-COMPS_0001623636": "请检查输入项！",
      "P_YS_PF_GZTSYS_0000096443": "Multi-Org",
      "P_YS_FED_MDF-COMPS_0001623639": "上架设置",
      "P_YS_FI_GL_0000088698": "“",
      "P_YS_FED_MDF-COMPS_0001623638": "页面编码必须以业务对象code为前缀 (",
      "P_YS_SD_SDSD-FE_0001184980": "Blank Template",
      "P_YS_OA_app_xtyyjm_0000035873": "Existing Form",
      "P_YS_PF_PC-UI_0000159776": "On-shelf Status",
      "P_YS_PF_GZTTMP_0000079010": "Certificate File",
      "P_YS_PF_PRINT_0000057998": "Upstream Doc No.",
      "P_YS_OA_XTLCZX_0000030652": "Created On",
      "P_YS_FED_IMP_IOT_0000129813": "Equipment Qty",
      "P_YS_SCM_STOCK_0000034501": "Account",
      "P_YS_FED_MDF-COMPS_0001623600": "白名单控制（在APP工作台仅显示以下应用）",
      "P_YS_OA_XTDYH_0000168902": "Incorrect address format",
      "P_YS_FED_MDF-COMPS_0001623602": "选中图标",
      "P_YS_FED_MDF-COMPS_0001623601": "友户通令牌",
      "P_YS_FED_MDF-COMPS_0001623604": "APP标识",
      "P_YS_PES_PES-FE_0001108224": "Application Description",
      "P_YS_FED_MDF-COMPS_0001623603": "开发ing",
      "P_YS_FED_MDF-COMPS_0001623606": "暂无可申请领域",
      "P_YS_FED_MDF-COMPS_0001623605": "修改导航标题会同时作用于移动端底部导航标题及该导航对应的页面头部标题（动态页面外），导航图标只能上传不超过20kb，尺寸是122px X 122px 的png图标。",
      "P_YS_IPAAS_UCG_0001111718": "Deployed",
      "P_YS_FED_MDF-COMPS_0001623608": "首页配置",
      "P_YS_FED_MDF-COMPS_0001623607": "最多只能添加24个常用应用",
      "P_YS_PF_YHTTNT-FE_0001045644": "User imported successfully",
      "P_YS_FED_MDF-COMPS_0001623609": "无限制（隐藏应用后在移动APP工作台中将无法查看）",
      "P_YS_HR_HRJQF_0000054381": "Off-site Check-in",
      "P_YS_YYGJFW_YBUC_0001336548": "Version No. is required",
      "P_YS_OA_app_xtyyjm_0000035882": "New Page",
      "P_YS_FED_FW_0000021437": "End Date",
      "P_YS_SD_SDSD-FE_0001491485": "規格",
      "P_YS_HR_HRJQF_0000054146": "On-boarding",
      "P_YS_SD_SDSD-FE_0001491490": "單價",
      "P_YS_FED_MDF-COMPS_0001623611": "管理员（创建人）",
      "P_YS_FED_MDF-COMPS_0001623610": "应用所在位置: 工作台-",
      "P_YS_PF_GZTPRE_0000071064": "Entity",
      "P_YS_FED_MDF-COMPS_0001623613": "页面名称长度不超过30个字符",
      "P_YS_FED_EXAMP_0000020130": "Qty",
      "P_YS_FED_MDF-COMPS_0001623612": "请重新设定版本号，并且不小于",
      "P_YS_PF_PRINT_0000056670": "Operator",
      "P_YS_FED_MDF-COMPS_0001623615": "移动端名称",
      "P_YS_FED_MDF-COMPS_0001623614": "强制上架，上架该版本，强制更新",
      "P_YS_FED_MDF-COMPS_0001623617": "正在处理，暂不支持操作",
      "P_YS_FED_MDF-COMPS_0001623616": "请先新建分组",
      "P_YS_FED_MDF-COMPS_0001623619": "用户自建系统",
      "P_YS_FED_MDF-COMPS_0001623618": "配置成功",
      "P_YS_FED_EXAMP_0000020127": "Upload failed",
      "P_YS_PF_GZTSYS_0000013478": "Transfer",
      "P_YS_FED_FW_0000021683": "English",
      "P_YS_OA_XTLCZX_0000030656": "Overwrite",
      "P_YS_FI_CM_0000029869": "Please select the date",
      "P_YS_FED_FW_0000021660": "Unit Price Precision",
      "P_YS_FED_EXAMP_0000020383": "Please enter",
      "P_YS_PF_YHTTNT-FE_0001045672": "Time of Invitation",
      "P_YS_PF_GZTSYS_0000012596": "Leave",
      "P_YS_PF_PRINT_0000065599": "Group No.",
      "P_YS_FED_UCFBASEDOC_0000025558": "Mobile Verification Code",
      "P_YS_HR_HRXC_0000177247": "Latest Ver",
      "P_YS_FED_FW_0000021651": "Selected",
      "P_YS_OA_XTLCZX_0000030666": "Description",
      "P_YS_PF_PRINT_0000056891": "Expiration Date",
      "P_YS_SD_SDMAUF_0000151755": "Deposit",
      "P_YS_PF_GZTBDM_0000036680": "Current Tenant",
      "P_YS_PF_GZTSYS_0000012366": "On-boarding by Scanning QR Code",
      "P_YS_FED_FW_0000022511": "Remove",
      "P_YS_PF_PRINT_0000065360": "Item Code",
      "P_YS_HR_HRJQF_0000054355": "Application Time",
      "P_YS_OA_XTNEIBUQUN_0000046144": "Cancel Administrator",
      "P_YS_OA_XTGONGGAO_0000045866": "Please enter category name",
      "P_YS_PF_PRINT_0000057797": "Header User-defined Field 4",
      "P_YS_FED_FW_0000021482": "Basic Info",
      "P_YS_PF_PRINT_0000057795": "Header User-defined Field 5",
      "P_YS_PF_PRINT_0000057794": "Header User-defined Field 6",
      "P_YS_PF_PRINT_0000057793": "Header User-defined Field 7",
      "P_YS_PF_PRINT_0000057792": "Header User-defined Field 1",
      "P_YS_PF_PRINT_0000057791": "Header User-defined Field 2",
      "P_YS_PF_PRINT_0000057790": "Header User-defined Field 3",
      "P_YS_PF_PROCENTER_0001131746": "Print Template",
      "P_YS_YYGJFW_YBUC_0001315646": "Member Mgmt",
      "P_YS_FED_UCFBASEDOC_0000025539": "test",
      "P_YS_PF_PRINT_0000065174": "Primary UOM",
      "P_YS_FED_FW_0000022323": "Enter Dir",
      "P_YS_PF_PROCENTER_0000027306": "Code:",
      "P_YS_FED_UIMG_0001088007": "Tips",
      "P_YS_FED_EXAMP_0000020559": "Revision",
      "P_YS_FED_FW_0000021239": "Start Date",
      "P_YS_FED_UCFBASEDOC_0000025533": "Submitted successfully",
      "P_YS_FED_EXAMP_0000020319": "Please enter your password",
      "P_YS_YYGJFW_YBUC_0001315649": "Pack",
      "P_YS_FED_FW_0000022342": "Verify",
      "P_YS_PF_GZTSYS_0001085920": "Integration Configuration",
      "P_YS_PF_GZTSYS_0000012188": "Publish Mgmt",
      "P_YS_IPAAS_UCG_0001072751": "Develop App",
      "P_YS_PF_PRINT_0000065185": "Pieces",
      "P_YS_FED_FW_0000021007": "Numeric Value",
      "P_YS_FED_EXAMP_0000020328": "Status",
      "P_YS_FED_FW_0000021005": "Time",
      "P_YS_FED_IMP-DFM-FE_0000020891": "Data Binding",
      "P_YS_YYGJFW_YBUC_0001315625": "Packed By",
      "P_YS_PF_GZTSYS_0000013006": "New DuDu",
      "P_YS_SD_SDSD-FE_0001184912": "User-defined Link",
      "P_YS_FED_FW_0000021452": ")",
      "P_YS_SD_UDHWS_0000070903": "Comments",
      "P_YS_YYGJFW_YBUC_0001315628": "Packed At",
      "P_YS_PF_GZTSYS_0000108693": "Pre-publish Env.",
      "P_YS_PF_GZTSYS_0000013023": "Work Report",
      "P_YS_PF_GZTSYS_0000013020": "You Reimbursement",
      "P_YS_SD_SDMA_0000123026": "Failed to disable",
      "P_YS_FED_FW_0000022310": "Click to upload",
      "P_YS_FED_UCFBASEDOC_0000024895": "test",
      "P_YS_OA_app_xtywlj_0000037593": "Property",
      "P_YS_FED_FW_0000020987": "2.0",
      "P_YS_OA_XTMENHUCMS_0001072305": "Location",
      "P_YS_PF_GZTSYS_0000012657": "Employee",
      "P_YS_OA_XTMENHUCMS_0001072304": "Time",
      "P_YS_OA_XTMENHUCMS_0001072301": "Cloud Conference",
      "P_YS_OA_XTMENHUCMS_0001072302": "Subject",
      "P_YS_FED_FW_0000020982": "Successful",
      "P_YS_FED_FW_0000020985": "Sun",
      "P_YS_FED_FW_0000020999": "Wed",
      "P_YS_PF_GZTSYS_0000015934": "Mobile Check-in",
      "P_YS_PF_GZTSYS_0000012423": "Homepage Sorting",
      "P_YS_OA_XTMENHUCMS_0001072316": "London, UK",
      "P_YS_OA_XTMENHUCMS_0001072315": "The International Liver Congress",
      "P_YS_OA_XTMENHUCMS_0001072312": "MM/dd/yyyy",
      "P_YS_FED_FW_0000020991": "5.0",
      "P_YS_PF_GZTSYS_0000015937": "Mobile Portal Settings",
      "P_YS_OA_XTMENHU_0001025398": "Please adjust the picture size",
      "P_YS_FED_UCFBASEDOC_0000025041": "Enter name",
      "P_YS_PF_GZTSYS_0000012873": "Check In",
      "P_YS_OA_XTMENHU_0001025399": "New Launch Image",
      "P_YS_FED_EXAMP_0000020538": "OK",
      "P_YS_PF_PROCENTER_0000027553": "Prompt",
      "P_YS_MF_MR-UI_0001142058": "By Org",
      "P_YS_FED_FW_0000020970": "6.0",
      "P_YS_FED_UCFBASEDOC_0000025029": "Next",
      "P_YS_FED_FW_0000020972": "Thu",
      "P_YS_OA_XTMENHUCMS_0000045309": "Image size cannot exceed 5 MB.",
      "P_YS_OA_XTMENHUCMS_0000045308": "Camper Sign-in",
      "P_YS_HR_HRJQ_0000031659": "Late",
      "P_YS_OA_XTMENHUCMS_1420844201648586752": "The scene-based APP can be multi-selected and dragged left and right, and sorted up and down",
      "P_YS_OA_XTMENHUCMS_0001368770": "Property Interface Addr",
      "P_YS_OA_XTMENHUCMS_0000045306": "Image size cannot exceed 1 MB.",
      "P_YS_OA_XTMENHUCMS_0000045305": "3. External organizations/members not subject to this rule can also be set",
      "P_YS_OA_XTMENHUCMS_0000045304": "Approve Provident Fund Withdrawal Application Through Cloud",
      "P_YS_OA_XTMENHUCMS_0000045303": "Loading error. Please try again later.",
      "P_YS_OA_XTMENHUCMS_0000045302": "Create from Existing Portal Page",
      "P_YS_OA_XTMENHUCMS_0000045301": "Deleting a level-1 menu will delete associated level-2 menus. Are you sure you want to delete the menu?",
      "P_YS_OA_XTMENHUCMS_0000045300": "Please enter a name.",
      "P_YS_PF_GZTSYS_0000012471": "Internal Group Docs",
      "P_YS_FED_IMP_IOT0000005525": "Property Name",
      "P_YS_OA_XTMENHUCMS_0001473589": "Message Tab",
      "P_YS_OA_XTMENHUCMS_0001035139": "Edit Startup Picture",
      "P_YS_OA_XTMENHUCMS_0001035138": "Upload failed. Please upload the file type of jpg, jpeg, png",
      "P_YS_OA_XTMENHUCMS_0001035137": "Suggested size: 1080*1920; no more than 300KB",
      "P_YS_OA_XTMENHUCMS_0001035136": "Default Startup Picture",
      "P_YS_OA_XTMENHUCMS_0001035135": "Upload failed. Please upload the picture no more than 300K",
      "P_YS_OA_XTRIZHI_0000036838": "Share Scope",
      "P_YS_OA_XTMENHUCMS_0001035134": "Startup Picture Settings",
      "P_YS_OA_XTMENHUCMS_0001035133": "Effective From",
      "P_YS_OA_XTMENHUCMS_0001035132": "Expiration",
      "P_YS_OA_XTMENHUCMS_0001035131": "The date of effective cannot be later than the date of expiration",
      "P_YS_OA_XTMENHUCMS_0001035130": "Custom Startup Picture",
      "P_YS_OA_XTMENHUCMS_0001035371": "Drag layout here ",
      "P_YS_OA_XTMENHUCMS_0001035370": "Confirm change template?",
      "P_YS_HR_HRSBF_0001287444": "Edit Component",
      "P_YS_OA_XTRIZHI_0000036822": "Maximum 20 Chars",
      "P_YS_OA_XTMENHUCMS_0001035146": "New Startup Picture",
      "P_YS_OA_XTMENHUCMS_0001035145": "Please adjust the picture size",
      "P_YS_OA_XTMENHUCMS_0001035144": "Click to modify",
      "P_YS_OA_XTMENHUCMS_0001035143": "The startup picture for company anniversary or important notification",
      "P_YS_OA_XTMENHUCMS_0001035142": "Please upload startup picture",
      "P_YS_OA_XTMENHUCMS_0001035141": "It cannot be recovered once deleted. Please confirm",
      "P_YS_OA_XTMENHUCMS_0001035140": "Hint: for the time period during which the custom startup picture is not set, the default picture will be used for mobile startup picture",
      "P_YS_SD_UDHWN_0000121778": "Interface Addr",
      "P_YS_HR_HRJQ_0000055421": "Type",
      "P_YS_OA_XTMENHUCMS_0000045101": "Some of the spaces you added already exist. Auto dedup has been performed",
      "P_YS_OA_XTMENHUCMS_0000045343": "Recommend to Mobile Homepage",
      "P_YS_OA_XTMENHUCMS_0000045342": "Upload Logo",
      "P_YS_OA_XTMENHUCMS_0000045341": "Please enter a correct homepage URL on pcClient",
      "P_YS_OA_app_xtyyjm_0000036050": "Logo",
      "P_YS_OA_XTMENHUCMS_0000045109": "Customize Menu Address",
      "P_YS_OA_XTMENHUCMS_0000045108": "Meeting at 4:00pm",
      "P_YS_OA_XTMENHUCMS_0000045107": "Standard (60px)",
      "P_YS_OA_XTMENHUCMS_0000045349": "Enterprise-created",
      "P_YS_OA_XTMENHUCMS_0000045106": "Background image size for 2-row of icons: 690×350 PX ",
      "P_YS_OA_XTMENHUCMS_0000045348": "Please enter a correct URL on Web",
      "P_YS_OA_XTMENHUCMS_0000045105": "Confirm to disable?",
      "P_YS_OA_XTMENHUCMS_0000045347": "Use YouZone Native Navigation",
      "P_YS_OA_XTMENHUCMS_0000045104": "No pending approval requests",
      "P_YS_OA_XTMENHUCMS_0000045346": "No more than 200 chars",
      "P_YS_OA_XTMENHUCMS_0000045103": "Daily Aver. Attnd Hrs",
      "P_YS_OA_XTMENHUCMS_0000045345": "For local Apps, please enter URLs in the form of **://**; for other types, please enter URLs beginning with http:// or https://",
      "P_YS_OA_XTMENHUCMS_0000045102": "New Navigation",
      "P_YS_FED_FW_0000020919": "1.0",
      "P_YS_OA_XTRIZHI_0000036806": "Network error",
      "P_YS_OA_XTMENHUCMS_0000045332": "Source",
      "P_YS_OA_XTMENHUCMS_0000045331": "pcClient Homepage",
      "P_YS_OA_XTMENHUCMS_0000045330": "IOS download link is required and cannot exceed 500 chars",
      "P_YS_FED_FW_0000020917": "Confirm",
      "P_YS_OA_XTMENHUCMS_0000045339": "Http protocol is used when opening the current page in the web client, which may lead to redirection failure. Do you want to change to https protocol?",
      "P_YS_OA_XTMENHUCMS_0000045338": "Sorting on Web",
      "P_YS_OA_XTMENHUCMS_0000045337": "No more than 12 chars",
      "P_YS_SD_SDMBF_0000141321": "Bound successfully.",
      "P_YS_OA_XTMENHUCMS_0000045336": "Please upload logo",
      "P_YS_OA_XTMENHUCMS_0000045335": "Visible Scope is required",
      "P_YS_OA_XTMENHUCMS_0000045334": "Web Homepage",
      "P_YS_OA_XTMENHUCMS_0000045333": "After deleting, the App-related data will also be deleted. Please confirm the data has been migrated",
      "P_YS_PF_MANAGER-FE_1420836367628238848": "Please select the Characterization Workbench first",
      "P_YS_OA_XTMENHUCMS_0000045321": "Adapt to the width of screen",
      "P_YS_OA_XTMENHUCMS_0001473597": "Directly create a workbench",
      "P_YS_OA_XTMENHUCMS_0000045320": "YoY Changes",
      "P_YS_OA_XTMENHUCMS_0001473598": "Create a workbench by service",
      "P_YS_OA_XTMENHUCMS_0001473595": "Create an existing service as a workbench",
      "P_YS_OA_XTMENHUCMS_0001473596": "Authorize by Dept",
      "P_YS_OA_XTMENHUCMS_0001473599": "Create from an existing workbench",
      "P_YS_OA_XTMENHUCMS_0000045329": "Please select App category",
      "P_YS_OA_XTMENHUCMS_0000045328": "Picture error",
      "P_YS_OA_XTMENHUCMS_0001473590": "Please select an existing workbench",
      "P_YS_OA_XTMENHUCMS_0000045327": "Android download link is required and cannot exceed 500 chars",
      "P_YS_OA_XTMENHUCMS_0000045326": "All Apps Sorting",
      "P_YS_OA_XTMENHUCMS_0000045325": "summer Package ID is required",
      "P_YS_OA_XTMENHUCMS_0001473593": "Create a workbench with an existing workbench as a template",
      "P_YS_OA_XTMENHUCMS_0000045324": "Open App in New Browser Page",
      "P_YS_OA_XTMENHUCMS_0001473594": "Designer Allocation",
      "P_YS_OA_XTMENHUCMS_0000045323": "Icon Style Change",
      "P_YS_OA_XTMENHUCMS_0001473591": "Authorize by Employee",
      "P_YS_OA_XTMENHUCMS_0000045322": "In Charge",
      "P_YS_SD_SDMBF_0000141358": "Check",
      "P_YS_OA_XTMENHUCMS_0001473592": "Create a brand new workbench in the most usual way",
      "P_YS_HR_HRYGF_0000055799": "Processed",
      "P_YS_OA_XTMENHUCMS_0000045319": "Meeting",
      "P_YS_OA_XTMENHUCMS_0000045310": "Notify",
      "P_YS_OA_XTMENHUCMS_0000045318": "Network request slow",
      "P_YS_OA_XTMENHUCMS_0000045317": "Linear Navigation",
      "P_YS_OA_XTMENHUCMS_0000045316": "Graphic Announcement 2 (Left-Right)",
      "P_YS_OA_XTMENHUCMS_0000045315": "Selected Text Color",
      "P_YS_OA_XTMENHUCMS_0000045314": "Searching...",
      "P_YS_OA_XTMENHUCMS_0000045313": "Link Page cannot be empty.",
      "P_YS_OA_XTMENHUCMS_0000045145": "Saved successfully",
      "P_YS_OA_XTMENHUCMS_0001256783": "Workbench status",
      "P_YS_OA_XTTMCENTER_0000035124": "New Ads Picture",
      "P_YS_OA_XTMENHUCMS_0000045144": "Appointment or Dismissal of Cadres",
      "P_YS_OA_XTMENHUCMS_0001256784": "Create information",
      "P_YS_FED_FW_0000020946": "Fri",
      "P_YS_OA_XTMENHUCMS_0001256781": "Workbench name",
      "P_YS_OA_XTMENHUCMS_0000045142": "Header Settings",
      "P_YS_OA_XTMENHUCMS_0001256782": "update information",
      "P_YS_OA_XTMENHUCMS_0000045141": "Restored to the latest change",
      "P_YS_OA_XTMENHUCMS_0000045383": "Local path of the installation package such as: html/app_sign/sign.html",
      "P_YS_OA_XTMENHUCMS_0001256780": "Workbench icon",
      "P_YS_OA_XTMENHUCMS_0000045381": "Android redirection parameter cannot exceed 200 chars",
      "P_YS_OA_XTMENHUCMS_0000045380": "Sorting on Left Tree",
      "P_YS_OA_XTMENHUCMS_0000045149": "Please enter the App name.",
      "P_YS_OA_XTMENHUCMS_0001256787": "staff",
      "P_YS_OA_XTMENHUCMS_0000045148": "Picture size 690×150 PX",
      "P_YS_OA_XTMENHUCMS_0001256788": "organization",
      "P_YS_OA_XTMENHUCMS_0000045147": "Please select the correct text color.",
      "P_YS_OA_XTMENHUCMS_0001256785": "Workbench usage rights",
      "P_YS_OA_XTMENHUCMS_0000045146": "Up to 4 components can be added into the target container.",
      "P_YS_OA_XTMENHUCMS_0001256786": "Workbench design authority",
      "P_YS_HR_HRJQ_0000031027": "Cannot be empty",
      "P_YS_OA_XTLCZX_0000030184": "Edited successfully",
      "P_YS_OA_XTMENHUCMS_0000045134": "Please enter max. 20 characters.",
      "P_YS_OA_XTMENHUCMS_0000045133": "This subject applies to",
      "P_YS_OA_XTMENHUCMS_0000045132": "Submitted By:",
      "P_YS_OA_XTMENHUCMS_0000045374": "Please reset carefully. After resetting, the enabled Apps in the current enterprise need to be modified. Reset?",
      "P_YS_OA_XTMENHUCMS_0000045131": "Error Prompt",
      "P_YS_OA_XTMENHUCMS_0000045373": "Mobile Homepage",
      "P_YS_OA_XTMENHUCMS_0000045130": "Customize Portal",
      "P_YS_OA_XTMENHUCMS_0000045372": "Native",
      "P_YS_OA_XTMENHUCMS_0000045371": "Duplicate App name",
      "P_YS_OA_XTMENHUCMS_0000045370": "Only the installation packages uploaded to iuap platform are supported",
      "P_YS_OA_XTMENHUCMS_0000045139": "Confirm to delete",
      "P_YS_OA_XTMENHUCMS_0000045138": "In New Page",
      "P_YS_OA_XTMENHUCMS_0000045137": "Operation failed! Please try again later.",
      "P_YS_OA_XTMENHUCMS_0000045379": "Please enter a correct URL on mobile",
      "P_YS_OA_XTMENHUCMS_0000045136": "For width/height, upload picture of size ",
      "P_YS_OA_XTMENHUCMS_0000045378": "IOS redirection parameter cannot exceed 200 chars",
      "P_YS_OA_XTMENHUCMS_0000045135": "Saving failed. Please try again later.",
      "P_YS_OA_XTMENHUCMS_0000045377": "App Desc.",
      "P_YS_OA_XTLCZX_0000030198": "Title Text Color",
      "P_YS_HR_HRJXF_0001118178": "Confirm to disable?",
      "P_YS_OA_XTTMCENTER_0000035137": "Ads Picture",
      "P_YS_OA_XTMENHUCMS_0000045123": "Use fixed Max display width of 1400 PX",
      "P_YS_OA_XTMENHUCMS_0000045365": "Confirm to disable?",
      "P_YS_OA_XTTMCENTER_0000035145": " ",
      "P_YS_OA_XTMENHUCMS_0000045122": "xxx",
      "P_YS_OA_XTMENHUCMS_0000045364": "Begin with http:// or https://",
      "P_YS_OA_XTMENHUCMS_0000045363": "pcClient",
      "P_YS_OA_XTMENHUCMS_0000045120": "Regulations on Using Public Meeting Rooms",
      "P_YS_FED_FW_0000020926": "3.0",
      "P_YS_OA_XTMENHUCMS_0000045361": "Publish To is required",
      "P_YS_OA_XTMENHUCMS_0000045360": "App name is required",
      "P_YS_OA_XTMENHUCMS_0000045129": "xxx",
      "P_YS_OA_XTMENHUCMS_0000045128": "The component is in use. Are you sure you want to delete it?",
      "P_YS_OA_XTMENHUCMS_0000045127": "Confirm to delete “",
      "P_YS_OA_XTMENHUCMS_0000045369": "App name failed to pass detection",
      "P_YS_OA_XTMENHUCMS_0001538954": "Note: up to ",
      "P_YS_OA_XTMENHUCMS_0000045126": "Please select portal user",
      "P_YS_OA_XTMENHUCMS_0000045368": "Background",
      "P_YS_FED_FW_0000020920": "Sat",
      "P_YS_OA_XTMENHUCMS_0000045125": "Add widget and drag it here.",
      "P_YS_OA_XTMENHUCMS_0000045367": "Open Local App",
      "P_YS_OA_XTMENHUCMS_0000045124": "Camper Manual",
      "P_YS_OA_XTMENHUCMS_0000045112": "Confirm to delete ",
      "P_YS_OA_XTMENHUCMS_0000045354": "Mobile or web client error",
      "P_YS_OA_XTMENHUCMS_0000045596": "Clear All Layouts",
      "P_YS_OA_XTMENHUCMS_0001561834": "No. of Duplicate Personnel",
      "P_YS_OA_XTMENHUCMS_0000045111": "Add Level-2 Menu",
      "P_YS_OA_XTMENHUCMS_0000045110": "Graphic Announcement (Left-Right)",
      "P_YS_PF_GZTSYS_0000011996": "Internal Group",
      "P_YS_FED_FW_0000020937": "Tue",
      "P_YS_OA_XTMENHUCMS_0000045350": "Sorting by Category",
      "P_YS_OA_XTMENHUCMS_0000045119": "Failed to delete. Please try again later.",
      "P_YS_OA_XTMENHUCMS_0000045118": "Enable Portal",
      "P_YS_OA_XTMENHUCMS_0000045117": "Title Area Background Area",
      "P_YS_OA_XTMENHUCMS_0000045116": "?",
      "P_YS_OA_XTMENHUCMS_0000045358": "Add description",
      "P_YS_OA_XTMENHUCMS_0000045115": "Control Scope is mandatory.",
      "P_YS_OA_XTMENHUCMS_0000045357": "No more than 500 chars",
      "P_YS_OA_XTMENHUCMS_0000045599": "Saved and enabled. The modification only becomes effective after logging out and logging in to the portal again.",
      "P_YS_OA_XTMENHUCMS_0000045114": "The portal name cannot exceed 20 characters.",
      "P_YS_OA_XTMENHUCMS_0000045356": "Open App in PC Client",
      "P_YS_OA_XTMENHUCMS_0000045598": "Failed to add",
      "P_YS_OA_XTMENHUCMS_0000045113": "Graphic Announcement (Up-Down)",
      "P_YS_OA_XTMENHUCMS_0000045355": "summer Package ID",
      "P_YS_OA_XTMENHUCMS_0000045597": "Enter a group of layout ratios separated by space, of which the sum is equal to 12, e.g., 3 3 3 3",
      "P_YS_OA_XTMENHUCMS_0000045192": "Associated Content is required",
      "P_YS_OA_XTMENHUCMS_0000045191": "Compatibility Test 2018_666666666",
      "P_YS_OA_XTMENHUCMS_0000045190": "Adapt to Screen",
      "P_YS_OA_XTMENHUCMS_0000045189": "Bottom Settings",
      "P_YS_OA_XTMENHUCMS_0000045188": "Self-Adaptive Style",
      "P_YS_OA_XTMENHUCMS_0000045187": "Description: the modification to the navigation title will apply to both the bottom navigation title and the header title in the corresponding page of the navigation in mobile version (except for dynamic pages).",
      "P_YS_OA_XTMENHUCMS_0000045186": "The names in My Component Library cannot be duplicate.",
      "P_YS_OA_XTMENHUCMS_0000045185": "JPG/PNG only",
      "P_YS_OA_XTMENHUCMS_0000045184": "Approve Q1 Transportation Fees",
      "P_YS_OA_XTSPZX_0000034655": "Sorted successfully",
      "P_YS_OA_XTMENHUCMS_0000045183": "Portal Administrator",
      "P_YS_OA_XTMENHUCMS_0000045182": "Participate",
      "P_YS_PF_MANAGER-FE_1420076596501413915": "APPLICATIONS TO BE SELECTED",
      "P_YS_FED_FW_0000022486": "Display Disabled",
      "P_YS_FED_FW_0000022245": "Style",
      "P_YS_OA_app_xtyyjm_0000035936": "Open Method",
      "P_YS_OA_XTMENHUCMS_0000045181": "Display in Navigation Bar",
      "P_YS_OA_XTMENHUCMS_0000045180": "Select Org",
      "P_YS_OA_XTMENHUCMS_0000045178": "You can enter up to10 characters.",
      "P_YS_PF_GZTACT_0001061469": "Identity Type",
      "P_YS_OA_XTMENHUCMS_0000045177": "Failed to add the component. Please try again.",
      "P_YS_OA_XTMENHUCMS_0000045176": "Processing... Please wait.",
      "P_YS_OA_XTMENHUCMS_0000045175": "Navigation Name",
      "P_YS_OA_XTMENHUCMS_0000045173": "BU Notice",
      "P_YS_OA_XTMENHUCMS_0000045172": "Pingping",
      "P_YS_OA_XTMENHUCMS_0000045171": "You can add up to 24 Apps",
      "P_YS_PF_PROCENTER_0000022566": "Disable",
      "P_YS_PF_MANAGER-FE_1420076596501413921": "Scene-based APP binding role workbench",
      "P_YS_OA_XTMENHUCMS_0000045179": "Meeting at 5:00pm",
      "P_YS_OA_XTMENHUCMS_0000045170": "xxx",
      "P_YS_OA_XTMENHUCMS_0001314260": "Enter an integer (up to three-digit; unit: px)",
      "P_YS_OA_XTMENHUCMS_0000045167": "Proposal of Cadre Appointment and Dismissal",
      "P_YS_OA_XTMENHUCMS_0001314253": "New Text Type",
      "P_YS_OA_XTMENHUCMS_0000045166": "Global Scope cannot be set. It is not displayed in the selection area. Please select a lower-level Org.",
      "P_YS_OA_XTMENHUCMS_0001314252": "Workbench User - Employee",
      "P_YS_OA_XTMENHUCMS_0000045165": "Modify Level-2 Menu",
      "P_YS_OA_XTMENHUCMS_0001314255": "Confirm to not allow employees to set the default workbench?",
      "P_YS_OA_XTMENHUCMS_0000045164": "Approval Source:",
      "P_YS_OA_XTMENHUCMS_0001314254": "Disable Workbench",
      "P_YS_OA_XTMENHUCMS_0000045163": "Portal Configuration Designer",
      "P_YS_OA_XTMENHUCMS_0001314257": "Theme deleted successfully",
      "P_YS_OA_XTMENHUCMS_0000045162": "Save Customized Components",
      "P_YS_OA_XTMENHUCMS_0001314256": "Please enter the theme name (less than 6 characters)",
      "P_YS_OA_XTMENHUCMS_0000045161": "Click to View Component Development File",
      "P_YS_OA_XTMENHUCMS_0001314259": "Confirm to delete the theme?",
      "P_YS_OA_XTMENHUCMS_0000045160": "Some of the departments you added already exist. Auto dedup has been performed",
      "P_YS_OA_XTMENHUCMS_0001314258": "Please select the default theme color",
      "P_YS_FED_FW_0000022223": "Use",
      "P_YS_FED_UCFBASEDOC_0000024984": "Submit",
      "P_YS_OA_XTMENHUCMS_0000045169": "Industrial Base Construction and Asset Management Center",
      "P_YS_OA_XTLCZX_0000030167": "Recommended Size",
      "P_YS_PF_GZTLOG_0000025973": "Enable",
      "P_YS_OA_XTMENHUCMS_0000045156": "No relevant notices",
      "P_YS_OA_XTTMCENTER_0000035113": "App Category",
      "P_YS_PF_MANAGER-FE_1420076596501413903": "Please enter role code/name",
      "P_YS_OA_XTMENHUCMS_0000045155": "News",
      "P_YS_OA_XTMENHUCMS_0001258356": "Role",
      "P_YS_OA_XTMENHUCMS_0000045154": "Customize Card Template",
      "P_YS_OA_XTMENHUCMS_0001258355": "Workbench Description",
      "P_YS_OA_XTMENHUCMS_0000045153": "Please enter the button name.",
      "P_YS_OA_XTMENHUCMS_0001258354": "Workbench Info",
      "P_YS_PF_MANAGER-FE_1420076596501413900": "Please select a character workbench to bind",
      "P_YS_OA_XTMENHUCMS_0000045152": "Please select the correct picture.",
      "P_YS_OA_XTMENHUCMS_0000045151": "No access permission",
      "P_YS_PF_MANAGER-FE_1420076596501413906": "Scene APP",
      "P_YS_OA_XTMENHUCMS_0000045150": "Recommended format: PNG (size: 120px*120px)",
      "P_YS_OA_XTMENHUCMS_0000045159": "No announcements",
      "P_YS_OA_XTWENKU_0000035710": "Follow",
      "P_YS_OA_XTMENHUCMS_0000045157": "Failed to get modification information",
      "P_YS_OA_XTMENHUCMS_0001548827": "Click to download the import exception records",
      "P_YS_SD_UDHAR_0000051719": "Copy",
      "P_YS_OA_XTMENHUCMS_0001503919": "Add Level-2 Navigation",
      "P_YS_PF_PROCENTER_0000023464": "Enable",
      "P_YS_OA_app_xtyyjm_0000035733": "Save and Publish",
      "P_YS_OA_app_xtyyjm_0000035745": "Create",
      "P_YS_PF_GZTEVENT_0000025946": "Failed to obtain user Info",
      "P_YS_OA_app_xtyyjm_0000035758": "In Current Page",
      "P_YS_OA_XTMENHUCMS_0001503922": "Modify Level-2 Navigation",
      "P_YS_OA_XTMENHUCMS_0001503923": "Modify Level-1 Navigation",
      "P_YS_OA_XTMENHUCMS_0001503924": "After it is set as a virtual group, the navigation does not need to be associated with any function. It is only used for virtual grouping or displaying by category",
      "P_YS_OA_XTMENHUCMS_0001503920": "Virtual Group",
      "P_YS_OA_XTMENHUCMS_0001503921": "Add Level-1 Navigation",
      "P_YS_OA_app_xtywlj_0000037823": "Process Node",
      "P_YS_OA_XTMENHUCMS_0000045199": "Outbound Mail Server",
      "P_YS_OA_XTMENHUCMS_0000045198": "Banner Property",
      "P_YS_OA_XTMENHUCMS_0000045197": "Meeting at 6:00pm",
      "P_YS_OA_XTMENHUCMS_0000045196": "To-do Component",
      "P_YS_OA_XTMENHUCMS_0000045195": "Too many operations. Please try again later.",
      "P_YS_FI_FA_0000033363": "Category Name",
      "P_YS_OA_XTMENHUCMS_0000045194": "Work Mobile Permissions",
      "P_YS_OA_XTMENHUCMS_0000045193": "Delete Theme",
      "P_YS_OA_XTMENHUCMS_0000044939": "Summer Camp Voting",
      "P_YS_OA_XTMENHUCMS_0000044937": "Group Audit Supervision Dept",
      "P_YS_OA_XTMENHUCMS_0000044936": "Please enter height",
      "P_YS_SCM_USTOCK-UI_0000177858": "Menu Name",
      "P_YS_FED_UIMG_0000025856": "Rename",
      "P_YS_PF_GZTSYS_0000012499": "Org",
      "P_YS_OA_XTMENHUCMS_0000044933": "Xiamen Travel Tips",
      "P_YS_OA_XTMENHUCMS_0000044931": "Navigation Name is required",
      "P_YS_OA_XTMENHUCMS_0000044930": "Click to View Development File",
      "P_YS_OA_XTLCZX_0000030769": "From",
      "P_YS_OA_XTMENHUCMS_0000044928": "Incoming Mail Server",
      "P_YS_OA_XTMENHUCMS_0000044927": "Submitted At:",
      "P_YS_OA_XTMENHUCMS_0000044926": "Submitted By",
      "P_YS_OA_XTMENHUCMS_0000044925": "Reset to Defaults",
      "P_YS_OA_XTMENHUCMS_0000044924": "xxx",
      "P_YS_OA_XTMENHUCMS_0000044923": "Some of the persons you added already exist. Auto dedup has been performed",
      "P_YS_OA_XTMENHUCMS_0000044922": "No relevant tasks",
      "P_YS_OA_XTMENHUCMS_0000044921": "Navigation Style",
      "P_YS_OA_XTMENHUCMS_0000044920": "Title Announcement",
      "P_YS_HR_HRXCF_0001116208": "Authorize by User",
      "P_YS_OA_XTMENHUCMS_0000044919": "Content Area Background Area",
      "P_YS_OA_XTMENHUCMS_0000044918": "Bann Property",
      "P_YS_OA_XTMENHUCMS_0000044917": "Copy Portal",
      "P_YS_OA_XTMENHUCMS_0000044916": "Enter keywords to search",
      "P_YS_OA_XTMENHUCMS_0000044915": "Beitucheng Fairy",
      "P_YS_OA_XTMENHUCMS_0000044914": "Restore Default Template",
      "P_YS_PF_GZTVPA_0001137668": "Authorization Settings",
      "P_YS_OA_XTMENHUCMS_0000044913": "Content Area Header Settings",
      "P_YS_OA_XTMENHUCMS_0000044912": "Gradient Direction",
      "P_YS_SCM_USTOCK_0001131722": "New",
      "P_YS_OA_XTMENHUCMS_0000044911": "Not found \"",
      "P_YS_OA_XTMENHUCMS_0000044910": "Note: This is a value-added service. Please contact customer service to enable it.",
      "P_YS_SCM_USTOCK_0001131725": "Save",
      "P_YS_OA_XTMENHUCMS_0000044908": "Enable Prompt",
      "P_YS_OA_XTMENHUCMS_0000044907": "Connect using SSL",
      "P_YS_OA_XTMENHUCMS_0000044906": "The format of the picture you uploaded is not supported.",
      "P_YS_OA_XTMENHUCMS_0000044905": "Short Ads Space",
      "P_YS_OA_XTMENHUCMS_0000044904": "Select default theme color",
      "P_YS_OA_XTMENHUCMS_0000044903": "Customized bottom saved successfully",
      "P_YS_PF_MANAGER-FE_0001087862": "Group Mgmt",
      "P_YS_FED_EXAMP_0000020272": "Book",
      "P_YS_OA_XTMENHUCMS_0000044909": "Selected Color for Title Text",
      "P_YS_OA_XTMENHUCMS_0001368767": "Publishing Workbench",
      "P_YS_OA_XTMENHUCMS_0001387022": "Merchant ID Type",
      "P_YS_OA_XTMENHUCMS_0001368768": "Synchronize Logo",
      "P_YS_OA_XTMENHUCMS_0001368765": "Synchronize Name",
      "P_YS_OA_XTMENHUCMS_0001387024": "Initial - Security Officer",
      "P_YS_OA_XTMENHUCMS_0001368766": "Confirm to publish",
      "P_YS_OA_XTMENHUCMS_0001387023": "Initial - System Admin",
      "P_YS_SD_UDHWS_0000117730": "Please enter group name",
      "P_YS_OA_XTMENHU_0000046633": "Search App Name",
      "P_YS_OA_XTMENHUCMS_0001368769": "Synchronize Role",
      "P_YS_OA_XTMENHUCMS_0001368763": "Synchronize ID Type",
      "P_YS_OA_XTMENHUCMS_0001387026": "Initial - Security Auditor",
      "P_YS_OA_XTMENHUCMS_0001368764": "Synchronize Template",
      "P_YS_OA_XTMENHUCMS_0001387025": "Security Auditor",
      "P_YS_OA_XTMENHUCMS_0001387028": "Security Officer",
      "P_YS_OA_XTMENHUCMS_0001387027": "ID Type",
      "P_YS_OA_XTMENHUCMS_0000044979": "Keyword Search",
      "P_YS_OA_XTMENHUCMS_0000044978": "You can also view",
      "P_YS_FI_TP_0000071994": "Multi-choice",
      "P_YS_OA_XTMENHUCMS_0000044977": "View Permission",
      "P_YS_OA_XTMENHUCMS_0000044976": "Meeting at 2:00am",
      "P_YS_OA_XTMENHUCMS_0000044975": "Portal Name",
      "P_YS_OA_XTMENHUCMS_0001623249": "Duplicate workbench name",
      "P_YS_OA_XTMENHUCMS_0000044974": "Associated System",
      "P_YS_OA_XTMENHUCMS_0000044973": "Feedback of Project Component Test Results",
      "P_YS_OA_XTMENHUCMS_0000044971": "Image size cannot exceed 20 KB",
      "P_YS_OA_XTMENHUCMS_0000044970": "Associate Portal",
      "P_YS_OA_XTMENHUCMS_0000044969": "Are you sure you want to enable it?",
      "P_YS_OA_XTMENHUCMS_0000044968": "Are you sure you want to continue?",
      "P_YS_OA_XTMENHUCMS_0000044967": "Control Scope",
      "P_YS_OA_XTTXL_0000046015": "New Group",
      "P_YS_OA_XTMENHUCMS_0000044966": "Logo Display Mode",
      "P_YS_OA_XTMENHUCMS_0000044965": "Level-2 Navigation Name",
      "P_YS_OA_XTMENHUCMS_0000044964": "Widget",
      "P_YS_OA_XTMENHUCMS_0000044963": "Enabled only",
      "P_YS_OA_XTMENHUCMS_0000044962": "Uploading... Please wait",
      "P_YS_OA_XTMENHUCMS_0000044961": "Your modifications have not been saved. Are you sure you want to exit?",
      "P_YS_OA_XTMENHUCMS_0000044960": "Dept. Statistics-Date",
      "P_YS_FED_EXAMP_0000020208": "Picture",
      "P_YS_OA_XTMENHUCMS_0000044959": "No relevant events",
      "P_YS_OA_XTMENHUCMS_0000044958": "Delete Reminder",
      "P_YS_OA_XTMENHUCMS_0000044957": "Sorry. You don't have the permission to design the portal.",
      "P_YS_OA_XTMENHUCMS_0000044956": "After it is set as a virtual menu, the menu does not need to associate any function. It is used for virtual grouping or category display.",
      "P_YS_PF_GZTSYS_0000012039": "Partners",
      "P_YS_OA_XTMENHUCMS_0000044955": "App Name and Text Color Settings",
      "P_YS_OA_XTMENHUCMS_0000044954": "What Yonyou Knows",
      "P_YS_OA_XTMENHUCMS_0000044953": "No applications set",
      "P_YS_OA_XTMENHUCMS_0000044952": "Foshan",
      "P_YS_OA_XTMENHUCMS_0000044951": "Summer 5.8 Bullet-screen Comments",
      "P_YS_FED_FW_0000021338": "Zhejiang",
      "P_YS_OA_XTMENHUCMS_0000044950": "Enter an integer with up to 3 digits (unit: px)",
      "P_YS_OA_XTMENHUCMS_0000044949": "This is a fixed-size image that cannot be copied or stretched",
      "P_YS_OA_XTMENHUCMS_0000044948": "New Member",
      "P_YS_OA_XTMENHUCMS_0000044947": "Select Link Page",
      "P_YS_OA_XTMENHUCMS_0000044946": "Portal name cannot be empty.",
      "P_YS_OA_XTMENHUCMS_0000044945": "No Relevant Contents",
      "P_YS_OA_XTMENHUCMS_1420420649154248704": "The getAppmenus interface reports an error",
      "P_YS_FED_UIMG_0000025842": "Parent Org",
      "P_YS_OA_XTMENHUCMS_0000044944": "Already maximum 12 favorite App",
      "P_YS_OA_XTMENHUCMS_0000044943": "Group Notice",
      "P_YS_OA_XTMENHUCMS_0000044942": "Display in Center",
      "P_YS_OA_XTMENHUCMS_0000044941": "No relevant statistics report",
      "P_YS_OA_XTMENHUCMS_0000044940": "Ranking",
      "P_YS_OA_XTRIZHI_0000036782": "Maximum 10 Chars",
      "P_YS_OA_XTMENHUCMS_1420420649154248707": "The associateAppMenus interface reports an error",
      "P_YS_PF_MES-FE_0001269116": "Mark All as Read",
      "P_DIWORK_OA_XTMENHUCMS_0000065678": "Dept of Portal User",
      "P_YS_OA_XTMENHUCMS_1420430312830664704": "Multi-select left and right drag, up and down sorting",
      "P_YS_FED_EXAMP_0000020639": "Cancel",
      "P_YS_PF_AUTHF_0000096121": "ID Type",
      "P_YS_PF_GZTSYS_0001236869": "Component Settings - Web Ads Picture",
      "OA_web_ad_0000000102": "Validation Time",
      "P_YS_PF_GZTSYS_0000012792": "Pending Approval",
      "P_YS_PF_GZTSYS_0000012303": "Enterprise Docs",
      "P_YS_OA_XTMENHUCMS_0000045615": "Please enter the template name.",
      "P_YS_OA_XTMENHUCMS_0000045614": "The numbers must be integers and the sum equal to 12.",
      "P_YS_OA_XTMENHUCMS_0000045613": "No template data. Please drag from left to add row layout",
      "P_YS_OA_XTMENHUCMS_0000045612": "Please enter a positive integer",
      "P_YS_FED_EXAMP_0000020405": "Birthday",
      "P_YS_OA_XTMENHUCMS_0000045611": "Return Prompt",
      "P_YS_OA_XTMENHUCMS_0000045610": "Wrong proportion~",
      "P_YS_OA_XTMENHUCMS_0001537390": "Export",
      "P_YS_OA_XTMENHUCMS_0001537391": "Edit",
      "P_YS_OA_XTRIZHI_0000036750": "Control",
      "P_YS_OA_XTMENHUCMS_0000045609": "1:1:1",
      "P_YS_FED_UCFBASEDOC_0000025163": "Preset",
      "P_YS_OA_XTMENHUCMS_0000045608": "Change Template Layout",
      "P_YS_OA_XTMENHUCMS_0001537394": "Confirm to remove",
      "P_YS_OA_XTMENHUCMS_0000045607": "Are you sure you want to clear the current layout?",
      "P_YS_OA_XTMENHUCMS_0000045606": "Add Row Layout",
      "P_YS_OA_XTMENHUCMS_0000045605": "2:1",
      "P_YS_SD_UDHWN_0000177855": "?",
      "P_YS_OA_XTMENHUCMS_0001319032": "Failed to update the ad",
      "P_YS_OA_XTMENHUCMS_0001319031": "Confirm to delete the ad space?",
      "P_YS_OA_XTMENHUCMS_0001319030": "Ad updated successfully",
      "P_YS_OA_XTMENHUCMS_0000044991": "More > Color",
      "P_YS_OA_XTMENHUCMS_0001319029": "Confirm to delete the ad picture?",
      "P_YS_OA_XTMENHUCMS_0000044990": "Failed to get component information",
      "P_YS_OA_XTMENHUCMS_0001319028": "Ad added successfully",
      "P_YS_PF_AUTHF_0000096106": "Select ID Type",
      "P_YS_OA_XTMENHUCMS_0001319027": "Failed to add the ad",
      "P_YS_OA_XTMENHUCMS_0000044999": "Duplicate template name",
      "P_YS_OA_XTMENHUCMS_0000045604": "Personal portal set successfully",
      "P_YS_OA_XTMENHUCMS_0000044998": "Associated App",
      "P_YS_OA_XTMENHUCMS_0000045603": "Enter a custom layout ratio",
      "P_YS_OA_XTMENHUCMS_0000044997": "Are you sure you want to enable it? Log in again to make it take effect after enabling it",
      "P_YS_OA_XTMENHUCMS_0000045602": "Save As Layout Template",
      "P_YS_OA_XTMENHUCMS_0001537389": "Import",
      "P_YS_OA_XTMENHUCMS_0000044996": "Pension Division (2016)",
      "P_YS_OA_XTMENHUCMS_0000045601": "Add Custom Layout",
      "P_YS_OA_XTMENHUCMS_0000044995": "Virtual Menu",
      "P_YS_OA_XTMENHUCMS_0000045600": "Are you sure you want to delete the template?",
      "P_YS_OA_XTMENHUCMS_0000044994": "Already maximum 24 favorite Apps",
      "P_YS_OA_XTMENHUCMS_0000044993": "Failed to set template. Please try again later.",
      "P_YS_OA_XTMENHUCMS_0000044992": "Personalized Control",
      "P_YS_HR_HRJQ_0000031763": "Early Leave",
      "P_YS_OA_XTMENHUCMS_0000044989": "Disable Portal",
      "P_YS_OA_XTMENHUCMS_0000044980": "Element Library",
      "P_YS_FED_IMP_IOT0000005427": "Processing Status",
      "P_YS_OA_XTMENHUCMS_0000044988": "Wu Delun",
      "P_YS_OA_XTMENHUCMS_0000044987": "Return Prompt",
      "P_YS_OA_XTMENHUCMS_0000044986": "Processing... Please try again later!",
      "P_YS_PF_MES-FE_0001269119": "Source:",
      "P_YS_OA_XTMENHUCMS_0000044984": "Save to My Components",
      "P_YS_OA_XTMENHUCMS_0000044983": "Recommended size 120*120px",
      "P_YS_OA_XTMENHUCMS_0000044982": "Org Permission",
      "P_YS_OA_XTMENHUCMS_0000044981": "System Theme",
      "P_YS_OA_XTMENHUCMS_0001073711": "Online: ",
      "P_YS_OA_XTMENHUCMS_0001073710": "Copyright @yonyou Network Technology Co., Ltd",
      "P_YS_OA_XTTMCENTER_0000035084": "Off Shelves",
      "P_YS_HR_HRJQ_0000054657": "Org",
      "P_YS_SD_SDMAUF_0000152050": "Add Icon",
      "P_YS_PF_GZTSYS_0000015860": "Outgoing Docs",
      "P_YS_PF_GZTSYS_0000012104": "Launch Image",
      "P_YS_OA_XTTMCENTER_0000035096": "Please enter title",
      "P_YS_OA_XTTMCENTER_0000035095": "On Shelves",
      "P_YS_OA_XTMENHUCMS_0001537350": "Please enter contents",
      "P_YS_OA_XTMENHUCMS_0001537351": "Delete",
      "P_YS_OA_XTMENHU_0000046561": "Component Type",
      "P_YS_FED_EXAMP_0000019871": "Delete",
      "P_YS_OA_XTWENKU_0000035581": " files",
      "P_YS_OA_XTMENHUCMS_0001537343": "Cancel",
      "P_YS_OA_XTMENHUCMS_0001537344": "Group Introduction",
      "P_YS_OA_XTMENHUCMS_0001537345": "Please enter",
      "P_YS_OA_XTMENHUCMS_0001537346": "Group name is required",
      "P_YS_FED_EXAMP_0000020614": "Deleted successfully",
      "P_YS_OA_XTMENHUCMS_0001537347": "Modified successfully",
      "P_YS_HR_HRXCF_0001116185": "Authorize by Role",
      "P_YS_OA_XTHUATI_0000046180": "Single Choice",
      "P_YS_OA_XTMENHUCMS_0001537331": "Dept/Org",
      "P_YS_OA_XTMENHUCMS_0001540743": "Confirm to delete ${num} in batch?",
      "P_YS_OA_XTMENHUCMS_0000045222": "App Collection",
      "P_YS_OA_XTMENHUCMS_0000045221": "Content Area Setting Help",
      "P_YS_PF_GZTSYS_0001010844": "System Admin",
      "P_YS_OA_XTMENHUCMS_0000045220": "Official Navigation Template",
      "P_YS_OA_XTMENHUCMS_0000045229": "Please enter the customized bottom link.",
      "P_YS_OA_XTMENHUCMS_0000045228": "Organization and Responsibility of Internal Control System Construction of Yonyou in 2018",
      "P_YS_OA_XTMENHUCMS_0001537323": "Email (O)",
      "P_YS_OA_XTMENHUCMS_0000045227": "New Cloud Approval",
      "P_YS_OA_XTMENHUCMS_0000045226": "Tip: no more than 20 KB; size: 122px*122px",
      "P_YS_OA_XTMENHUCMS_0001256468": "Custom bottom height",
      "P_YS_OA_XTMENHUCMS_0001537325": "Added successfully",
      "P_YS_OA_XTMENHUCMS_0000045225": "Navigation Settings Help",
      "P_YS_OA_XTMENHUCMS_0000045224": "Pearl White",
      "P_YS_OA_XTMENHUCMS_0000045223": "Please enter the new customized App name.",
      "P_YS_OA_XTMENHUCMS_0001537328": "Name",
      "P_YS_OA_XTMENHUCMS_0001537340": "New member needs approval by Admin",
      "P_YS_OA_XTMENHUCMS_0001537342": "Save",
      "P_YS_PF_MANAGER-FE_0001470878": "Associated Service",
      "P_YS_OA_XTMENHUCMS_0000045211": "JPG/PNG only. Less than 5 MB",
      "P_YS_OA_XTMENHUCMS_0001540739": "Confirm to delete in batch",
      "P_YS_OA_XTMENHUCMS_0000045210": "New Category",
      "P_YS_OA_XTMENHUCMS_0001314200": "Workbench LOGO",
      "P_YS_OA_XTMENHUCMS_0001256471": "After setting it as default, the default workbench of all employees will be changed to this workbench. It is recommended to inform all employees in advance!",
      "P_YS_OA_XTMENHUCMS_0001314202": "Please enter the property name",
      "P_YS_OA_XTMENHUCMS_0001314201": "Ad space deleted successfully",
      "P_YS_SD_UDHWN_0000121432": "Please enter theme name",
      "P_YS_OA_XTMENHUCMS_0001314204": "Read",
      "P_YS_OA_XTMENHUCMS_0001314203": "Platform Service Code",
      "P_YS_OA_XTWENKU_0000035576": "Server busy",
      "P_YS_OA_XTMENHUCMS_0000045219": "Click Color Block to Edit",
      "P_YS_OA_XTMENHUCMS_0001314206": "Unread",
      "P_YS_OA_XTMENHUCMS_0000045218": "Top to Bottom",
      "P_YS_OA_XTMENHUCMS_0001314205": "The JS file size cannot exceed 3M",
      "P_YS_OA_XTMENHUCMS_0001537333": "Uploaded successfully",
      "P_YS_OA_XTMENHUCMS_0000045217": "Please enter App set name.",
      "P_YS_OA_XTMENHUCMS_0001314208": "Component Picture",
      "P_YS_OA_XTMENHUCMS_0001537334": "Click to upload",
      "P_YS_OA_XTMENHUCMS_0000045216": "Cloud Product Filing List of Yonyou Motor Co",
      "P_YS_OA_XTMENHUCMS_0001314207": "New Tree Structure",
      "P_YS_OA_XTMENHUCMS_0001537335": "Only supports JPG; max 5 Mb",
      "P_YS_OA_XTMENHUCMS_0000045215": "? Log in again for it to take effect after it is enabled.",
      "P_YS_OA_XTMENHUCMS_0001537336": "Edit Internal Group",
      "P_YS_OA_XTMENHUCMS_0000045214": "Log 2.0",
      "P_YS_OA_XTMENHUCMS_0001314209": "The ad space resource can be set in Component Settings - Web Ads Picture",
      "P_YS_OA_XTMENHUCMS_0001537337": "Group Profile Picture",
      "P_YS_OA_XTMENHUCMS_0000045213": "Yuan Sanliu",
      "P_YS_OA_XTMENHUCMS_0000045212": "System Controls",
      "P_YS_OA_XTMENHUCMS_0001537339": "Administrator",
      "P_YS_OA_XTMENHUCMS_1417407282912165888": "Is it authorized to all employees?",
      "P_YS_OA_XTMENHUCMS_0000045209": "Deleting failed. Please try again later",
      "P_YS_OA_XTMENHUCMS_0000045200": "Name required",
      "P_YS_OA_XTMENHUCMS_0001537309": "Internal Group Name",
      "P_YS_OA_XTMENHUCMS_0000045208": "Participants",
      "P_YS_OA_XTMENHUCMS_0000045207": "Navigation Block Color",
      "P_YS_OA_XTMENHUCMS_0000045205": "Customized bottom enabled successfully",
      "P_YS_OA_XTMENHUCMS_0000045204": "Zoom in (80px)",
      "P_YS_OA_XTMENHUCMS_0000045203": "Please select at least one.",
      "P_YS_OA_XTMENHUCMS_0000045202": "Enable Horizontal Navigation",
      "P_YS_OA_XTMENHUCMS_0000045201": "Official Homepage",
      "P_YS_OA_XTMENHUCMS_1422527639852154883": "Temporarily no scene APP",
      "P_YS_OA_XTMENHUCMS_1422527639852154880": "Please select the scene APP first.",
      "P_YS_OA_XTMENHUCMS_0001537320": "Tel (O)",
      "P_YS_OA_XTMENHUCMS_0001537318": "Deleted successfully",
      "P_YS_PF_METADATA_0001038634": "Revision/Version Rule Usage",
      "P_YS_OA_XTMENHUCMS_0001537312": "Internal Group ID",
      "P_YS_OA_XTMENHUCMS_0001073709": "Beijing ICP No. 15057199",
      "P_YS_OA_XTMENHUCMS_0001537314": "Owner",
      "P_YS_OA_XTMENHUCMS_0001073708": "Beijing CIN No. 11010802020548",
      "P_YS_OA_XTMENHUCMS_1422527639852154886": "Scene APP has been associated.",
      "P_YS_OA_XTLCZX_0000030299": "Added",
      "P_YS_PF_GZTTMP_0000078402": "Group",
      "P_YS_OA_XTMENHUCMS_0001314240": "The max file size is ",
      "P_YS_OA_XTMENHUCMS_0000045024": "No more than 4 characters",
      "P_YS_OA_XTMENHUCMS_0000045266": "0-10px recommended",
      "P_YS_OA_XTMENHUCMS_0001314231": "Component Callback Function Name",
      "P_YS_OA_XTMENHUCMS_0000045023": "New Theme",
      "P_YS_OA_XTMENHUCMS_0000045265": "Confirm to copy ",
      "P_YS_OA_XTMENHUCMS_0001314230": "New Component",
      "P_YS_OA_XTMENHUCMS_0000045022": "Add Level-1 Menu",
      "P_YS_OA_XTMENHUCMS_0000045264": "1400 PX Fixed Width",
      "P_YS_PF_METADATA_0001038628": "Revision/Version Rule",
      "P_YS_OA_XTMENHUCMS_0001314233": "Please enter the property key value",
      "P_YS_OA_XTMENHUCMS_0000045021": "Associated Content",
      "P_YS_OA_XTMENHUCMS_0000045263": "Back",
      "P_YS_OA_XTMENHUCMS_0001314232": "Delete Workbench",
      "P_YS_OA_XTMENHUCMS_0000045020": "No relevant favorite Apps",
      "P_YS_OA_XTMENHUCMS_0000045262": "Cumulative Amt (10,000 yuan)",
      "P_YS_OA_XTMENHUCMS_0001314235": "Select from Dashboard",
      "P_YS_OA_LCGL_0000037192": "Personal Statistics",
      "P_YS_OA_XTMENHUCMS_0000045261": "Please enter customized bottom height",
      "P_YS_OA_XTMENHUCMS_0001314234": "Workbench Manager",
      "P_YS_PF_GZTSYS_0000012730": "Navigation",
      "P_YS_OA_XTMENHUCMS_0000045260": "Please enter a name with between 1 and 12 characters.",
      "P_YS_OA_XTMENHUCMS_0001314237": "The type area is for placing the key info",
      "P_YS_OA_XTMENHUCMS_0001314236": "Allow employees to set the default workbench",
      "P_YS_OA_XTMENHUCMS_0001314239": "個人工作台",
      "P_YS_OA_XTMENHUCMS_0001314238": "Prompt of restoring the default template",
      "P_YS_OA_XTMENHUCMS_0000045029": "Current data is blank.",
      "P_YS_OA_XTMENHUCMS_0000045028": "Current Org",
      "P_YS_OA_XTMENHUCMS_0000045027": "Code",
      "P_YS_OA_XTMENHUCMS_0000045269": "Uploaded file size cannot be greater than ",
      "P_YS_OA_XTMENHUCMS_0000045026": "Pixel/px",
      "P_YS_OA_XTMENHUCMS_0000045268": "Appointment of Cadres",
      "P_YS_OA_XTMENHUCMS_0000045025": "Upload Icon",
      "P_YS_OA_XTMENHUCMS_0000045267": "Meeting at 8:00pm",
      "P_YS_OA_XTMENHUCMS_1420420752232939520": "Edit scene-based APP",
      "OA_web_ad_000000003": "Ads Space Name",
      "P_YS_OA_XTMENHUCMS_0001314251": "Change Dashboard",
      "P_YS_OA_XTMENHUCMS_0001314250": "Change Template Layout",
      "P_YS_OA_XTMENHUCMS_0000045013": "Menu Name",
      "P_YS_OA_XTMENHUCMS_0000045255": "You can add up to 24 applications.",
      "P_YS_OA_XTMENHUCMS_0001314242": "Header Background Picture",
      "P_YS_OA_XTMENHUCMS_0000045012": "Please enter a positive integer up to 3 digits.",
      "P_YS_OA_XTMENHUCMS_0000045254": "Click to Use the Layout",
      "P_YS_OA_XTMENHUCMS_0001314241": "Please select the component type",
      "P_YS_OA_XTMENHUCMS_0000045011": "Radial",
      "P_YS_OA_XTMENHUCMS_0000045253": "Jade Green",
      "P_YS_OA_XTMENHUCMS_0001314244": "Recommended: 50-100px ",
      "P_YS_OA_XTMENHUCMS_0000045010": "App Name",
      "P_YS_OA_XTMENHUCMS_0000045252": "Please enter the rows displayed.",
      "P_YS_OA_XTMENHUCMS_0001314243": "Please enter the component callback function name",
      "P_YS_OA_XTMENHUCMS_0000045251": "Network is unavailable. Please check your network settings.",
      "P_YS_OA_XTMENHUCMS_0001314246": "Click OK to restore the default template",
      "P_YS_OA_XTMENHUCMS_0000045250": "The name cannot exceed 12 characters.",
      "P_YS_OA_XTMENHUCMS_0001314245": "Enable Workbench",
      "P_YS_OA_XTMENHUCMS_0001314248": "New Enterprise Custom Component",
      "P_YS_OA_XTMENHUCMS_0001314247": "Select Service",
      "P_YS_OA_XTMENHUCMS_0001314249": "Tree Structure Selection",
      "P_YS_OA_XTMENHUCMS_0000045019": "My Component Library",
      "P_YS_OA_XTMENHUCMS_0000045018": "Meeting at 2:00pm",
      "P_YS_OA_XTMENHUCMS_0000045017": "Enterprise Portal Template",
      "P_YS_OA_XTMENHUCMS_0000045259": "NC Approval",
      "P_YS_OA_XTMENHUCMS_0000045016": "Level-2 Navigation",
      "P_YS_OA_XTMENHUCMS_0000045258": "1. Members in restricted organizations can only view contracts within the current organization",
      "P_YS_OA_XTMENHUCMS_0000045015": "Test",
      "P_YS_OA_XTMENHUCMS_0000045257": "Saved and published successfully",
      "P_YS_OA_XTMENHUCMS_0000045014": "Please enter a subject name within 12 characters.",
      "P_YS_OA_XTMENHUCMS_0000045256": "Meeting at 7:00pm",
      "OA_web_ad_000000004": "Please select the Ads space to set",
      "P_YS_OA_XTWENKU_0000035505": "Deleted successfully",
      "P_YS_OA_XTMENHUCMS_0000045002": "” related contents",
      "P_YS_OA_XTMENHUCMS_0000045244": "Component added",
      "P_YS_OA_XTMENHUCMS_0000045001": "Diagonal",
      "P_YS_OA_XTMENHUCMS_0000045243": "Workbench H5 Test Interface Joint Debugging",
      "P_YS_OA_XTMENHUCMS_0000045000": "End Color",
      "P_YS_OA_XTMENHUCMS_0000045242": "xxx",
      "P_YS_OA_XTMENHUCMS_0001314211": "New Ad Space",
      "P_YS_OA_XTMENHUCMS_0000045241": "Top 5 work hours",
      "P_YS_OA_XTMENHUCMS_0001314210": "Workbench Self-Adaptive Style",
      "P_YS_OA_XTMENHUCMS_0000045240": "Theme added successfully",
      "P_YS_OA_XTMENHUCMS_0001314213": "Failed to delete the ad",
      "P_YS_OA_XTMENHUCMS_0001314212": "Ad space added successfully",
      "P_YS_OA_XTMENHUCMS_0001314215": "Component Info",
      "P_YS_OA_XTMENHUCMS_0001314214": "Confirm to enable?",
      "P_YS_OA_XTMENHUCMS_0001314217": "Please enter the component property function name",
      "P_YS_OA_XTMENHUCMS_0001438086": "The group name already exists. Please enter another name",
      "P_YS_OA_XTMENHUCMS_0000045009": "App Settings, Sort by Dragging",
      "P_YS_OA_XTMENHUCMS_0001314216": "Please enter the workbench name",
      "P_YS_OA_XTMENHUCMS_0001438087": "Please select the group",
      "P_YS_PF_MANAGER-FE_1420076596501413894": "Selected scene-based APP",
      "P_YS_OA_XTMENHUCMS_0000045008": "Customized Bottom Link",
      "P_YS_OA_XTMENHUCMS_0001314219": "Recommended size: 230 x 190",
      "P_YS_OA_XTMENHUCMS_0001438088": "+ New Group",
      "P_YS_OA_XTMENHUCMS_0000045007": "Performance Dashboard",
      "P_YS_OA_XTMENHUCMS_0000045249": "Notice on the Merge of the Medical Company into the Joint-stock Company for Integrated Development",
      "P_YS_OA_XTMENHUCMS_0001314218": "Ads deleted successfully",
      "P_YS_OA_XTMENHUCMS_0001438089": "Please move the workbench in the current group to another group, otherwise the deletion operation cannot be performed",
      "P_YS_OA_XTMENHUCMS_0000045006": "Header Decorative Color",
      "P_YS_OA_XTMENHUCMS_0000045248": "Blank Portal Layout",
      "P_YS_OA_XTMENHUCMS_0000045005": "No projects",
      "P_YS_OA_XTMENHUCMS_0000045247": "Email Suffix",
      "P_YS_OA_XTMENHUCMS_0000045004": "Enterprise Component Library",
      "P_YS_OA_XTMENHUCMS_0000045246": "Failed to get current space",
      "P_YS_OA_XTMENHUCMS_0000045003": "Attended",
      "P_YS_OA_XTMENHUCMS_0000045245": "To guarantee the page visual effects, the uploaded navigation icons should be PNG; 122px X 122px; and no more than 20 KB.",
      "P_YS_OA_XTMENHUCMS_0001438085": "Please select the group",
      "P_YS_OA_app_xtyyjm_0000035910": "Web",
      "P_YS_OA_XTMENHUCMS_0001009159": "Add Watermark",
      "P_YS_OA_XTMENHUCMS_0000045233": "Vertically Tiled",
      "P_YS_OA_XTMENHUCMS_0001314220": "Type Page",
      "P_YS_OA_XTMENHUCMS_0000045232": "Chinese Red",
      "P_YS_OA_XTMENHUCMS_0000045231": "Operations too fast. Please try again later.",
      "P_YS_OA_XTMENHUCMS_0001314222": "The picture size cannot exceed 500KB",
      "P_YS_OA_XTMENHUCMS_0000045230": "Disable Prompt",
      "P_YS_OA_XTMENHUCMS_0001314221": "Dept of Workbench User",
      "P_YS_OA_XTMENHUCMS_0001314224": "Please enter the ad space name",
      "P_YS_OA_XTMENHUCMS_0001314223": "Please select the associated app",
      "P_YS_OA_XTMENHUCMS_0001314226": "Ad Space Size",
      "P_YS_OA_XTMENHUCMS_0001314225": "Forbid employees to set the default workbench",
      "P_YS_OA_XTMENHUCMS_0001314228": "Select ID Type",
      "P_YS_OA_XTMENHUCMS_0001314227": "Configuration Value",
      "P_YS_OA_XTMENHUCMS_0000045239": "Liu Hao-Be successful at the age of thirty and winning the world",
      "P_YS_OA_XTMENHUCMS_0000045238": "Missed Check-in",
      "P_YS_OA_XTMENHUCMS_0001314229": "Please upload the component source file",
      "P_YS_OA_XTMENHUCMS_0000045237": "Delete Portal",
      "P_YS_OA_XTMENHUCMS_0001009162": "Turned off successfully",
      "P_YS_OA_XTMENHUCMS_0000045236": "Color Block Navigation",
      "P_YS_OA_XTMENHUCMS_0001009161": "Turned on successfully",
      "P_YS_OA_XTMENHUCMS_0000045235": "New Portal",
      "P_YS_OA_XTMENHUCMS_0001009160": "Dynamic Classification Management",
      "P_YS_OA_XTMENHUCMS_0000045234": "Network error. Please try again later.",
      "P_YS_OA_XTWENKU_0000035606": "Project Docs",
      "P_YS_OA_XTMENHUCMS_0000045071": "Saved successfully",
      "P_YS_OA_XTMENHUCMS_0000045070": "Header Text Color",
      "P_YS_OA_XTMENHUCMS_0001314161": "法語",
      "P_YS_OA_XTMENHUCMS_0001314160": "日語",
      "P_YS_OA_XTMENHUCMS_0001314163": "Tree Structure",
      "P_YS_OA_XTMENHUCMS_0001314162": "List Tree Structure",
      "P_YS_OA_XTMENHUCMS_0000045068": "Customized App",
      "P_YS_OA_XTMENHUCMS_0001314154": "葡萄牙語",
      "P_YS_OA_XTMENHUCMS_0000045067": "Reset successfully",
      "P_YS_OA_XTMENHUCMS_0001314153": "【提示】若系統語言（<%=enterprise%>）內容未維護，系統將默認按當前語言（<%=current%>）內容賦值處理",
      "P_YS_OA_XTMENHUCMS_0000045066": "Coordinator",
      "P_YS_OA_XTMENHUCMS_0001314156": "研發建模服務-前端",
      "P_YS_OA_XTMENHUCMS_0000045065": "2. Organization members not subject to this rule can be set",
      "P_YS_OA_XTMENHUCMS_0001314155": "德語",
      "P_YS_OA_XTMENHUCMS_0000045064": "Camper Info",
      "P_YS_OA_XTMENHUCMS_0001314158": "請輸入...",
      "P_YS_OA_XTMENHUCMS_0000045063": "Background image size for 3-row of icons: 690×515 PX ",
      "P_YS_OA_XTMENHUCMS_0001314157": "俄語",
      "P_YS_OA_XTMENHUCMS_0000045062": "Same-period Amt (10,000 yuan)",
      "P_YS_OA_XTMENHUCMS_0000045061": "70-100px Recommended",
      "P_YS_OA_XTMENHUCMS_0001314159": "當前語言",
      "P_YS_OA_XTMENHUCMS_0000045069": "Customized Bottom Height",
      "P_YS_OA_XTLCZX_0000030265": "Loading",
      "P_YS_OA_XTMENHUCMS_0000045060": "Restored successfully",
      "P_YS_OA_XTMENHUCMS_0001314170": "There is an ad under the category. Cannot delete",
      "P_YS_OA_XTMENHUCMS_0001314172": "Please enter the component name",
      "P_YS_OA_XTMENHUCMS_0001314171": "Assc. Workbench",
      "P_YS_OA_XTMENHUCMS_0001314174": "Please enter the interface address",
      "P_YS_OA_XTMENHUCMS_0001314173": "Component Property Function Name",
      "P_YS_OA_XTMENHUCMS_0000045057": "Being Executed",
      "P_YS_OA_XTMENHUCMS_0001314165": "New Radio Type",
      "P_YS_OA_XTMENHUCMS_0000045056": "Highlight Icon",
      "P_YS_OA_XTMENHUCMS_0001314164": "意大利語",
      "P_YS_OA_XTMENHUCMS_0001438090": "Please enter the workbench name",
      "P_YS_OA_XTMENHUCMS_0000045055": "Bai Yuyu/fiona",
      "P_YS_OA_XTMENHUCMS_0000045297": "Penalty Decision",
      "P_YS_OA_XTMENHUCMS_0001314167": "Blank Workbench Layout",
      "P_YS_OA_XTMENHUCMS_0000045054": "Failed to save. Please try again later.",
      "P_YS_OA_XTMENHUCMS_0000045296": "Are you sure you want to delete this restriction rule?",
      "P_YS_OA_XTMENHUCMS_0001314166": "Service Creation Workbench",
      "P_YS_OA_XTMENHUCMS_0000045053": "Failed to save. Please try again later.",
      "P_YS_OA_XTMENHUCMS_0000045295": "(Drag to sort)",
      "P_YS_OA_XTMENHUCMS_0000045052": "Customized Bottom",
      "P_YS_OA_XTMENHUCMS_0000045294": "Test Picture",
      "P_YS_OA_XTMENHUCMS_0001314168": "The workbench name cannot exceed 20 characters",
      "P_YS_OA_XTMENHUCMS_0000045051": "Normal Icon",
      "P_YS_OA_XTMENHUCMS_0000045293": "New Schedule",
      "P_YS_OA_XTMENHUCMS_0000045050": "“ ?",
      "P_YS_OA_XTMENHUCMS_0000045292": "Modify Level-1 Menu",
      "P_YS_OA_XTMENHUCMS_0001538941": "1. Before importing, please download the template first, edit user Info according to the specified format, and then import the data;",
      "P_YS_OA_XTLCZX_0000030259": "Closed successfully",
      "P_YS_OA_XTMENHUCMS_0001538943": "2. Please fill in the Info carefully, otherwise the import may fail;",
      "P_YS_OA_XTMENHUCMS_0000045059": "Personal Design Allowed",
      "P_YS_OA_XTMENHUCMS_0001538945": "3. It is not recommended to import the same file repeatedly;",
      "P_YS_OA_XTMENHUCMS_0000045058": "Customized App Copy",
      "P_YS_FED_FW_0000022352": "Layout",
      "P_YS_FED_FW_0000021022": "Day",
      "P_YS_OA_XTMENHUCMS_0000045291": "Left to right",
      "P_YS_OA_XTMENHUCMS_0000045046": "New Task",
      "P_YS_OA_XTMENHUCMS_0000045288": "Start Color",
      "P_YS_OA_XTMENHUCMS_0000045045": "Border Round Corner",
      "P_YS_OA_XTMENHUCMS_0000045044": "Work Email Permissions",
      "P_YS_OA_XTMENHUCMS_0000045286": "Horizontal Navigation enabled successfully",
      "P_YS_OA_XTMENHUCMS_0000045043": "First Screen",
      "P_YS_OA_XTMENHUCMS_0000045042": "Are you sure you want to delete navigation?",
      "P_YS_OA_XTMENHUCMS_0000045283": "Fields in Personal Details",
      "P_YS_OA_XTMENHUCMS_0000045040": "Up to 6 level-1 menus can be added",
      "P_YS_OA_XTMENHUCMS_0000045282": "Deleted successfully",
      "P_YS_OA_XTMENHUCMS_0000045281": "Category Logo",
      "P_YS_FED_FW_0000021499": "Reset",
      "P_YS_FED_FW_0000021018": "4.0",
      "P_YS_OA_XTMENHUCMS_0000045049": "Free layout supports Chrome only. Change the browser and try again.",
      "P_YS_OA_XTMENHUCMS_0000045048": "YouZone Workbench Homepage",
      "P_YS_OA_XTMENHUCMS_0000045047": "Control Property",
      "P_YS_OA_XTMENHUCMS_0000045289": "Confirm to delete the component?",
      "P_YS_OA_XTMENHUCMS_0000045280": "Mobile Terminal Customization",
      "P_YS_OA_XTMENHUCMS_0001314150": "研发建模服务-前端",
      "P_YS_OA_XTMENHUCMS_0001314152": "系統語言",
      "P_YS_OA_XTMENHUCMS_0001314151": "韓語",
      "P_YS_OA_XTMENHUCMS_0000045035": "Portal Logo",
      "P_YS_OA_XTMENHUCMS_0000045277": "Brat",
      "P_YS_OA_XTMENHUCMS_0001314143": "默認語種",
      "P_YS_OA_XTMENHUCMS_0000045034": "Each time upload at most ",
      "P_YS_OA_XTMENHUCMS_0000045276": "Horizontal Navigation Closed successfully",
      "P_YS_OA_XTMENHUCMS_0000045033": "Zhao Zijian",
      "P_YS_OA_XTMENHUCMS_0000045275": "Opening...",
      "P_YS_OA_XTMENHUCMS_0001314145": "當前",
      "P_YS_OA_XTMENHUCMS_0000045032": "Zhang c",
      "P_YS_OA_XTMENHUCMS_0000045274": "Gem Blue",
      "P_YS_OA_XTMENHUCMS_0001314144": "當前語種",
      "P_YS_OA_XTMENHUCMS_0000045031": "Log in again to make it take effect after disabling it",
      "P_YS_OA_XTMENHUCMS_0000045273": "Link Page",
      "P_YS_OA_XTMENHUCMS_0001314147": "默認",
      "P_YS_OA_XTMENHUCMS_0000045030": "Sea Blue",
      "P_YS_OA_XTMENHUCMS_0000045272": "(Sort by Dragging)",
      "P_YS_OA_XTMENHUCMS_0001314146": "【提示】若“系統語言”為空，則按照“當前語言”進行保存。",
      "P_YS_OA_XTMENHUCMS_0000045271": "Dept. Statistics-Month",
      "P_YS_OA_XTMENHUCMS_0001314149": "簡體中文",
      "P_YS_OA_XTMENHUCMS_0000045270": "Workbench Background Management Interface Joint Debugging Test",
      "P_YS_OA_XTMENHUCMS_0001314148": "多語言設置",
      "P_YS_OA_XTMENHUCMS_0000045039": "Completion",
      "P_YS_OA_XTMENHUCMS_0000045038": "No relevant Docs",
      "P_YS_OA_XTMENHUCMS_0000045037": "Customized Theme",
      "P_YS_OA_XTMENHUCMS_0000045279": "Linear Navigation Color",
      "P_YS_OA_XTMENHUCMS_0000045036": "?",
      "P_YS_OA_XTMENHUCMS_0000045278": "Preview Effect",
      "P_YS_OA_XTMENHUCMS_0001314198": "Component Property Config",
      "P_YS_OA_XTMENHUCMS_0001314197": "Create Workbench",
      "P_YS_OA_XTMENHUCMS_0001314199": "Workbench User Role",
      "P_YS_OA_XTMENHU_0001025410": "User-defined Launch Image",
      "P_YS_OA_XTMENHU_0001025411": "The date of effective cannot be later than the date of expiration",
      "P_YS_OA_XTLCZX_0000030460": "Hour",
      "P_YS_FED_EXAMP_0000020173": "All Apps",
      "P_YS_OA_XTMENHUCMS_0001320648": "Workbench Design",
      "P_YS_OA_XTMENHUCMS_0000045099": "Associated Function",
      "P_YS_OA_XTMENHUCMS_0000045098": "Number of Stories",
      "P_YS_OA_XTMENHUCMS_0000045097": "Please enter the website in the correct format.",
      "P_YS_OA_XTMENHU_0001025409": "Upload failed. Please upload the picture no more than 300K",
      "P_YS_OA_XTMENHUCMS_0000045096": "Regulations on Using Public Meeting Rooms in Yonyou Industrial Park",
      "P_YS_OA_XTMENHUCMS_0000045095": "Level-1 Navigation",
      "P_YS_OA_XTMENHU_0001025407": "Default Launch Image",
      "P_YS_OA_XTMENHUCMS_0000045094": "Header Background Color",
      "P_YS_OA_XTMENHU_0001025408": "Suggested size: 1080*1920; no more than 300KB",
      "P_YS_OA_XTMENHU_0001025405": "Upload failed. Please upload jpg, jpeg, and png pictures",
      "P_YS_OA_XTMENHU_0001025406": "Edit Launch Image",
      "P_YS_OA_XTMENHU_0001025403": "Hint: for the time period during which the custom launch image is not set, the default picture will be used for mobile launch image",
      "P_YS_OA_XTMENHU_0001025404": "It cannot be recovered once deleted. Please confirm",
      "P_YS_OA_XTMENHU_0001025401": "Please upload launch image",
      "P_YS_OA_XTMENHU_0001025402": "The launch image for company anniversary or important notification",
      "P_YS_OA_XTMENHU_0001025400": "Click to modify",
      "P_YS_FED_FW_0000022154": "Restore",
      "P_YS_OA_XTMENHUCMS_0000045093": "Logical Sorting",
      "P_YS_OA_XTMENHUCMS_0000045091": "Feedback of Check-in Data Test Results",
      "P_YS_OA_XTMENHUCMS_0001314181": "Please enter the platform service code (optional)",
      "P_YS_OA_XTMENHUCMS_0000045090": "Confirm disable? Log in again to make it take effect after disabling it",
      "P_YS_SD_SDMAUF_0000151788": "Tab 1",
      "P_YS_OA_XTMENHUCMS_0001314183": "New Check Type",
      "P_YS_OA_XTMENHUCMS_0001314182": "Create from existing workbench page",
      "P_YS_OA_XTMENHUCMS_0001314185": "For this type of workbench, its activation/deactivation status and usage rights are controlled by the corresponding services, which are not controlled here",
      "P_YS_OA_XTMENHUCMS_0001314184": "Please select the workbench user",
      "P_YS_OA_XTMENHUCMS_0001314176": "Create by Service",
      "P_YS_OA_XTMENHUCMS_0000045089": "Selected Navigation Color",
      "P_YS_OA_XTMENHUCMS_0001314175": "Please select the service",
      "P_YS_OA_XTMENHUCMS_0000045088": "Portal User",
      "P_YS_OA_XTMENHUCMS_0001314178": "門戶",
      "P_YS_OA_XTMENHUCMS_0000045087": "Some internal groups you added already exist. Deduplication is performed automatically.",
      "P_YS_OA_XTMENHUCMS_0001314177": "The name length should be less than or equal to 20 characters",
      "P_YS_OA_XTMENHUCMS_0000045086": "Link to Enterprise Portal",
      "P_YS_OA_XTMENHUCMS_0000045085": "JPG/PNG only",
      "P_YS_OA_XTMENHUCMS_0001314179": "Wrong proportion entered",
      "P_YS_OA_XTMENHUCMS_0000045084": "Meeting at 3:00pm",
      "P_YS_OA_XTMENHUCMS_0000045083": "Please select Org",
      "P_YS_OA_XTMENHUCMS_0000045082": "Customized bottom canceled successfully",
      "P_YS_OA_XTMENHUCMS_0001314190": "Please select the resource size to set",
      "P_YS_OA_XTMENHUCMS_0000045081": "Enter up to 6 characters.",
      "P_YS_OA_XTMENHUCMS_0000045080": "Add up to 4 to-do components.",
      "P_YS_OA_XTMENHUCMS_0001314192": "Failed to delete the ad space",
      "P_YS_OA_XTMENHUCMS_0001314191": "頁簽1",
      "P_YS_OA_XTMENHUCMS_0001314194": "Property Key Value",
      "P_YS_OA_XTMENHUCMS_0001314193": "Workbench name is required",
      "P_YS_OA_XTMENHUCMS_0001314196": "%c加载资源为前端合包资源-portal",
      "P_YS_OA_XTMENHUCMS_0001314195": "New Drop-down Type",
      "P_YS_OA_XTMENHUCMS_0001314187": "Copy Workbench",
      "P_YS_OA_XTMENHUCMS_0000045078": "Control changes apply to this control only.",
      "P_YS_OA_XTMENHUCMS_0001314186": "Search Interface Key Value",
      "P_YS_OA_XTMENHUCMS_0000045077": "15 days of annual leave",
      "P_YS_OA_XTMENHUCMS_0001314189": "Please enter the URL",
      "P_YS_OA_XTMENHUCMS_0000045076": "Total Collection",
      "P_YS_OA_XTMENHUCMS_0001314188": "Component Source File",
      "P_YS_OA_XTMENHUCMS_0000045075": "Personal Design",
      "P_YS_OA_XTMENHUCMS_0000045074": "Hot Topics",
      "P_YS_OA_XTMENHUCMS_0000045073": "Summer Camp",
      "P_YS_OA_XTMENHUCMS_0000045072": "Acct Type",
      "P_YS_PF_GZTSYS_0000011913": "Moments Category",
      "P_YS_OA_XTLCZX_0000030653": "Common",
      "P_YS_FED_FW_0000022521": "Name is required",
      "P_YS_IPAAS_UCG_0001156621": "Drop-down Selection",
      "P_YS_SD_SDMA_0000037949": "Menu Name required",
      "P_YS_OA_XTGONGGAO_0000045838": "Please select Org.",
      "P_YS_OA_XTMENHUCMS_0001539173": "Total No. of Personnel Imported",
      "P_YS_OA_XTMENHUCMS_0001539174": "No. Failed",
      "P_YS_OA_XTMENHUCMS_0001539177": "No. Succeeded",
      "P_YS_OA_XTMENHUCMS_0001539170": "Back",
      "P_YS_FED_UCFBASEDOC_0000025313": "Disabled successfully",
      "P_YS_FED_FW_0000021412": "Ranking",
      "P_YS_FED_IMP-DFM-FE_0000020868": "Operation",
      "P_YS_FED_EXAMP_0000020394": "All",
      "P_YS_OA_XTHUIYI_0000046420": "Pending",
      "P_YS_OA_XTLCZX_0000030677": "Drag to Sort",
      "P_YS_FED_IMP-DFM-FE_0000020875": "Edit",
      "P_YS_FED_FW_0000021476": "Shanxi",
      "P_YS_SD_SDMB_0000147720": "Callback Function",
      "P_YS_FED_IMP_IOT0000006584": "Deleted",
      "gwportal.erp.xiaoyou": "Xiaoyou",
      "P_YS_OA_XTYUNHUIWU_0000162483": "Org",
      "P_YS_FED_FW_0000021003": "Mon",
      "P_YS_OA_app_xtywlj_0000037530": "No content",
      "P_YS_YYGJFW_YBUC_0001302338": "Component Icon",
      "P_YS_OA_XTMENHUCMS_0001095593": "No more than 4 Chars for Chinese; no more than 8 Chars for English letters"
  },
  "zhtw": {
      "P_YS_FI_CM_0000026181": "有",
      "P_YS_PF_PROCENTER_0000027341": "應用",
      "P_YS_PF_WORKBENCH-FE_0001133355": "產品模塊定制",
      "P_YS_PF_WORKBENCH-FE_0001133356": "日過期",
      "P_YS_PF_WORKBENCH-FE_0001133353": "讓我能夠運籌帷幄，時刻掌握公司的經營情況",
      "P_YS_PF_WORKBENCH-FE_0001133354": "修改業務日期",
      "P_YS_PF_WORKBENCH-FE_0001133359": "我的首選",
      "P_YS_PF_WORKBENCH-FE_0001228612": "頁面屬性設置",
      "P_YS_PF_WORKBENCH-FE_0001228611": "圖片模糊",
      "P_YS_PF_WORKBENCH-FE_0001133357": "首頁設置",
      "P_YS_PF_WORKBENCH-FE_0001228610": "圖片蒙版",
      "P_YS_PF_WORKBENCH-FE_0001133358": "門戶",
      "P_YS_PF_WORKBENCH-FE_0001133362": "最多打開20個分頁，請關閉不需要的分頁。",
      "P_YS_PF_WORKBENCH-FE_0001228609": "卡片間距",
      "P_YS_PF_GZTSYS_0000012650": "應用管理",
      "P_YS_PF_WORKBENCH-FE_0001133363": "能夠有精力更關注每一位員工，賦能與激活團隊",
      "P_YS_PF_WORKBENCH-FE_0001228608": "頁面寬度",
      "P_YS_PF_WORKBENCH-FE_0001133360": "退出後，您在當前團隊下的應用將不能再使用，相應的數據也將被刪除，請確認數據已備份",
      "P_YS_PF_WORKBENCH-FE_0001228607": "固定寬度",
      "P_YS_PF_WORKBENCH-FE_0001133361": "未知搜索結果",
      "P_YS_PF_WORKBENCH-FE_1420197920737591296": "切換佈局類型，則畫布中已存在的組件會丟失，您確定要切換嗎？",
      "P_YS_FED_UCFBASEDOC_0000025292": "未添加",
      "P_YS_PF_WORKBENCH-FE_0001133366": "用友網路科技股份有限公司 © Copyright 2018 京ICP備05007539號-24 京ICP證100714號",
      "P_YS_PF_WORKBENCH-FE_0001133367": "搜索您想查找的功能、通訊錄等 信息。",
      "P_YS_PF_WORKBENCH-FE_0001133364": "星空藍",
      "P_YS_PF_WORKBENCH-FE_0001133365": "專屬化定制",
      "P_YS_PF_WORKBENCH-FE_0001228600": "友空間歡迎您!",
      "P_YS_PF_WORKBENCH-FE_0001133368": "功能服務發布",
      "P_YS_PF_WORKBENCH-FE_0001133369": "電郵號",
      "P_YS_PF_WORKBENCH-FE_0001133370": "電話溝通",
      "P_YS_FED_FW_0000021848": "大於",
      "P_YS_PF_WORKBENCH-FE_0001133373": "· 企業級統一基礎檔案與數據管控",
      "P_YS_FED_IMP_IOT0000005567": "確認",
      "P_YS_PF_WORKBENCH-FE_0001133374": "退出全屏",
      "P_YS_FED_EXAMP_0000020762": "企業",
      "P_YS_PF_WORKBENCH-FE_0001133371": "查看您的使用記錄",
      "P_YS_OA_app_xtywlj_0000037365": "忽略",
      "P_YS_PF_WORKBENCH-FE_0001133372": "“我是一名研發工程師”",
      "P_YS_PF_WORKBENCH-FE_0001663982": "佈局比例",
      "P_YS_PF_WORKBENCH-FE_0001663983": "角色工作臺預覽狀態，關閉該流覽器頁簽結束預覽",
      "P_YS_PF_WORKBENCH-FE_0001663984": "LOGO欄設置",
      "P_YS_PF_WORKBENCH-FE_0001663985": "組件設置",
      "P_YS_FED_UCFBASEDOC_0000025043": "添加快捷方式至首頁",
      "P_YS_PF_WORKBENCH-FE_0001133377": "專家團隊，根據企業需求，提供產品方_x0008_案",
      "country66": "泰國",
      "P_YS_PF_WORKBENCH-FE_0001133378": "移交團隊",
      "country65": "新加坡",
      "P_YS_PF_WORKBENCH-FE_0001133375": "免費咨詢",
      "P_YS_PF_WORKBENCH-FE_0001133376": "· 企業級應用市場提供全方位數字化服務入口",
      "country63": "菲律賓",
      "country62": "印度尼西亞",
      "country61": "澳大利亞",
      "P_YS_PF_WORKBENCH-FE_0001133379": "請先輸入菜單名稱",
      "country60": "馬來西亞",
      "P_YS_PF_WORKBENCH-FE_0001133380": "淡雅藍",
      "P_YS_PF_WORKBENCH-FE_0001133381": "點擊確定後即將刷新頁面，是否繼續？",
      "P_YS_FED_FW_0000020965": "條",
      "P_YS_PF_WORKBENCH-FE_0001196918": "Copyright ©2021",
      "P_YS_FED_FW_0000021813": "不等於",
      "P_YS_PF_WORKBENCH-FE_0001133384": "“我是一名人力資源經理”",
      "P_YS_PF_WORKBENCH-FE_0001133385": "提供產品部署方案，可隨意選擇需要的產品",
      "P_YS_PF_WORKBENCH-FE_0001133382": "需要切換到對應的企業查看詳情，是否切換?",
      "P_YS_PF_WORKBENCH-FE_0001133383": "管理：默認企業賬號管理員可見，可通過角色授權給其他用戶",
      "P_YS_PF_YS-LOGIN_0001079475": "接口未返回數據",
      "P_YS_PF_WORKBENCH-FE_0001245170": "“門戶管理”改為“工作臺管理”，且入口從“協同配置”處移動到“系統管理”處",
      "P_YS_FED_FW_0000020962": "簡體中文",
      "P_YS_FED_FW_0000020961": "保存成功",
      "P_YS_PF_WORKBENCH-FE_0001237311": "多設備登錄提示",
      "P_YS_PF_WORKBENCH-FE_0001237312": "搜索服務名稱",
      "P_YS_PF_WORKBENCH-FE_0001237313": "登錄並剔除其他用戶",
      "P_YS_PF_WORKBENCH-FE_0001237314": "未找到相關結果",
      "P_YS_PF_WORKBENCH-FE_0001237315": "你的帳號已在其他設備或流覽器中登錄，是否強制登錄?",
      "P_YS_PF_WORKBENCH-FE_0001237316": "不登錄",
      "P_YS_PF_WORKBENCH-FE_0001237317": "登錄並保留其他用戶",
      "P_YS_PF_WORKBENCH-FE_0001237318": "你的帳號已在其他設備或流覽器中登錄，是否繼續登錄?",
      "P_YS_PF_WORKBENCH-FE_0001237319": "登錄管理提示",
      "P_YS_HR_HRPXF_0000055966": "請輸入團隊名稱",
      "P_YS_PF_WORKBENCH-FE_0001133388": "產品模塊可部署到專屬機房，免去您的安全擔憂",
      "P_YS_PF_MANAGER-FE_0001087904": "禁止用戶申請加入",
      "P_YS_PF_WORKBENCH-FE_0001133389": "收費咨詢",
      "P_YS_PF_WORKBENCH-FE_0001133386": "PC客戶端",
      "P_YS_PF_WORKBENCH-FE_0001203344": "關閉其它",
      "P_YS_PF_WORKBENCH-FE_0001133387": "解散後，當期團隊下的應用將不能再使用，相應的數據也將被刪除，請確認數據已備份",
      "P_YS_PF_WORKBENCH-FE_0001228620": "企業組件",
      "P_YS_PF_WORKBENCH-FE_0001133391": "“我是一位企業家”",
      "P_YS_PF_WORKBENCH-FE_0001133392": "mdf加載完畢，可以執行打開",
      "P_YS_PF_WORKBENCH-FE_0001271682": "用戶調研",
      "P_YS_PF_WORKBENCH-FE_0001133390": "請搜索被移交用戶",
      "P_YS_PF_WORKBENCH-FE_0001228613": "蒙版透明度",
      "P_YS_PF_WORKBENCH-FE_0001271683": "【溫馨提示】用戶調研點擊這裏使用，期待您的參與",
      "P_YS_PF_WORKBENCH-FE_0001133395": "未加入任何企業",
      "P_YS_PF_WORKBENCH-FE_0001133396": "頁面中有未保存的數據，點擊確定後將重新打開此頁面，是否繼續？",
      "P_YS_PF_WORKBENCH-FE_0001133393": "申請服務",
      "P_YS_PF_WORKBENCH-FE_0001133394": "售前人員將與您取得聯繫，和您討論定制需求",
      "P_YS_HR_HRXCF_0000056202": "電郵格式錯誤",
      "P_YS_FI_CM_0000029891": "請輸入驗證碼",
      "P_YS_PF_WORKBENCH-FE_0001133319": "退出團隊",
      "P_YS_PF_WORKBENCH-FE_0001133317": "工作效率越來越高，和團隊間溝通、協作的更便捷。",
      "P_YS_PF_WORKBENCH-FE_0001133318": "專家咨詢團隊提供一對一解決方案",
      "P_YS_PF_YS-LOGIN_0001196671": "京ICP 備05007539號-24",
      "P_YS_PF_GZTTMP_0000075890": "手機端",
      "P_YS_PF_WORKBENCH-FE_0001133311": "· 基礎假勤與薪資查詢服務",
      "P_YS_OA_XTMENHU_0000046660": "發起時間：",
      "P_YS_PF_MANAGER-FE_0001087915": "移動端主頁、summerId選填一個",
      "P_YS_PF_WORKBENCH-FE_0001133312": "馬上定制",
      "P_YS_PF_WORKBENCH-FE_0001133310": "地址：北京市海淀區北清路68號用友產業園",
      "P_YS_PF_MANAGER-FE_0001087912": "運行環境版本",
      "P_YS_PF_WORKBENCH-FE_0001133315": "歷史記錄：",
      "P_YS_PF_WORKBENCH-FE_0001133316": "用友雲，提供的人力共享服務",
      "P_YS_PF_WORKBENCH-FE_0001133313": "不切換",
      "P_YS_PF_WORKBENCH-FE_0001133314": "掃描下載客戶端",
      "P_YS_PF_WORKBENCH-FE_0001274545": "暫無新成員",
      "P_YS_PF_GZTSYS_0000015967": "設置",
      "P_YS_PF_WORKBENCH-FE_0001274544": "未知部門",
      "P_YS_PF_WORKBENCH-FE_0001274543": "請輸入組件鏈接",
      "P_YS_PF_WORKBENCH-FE_0001274542": "快捷應用",
      "P_YS_PF_MANAGER-FE_0001087908": "確認移除所選用戶？",
      "P_YS_IPAAS_UCG_0001063993": "水利、環境和公共設施管理業",
      "P_YS_IPAAS_UCG_0001063994": "信息傳輸、計算機服務和軟件業",
      "P_YS_OA_XTMENHUCMS_0000045307": "主題名稱",
      "P_YS_FED_EXAMP_0000020713": "手機號",
      "P_YS_PF_WORKBENCH-FE_0001274549": "內容名稱",
      "P_YS_OA_LCGL_0000037233": "用戶名稱",
      "P_YS_PF_WORKBENCH-FE_0001274548": "按提交時間最早",
      "P_YS_FED_UCFBASEDOC_0000025257": "搜索內容...",
      "P_YS_FED_FW_0000021874": "型別",
      "P_YS_PF_WORKBENCH-FE_0001274547": "單據類型/提交時間",
      "P_YS_PF_WORKBENCH-FE_0001274546": "最大顯示數量",
      "P_YS_FED_UCFBASEDOC_0000025253": "立即註冊",
      "P_YS_PF_WORKBENCH-FE_0001133328": "典雅灰",
      "P_YS_PF_MANAGER-FE_0001087920": "免登策略",
      "P_YS_PF_WORKBENCH-FE_0001133329": "展開標籤欄",
      "P_YS_PF_PROCENTER_0000027376": "高級設置",
      "P_YS_FED_UCFBASEDOC_0000025251": "請輸入手機號",
      "P_YS_PF_WORKBENCH-FE_0001133322": "· 更嚴格的企業成員管理",
      "P_YS_PF_MANAGER-FE_0001087926": "上傳本地圖標",
      "P_YS_PF_WORKBENCH-FE_0001133323": "成員權限",
      "P_YS_PF_WORKBENCH-FE_0001133320": "“我是一名財務經理”",
      "P_YS_PF_WORKBENCH-FE_0001133321": "業務：必須通過角色授權",
      "P_YS_PF_MANAGER-FE_0001087923": "web端打開方式",
      "P_YS_PF_WORKBENCH-FE_0001133326": "切換企業",
      "P_YS_PF_MANAGER-FE_0001087922": "請輸入服務名稱",
      "P_YS_PF_WORKBENCH-FE_0001133327": "熱線電話：010-62438888",
      "P_YS_PF_MANAGER-FE_0001087925": "圖標尺寸必須為120*120的JPG、GIF、PNG圖片且尺寸不大於5M",
      "P_YS_PF_WORKBENCH-FE_0001133324": "分組名稱,最多12個字符",
      "P_YS_PF_WORKBENCH-FE_0001133325": "數據正在加載中....",
      "P_YS_PF_WORKBENCH-FE_0001133330": "· 基於管理角色的應用權限管理",
      "P_YS_FI_FCWEB_0001047535": "全屏顯示",
      "P_YS_PF_WORKBENCH-FE_0001324054": "權限受限，無法查看",
      "P_YS_OA_LCGL_0000037221": "用戶編碼",
      "P_YS_PF_WORKBENCH-FE_0001133339": "升級為企業後您可以獲得更多權限",
      "P_YS_FED_UCFBASEDOC_0000025483": "已添加",
      "P_YS_HR_HRYGF_0000058856": "個性化設置",
      "P_YS_PF_WORKBENCH-FE_0001220704": "動態",
      "P_YS_PF_GZTSYS_0000109035": "我的常用",
      "P_YS_PF_WORKBENCH-FE_0001133333": "將鏈接發給小夥伴就可以啦",
      "P_YS_PF_MANAGER-FE_0001087937": "請選擇規模範圍",
      "P_YS_PF_WORKBENCH-FE_0001133334": "資料",
      "P_YS_PF_WORKBENCH-FE_0001133331": "關閉全部分頁",
      "P_YS_PF_WORKBENCH-FE_0001528930": "升級提醒",
      "P_YS_PF_WORKBENCH-FE_0001133332": "升級企業",
      "P_YS_PF_WORKBENCH-FE_0001133337": "莫蘭迪黃",
      "P_YS_PF_WORKBENCH-FE_0001528932": "去升級",
      "P_YS_PF_MANAGER-FE_0001087933": "管理員邀請",
      "P_YS_PF_WORKBENCH-FE_0001133338": "您的企業新增了",
      "P_YS_PF_WORKBENCH-FE_0001528931": "租戶產品形態有更新，請進行產品升級。",
      "P_YS_PF_WORKBENCH-FE_0001133335": "應用續費",
      "P_YS_PF_WORKBENCH-FE_0001133336": "請填寫圖形驗證碼",
      "P_YS_PF_GZTSYS_0000012679": "團隊管理",
      "P_YS_PF_WORKBENCH-FE_0001133340": "團隊應用",
      "P_YS_OA_XTMENHU_0000046686": "提交人：",
      "P_YS_PF_WORKBENCH-FE_0001133341": "根據您的需求，我們可以提供專家團隊上門解決方案",
      "P_YS_YYGJFW_YBUC_0001335998": "深色",
      "P_YS_FED_IMP_IOT0000005514": "下一步",
      "P_YS_IPAAS_UCG_0001063976": "採礦業",
      "P_YS_FED_FW_0000021612": "加載失敗",
      "P_YS_HR_HRXCF_0000072788": "請選擇數據來源",
      "P_YS_FED_FW_0000021852": "刷新",
      "P_YS_FED_EXAMP_0000020736": "收起",
      "P_YS_PF_MANAGER-FE_0001087941": "退出後，您在當前企業下的應用將不能再使用，相應的數據也將被刪除，請確認數據已備份",
      "P_YS_SD_SDMAF_0000137211": "恢復默認設置",
      "P_YS_PF_MANAGER-FE_0001087943": "手機號格式錯誤",
      "P_YS_PF_WORKBENCH-FE_0001497250": "分鐘】，您已超時，請重新登錄。",
      "P_YS_OA_XTRIZHI_0000036826": "我的收藏",
      "P_YS_PF_WORKBENCH-FE_0001133344": "電話：010-62438888",
      "P_YS_PF_WORKBENCH-FE_0001133345": "服務流程",
      "P_YS_PF_WORKBENCH-FE_0001133342": "用友雲，讓我們每天都可以跟蹤公司的實時財務數據，真正使財務成為業務的一部分",
      "P_YS_FI_CM_0000026177": "已審批",
      "P_YS_PF_WORKBENCH-FE_0001133343": "功能圖標",
      "P_YS_PF_MANAGER-FE_0001087945": "請輸入在友空間桌面端打開時跳轉地址",
      "P_YS_PF_WORKBENCH-FE_0001133348": "· 企業級組織架構管理",
      "P_YS_PF_WORKBENCH-FE_0001133349": "請輸入圖形驗證碼",
      "P_YS_PF_WORKBENCH-FE_0001133346": "登出",
      "P_YS_PF_WORKBENCH-FE_0001133347": "菜單路徑",
      "P_YS_PF_WORKBENCH-FE_0001133351": "確定設置當前用戶為管理員？",
      "P_YS_OA_XTMENHU_0000046675": "送祝福",
      "P_YS_PF_WORKBENCH-FE_0001133352": "我的門戶",
      "P_YS_PF_WORKBENCH-FE_0001133350": "用友集團",
      "P_YS_FED_IMP_IOT0000005503": "查詢",
      "P_YS_PF_WORKBENCH-FE_0001497249": "溫馨提示：您所在的企業帳號",
      "P_YS_PF_WORKBENCH-FE_0001497248": "）啟用了無操作時長限制【",
      "OA_web_statistics_000000106": "產品名稱",
      "P_YS_PF_WORKBENCH-FE_0001312270": "分頁設置",
      "P_YS_PF_WORKBENCH-FE_1422621673865084940": "1. 个人中心升级为通栏形式，将拥有更多的便捷入口",
      "P_YS_FED_FW_0000022099": "小於",
      "P_YS_PF_WORKBENCH-FE_1420197852018638911": "建議圖片尺寸750 * 88，大小不超2M，支持類型jpg、png。",
      "P_YS_PF_WORKBENCH-FE_1422621673865084946": "4. 工作台设计器优化，个人工作台设置按钮调整",
      "P_YS_PF_WORKBENCH-FE_1422621673865084943": "5. 角色工作台快捷菜单交互体验优化",
      "P_YS_PF_WORKBENCH-FE_0001301136": "顏色選擇",
      "P_YS_PF_WORKBENCH-FE_1422621673865084949": "支持拖拽排序，可将三个常用工作台拖拽至虚线上方并常驻显示，实现快速切换",
      "P_YS_PF_WORKBENCH-FE_0001301137": "紅色-#FF5735",
      "P_YS_PF_WORKBENCH-FE_0001301138": "藍色-#588CE9",
      "P_YS_PF_WORKBENCH-FE_0001301139": "分組條件設置",
      "P_YS_PF_WORKBENCH-FE_0001312273": "/單據概要",
      "P_YS_PF_WORKBENCH-FE_0001312272": "按接收時間最晚",
      "P_YS_PF_WORKBENCH-FE_0001312271": "按接收時間最早",
      "P_YS_PF_WORKBENCH-FE_0001301140": "報表來源",
      "P_YS_OA_XTMENHUCMS_0000045100": "背景設置",
      "P_YS_PF_WORKBENCH-FE_0001301141": "指標卡設置",
      "P_YS_OA_XTMENHUCMS_0000045340": "最前",
      "P_YS_PF_WORKBENCH-FE_0001301142": "暫無指標",
      "P_YS_PF_WORKBENCH-FE_0001301143": "確認刪除此條指標卡",
      "P_YS_FED_EXAMP_0000019931": "電郵",
      "P_YS_PF_WORKBENCH-FE_0001312269": "/單據型別",
      "P_YS_PF_WORKBENCH-FE_0001312268": "選擇過濾條件",
      "P_YS_IPAAS_UCG_0001064402": "房地產業",
      "P_YS_PF_WORKBENCH-FE_1420197852018638914": "主題顏色設置",
      "P_YS_PF_GZTSYS_0000012812": "待辦",
      "P_YS_PF_WORKBENCH-FE_1420197852018638917": "建議圖片尺寸750 * 88",
      "P_YS_OA_XTMENHUCMS_0000045344": "IOS跳轉參數",
      "P_YS_PF_GZTSYS_0000012814": "團隊",
      "000322": "请输入url地址",
      "P_YS_PF_WORKBENCH-FE_1422621673865084931": "2. 工作台切换处支持拖拽排序，前三个常驻显示，首个即为默认工作台",
      "P_YS_PF_WORKBENCH-FE_1422621673865084934": "3. 全局导航新增领域云并支持五级导航，支持抽屉形式展开收起",
      "P_YS_PF_WORKBENCH-FE_1420197852018638920": "小裝飾修飾",
      "P_YS_YYGJFW_YBUC_0001337063": "顯示圖標",
      "P_YS_PF_WORKBENCH-FE_1422621673865084937": "定义“企业自定义”",
      "P_YS_FED_EXAMP_0000019939": "確定",
      "P_YS_IPAAS_UCG_0001064652": "製造業",
      "P_YS_PF_WORKBENCH-FE_1420197852018638926": "收藏和歷史",
      "P_YS_IPAAS_UCG_0001064416": "環境和公共設施管理業、社會保障和社會組織",
      "P_YS_PF_WORKBENCH-FE_1420197852018638923": "允許用戶修改",
      "P_YS_PF_WORKBENCH-FE_1420197852018638929": "商務藍",
      "P_YS_PF_WORKBENCH-FE_1420197852018638932": "標籤欄-默認logo",
      "000357": "删除文件",
      "P_YS_PF_WORKBENCH-FE_1422621673865084928": "自建子领域暂时统一归于“企业自定义”中，管理员可在“菜单管理”中找到该子领域，自主将其归类于某个特定的领域云中",
      "P_YS_PF_ANALYTIC-FE_0001652738": "編輯主題",
      "P_YS_OA_LCSJ_0001130313": "之前",
      "P_YS_PF_WORKBENCH-FE_0001301366": "用戶社區",
      "P_YS_PF_WORKBENCH-FE_1420197852018638935": "縱向分割線",
      "P_YS_FI_IA_0000054929": "單據型別",
      "P_YS_PF_WORKBENCH-FE_1420197852018638938": "頭部背景",
      "P_YS_HR_HRYGF_0000058823": "升級",
      "P_YS_PF_WORKBENCH-FE_1420197852018638944": "圖示選中",
      "P_YS_PF_WORKBENCH-FE_1420197852018638941": "標題選中",
      "P_YS_PF_WORKBENCH-FE_0001312267": "/提交人",
      "P_YS_PF_WORKBENCH-FE_0001301129": "綠色-#18B681",
      "P_YS_PF_PC-UI_0000167466": "過濾區",
      "P_YS_PF_WORKBENCH-FE_0001274556": "”調用失敗，DomID：",
      "P_YS_SD_UDHWN_0000122417": "白色",
      "P_YS_PF_WORKBENCH-FE_0001274555": "\"磁貼的渲染函數“",
      "P_YS_PF_WORKBENCH-FE_0001274554": "按提交時間最晚",
      "P_YS_PF_WORKBENCH-FE_0001301130": "請填寫統計名稱",
      "P_YS_PF_WORKBENCH-FE_0001274553": "磁貼的屬性渲染失敗“",
      "P_YS_PF_WORKBENCH-FE_0001301131": "單據選擇",
      "P_YS_PF_AUTHF_0000096078": "20條/頁",
      "P_YS_PF_WORKBENCH-FE_0001274552": "請輸入最大顯示數量",
      "P_YS_PF_WORKBENCH-FE_0001301132": "深灰色-#333333",
      "P_YS_PF_WORKBENCH-FE_0001274551": "請輸入請求地址",
      "P_YS_PF_WORKBENCH-FE_0001301133": "統計名稱",
      "P_YS_IPAAS_UCG_0001064631": "科學研究、技術服務和地質勘查業",
      "P_YS_PF_WORKBENCH-FE_0001274550": "按單據類型",
      "P_YS_PF_WORKBENCH-FE_0001301134": "黃色-#FFA600",
      "P_YS_PF_WORKBENCH-FE_0001301135": "公告通知歷史",
      "P_YS_SD_SDSD-FE_0001301389": "企業自定義",
      "P_YS_PF_WORKBENCH-FE_1420197852018638947": "Logo更換",
      "P_YS_PF_WORKBENCH-FE_0001238259": "待辦通知",
      "P_YS_PF_WORKBENCH-FE_0001274558": "介面返回格式",
      "P_YS_OA_XTMENHUCMS_0000045311": "統一審批中心",
      "P_YS_PF_WORKBENCH-FE_0001274557": "請輸入內容名稱",
      "P_YS_PF_WORKBENCH-FE_0001133399": "移交團隊後您將不再有管理員權限。",
      "P_YS_PF_WORKBENCH-FE_0001133397": "鏈接複製成功，趕快發送給你的小夥伴吧!",
      "P_YS_PF_WORKBENCH-FE_0001133398": "數字化工作入口",
      "country84": "越南",
      "P_YS_OA_XTTMCENTER_0000035122": "管理員",
      "P_YS_OA_XTMENHUCMS_0000045143": "快捷應用",
      "P_YS_OA_XTMENHUCMS_0000045382": "Android下載地址",
      "P_YS_OA_XTMENHUCMS_0000045140": "流式布局",
      "P_YS_FED_IMP_IOT0000011425": "關閉",
      "P_YS_SD_SDMA_0000060355": "幫助中心",
      "P_YS_IPAAS_UCG_0001064207": "居民服務和其他服務業",
      "P_YS_FI_CM_0000029021": "跳至",
      "P_YS_FED_UCFBASEDOC_0000025096": "您確認要刪除服務",
      "country95": "緬甸",
      "P_YS_SCM_PU_0000028725": "普通",
      "P_YS_OA_XTMENHUCMS_0000045376": "服務器地址",
      "P_YS_FED_FW_0000020955": "停用",
      "P_YS_OA_XTMENHUCMS_0000045375": "移動端主頁",
      "P_YS_PF_GZTSYS_0000012860": "動態",
      "P_YS_PF_WORKBENCH-FE_0001663980": "切換佈局類型，則畫布中已存在的磁貼會丟失，您確定要切換嗎？",
      "P_YS_PF_WORKBENCH-FE_0001663981": "頁簽欄設置",
      "P_YS_FI_CM_0000030025": "到期時間",
      "P_YS_FED_EXAMP_0000019906": "更多",
      "P_YS_PF_WORKBENCH-FE_0001217140": "請至少選擇一個客戶端",
      "P_YS_OA_XTMENHUCMS_0000045121": "暫無搜索結果",
      "P_YS_OA_XTMENHUCMS_0000045362": "Android跳轉參數",
      "P_YS_PF_WORKBENCH-FE_0001663979": "當前頁面為",
      "P_YS_OA_XTTMCENTER_0000035140": "退出",
      "P_YS_PF_WORKBENCH-FE_0001625479": "取消設置",
      "P_YS_PF_WORKBENCH-FE_0001663975": "固定寬度（1400px）",
      "P_YS_PF_WORKBENCH-FE_0001663976": "組件間距",
      "P_YS_PF_WORKBENCH-FE_0001663977": "畫布設置",
      "P_YS_PF_WORKBENCH-FE_0001663978": "組件頭部設置",
      "P_YS_HR_HRJQ_0000031030": "最後",
      "P_YS_OA_XTMENHUCMS_0000045366": "在當前頁面打開",
      "P_YS_FED_UCFBASEDOC_0000025079": "發布失敗",
      "P_YS_PF_WORKBENCH-FE_1422621673865084952": "6. 支持企业自建场景化APP并绑定至指定工作台",
      "P_YS_SCM_PU_0000028707": "商家",
      "P_YS_SD_UDHWN_0000122693": "最近一周",
      "P_YS_PF_WORKBENCH-FE_0001565488": "青啤綠",
      "P_YS_PF_WORKBENCH-FE_0001625482": "工作臺版本",
      "P_YS_OA_XTMENHUCMS_0000045353": "IOS下載地址",
      "P_YS_PF_WORKBENCH-FE_0001625481": "支持個人工作臺",
      "P_YS_OA_XTMENHUCMS_0000045352": "發布客戶端",
      "P_YS_PF_WORKBENCH-FE_0001625484": "｜正在編輯：",
      "P_YS_OA_XTMENHUCMS_0000045351": "請輸入應用名稱",
      "P_YS_PF_WORKBENCH-FE_0001625483": "工作臺設計器",
      "P_YS_PF_GZTSYS_0000011990": "門戶頁面管理",
      "P_YS_PF_WORKBENCH-FE_0001625480": "默認業務單元",
      "P_YS_PF_WORKBENCH-FE_1420197852018638902": "拖動至第一位則為默認工作臺",
      "P_YS_OA_XTMENHUCMS_0000045359": "在新頁面打開",
      "P_YS_PF_WORKBENCH-FE_1420197852018638908": "選中主題不允許停用",
      "P_YS_PF_WORKBENCH-FE_0001625485": "搜索組件名稱",
      "P_YS_PF_WORKBENCH-FE_1420197852018638905": "背景類型",
      "P_YS_PF_WORKBENCH-FE_0001229381": "選擇磁貼",
      "P_YS_PF_WORKBENCH-FE_0001229380": "拖拽進行排序",
      "P_YS_PF_WORKBENCH-FE_0001229385": "建議為87的整數倍",
      "P_YS_PF_WORKBENCH-FE_0001229384": "自定義高度",
      "P_YS_PF_WORKBENCH-FE_0001229383": "內容設置",
      "P_YS_PF_WORKBENCH-FE_0001229382": "富文本內容設置",
      "P_YS_OA_XTLCZX_0000030376": "更換",
      "P_YS_PF_WORKBENCH-FE_0001229388": "顯示姓名",
      "P_YS_PF_WORKBENCH-FE_0001229387": "顯示通知",
      "P_YS_PF_WORKBENCH-FE_0001229386": "組件高度",
      "P_YS_PF_GZTSYS_0000013194": "工作台",
      "P_YS_PF_GZTSYS_0001025942": "門戶廣告位設置",
      "P_YS_IPAAS_UCG_0001064361": "農、林、牧、漁業",
      "P_YS_OA_XTTXL_0000045990": "管理",
      "P_YS_OA_XTSPZX_0000034652": "排序",
      "P_YS_FED_UCFBASEDOC_0000025619": "添加文件夾",
      "P_YS_IPAAS_UCG_0001064369": "國際組織",
      "P_YS_FED_FW_0000022488": "本月",
      "P_YS_FED_UCFBASEDOC_0000025614": "手機",
      "P_YS_FED_UCFORG_0000066189": "顯示內容",
      "P_YS_OA_XTLCZX_0000030141": "選擇布局",
      "P_YS_PF_WORKBENCH-FE_0001229374": "廣告位資源可在“門戶廣告位設置”中設置",
      "P_YS_PF_WORKBENCH-FE_0001229373": "存在多張廣告圖時，設定圖片播放間隔",
      "P_YS_PF_WORKBENCH-FE_0001229377": "是否啟用自定義",
      "P_YS_PF_WORKBENCH-FE_0001229376": "關聯廣告",
      "P_YS_PF_WORKBENCH-FE_0001229375": "圖片輪播間隔",
      "P_YS_PF_WORKBENCH-FE_0001229379": "搜索應用名稱",
      "P_YS_OA_XTMENHUCMS_0000045174": "廣告位",
      "P_YS_IPAAS_UCG_0001064138": "電力、熱力、燃氣及水的生產和供應業",
      "P_YS_FED_UCFBASEDOC_0000024755": "刪除文件夾",
      "P_YS_FED_UCFBASEDOC_0000025601": "請輸入電郵",
      "P_YS_FED_UCFBASEDOC_0000024752": "您確認要刪除此項服務？",
      "P_YS_SD_SDMAUF_0000152315": "之後",
      "P_YS_PF_GZTLOG_0000025988": "解鎖",
      "P_YS_FED_UCFBASEDOC_0000024748": "您確認要批量刪除嗎？",
      "P_YS_FED_FW_0000022466": "預設",
      "P_YS_FED_FW_0000021376": "中國",
      "P_YS_OA_XTSPZX_0000034639": "領域",
      "P_YS_FED_FW_0000021379": "北京",
      "P_YS_FED_UCFBASEDOC_0000024982": "不保存",
      "P_YS_OA_XTMENHUCMS_0000045168": "消息中心",
      "P_YS_SCM_STOCK_0000034455": "未讀",
      "P_YS_PF_WORKBENCH-FE_0001241019": "工作台",
      "P_YS_PF_ANALYTIC-FE_0001651354": "表格設置",
      "P_YS_FED_FW_0000022483": "：",
      "P_YS_OA_XTTMCENTER_0000035108": "用友雲市場",
      "P_YS_PF_MANAGER-FE_0001120439": "錯誤的參數：applicationId",
      "P_YS_PF_WORKBENCH-FE_0001302733": "過濾區渲染失敗",
      "P_YS_PF_GZTLOG_0000025976": "鎖定",
      "P_YS_PF_GZTSYS_0000012092": "租戶升級",
      "P_YS_PF_GZTLOG_0000025975": "自定義",
      "P_YS_FED_FW_0000022475": "所屬行業",
      "P_YS_OA_XTMENHUCMS_0000045158": "工作門戶",
      "P_YS_FI_CM_0000029097": "今天",
      "P_YS_PF_WORKBENCH-FE_1420197852018638950": "更多個性化設置",
      "P_YS_FI_FA_0000056249": "新增卡片",
      "P_YS_OA_XTLCZX_0000030333": "接收時間",
      "P_YS_PF_WORKBENCH-FE_1420197852018638953": "文字選中",
      "P_YS_FED_FW_0000022290": "小於等於",
      "P_YS_YYGJFW_YYSJQ-FE_0001661128": "顏色背景",
      "P_YS_PF_WORKBENCH-FE_1420197852018638956": "logo更換",
      "P_YS_FED_UCFBASEDOC_0000024960": "發布",
      "P_YS_FED_FW_0000022064": "暫無資料",
      "P_YS_PF_WORKBENCH-FE_0001202943": "固定導航",
      "P_YS_PF_WORKBENCH-FE_0001202944": "菜單鎖定",
      "P_YS_PF_WORKBENCH-FE_0001202945": "導航切換",
      "P_YS_PF_WORKBENCH-FE_0001202946": "解除固定",
      "P_YS_PF_WORKBENCH-FE_0001202941": "快捷導航",
      "P_YS_PF_WORKBENCH-FE_0001202942": "全局導航",
      "P_YS_SCM_USTOCK-UI_0000173476": "業務設置",
      "country886": "中國台灣",
      "P_YS_FI_FCWEB_0001048065": "工作台設置",
      "P_YS_OA_XTSPZX_0000034846": "展開",
      "P_YS_SD_SDMB_0000146348": "服務支持",
      "P_YS_PF_WORKBENCH-FE_0001202947": "快捷導航設置",
      "P_YS_FED_UCFBASEDOC_0000024951": "無內容的分組在首頁不可見",
      "P_YS_PF_GZTSYS_0000012902": "其它",
      "P_YS_PF_PROCENTER_0000022591": "校驗失敗",
      "P_YS_PF_WORKBENCH-FE_0001212762": "快捷搜索",
      "P_YS_OA_XTLCZX_0000030351": "顯示",
      "P_YS_FED_FW_0000022274": "研發中心",
      "P_YS_SD_SDMAUF_0000152518": "還有",
      "P_YS_PF_WORKBENCH-FE_0001153504": "不再提醒",
      "P_YS_SD_SDMB_0000138761": "經典藍",
      "P_YS_PF_WORKBENCH-FE_0001137268": "忽略，不再提醒",
      "P_YS_FED_UCFBASEDOC_0000024702": "請選擇單據",
      "P_YS_FED_UCFBASEDOC_0000024942": "加入",
      "P_YS_OA_XTLCZX_0000030589": "顏色",
      "P_YS_OA_app_xtyyjm_0000035755": "待審批",
      "P_YS_OA_XTSPZX_0000034819": "確認",
      "P_YS_PF_YHTT_0001204706": "我的語言",
      "P_YS_FED_EXAMP_0000020075": "操作成功",
      "P_YS_FED_UCFBASEDOC_0000024938": "刪除服務",
      "P_YS_FED_UCFBASEDOC_0000024930": "請輸入聯繫人姓名",
      "P_YS_OA_app_xtyyjm_0000035750": "成員",
      "P_YS_PF_WORKBENCH-FE_0001212760": "”相關結果，請重新輸入",
      "P_YS_PF_WORKBENCH-FE_0001212761": "未搜索到“",
      "P_YS_SD_SDMB_0000139191": "消息詳情",
      "P_YS_PF_MANAGER-FE_0001087832": "申請權限",
      "P_YS_OA_XTMENHUCMS_0000044935": "發言",
      "P_YS_PF_GZTSYS_0000109255": "獲取驗證碼失敗",
      "P_YS_OA_XTMENHUCMS_0000044934": "長廣告位",
      "P_YS_OA_YBMOB_0000036991": "審批詳情",
      "P_YS_SD_SDMA_0000123136": "主頁",
      "P_YS_PF_MANAGER-FE_0001087834": "請輸入在友空間APP打開時跳轉地址",
      "P_YS_PF_MANAGER-FE_0001087836": "請輸入IOS下載地址",
      "P_YS_FED_EXAMP_0000020236": "複製鏈接",
      "P_YS_OA_XTMENHU_0000046620": "鏈接名稱",
      "P_YS_OA_app_xtywlj_0000037874": "屬性設置",
      "P_YS_SD_UDHMC_0000051143": "請輸入企業名稱",
      "P_YS_OA_XTMENHUCMS_0000044932": "活力橙",
      "P_YS_FED_UCFBASEDOC_0000025215": "登錄",
      "P_YS_OA_app_xtywlj_0000037879": "所屬分類",
      "P_YS_PF_GZTSYS_0001127465": "儀表板",
      "P_YS_PF_MANAGER-FE_0001087842": "跳轉參數",
      "P_YS_PF_MANAGER-FE_0001087841": "全員邀請",
      "P_YS_OA_XTMENHUCMS_0000044929": "主題設置",
      "P_YS_PF_MANAGER-FE_0001087844": "請選擇是否原生",
      "P_YS_PF_MANAGER-FE_0001087843": "請輸入上傳到iUAP平台的安裝包ID",
      "P_YS_FED_FW_0000055346": "請選擇資料",
      "P_YS_PF_MANAGER-FE_0001087840": "Web端主頁",
      "P_YS_FI_CM_0000029500": "頁",
      "P_YS_FI_FP_0000034914": "本年",
      "P_YS_PF_MANAGER-FE_0001087846": "是否離線應用",
      "P_YS_PF_MANAGER-FE_0001087848": "邀請規則",
      "P_YS_PF_MANAGER-FE_0001087847": "請輸入在Web瀏覽器打開時跳轉地址",
      "P_YS_FED_EXAMP_0000020005": "模板",
      "P_YS_FED_EXAMP_0000020004": "共",
      "P_YS_FI_CM_0000028899": "退出登錄",
      "P_YS_OA_XTLCZX_0000030776": "正常",
      "P_YS_FED_UCFBASEDOC_0000025203": "獲取驗證碼",
      "P_YS_PF_WORKBENCH-FE_0001233465": "开始了解",
      "P_YS_OA_XTMENHU_0000046618": "我的項目",
      "P_YS_FED_IMP_IOT_0000724713": "查詢",
      "P_YS_FED_UCFBASEDOC_0000025200": "移動",
      "P_YS_PF_MANAGER-FE_0001087852": "請上傳服務圖標",
      "P_YS_HR_HRSBF_0001183870": "個人設置",
      "P_YS_OA_XTLCZX_0000030555": "平鋪",
      "P_YS_PF_WORKBENCH-FE_0001232171": "剩餘數",
      "P_YS_HR_HRJQ_0000030940": "補考勤",
      "P_YS_PF_WORKBENCH-FE_0001232170": "更早",
      "P_YS_PF_MANAGER-FE_0001087858": "置頂優先級",
      "P_YS_FED_EXAMP_0000020016": "首頁",
      "P_YS_OA_XTMENHU_0000046646": "鏈接邀請",
      "P_YS_SD_SDMA_0000098308": "頁面設置",
      "P_YS_PF_WORKBENCH-FE_0001245211": "左右排列",
      "P_YS_PF_GZTSYS_0000013325": "標籤",
      "P_YS_OA_XTMENHU_0000046644": "空間管理",
      "P_YS_PF_METADATA_0000085782": "產品編碼",
      "P_YS_OA_app_xtyyjm_0000035782": "選擇報表",
      "P_YS_PF_WORKBENCH-FE_0001232168": "獲取數據異常，請刷新",
      "P_YS_FED_FW_0000021777": "不包含",
      "P_YS_OA_app_xtywlj_0000037618": "業務",
      "P_YS_PF_WORKBENCH-FE_0001232169": "佔用率",
      "P_YS_PF_WORKBENCH-FE_0001232166": "授權佔用明細",
      "P_YS_IMP_DFM-UI_0001273087": "寬鬆",
      "P_YS_PF_WORKBENCH-FE_0001232167": "授權數",
      "P_YS_PF_WORKBENCH-FE_0001232164": "消息分類",
      "P_YS_PF_MANAGER-FE_0001087863": "相關操作",
      "P_YS_PF_WORKBENCH-FE_0001133309": "系統：僅企業賬號管理員可見",
      "P_YS_PF_WORKBENCH-FE_0001232165": "最近三天",
      "P_YS_PF_MANAGER-FE_0001087865": "pc客戶端內",
      "P_YS_PF_WORKBENCH-FE_0001232163": "部署費",
      "P_YS_SD_SDMAUF_0000151876": "請填寫",
      "P_YS_PF_MANAGER-FE_0001087868": "請選擇所屬行業",
      "P_YS_PF_MANAGER-FE_0001087867": "圖標尺寸須為120*120，且不大於5M！",
      "P_YS_PF_WORKBENCH-FE_0001245212": "上下排列",
      "P_YS_OA_app_xtywlj_0001081532": "來源租戶",
      "P_YS_SD_SDMB-UI_0001160783": "顯示頭像",
      "P_YS_PF_WORKBENCH-FE_0001145762": "已知曉，不再提示",
      "P_YS_FED_FW_0000021785": "包含",
      "P_YS_PF_WORKBENCH-FE_0001145764": "您購買的產品即將過期",
      "P_YS_PF_WORKBENCH-FE_0001145763": "您購買的產品已經到期",
      "P_YS_SD_SDMB_0000146962": "返回頂部",
      "P_YS_PF_WORKBENCH-FE_0001145765": "立即查看",
      "P_YS_PF_MANAGER-FE_0001087877": "中國香港特別行政區",
      "P_YS_PF_MANAGER-FE_0001087870": "規模範圍",
      "P_YS_PF_MANAGER-FE_0001087873": "禁止邀請",
      "P_YS_FI_CM_0000029930": "我知道了",
      "P_YS_PF_MANAGER-FE_0001087872": "不能多於6個漢字或者12個字母",
      "P_YS_PF_PROCENTER_0000023062": "其他",
      "P_YS_HR_HRPXF_0000055999": "發榮耀",
      "P_YS_OA_XTSPZX_0000034691": "提交人",
      "OA_web_footer_000000023": "京公網安備 11010802021935號",
      "P_YS_FED_UCFBASEDOC_0000025417": "重命名文件夾",
      "P_YS_FED_FW_0000022200": "當前",
      "P_YS_PF_PROCENTER_0000026339": "聯繫人信息",
      "P_YS_HR_HRJQF_0000054222": "日期範圍",
      "P_YS_FED_FW_0000022205": "下移",
      "P_YS_PF_MANAGER-FE_0001087885": "企業頭像",
      "P_YS_PF_MANAGER-FE_0001087882": "下載地址",
      "P_YS_PF_MANAGER-FE_0001087884": "禁止",
      "P_YS_PF_MANAGER-FE_0001087883": "推薦到移動端首頁",
      "P_YS_SD_SDMAUF_0000151808": "∨",
      "P_YS_PF_METADATA_0000084287": "占用數",
      "P_YS_PF_WORKBENCH-FE_0001371281": "綁定服務",
      "P_YS_OA_XTSPZX_0000034688": "數據來源",
      "P_YS_FED_EXAMP_0000020207": "通過",
      "P_YS_FED_FW_0000022215": "分組",
      "P_YS_FED_UCFBASEDOC_0000025400": "您確認要刪除此項？",
      "P_YS_PF_MANAGER-FE_0001087899": "上傳圖標失敗！",
      "P_YS_PF_MANAGER-FE_0001087893": "最多60個字符",
      "P_YS_PF_MANAGER-FE_0001087895": "集成免登策略",
      "P_YS_OA_XTMENHU_0000046601": "最多添加19個應用",
      "P_YS_OA_XTSPZX_0000034674": "添加應用",
      "P_YS_OA_XTMENHU_0000046840": "暫無記錄，快去手機端打卡吧~",
      "P_YS_PF_GZTLOG_0000028817": "請求失敗",
      "P_YS_OA_XTSPZX_0000034678": "企業賬號",
      "P_YS_PF_MANAGER-FE_0001087891": "根據組織機構負責人顯示上下級",
      "P_YS_OA_XTRIZHI_0000036793": "取消編輯",
      "P_YS_OA_XTMENHU_0000046605": "待辦審批",
      "P_YS_PF_GZTTMP_0000079164": "消息狀態",
      "P_YS_YYGJFW_YYSJQ-FE_0001661175": "圖片背景",
      "P_YS_PF_METADATA_0000088627": "回報問題",
      "P_YS_OA_XTSPZX_0000034661": "移動端",
      "P_YS_PF_WORKBENCH-FE_0001204986": "請輸入服務名稱(最多15個漢字)",
      "P_YS_FED_EXAMP_0000020462": "清空",
      "P_YS_FI_GL_0000088728": "允許",
      "P_YS_FED_FW_0001097161": "頁面中有未保存的數據，點擊確定後將關閉頁面，是否繼續？",
      "P_YS_PF_WORKBENCH-FE_0001620597": "請檢查頁面中有無未保存的數據",
      "P_YS_PF_WORKBENCH-FE_0001620596": "點擊確定後將關閉頁面，是否繼續？",
      "P_YS_SD_SDMAF_0000136493": "發郵件",
      "P_YS_PF_MES-FE_0001269113": "單據標題",
      "P_YS_SD_SDMAUF_0000153186": "企業地址",
      "P_YS_OA_XTMENHU_0000046582": "最晚打卡：",
      "P_YS_SD_SDMA-UI_0001140473": "組件鏈接",
      "P_YS_FI_ETL_0000111653": "本地",
      "P_YS_PF_WORKBENCH-FE_0001233073": "菜單結構有所改動",
      "P_YS_PF_WORKBENCH-FE_0001233072": "切換舊版",
      "P_YS_PF_WORKBENCH-FE_0001233071": "新菜單",
      "P_YS_PF_WORKBENCH-FE_0001233070": "個性化配置與租戶切換",
      "P_YS_FED_FW_0000021969": "上傳圖片",
      "P_YS_FED_IMP_IOT0000005442": "刪除成功",
      "P_YS_OA_XTMENHU_0000046575": "組件地址",
      "P_YS_PF_PRINT_0000072986": "暫無相關內容",
      "P_YS_PF_GUIDE_0001071542": "必須是一個圖片",
      "P_YS_PF_YS-LOGIN_0001079584": "你點擊了這個圖標；調用參數為：",
      "P_YS_PF_WORKBENCH-FE_0001233069": "此處可進行菜單的切換",
      "P_YS_PF_WORKBENCH-FE_0001233068": "租戶切換",
      "P_YS_FED_UCFBASEDOC_0000025165": "添加分組",
      "P_YS_PF_WORKBENCH-FE_1421570875756380169": "導航菜單添加領域雲概念，原有一級改為子領域，分別置於領域雲下；領域雲下含有多個子領域，可以抽屜形式進行收起",
      "P_YS_FED_UCFBASEDOC_0000025164": "拖動下方磁貼至右側所需位置",
      "P_YS_PES_PES-FE_0001108409": "用友紅",
      "P_YS_SD_SDMAF_0000137369": "自適應",
      "P_YS_PF_WORKBENCH-FE_1421570875756380163": "個人中心體驗提升",
      "P_YS_PF_WORKBENCH-FE_1421570875756380166": "定義“未分組”",
      "P_YS_OA_XTLCZX_0000030707": "同意",
      "P_YS_PF_WORKBENCH-FE_1421570875756380160": "工作臺切換體驗優化",
      "P_YS_FED_IMP_IOT0000008702": "複製",
      "P_YS_FED_IMP_IOT_0000129791": "功能名稱",
      "P_YS_PF_GZTTMP_0000079071": "單據時間",
      "P_YS_PF_MES-FE_0001269123": "單據概要",
      "P_YS_PF_MES-FE_0001269122": "介面錯誤",
      "P_YS_PF_WORKBENCH-FE_1421570875756380175": "功能位置調整",
      "P_YS_PF_WORKBENCH-FE_1421570875756380172": "定義全局菜單級次",
      "P_YS_PF_WORKBENCH-FE_1421570875756380178": "個人中心重新設計，為通頂形式，添加更多個人用戶的配置項",
      "P_YS_HR_HRCOREF_0000058490": "下載文件",
      "P_YS_PF_GZTSYS_0000012764": "審批中心",
      "P_YS_PF_PFRPT_0001631363": "標題文字",
      "P_YS_FED_IMP_IOT0000005424": "編輯",
      "P_YS_FED_EXAMP_0000020660": "我的",
      "P_YS_PF_AUTHF_0000096113": "5條/頁",
      "P_YS_FED_FW_0000021948": "分類",
      "P_YS_FED_IMP_IOT0000006999": "默認篩選",
      "P_YS_FED_FW_0000177597": "立即升級",
      "P_YS_OA_XTMENHUCMS_0000044985": "組件",
      "P_YS_PF_WORKBENCH-FE_0001133438": "用友雲，讓我的工作越來越有章法",
      "P_YS_PF_WORKBENCH-FE_0001133439": "菜單入口：",
      "P_YS_PF_WORKBENCH-FE_0001133432": "“我是一名IT運維經理”",
      "P_YS_FI_FCWEB_0001048519": "最近使用",
      "P_YS_PF_WORKBENCH-FE_0001133433": "未購買開通任何服務",
      "P_YS_OA_XTMENHU_0000046780": "暫無審批",
      "P_YS_PF_WORKBENCH-FE_0001133430": "添加分頁",
      "P_YS_PF_WORKBENCH-FE_0001133431": "缺少必須參數：serviceCode",
      "P_YS_OA_XTMENHU_0000046540": "文字模式",
      "P_YS_PF_WORKBENCH-FE_0001133436": "mdf正在加載中",
      "P_YS_PF_WORKBENCH-FE_0001133437": "專家團隊上門",
      "P_YS_PF_WORKBENCH-FE_0001133434": "智能通訊",
      "P_YS_PF_WORKBENCH-FE_0001133435": "正在升級企業...",
      "P_YS_PF_GZTSYS_0000012336": "開放平台",
      "P_YS_FED_FW_0000021759": "標題",
      "P_YS_OA_XTTMCENTER_0000035088": "用友官網",
      "P_YS_SD_SDMB_0000146914": "北京總部",
      "P_YS_PF_WORKBENCH-FE_0001133440": "解散團隊",
      "P_YS_OA_XTMENHU_0000046542": "模式選擇",
      "P_YS_FED_FW_0000021994": "設為默認",
      "P_YS_PF_MANAGER-FE_0001087800": "移除成員",
      "P_YS_PF_WORKBENCH-FE_0001133449": "收起標籤欄",
      "P_YS_PF_MANAGER-FE_0001087806": "瀏覽器新頁",
      "P_YS_PF_WORKBENCH-FE_0001133443": "點擊不保存，則最新修改將丟失",
      "P_YS_PF_WORKBENCH-FE_0001133444": "每到月底和年底，我們總是加班加點，才勉強完成 各種各樣的財務報表",
      "P_YS_PF_WORKBENCH-FE_0001133441": "錄入您的個人信息，方便我們與您取得聯繫",
      "P_YS_PF_MANAGER-FE_0001087807": "允許用戶退出",
      "P_YS_PF_WORKBENCH-FE_0001133442": "返回官網",
      "P_YS_PF_WORKBENCH-FE_0001133447": "移除成功!",
      "P_YS_PF_WORKBENCH-FE_0001133448": "個人主頁",
      "P_YS_PF_MANAGER-FE_0001087804": "服務名稱不能多於6個漢字或者12個字母",
      "P_YS_PF_WORKBENCH-FE_0001133445": "您當前所在的網絡環境可能使您無法訪問幫助中心，請連接到公網再嘗試。",
      "P_YS_PF_WORKBENCH-FE_0001133446": "在這裡可以找到全部的功能",
      "P_YS_PF_GZTSYS_0000012589": "審批",
      "P_YS_FED_EXAMP_0000020600": "是",
      "P_YS_PF_WORKBENCH-FE_0001133450": "團隊頭像",
      "P_YS_PF_WORKBENCH-FE_0001133451": "確認移除所選用戶?",
      "P_YS_OA_XTMENHU_0000046775": "選擇好友",
      "P_YS_OA_XTMENHU_0000046539": "生日祝福",
      "P_YS_PF_MANAGER-FE_0001087811": ">2000人",
      "P_YS_HR_HRJXF_0000059541": "驗證失敗",
      "P_YS_PF_MANAGER-FE_0001087817": "請輸入企業地址",
      "P_YS_PF_WORKBENCH-FE_0001133454": "上門演示",
      "P_YS_PF_WORKBENCH-FE_0001133452": "個應用即將到期，",
      "P_YS_PF_GZTPRE_0000071076": "所屬應用",
      "P_YS_PF_WORKBENCH-FE_0001133453": "經營企業，我不再焦慮",
      "P_YS_SD_UDHWS_0000070064": "企業名稱",
      "P_YS_PF_MANAGER-FE_0001087814": "是否使用原生導航",
      "P_YS_OA_XTMENHU_0000046569": "工作通知",
      "P_YS_PF_MANAGER-FE_0001087809": "中國澳門特別行政區",
      "P_YS_OA_XTWENKU_0000035585": "暫無數據",
      "P_YS_FED_FW_0000021973": "過濾條件",
      "P_YS_PF_MANAGER-FE_0001087820": "1001－2000",
      "P_YS_PF_MANAGER-FE_0001087827": "是否原生",
      "P_YS_PF_MANAGER-FE_0001087829": "app原生導航",
      "P_YS_PF_MANAGER-FE_0001087823": "選擇默認圖標",
      "P_YS_PF_GZTSYS_0001010812": "企業認證",
      "P_YS_OA_XTMENHU_0000046557": "未讀微郵",
      "P_YS_PF_WORKBENCH-FE_0001291199": "生日之時不要忘記母親，工作當中不要忘記朋友，煩惱之時多想想快樂之事。生日快樂，願你日日都快樂！",
      "P_YS_PF_PRINT_0000106816": "垂直居中",
      "P_YS_OA_XTMENHU_0000046556": "請輸入自定義組件的網絡地址",
      "P_YS_FED_UCFBASEDOC_0000025589": "發布成功",
      "P_YS_FED_FW_0000021744": "大於等於",
      "P_YS_FED_UCFBASEDOC_0000025345": "移動到",
      "P_YS_SD_SDMBF_0000141689": "到期時間：",
      "P_YS_PF_ECON-FE_0001309288": "顯示",
      "P_YS_SCM_USTOCK-UI_0000173932": "本季",
      "P_YS_PF_WORKBENCH-FE_0001208723": "界面放大",
      "P_YS_PF_WORKBENCH-FE_0001228574": "友小秘",
      "P_YS_PF_WORKBENCH-FE_0001208722": "超大",
      "P_YS_SD_SDMAUF_0000153108": "雲客服",
      "P_YS_OA_XTLCZX_0000030093": "提交時間",
      "P_YS_OA_XTTMCENTER_0000035047": "友空間",
      "P_YS_OA_XTTMCENTER_0000035043": "鏈接",
      "P_YS_SD_SDMBF_0000144965": "展示方式",
      "P_YS_SD_SDMBF_0000142527": "請點擊",
      "P_YS_HR_HRJQ_0000031105": "未處理",
      "P_YS_PF_WORKBENCH-FE_0001228566": "廣告詳情",
      "P_YS_PF_WORKBENCH-FE_0001228565": "數字內容",
      "P_YS_OA_XTTMCENTER_0000035058": "用友雲官網",
      "P_YS_PF_WORKBENCH-FE_0001274451": "sed 執行報錯",
      "P_YS_PF_WORKBENCH-FE_0001274450": "臨時檔夾位置",
      "P_YS_HR_HRRCPDF_0000071164": "標準",
      "P_YS_FED_FW_0000022192": "上移",
      "P_YS_PF_WORKBENCH-FE_0001228592": "大",
      "P_YS_FED_FW_0000022197": "展開",
      "P_YS_PF_WORKBENCH-FE_0001228597": "確定要恢復默認設置嗎?",
      "P_YS_PF_WORKBENCH-FE_0001228599": "卡片編輯",
      "P_YS_PF_WORKBENCH-FE_0001228598": "恢復提示",
      "P_YS_OA_XTTMCENTER_0000035064": "下載",
      "P_YS_FED_EXAMP_0000019832": "、",
      "P_YS_OA_XTTMCENTER_0000035062": "客戶端下載",
      "P_YS_OA_XTMENHUCMS_0000045206": "頭部高度",
      "P_YS_PF_WORKBENCH-FE_0001274449": "刪除目錄",
      "P_YS_PF_WORKBENCH-FE_0001274448": "捕獲多語地址",
      "P_YS_PF_WORKBENCH-FE_0001243713": "功能引導",
      "P_YS_PF_MANAGER-FE_0001116216": "是否全員可見",
      "P_YS_PF_METADATA_0000087187": "所屬領域",
      "P_YS_PF_METADATA_0000086098": "用戶信息",
      "P_YS_PF_WORKBENCH-FE_0001228589": "卡片屬性設置",
      "P_YS_PF_SOCD-S_0000183662": "企業管理",
      "P_YS_SD_UDHMC_0000050938": "跳過",
      "P_YS_SD_UDHWN_0000121225": "最近一月",
      "P_YS_IPAAS_UCG_0001064325": "建築業",
      "P_YS_PF_GZTSYS_0000011889": "榮耀",
      "P_YS_PF_WORKBENCH-FE_0001421901": "搜索人員、服務等",
      "P_YS_HR_HRJQ_0000031143": "無",
      "P_YS_OA_XTTXL_0000045969": "發消息",
      "P_YS_IPAAS_UCG_0001064571": "租賃和商務服務業",
      "P_YS_FED_FW_0000021923": "知道了",
      "P_YS_SD_UDHWN_0000120386": "立即申請",
      "P_YS_IPAAS_UCG_0001064578": "金融、保險業",
      "P_YS_PF_GZTSYS_0000012505": "基礎設置",
      "P_YS_IPAAS_UCG_0001009417": "釘釘",
      "P_YS_PF_WORKBENCH-FE_0001232883": "切換新版",
      "P_YS_PF_WORKBENCH-FE_0001232882": "個人工作臺",
      "P_YS_PF_WORKBENCH-FE_0001469084": "關閉右側",
      "P_YS_PF_WORKBENCH-FE_0001473568": "%c磁貼運行出現錯誤:",
      "P_YS_OA_XTTMCENTER_0000035034": "聯繫我們",
      "P_YS_HR_HRRCPDF_0000071186": "用友網絡科技股份有限公司",
      "P_YS_HR_HRPXF_0000056053": "團隊名稱",
      "P_YS_PF_MANAGER-FE_0001087797": "所有用戶都可申請加入",
      "P_YS_PF_WORKBENCH-FE_1420197852018638872": "斑馬線",
      "P_YS_PF_MANAGER-FE_0001087794": "姓名、手機號碼、電郵",
      "P_YS_PF_WORKBENCH-FE_1420197852018638878": "工作臺編輯",
      "P_YS_PF_MANAGER-FE_0001087793": "通訊錄顯示水印",
      "P_YS_PF_MANAGER-FE_0001087796": "pcClient打開方式",
      "P_YS_OA_XTLCZX_0000030256": "抄送的",
      "P_YS_PF_MANAGER-FE_0001087795": "根據匯入的上下級關係顯示上下級",
      "P_YS_PF_WORKBENCH-FE_1420197852018638875": "建議圖片尺寸50-100PX",
      "P_YS_SD_SDMAF_0001008026": "創建成功！",
      "P_YS_FED_FW_0000021036": "篩選",
      "P_YS_FED_IMP_IOT0000008165": "刪除",
      "P_YS_IPAAS_UCG_0001064009": "住宿、餐飲業",
      "P_YS_FED_FW_0000022366": "修改成功！",
      "P_YS_DMP_DATAD-FE_0001665639": "新增主題",
      "P_YS_PF_WORKBENCH-FE_1420197852018638884": "推薦高度22px，默認為png格式",
      "P_YS_PF_WORKBENCH-FE_1420197852018638887": "馬爾斯綠",
      "P_YS_PF_WORKBENCH-FE_1420197852018638881": "開發調試",
      "P_YS_IPAAS_UCG_0001064490": "文化、體育、娛樂業",
      "P_YS_IPAAS_UCG_0001064251": "衛生、社會保障和社會服務業",
      "P_YS_OA_XTMENHUCMS_0000045299": "漸變",
      "P_YS_OA_XTMENHUCMS_0000045298": "圖片樣式",
      "P_YS_OA_XTMENHUCMS_0001314169": "更多顏色",
      "P_YS_PF_WORKBENCH-FE_1420197852018638896": "磁貼設置",
      "P_YS_FED_UCFBASEDOC_0000024870": "查看詳情",
      "P_YS_PF_WORKBENCH-FE_1420197852018638893": "#008c8c_馬爾斯綠",
      "P_YS_PF_AUTHF_0000080113": "10條/頁",
      "P_YS_PF_WORKBENCH-FE_1420197852018638899": "請輸入查詢條件 支持首字母與拼音",
      "P_YS_PF_WORKBENCH-FE_0001473777": "選擇分析卡片",
      "P_YS_FED_FW_0000021020": "新增",
      "P_YS_OA_XTMENHUCMS_0000045290": "背景顏色",
      "P_YS_PF_WORKBENCH-FE_0001413028": "磁貼樣式報錯,對應磁貼為:",
      "P_YS_PF_WORKBENCH-FE_0001413029": "縱向排列",
      "P_YS_PF_WORKBENCH-FE_1420197852018638890": "當前底色",
      "P_YS_PF_WORKBENCH-FE_0001413035": "使用預設樣式",
      "P_YS_OA_XTMENHUCMS_0000045287": "常用應用",
      "P_YS_PF_WORKBENCH-FE_0001413036": "不顯示圖標",
      "P_YS_PF_WORKBENCH-FE_0001413037": "鏈接-新增",
      "P_YS_PF_WORKBENCH-FE_0001413038": "填充方式",
      "P_YS_OA_XTMENHUCMS_0000045285": "文字顏色",
      "P_YS_OA_XTMENHUCMS_0000045284": "圖標大小設置",
      "P_YS_PF_WORKBENCH-FE_0001413031": "表格排列",
      "P_YS_OA_XTMENHUCMS_0000045041": "組件名稱",
      "P_YS_PF_WORKBENCH-FE_0001413032": "設置網址LOGO",
      "P_YS_PF_WORKBENCH-FE_0001413033": "自定義圖片樣式",
      "P_YS_PF_WORKBENCH-FE_0001413034": "自定義顏色",
      "P_YS_PF_GZTLOG_0000080270": "Web端",
      "P_YS_FED_UCFBASEDOC_0000024866": "是否保存最新修改？",
      "P_YS_FED_FW_0000021014": "啟用",
      "P_YS_PF_WORKBENCH-FE_0001413030": "圖片透明度",
      "P_YS_PLM_GDS-FE_0001246638": "檔上傳中",
      "P_YS_PF_WORKBENCH-FE_0001537178": "2017年3月15日",
      "P_YS_PF_WORKBENCH-FE_0001537177": "幫助中心問號",
      "P_YS_PF_WORKBENCH-FE_0001537176": "問號旁的倒三角",
      "P_YS_SD_SDMBF_0000144334": "請上傳圖片",
      "P_YS_FED_EXAMP_0000020397": "姓名",
      "P_YS_OA_XTLCZX_0000030206": "隱藏",
      "P_YS_FED_FW_0000022168": "請輸入關鍵字",
      "P_YS_PF_ECON-FE_0001309239": "條",
      "P_YS_PF_AUTHF_0000080120": "15條/頁",
      "P_YS_OA_XTLCZX_0000030221": "系統",
      "P_YS_IPAAS_UCG_0001064293": "批發和零售業",
      "P_YS_OA_XTMENHUCMS_0001320647": "預覽-",
      "P_YS_PF_WORKBENCH-FE_1420197852018638848": "緊湊",
      "P_YS_PF_GZTSYS_0000011939": "我的門戶管理",
      "P_YS_FED_FW_0000022179": "對齊方式",
      "P_YS_PF_ECON-FE_0001309228": "頁",
      "P_YS_OA_XTLCZX_0000030219": "搜索結果",
      "P_YS_SD_SDMAUF_0000152873": "歷史記錄",
      "P_YS_PF_WORKBENCH-FE_1420197852018638851": "標籤欄-反白logo",
      "P_YS_SCM_STOCK_0000034523": "已讀",
      "P_YS_PF_WORKBENCH-FE_1420197852018638854": "拖至虛線上方可固定至標題區",
      "P_YS_FED_EXAMP_0000020183": "關閉",
      "P_YS_OA_XTMENHUCMS_0001314180": "您當前處於預覽狀態",
      "P_YS_PF_WORKBENCH-FE_0001480260": "上新回顧",
      "P_YS_FED_UCFBASEDOC_0000024820": "到",
      "P_YS_PF_WORKBENCH-FE_1420197852018638857": "oppo綠",
      "P_YS_PF_WORKBENCH-FE_1420197852018638863": "舒眼綠",
      "P_YS_PF_WORKBENCH-FE_1420197852018638860": "風格設置",
      "P_YS_PF_WORKBENCH-FE_1420197852018638866": "建議圖片尺寸0-10",
      "P_YS_OA_XTLCZX_0000030480": "小",
      "P_YS_OA_XTMENHUCMS_0000045079": "自由布局",
      "P_YS_FED_EXAMP_0000020188": "駁回",
      "P_YS_OA_XTLCZX_0000030238": "背景圖片",
      "P_YS_PF_WORKBENCH-FE_0001561051": "url地址",
      "P_YS_PF_WORKBENCH-FE_1420197852018638869": "標籤區設置",
      "P_YS_PF_WORKBENCH-FE_0001561052": "請輸入獨立APPurl",
      "P_YS_PF_WORKBENCH-FE_0001561050": "獨立APP",
      "P_YS_PF_WORKBENCH-FE_0001233364": "工作臺個性化配置能力增強",
      "P_YS_PF_WORKBENCH-FE_0001300847": "待辦任務顯示方式",
      "P_YS_PF_WORKBENCH-FE_0001233363": "支持更多磁貼、更靈活佈局、更豐富的展示效果",
      "P_YS_PF_WORKBENCH-FE_0001300848": "審批中心按鈕（同意）",
      "P_YS_PF_WORKBENCH-FE_0001233362": "門戶合併到工作臺啦，支持多工作臺切換，角色化工作臺幫助您更好完成工作任務",
      "P_YS_PF_WORKBENCH-FE_0001300849": "預警和通知",
      "P_YS_PF_WORKBENCH-FE_0001233361": "企業帳號換位置啦",
      "P_YS_SD_UDHWN_0000049711": "主題顏色",
      "P_YS_FED_UCFBASEDOC_0000025581": "重命名分組",
      "P_YS_PF_WORKBENCH-FE_0001233360": "數位化工作入口升級啦",
      "P_YS_PLM_BASE-FE_0001160596": "頂部對齊",
      "P_YS_PF_MANAGER-FE_0001087957": "請輸入Android下載地址",
      "P_YS_FED_EXAMP_0000020115": "切換",
      "P_YS_OA_XTMENHU_0000046746": "預警通知",
      "P_YS_OA_XTMENHU_0000046742": "考勤審批",
      "P_YS_PF_WORKBENCH-FE_0001233359": "菜單導航優化",
      "P_YS_PF_WORKBENCH-FE_0001233358": "企業帳號轉移到用戶下拉麵板啦",
      "P_YS_PF_WORKBENCH-FE_0001470672": "加入快捷應用",
      "P_YS_PF_WORKBENCH-FE_0001233357": "工作臺配置能力更強\\n菜單導航更高效",
      "P_YS_PF_WORKBENCH-FE_0001233356": "支持多工作臺",
      "P_YS_PF_PTDESIGN-FE_0001184496": "邊框圓角",
      "P_YS_PF_WORKBENCH-FE_0001233355": "全新的菜單面板，新增搜索功能，支持快速收藏和查看最近使用",
      "P_YS_PF_WORKBENCH-FE_0001133407": "全部歷史",
      "P_YS_PF_WORKBENCH-FE_0001133408": "用友雲，完全公有雲化的企業服務，讓我從傳統的運維 工作解放出來，成為公司數據資產的建設者",
      "P_YS_PF_WORKBENCH-FE_0001133405": "開啟錄製",
      "P_YS_PF_MANAGER-FE_0001087964": "PC客戶端主頁",
      "P_YS_PF_WORKBENCH-FE_0001133406": "搜索人員信息、服務及其他內容",
      "P_YS_OA_XTWEIYOU_0000046270": "同時發送郵件",
      "P_YS_FED_FW_0000021451": "（",
      "P_YS_PF_MANAGER-FE_0001087961": "是否常用應用",
      "P_YS_PF_WORKBENCH-FE_0001133409": "移除首頁",
      "P_YS_PF_WORKBENCH-FE_0001300850": "清單內容配置",
      "P_YS_PF_WORKBENCH-FE_0001133400": "個應用已到期，",
      "P_YS_PF_WORKBENCH-FE_0001300851": "審批中心按鈕（駁回）",
      "P_YS_PF_WORKBENCH-FE_0001300852": "按領域應用",
      "P_YS_PF_WORKBENCH-FE_0001133403": "團隊設置",
      "P_YS_PF_MANAGER-FE_0001087966": "上下級顯示",
      "P_YS_PF_WORKBENCH-FE_0001133404": "讓我從很多重複性的事務工作中解放出來",
      "P_YS_PF_WORKBENCH-FE_0001133401": "背景色設定",
      "P_YS_PF_WORKBENCH-FE_0001133402": "更多結果",
      "P_YS_PF_WORKBENCH-FE_0001242095": "還未添加收藏,快去收藏吧",
      "P_YS_PF_WORKBENCH-FE_0001242096": "暫不了解",
      "P_YS_PF_GZTSYS_0000013477": "保存",
      "P_YS_PF_METADATA_0000084364": "請求地址",
      "P_YS_PF_GZTSYS_0000015896": "團隊成員",
      "P_YS_OA_XTMENHU_0000046739": "我的任務",
      "P_YS_OA_XTNEIBUQUN_0000046122": "設置管理員",
      "P_YS_PF_WORKBENCH-FE_0001133418": "每天維護公司的上百台服務器，總是怕出現一些問題， 影響公司業務發展",
      "P_YS_PF_WORKBENCH-FE_0001133419": "填寫信息",
      "P_YS_PF_WORKBENCH-FE_0001133416": "發布到菜單",
      "P_YS_PF_WORKBENCH-FE_0001133417": "電郵：diwork@yonyou.com",
      "OA_web_ad_000000095": "未分組",
      "P_YS_PF_WORKBENCH-FE_0001133410": "“相信美好，立刻開始數字化工作”",
      "P_YS_PF_WORKBENCH-FE_0001133411": "關閉錄製",
      "P_YS_PF_WORKBENCH-FE_0001133414": "中查看。",
      "P_YS_PF_WORKBENCH-FE_0001133415": "當前租戶已開啟登錄控制策略。控制方式為【驗證碼】",
      "P_YS_PF_WORKBENCH-FE_0001133412": "帳號管理",
      "P_YS_OA_YBMOB_0000036890": "檔案",
      "P_YS_PF_WORKBENCH-FE_0001133413": "如需購買或了解產品詳情請撥打熱線",
      "P_YS_OA_XTMENHU_0000046524": "待辦中心",
      "P_YS_FED_FW_0000022505": "本周",
      "P_YS_OA_XTMENHU_0000046523": "圖文模式",
      "P_YS_FED_EXAMP_0000020377": "等於",
      "P_YS_FED_UCFBASEDOC_0000025319": "驗證碼",
      "P_YS_HR_HRJQF_0000054364": "匯入完成",
      "P_YS_HR_HRCOREF_0000058092": "當前人數",
      "P_YS_PF_WORKBENCH-FE_0001133429": "掃描二維碼直接進入",
      "P_YS_PF_WORKBENCH-FE_0001133427": "升級為企業",
      "P_YS_PF_WORKBENCH-FE_0001133428": "菜單名稱：",
      "P_YS_OA_XTLCZX_0000030680": "我發起的",
      "P_YS_OA_XTLCZX_0000030202": "大",
      "P_YS_PF_WORKBENCH-FE_0001133421": "用友雲提供了企業所需要的各種數字化服務",
      "P_YS_PF_WORKBENCH-FE_0001133422": "全局搜索：",
      "P_YS_FED_EXAMP_0000020395": "搜索",
      "P_YS_OA_XTMENHU_0000046750": "最早打卡：",
      "P_YS_PF_WORKBENCH-FE_0001133420": "聯繫電郵：diwork@yonyou.com",
      "P_YS_IPAAS_UCG_0001064071": "交通運輸、倉儲業和郵政業",
      "P_YS_PF_WORKBENCH-FE_0001133425": "定製服務",
      "P_YS_PF_WORKBENCH-FE_0001133426": "遍布全國的專業售前團隊，整裝待命",
      "P_YS_FED_EXAMP_0000020391": "加載中...",
      "P_YS_PF_WORKBENCH-FE_0001133423": "菜單位置：",
      "P_YS_PF_WORKBENCH-FE_0001133424": "個應用，",
      "P_YS_PF_YHTTNT-FE_0001045676": "只顯示管理員",
      "P_YS_PF_WORKBENCH-FE_0001309411": "暫無匯總項",
      "P_YS_FED_EXAMP_0000020385": "取消",
      "P_YS_PF_WORKBENCH-FE_0001309412": "匯總項",
      "P_YS_PF_WORKBENCH-FE_0001309413": "保存過濾條件",
      "P_YS_FED_FW_0000022510": "恢復預設",
      "P_YS_OA_XTLCZX_0000030679": "中",
      "P_YS_FED_FW_0000022512": "沒有搜索結果",
      "P_YS_PF_WORKBENCH-FE_0001233366": "支持回到舊版，可以隨時切換回到新版工作臺",
      "P_YS_PF_WORKBENCH-FE_0001233365": "切換舊版工作臺",
      "P_YS_PF_WORKBENCH-FE_0001291208": "最好喝的酒也許不是最貴的那瓶，因為雖然可以買到酒，但買不到心情，最好的朋友也許不是聯絡最多的那個而是在平凡的日子裡一直惦記著你的人！祝生日快樂",
      "P_YS_PF_WORKBENCH-FE_0001291209": "生日快樂！雖然我只是你遠方的朋友，然而我的祝福卻一直伴在你身邊！願你生活愉快！",
      "P_YS_FED_UCFBASEDOC_0000024690": "分組名稱,最多4個字符",
      "P_YS_PF_WORKBENCH-FE_1421570875756380184": "將我的語言、默認業務單元、修改業務日期、工作臺版本切換調整至此處",
      "P_YS_PF_ANALYTIC-FE_0001651904": "頁簽設置",
      "P_YS_PF_WORKBENCH-FE_1421570875756380187": "將幫助中心、功能引導、上新回顧、用戶社區調整至此處",
      "P_YS_FED_IMP_IOT0000005499": "全部",
      "P_YS_PF_WORKBENCH-FE_1421570875756380181": "客戶自建子領域暫時統一歸於“未分組”中，後續將支持自由歸類於其他領域雲",
      "P_YS_SCM_PU_0000028165": "處理中",
      "P_YS_PF_WORKBENCH-FE_0001291202": "生日快樂，親愛的朋友，願你天天快樂，年年好運，一生幸福！",
      "P_YS_PF_WORKBENCH-FE_0001291203": "在你生日來臨之際，祝事業正當午，身體壯如虎，金錢不勝數，幹活不辛苦，悠閒像老鼠，浪漫似樂譜，快樂莫你屬！",
      "P_YS_PF_WORKBENCH-FE_0001291200": "祈望你心靈深處芳草永綠，青春常駐，笑口常開。祝你生日快樂，健康幸福！",
      "P_YS_PF_WORKBENCH-FE_0001291201": "願你的容顏像春天般絢爛，衷心祝福你——可愛的女孩，生日快樂！",
      "P_YS_OA_XTLCZX_0000030607": "拉伸",
      "P_YS_PF_WORKBENCH-FE_0001291206": "生日快樂!祝你今天抬頭看見丘比特，出門遇上大美人，低頭撿綑佰元鈔，回家踩上臭狗屎！哈哈！",
      "P_YS_PF_WORKBENCH-FE_0001291207": "顯示部門",
      "P_YS_PF_WORKBENCH-FE_0001291204": "又是一個美好的開始，願我虔誠的祝福，帶給你成功的一年，祝你生日快樂！",
      "P_YS_OA_XTLCZX_0000030605": "排列方式",
      "P_YS_PF_WORKBENCH-FE_0001291205": "日月輪轉永不斷，情苦真摯長相伴，不論你身在天涯海角，我將永遠記住這一天。祝你生日快樂!",
      "P_YS_FED_IMP-DFM-FE_0000020888": "序號",
      "P_YS_FED_IMP-DFM-FE_0000020886": "複製",
      "P_YS_PF_GZTTMP_0000073996": "教育",
      "P_YS_OA_XTNEIBUQUN_0000046136": "邀請成員",
      "P_YS_FED_EXAMP_0000020566": "微信",
      "P_YS_FED_FW_0001515909": "圖片展示",
      "P_YS_PF_WORKBENCH-FE_1421570875756380190": "支持拖拽排序，可將按個常用工作臺拖拽至虛線上方並常駐顯示，實現快速切換",
      "P_YS_FED_UCFBASEDOC_0000025523": "分組名稱已存在！",
      "P_YS_PF_WORKBENCH-FE_0001155883": "您購買的產品中，有應用即將到期，請及時安排續費！",
      "P_YS_PF_WORKBENCH-FE_0001155882": "您購買的產品中，有應用已到期，請儘快安排續費！",
      "P_YS_PF_GZTSYS_0000013012": "應用設置",
      "P_YS_FED_IMP-DFM-FE_0000020898": "剪切",
      "P_YS_YYGJFW_YBUC_0001336566": "幫助信息",
      "P_YS_OA_XTMENHU_0000046725": "添加鏈接",
      "P_YS_SD_SDMA_0000041132": "已過期",
      "P_YS_PF_AUTHF_0000112877": "輸入服務名稱",
      "P_YS_OA_XTMENHU_0000046721": "祝你生日快樂，永遠十八歲！",
      "P_YS_PF_GZTSYS_0000013245": "服務類型",
      "P_YS_FED_IMP_IOT0000005471": "提示",
      "P_YS_PF_MANAGER-FE_0001087788": "退出企業",
      "P_YS_OA_XTMENHU_0000046711": "二維碼邀請",
      "P_YS_FED_EXAMP_0000020345": "請選擇",
      "P_YS_FI_CM_0000029607": "錯誤",
      "P_YS_PF_WORKBENCH-FE_0001470623": "上傳本地圖片",
      "P_YS_FED_EXAMP_0000020344": "否",
      "P_YS_OA_XTMENHU_0000046710": "個人生日哦",
      "P_YS_PF_WORKBENCH-FE_0001470622": "選擇預設圖片",
      "P_YS_PF_AUTHF_0000112885": "暫無結果",
      "P_YS_PF_WORKBENCH-FE_0001582371": "是否單點",
      "P_YS_PF_GZTSYS_0000109539": "繁體中文",
      "P_YS_FED_UCFBASEDOC_0000025061": "角色編碼",
      "P_YS_FED_UIMG_0000157462": "【提示】若“系統語言”為空，則按照“當前語言”進行保存。",
      "P_YS_FED_UIMG_0000157460": "當前語言",
      "P_YS_FED_UIMG_0000157461": "系統語言",
      "P_YS_PF_GZTSYS_0001155401": "資產盤點移動應用",
      "P_YS_FED_FW_0000020988": "詳情",
      "message.common.lang0000522": "部署成功",
      "P_YS_OA_XTLCZX_0000030807": "選擇模板",
      "P_YS_HR_HRCM-UI_0001380153": "頁面建模",
      "P_YS_HR_HRXCF_0000056236": "重新上傳",
      "P_YS_FED_FW_0000020984": "文本",
      "P_YS_FED_UIMG_0000082816": "只讀狀態不可進行刪除操作",
      "P_YS_FED_UCFBASEDOC_0000025290": "部門放休",
      "P_YS_PF_GZTSYS_0000012671": "計量單位",
      "000723": "请输入网址",
      "P_YS_PF_PRINT_0000057241": "本幣無稅單價",
      "P_YS_PF_GZTSYS_0000012661": "假勤代申請",
      "P_YS_PF_YS-LOGIN_0001079461": "沙箱",
      "P_YS_FED_FW_0000020994": "整型",
      "P_YS_PF_GZTVPA_0001126493": "機器人名稱",
      "P_YS_FED_UCFBASEDOC_0000025044": "離職",
      "P_YS_SD_UDHWN_0000050202": "幫助",
      "P_YS_PF_PRINT_0000057698": "本幣無稅金額",
      "P_YS_FED_UIMG_0000101674": "系統預製的必輸欄位不允許修改為非必輸",
      "P_YS_AM_AMC-UI_0000170775": "樹",
      "P_YS_FED_FW_0000021815": "向右",
      "P_YS_OA_app_xtywlj_0000037579": "請選擇數據類型",
      "P_YS_HR_HRPXF_0000055973": "發布到",
      "P_YS_PF_GZTSYS_0000012631": "交易型別",
      "P_YS_SD_SDMA_0000062157": "未上架",
      "P_YS_PF_GZTSYS_0000015903": "門店",
      "P_YS_PF_TRS-FE_0000103851": "日常環境",
      "P_YS_PF_MANAGER-FE_0001087905": "標籤模式",
      "P_YS_FED_IMP_IOT0000007727": "匯出",
      "P_YS_PF_MANAGER-FE_0001087903": "確認啟用【",
      "P_YS_FED_EXAMP_0000020302": "上傳成功",
      "P_YS_FED_FW_0000021827": "刪行",
      "P_YS_FED_FW_0000020973": "不能為空",
      "P_YS_FED_FW_0000021821": "默認語種",
      "P_YS_PF_GZTSYS_0000015916": "分析",
      "P_YS_OA_XTGONGWEN_0000045558": "請選擇型別",
      "P_YS_SD_UDHMC_0001070473": "紅色主題",
      "P_YS_PF_GZTSYS_0000109292": "生產環境",
      "P_YS_PF_GZTSYS_0000013547": "採購入庫",
      "P_YS_SD_UDHWN_0000120893": "重新",
      "P_YS_PF_GZTSYS_0000012697": "項目",
      "P_YS_FED_FW_0000021631": "例：YYYY-MM-DD HH:mm:ss",
      "P_YS_FED_UCFBASEDOC_0000025019": "持續績效",
      "P_YS_FED_UCFBASEDOC_0000025016": "請輸入賬號",
      "P_YS_FED_FW_0000021875": "寬度",
      "P_YS_OA_app_xtyyjm_0000036018": "請選擇表單",
      "P_YS_FED_MDF-COMPS_0001573108": "物料名稱",
      "P_YS_FED_MDF-COMPS_0001573109": "表體-0528test",
      "P_YS_FI_FINBD-UI_0000161964": "表參照",
      "P_YS_FED_MDF-COMPS_0001573106": "組號",
      "P_YS_FED_MDF-COMPS_0001573107": "移到",
      "P_YS_FED_FW_0000021890": "日語",
      "P_YS_OA_XTWENKU_0000035495": "修改時間",
      "P_YS_FED_MDF-COMPS_0001573100": "ycx文本",
      "P_YS_FED_MDF-COMPS_0001573101": "表頭-0528test",
      "P_YS_SD_SDMA_0000062368": "PC端",
      "P_YS_FED_MDF-COMPS_0001573104": "應收件數",
      "P_YS_FED_UCFBASEDOC_0000025006": "轉正",
      "P_YS_FED_MDF-COMPS_0001573105": "客戶分類3",
      "P_YS_FED_MDF-COMPS_0001573102": "倉檔案",
      "P_YS_FED_UCFBASEDOC_0000025488": "HR日曆",
      "P_YS_FED_MDF-COMPS_0001573103": "主計量",
      "P_YS_FED_UCFBASEDOC_0000025485": "榮耀",
      "P_YS_FED_MDF-COMPS_0001573315": "麼?",
      "P_YS_PF_PTDESIGN-FE_0001184798": "卡片設置",
      "P_YS_PF_GZTORG_0000035405": "修改時間",
      "P_YS_SD_SDMAUF_0000153043": "版本列表",
      "P_YS_PF_PRINT_0000057017": "生效日期",
      "P_YS_FI_OMC_0001094475": "當前環境",
      "P_YS_FED_FW_0000021617": "數量小數位",
      "P_YS_FED_FW_0000021615": "草稿",
      "P_YS_FED_FW_0000021618": "公式編輯器",
      "P_YS_FED_EXAMP_0000020734": "，",
      "P_YS_HR_HRJXF_0000059668": "取消成功",
      "P_YS_PF_PROCENTER_0000027111": "修改",
      "P_YS_SCM_PU_0000028543": "獲取數據失敗",
      "P_YS_PF_GZTBDM_0000036354": "刷新成功",
      "P_YS_PF_GZTSYS_0000012688": "加班",
      "P_YS_PF_GZTSYS_0000012687": "考勤中心",
      "P_YS_FED_FW_0000021868": "沒有數據",
      "P_YS_AM_RMM-UI_0001217391": "申請內容",
      "P_YS_PF_GZTSYS_0000012208": "雲會務",
      "P_YS_FED_MDF-COMPS_0001623284": "沙箱許可",
      "P_YS_FED_MDF-COMPS_0001623283": "移動供應鏈",
      "P_YS_FED_MDF-COMPS_0001623286": "空頁面",
      "000572": "数据集",
      "P_YS_FED_MDF-COMPS_0001623285": "點擊條目快速預覽",
      "P_YS_FED_MDF-COMPS_0001623288": "請選擇發佈數據",
      "P_YS_FED_MDF-COMPS_0001623287": "確定發佈當前參照嗎?",
      "P_YS_FED_FW_0000022097": "替換",
      "P_YS_FED_MDF-COMPS_0001623289": "獨立應用和小程式",
      "P_YS_HR_HRYGF_0000058837": "枚舉",
      "P_YS_PF_GZTSYS_0000012811": "辦公協作",
      "P_YS_PF_METADATA_0001038547": "左樹右卡",
      "P_YS_FED_MDF-COMPS_0001623291": "請選擇系統預置",
      "P_YS_PF_GZTSYS_0000011965": "HR日曆",
      "P_YS_FED_MDF-COMPS_0001623290": "收件人名稱",
      "P_YS_FED_MDF-COMPS_0001623293": "包名必須包含至少兩個段，由一個或多個點隔開。例如：com.abc，tencent.qq.mm；每個段的字元必須是小寫字母、數字或下劃線[a-z0-9_]組成；每個段必須以字母開頭；",
      "P_YS_FED_MDF-COMPS_0001623292": "在APP工作臺中動態顯示應用管理中的應用。",
      "P_YS_FED_MDF-COMPS_0001623295": "錯誤數",
      "P_YS_FED_MDF-COMPS_0001623294": "企業文化",
      "P_YS_FED_MDF-COMPS_0001623297": "沙箱名稱",
      "P_YS_FED_MDF-COMPS_0001623296": "keystore密碼",
      "P_YS_FI_CM_0000030058": "單據編號",
      "P_YS_FED_MDF-COMPS_0001623299": "進入應用構建專業版",
      "P_YS_FED_MDF-COMPS_0001623298": "已選擇插件",
      "P_YS_IPAAS_UCG_0001009331": "開發者",
      "P_YS_OA_XTWENKU_0000035693": "大小",
      "P_YS_PF_TRS_0000034730": "應用編碼",
      "P_YS_HR_HRRCPDF_0000073224": "創建人:",
      "P_YS_YYGJFW_YBUC_0001333705": "環境配置",
      "P_YS_FED_MDF-COMPS_0001573117": "提交時間",
      "P_YS_FED_MDF-COMPS_0001573118": "自定義項目測試202008060001",
      "P_YS_FED_MDF-COMPS_0001623266": "應用編碼不能以數字或者下劃線開頭!",
      "P_YS_FED_MDF-COMPS_0001623265": "掃碼入職",
      "P_YS_FI_CM_0000029070": "函數",
      "P_YS_FED_MDF-COMPS_0001623268": "重新打包",
      "P_YS_FED_MDF-COMPS_0001623267": "暫無可用沙箱",
      "P_YS_FED_MDF-COMPS_0001623269": "請選擇表單分類",
      "P_YS_HR_HRYGF_0000058815": "模板類型",
      "P_YS_FI_AR_0000058963": "到期日",
      "P_YS_SD_UDHWN_0000120238": "未設置",
      "P_YS_SD_SDSD-FE_0001486556": "備註",
      "P_YS_SD_SDMA_0000038452": "業務員",
      "P_YS_PF_GZTSYS_0000135330": "T+服務",
      "P_YS_FED_MDF-COMPS_0001573111": "ycx檔案類型",
      "P_YS_FED_MDF-COMPS_0001573112": "likun-表體自定義項[文本]",
      "P_YS_FED_MDF-COMPS_0001573110": "枚舉",
      "P_YS_SD_SDMA_0000040767": "下單時間",
      "P_YS_FED_MDF-COMPS_0001573115": "上游單據號",
      "P_YS_FI_FCWEB_0001047524": "拖拽調整順序",
      "P_YS_FED_MDF-COMPS_0001573116": "保質期單位",
      "P_YS_FED_MDF-COMPS_0001573113": "物料編碼",
      "P_YS_FED_MDF-COMPS_0001623271": "我上傳的插件",
      "P_YS_FED_MDF-COMPS_0001573114": "倉庫",
      "P_YS_FED_MDF-COMPS_0001623270": "開發沙箱(",
      "P_YS_FED_MDF-COMPS_0001623273": "請上傳選中圖示",
      "P_YS_FED_MDF-COMPS_0001623272": "普通發佈",
      "P_YS_FED_MDF-COMPS_0001623275": "系統自建",
      "P_YS_FED_MDF-COMPS_0001623274": "視頻會議",
      "P_YS_FED_MDF-COMPS_0001623277": "指定頁面",
      "P_YS_PF_GZTSYS_0000012809": "資產管理",
      "P_YS_FED_MDF-COMPS_0001623276": "請輸入申請說明",
      "P_YS_FED_MDF-COMPS_0001623279": "請輸入打包資訊",
      "P_YS_FED_MDF-COMPS_0001623278": "打包序號",
      "P_YS_FED_EXAMP_0000019958": "金額",
      "P_YS_OA_app_xtyyjm_0000036087": "匯入文件",
      "P_YS_OA_XTWENKU_0000035676": "創建時間",
      "P_YS_FED_MDF-COMPS_0001623280": "青啤",
      "P_YS_PF_GZTSYS_0000012801": "權益",
      "P_YS_OA_XTMENHUCMS_0000045312": "默認圖標",
      "P_YS_FED_MDF-COMPS_0001623282": "請選擇申請領域",
      "P_YS_FED_EXAMP_0000020703": "規格",
      "P_YS_FED_MDF-COMPS_0001623281": "新建移動頁面",
      "P_YS_FED_MDF-COMPS_0001623482": "該應用已關聯",
      "P_YS_FED_MDF-COMPS_0001623481": "擴展行數範圍1-10",
      "P_YS_FED_MDF-COMPS_0001623484": "分析範本",
      "P_YS_FED_MDF-COMPS_0001623483": "PC端頁面",
      "P_YS_SCM_STOCK_0000034389": "行",
      "P_YS_FED_MDF-COMPS_0001623486": "適用組織職能",
      "P_YS_FED_MDF-COMPS_0001623485": "描述檔",
      "P_YS_FED_MDF-COMPS_0001623488": "別名(alias)",
      "P_YS_FED_MDF-COMPS_0001623487": "打包需要約30分鐘,請稍後刷新頁面",
      "P_YS_FED_MDF-COMPS_0001623489": "工作彙報",
      "P_YS_IPAAS_APIC-FE_0001577482": "許可權說明",
      "P_YS_OA_PACK_0001100778": "端配置",
      "P_YS_PF_PRINT_0000065739": "物料sku編碼",
      "P_YS_PF_GZTSYS_0000012611": "我的反饋",
      "P_YS_FI_FCWEB_0001047707": "生成模板",
      "P_YS_FED_FW_0000020949": "繁體中文",
      "P_YS_YYGJFW_YBUC_0001333755": "環境地址",
      "P_YS_YYGJFW_YBUC_0001335933": "設為管理員",
      "P_YS_HR_HRJXF_0000059247": "添加成員",
      "P_YS_FED_MDF-COMPS_0001623491": "閃屏",
      "P_YS_FI_YYFI-UI_0000167698": "其他入庫單列表區域",
      "P_YS_PF_GZTSYS_0000012858": "會議管理",
      "P_YS_FED_MDF-COMPS_0001623490": "引用已有",
      "P_YS_FED_MDF-COMPS_0001623493": "智能取件櫃",
      "P_YS_FED_MDF-COMPS_0001623492": "入職",
      "P_YS_PF_GZTSYS_0001146583": "移動工作台",
      "P_YS_FED_MDF-COMPS_0001623495": "立即開始",
      "P_YS_HR_HRYGF_0000058801": "選擇實體",
      "P_YS_FED_MDF-COMPS_0001623494": "採購入庫",
      "P_YS_FED_MDF-COMPS_0001623497": "管理應用 (刪改) | 管理成員 (增刪) | 開發應用",
      "P_YS_PF_PTDESIGN-FE_0001184504": "頁面名稱不能為空",
      "P_YS_FED_MDF-COMPS_0001623496": "友空間掃碼",
      "P_YS_FED_MDF-COMPS_0001623499": "請輸入自定義鏈接",
      "P_YS_SD_SDMA-UI_0001101601": "申請狀態",
      "P_YS_FED_MDF-COMPS_0001623498": "提供人",
      "P_YS_SD_SDSD-FE_0001491730": "數量",
      "P_YS_PF_GZTSYS_0000012867": "持續績效",
      "P_YS_OA_XTLCZX_0000030199": "第三方",
      "P_YS_FED_MDF-COMPS_0001623460": "描述檔案名稱",
      "P_YS_FED_MDF-COMPS_0001623462": "計劃盤點",
      "P_YS_FED_EXAMP_0000019909": "業務類型",
      "P_YS_FED_MDF-COMPS_0001623461": "下載插件模版",
      "P_YS_SD_SDMB_0000151538": "自",
      "P_YS_FED_MDF-COMPS_0001623464": "請選擇應用引擎",
      "P_YS_FED_MDF-COMPS_0001623463": "請選中分組",
      "P_YS_FED_MDF-COMPS_0001623466": "獲取當前登陸用戶id失敗",
      "P_YS_FED_MDF-COMPS_0001623465": "小圖卡片",
      "P_YS_FED_MDF-COMPS_0001623468": "請搜索登錄名",
      "P_YS_FED_MDF-COMPS_0001623467": "展示時長",
      "P_YS_FED_EXAMP_0000019901": "更新成功",
      "P_YS_FED_MDF-COMPS_0001623469": "確定刪除插件嗎？",
      "P_YS_FED_FW_0001573430": "! 重複的control對象為:%o",
      "P_YS_SCM_UIT-UI_0000174857": "企業畫像",
      "P_YS_FED_FW_0001573427": "{\\\"1\\\":\\\"年\\\",\\\"2\\\":\\\"月\\\",\\\"6\\\":\\\"天\\\"}",
      "P_YS_FED_IMP_IOT0000011205": "請輸入描述",
      "P_YS_FED_FW_0001573428": "[{\\\"key\\\":\\\"1\\\",\\\"nameType\\\":\\\"text\\\",\\\"value\\\":\\\"年\\\"},{\\\"key\\\":\\\"2\\\",\\\"nameType\\\":\\\"text\\\",\\\"value\\\":\\\"月\\\"},{\\\"key\\\":\\\"6\\\",\\\"nameType\\\":\\\"text\\\",\\\"value\\\":\\\"天\\\"}]",
      "P_YS_FED_FW_0001573429": "獲取刪除之後的數據",
      "P_YS_FED_MDF-COMPS_0001623471": "沙箱是特殊的企業租戶。",
      "P_YS_FED_MDF-COMPS_0001623470": "申請領域",
      "P_YS_FED_MDF-COMPS_0001623473": "列表結構",
      "P_YS_FED_MDF-COMPS_0001623472": "銷售下單",
      "P_YS_FED_MDF-COMPS_0001623475": "添加移動端",
      "P_YS_FED_UIMG_0001071360": "設計欄位不能設為必輸",
      "P_YS_FED_MDF-COMPS_0001623474": "刪除成員後，該成員創建的內容仍將保留",
      "P_YS_FED_MDF-COMPS_0001623477": "請先啟用閃屏再進行配置~",
      "P_YS_FED_MDF-COMPS_0001623476": "使用許可",
      "P_YS_FED_MDF-COMPS_0001623479": "應用圖示大小不能超過1M",
      "P_YS_FED_MDF-COMPS_0001623478": "發佈終端",
      "P_YS_PF_BD-UI_0001049486": "一主多子",
      "P_YS_FED_FW_0001573431": "[bill-setting] [BillModal] [updateGridModelDataValueByCRefRetId()] 獲取參照返回值ERROR:[",
      "P_YS_FED_FW_0001573432": "[bill-setting-new] [BillModal] [updateGridModelDataValueByCRefRetId()] 獲取參照返回值ERROR:[",
      "P_YS_FED_FW_0001573433": "%c[bill-setting] [redux billmodal] [mergeDirtyDataInModalDataOriginal()] 重複的cName",
      "P_YS_PF_GZTSYS_0000012602": "企業介紹",
      "P_YS_FED_FW_0000020935": "失敗",
      "P_YS_PF_GZTSYS_0000135360": "資產盤點",
      "P_YS_FED_FW_0001573434": ",阻止執行",
      "P_YS_PF_GZTSYS_0000012601": "出差",
      "P_YS_PF_TRS_0000034719": "應用名稱",
      "P_YS_FED_FW_0001573435": "渲染表格方法與組件",
      "P_YS_PF_PROCENTER_0000022745": "調用",
      "P_YS_FED_FW_0001573436": "參照缺少必要配置cRefRetId、cRefType",
      "P_YS_FED_FW_0001573437": "獲取下標為",
      "P_YS_PF_GZTORG_0000035493": "開通狀態",
      "P_YS_SD_UDHLIB_0000133243": "開通中",
      "P_YS_FED_MDF-COMPS_0001623480": "抽取開發數據",
      "P_YS_PF_GZTSYS_0000012605": "部門加班",
      "P_YS_PF_PRINT_0000056946": "錶頭自定義項14",
      "P_YS_OA_app_xtyyjm_0000035922": "頁面名稱",
      "P_YS_FED_MDF-COMPS_0001623561": "應用卡片預覽",
      "P_YS_PF_PRINT_0000056943": "錶頭自定義項10",
      "P_YS_FED_MDF-COMPS_0001623560": "雲直播",
      "P_YS_FED_MDF-COMPS_0001623321": "未選擇用戶",
      "P_YS_FED_MDF-COMPS_0001623563": "需跳轉到證書管理頁面",
      "P_YS_FED_MDF-COMPS_0001623320": "基本屬性",
      "P_YS_FED_MDF-COMPS_0001623562": "插件描述",
      "P_YS_FED_MDF-COMPS_0001623323": "描述檔被使用中，無法刪除",
      "P_YS_FED_MDF-COMPS_0001623565": "請輸入來源租戶",
      "P_YS_FED_MDF-COMPS_0001623322": "幫助你自動完成重複耗時的任務, 提高工作效率。",
      "P_YS_FED_MDF-COMPS_0001623564": "工作臺地址",
      "P_YS_FED_MDF-COMPS_0001623325": "可用沙箱",
      "P_YS_FED_MDF-COMPS_0001623567": "關聯已有APP或小程式，關聯後將採用關聯對象配置。",
      "P_YS_FED_MDF-COMPS_0001623324": "請選擇創建自",
      "P_YS_FED_MDF-COMPS_0001623566": "應用顯示規則",
      "P_YS_FED_MDF-COMPS_0001623327": "在APP工作臺中固定顯示白名單中的應用。",
      "P_YS_FED_MDF-COMPS_0001623569": "用友雲小程式",
      "P_YS_FED_MDF-COMPS_0001623326": "可選服務",
      "P_YS_FED_MDF-COMPS_0001623568": "新建參照",
      "P_YS_FED_MDF-COMPS_0001623329": "已擴展",
      "P_YS_FED_MDF-COMPS_0001623328": "請輸入檔案名稱搜索",
      "P_YS_PF_GZTTMP_0000078208": "往來件數",
      "P_YS_YYGJFW_YBUC_0001336020": "請選擇應用",
      "P_YS_SD_UDHWS_0000066531": "主計量數量",
      "P_YS_FED_FW_0000022004": "查看",
      "P_YS_FED_FW_0000022489": "今日",
      "P_YS_FI_FCWEB_0001324088": "未連接多語中心的詞條:",
      "P_YS_FED_FW_0000022003": "請選擇行再進行下移操作！",
      "P_YS_FI_FCWEB_0001324089": "不存在,請檢查詞條!",
      "P_YS_PF_PRINT_0000056949": "錶頭自定義項12",
      "P_YS_OA_XTWENKU_0000035721": "項目名稱",
      "P_YS_PF_PRINT_0000056947": "錶頭自定義項13",
      "P_YS_HR_HRSSC_0000135821": "取消失敗",
      "P_YS_FED_MDF-COMPS_0001623570": "頁面發佈",
      "P_YS_PF_PRINT_0000056956": "錶頭自定義項18",
      "P_YS_FED_MDF-COMPS_0001623330": "隱私協議地址",
      "P_YS_FED_MDF-COMPS_0001623572": "啟用失敗！",
      "P_YS_PF_PRINT_0000057801": "錶頭自定義項8",
      "P_YS_SCM_STOCK_0000034476": "單據",
      "P_YS_FED_MDF-COMPS_0001623571": "智能發件櫃",
      "P_YS_PF_PRINT_0000057800": "錶頭自定義項9",
      "P_YS_FED_MDF-COMPS_0001623332": "調班",
      "P_YS_FED_MDF-COMPS_0001623574": "工作臺地址不能為空！",
      "P_YS_FED_MDF-COMPS_0001623331": "不超2MB",
      "P_YS_FED_MDF-COMPS_0001623573": "別名密碼",
      "P_YS_FED_MDF-COMPS_0001623334": "今日錯誤數",
      "P_YS_FED_MDF-COMPS_0001623576": "未找到相關插件",
      "P_YS_PF_PRINT_0000056950": "錶頭自定義項11",
      "P_YS_FED_MDF-COMPS_0001623333": "PC端頁面名稱",
      "P_YS_FED_MDF-COMPS_0001623575": "客戶檔案",
      "P_YS_FED_MDF-COMPS_0001623336": "啟用狀態不允許刪除，請停用後刪除",
      "P_YS_FED_MDF-COMPS_0001623578": "只查看上架數據",
      "P_YS_FED_MDF-COMPS_0001623335": "沒有選中",
      "P_YS_FED_MDF-COMPS_0001623577": "刪除pc端",
      "P_YS_PF_METADATA_0000087362": "應用圖標",
      "RPA_L27_00050068": "机器人运行状态",
      "P_YS_FED_MDF-COMPS_0001623338": "銷售發貨",
      "P_YS_FED_MDF-COMPS_0001623337": "應用code不存在，請聯繫管理員！",
      "P_YS_FED_MDF-COMPS_0001623579": "確定要刪除服務嗎?",
      "P_YS_FED_MDF-COMPS_0001623339": "下載日誌",
      "P_YS_PF_GZTSYS_0000134847": "工資條",
      "P_YS_OA_XTSPZX_0000034640": "WEB端",
      "Running": "運行中",
      "P_YS_SD_SDMA-UI_0001101654": "終端",
      "P_YS_FED_FW_0000022255": "增加",
      "P_YS_FED_FW_0000022256": "未選中任何行！",
      "P_YS_FED_UCFBASEDOC_0000024994": "部門加班",
      "P_YS_PF_PRINT_0000056958": "錶頭自定義項17",
      "P_YS_FED_UCFBASEDOC_0000024990": "密碼",
      "P_YS_SCM_USTOCK-UI_0001025485": "必輸，請精確到sku",
      "P_YS_OA_XTLCZX_0000030159": "審批時間",
      "P_YS_FED_MDF-COMPS_0001623541": "請搜素",
      "P_YS_PF_GZTLOG_0000025980": "列表",
      "P_YS_OA_app_xtyyjm_0000035947": "新建應用",
      "P_YS_FED_MDF-COMPS_0001623540": "無限制：",
      "P_YS_FED_MDF-COMPS_0001623301": "社交溝通",
      "P_YS_FED_MDF-COMPS_0001623543": "]將被刷新",
      "P_YS_FED_MDF-COMPS_0001623300": "搜索領域名稱",
      "P_YS_FED_MDF-COMPS_0001623542": "說明：上傳圖片尺寸建議為750*244px，圖片支持JPG/PNG格式，大小不超過300KB",
      "P_YS_PF_METADATA_0000085152": "系統版本",
      "P_YS_FED_MDF-COMPS_0001623303": "），繼續保存將覆蓋已有APP。",
      "P_YS_FED_MDF-COMPS_0001623545": "穿梭表參照",
      "P_YS_FED_MDF-COMPS_0001623302": "確定帶數據許可權發佈當前參照嗎?",
      "P_YS_FED_MDF-COMPS_0001623544": "長度應小於",
      "P_YS_FED_MDF-COMPS_0001623305": "匿名",
      "P_YS_FED_MDF-COMPS_0001623547": "請選擇沙箱許可",
      "P_YS_FED_MDF-COMPS_0001623304": "開屏頁面大小不能超過2M",
      "P_YS_FED_MDF-COMPS_0001623546": "生成示例應用",
      "P_YS_FED_MDF-COMPS_0001623307": "應用引擎",
      "P_YS_FED_MDF-COMPS_0001623549": "主子孫",
      "P_YS_FED_MDF-COMPS_0001623306": "新建沙箱",
      "P_YS_FED_MDF-COMPS_0001623548": "開發租戶",
      "P_YS_FED_MDF-COMPS_0001623309": "首頁地址不能為空！",
      "P_YS_FED_MDF-COMPS_0001623308": "擴展成功",
      "P_YS_OA_XTYUNHUIWU_0000168712": "已複製到黏貼板",
      "P_YS_FED_UCFBASEDOC_0000024747": "辦公協作",
      "P_YS_FED_UCFBASEDOC_0000024986": "匯入",
      "P_YS_FED_FW_0000022228": "欄目名稱",
      "P_YS_FED_UCFBASEDOC_0000024742": "開證明",
      "P_YS_PF_GZTSYS_0001122252": "移動供應鏈",
      "P_YS_FED_MDF-COMPS_0001623550": "刷新沙箱",
      "P_YS_FED_MDF-COMPS_0001623310": "添加證書檔",
      "P_YS_FED_MDF-COMPS_0001623552": "按結構默認生成",
      "P_YS_FED_MDF-COMPS_0001623551": "查企業",
      "P_YS_FED_MDF-COMPS_0001623312": "許可權說明：管理沙箱(編輯、刪除、導入用戶)",
      "P_YS_FED_MDF-COMPS_0001623554": "業務對象（",
      "P_YS_SCM_STOCK_0000034216": "有效期至",
      "P_YS_FED_MDF-COMPS_0001623311": "創建自",
      "P_YS_FED_MDF-COMPS_0001623553": "圖片尺寸錯誤，請上傳122*122以內的圖片",
      "P_YS_FED_MDF-COMPS_0001623314": "打包日誌",
      "P_YS_FED_MDF-COMPS_0001623556": "請選擇要寫入cookie的環境",
      "P_YS_PF_GZTSYS_0000012096": "購買記錄",
      "P_YS_FED_MDF-COMPS_0001623313": "頒發者",
      "P_YS_FED_MDF-COMPS_0001623555": "應用顯示規則是控制當前租戶下應用在APP工作臺的顯示規則。",
      "P_YS_FED_MDF-COMPS_0001623316": "上架版本",
      "P_YS_FED_MDF-COMPS_0001623558": "(參照)",
      "RPA_L27_00050089": "取消部署",
      "P_YS_FED_MDF-COMPS_0001623315": "應用編碼長度應小於50個字元",
      "P_YS_FED_MDF-COMPS_0001623557": "僅發佈",
      "P_YS_PF_GZTTMP_0000078679": "證書密碼",
      "P_YS_FED_MDF-COMPS_0001623318": "需跳轉到證書管理頁面，當前頁面數據會以草稿態保存到 App 列表中。",
      "P_YS_FED_MDF-COMPS_0001623317": "證書下有描述檔被使用中，無法刪除",
      "P_YS_FED_MDF-COMPS_0001623559": "雲會務",
      "P_YS_FED_MDF-COMPS_0001623319": "頁面列表",
      "P_YS_FED_UCFBASEDOC_0000024737": "我的目標",
      "P_YS_FED_FW_0000022236": "導航",
      "P_YS_FED_FW_0000022234": "葡萄牙語",
      "P_YS_SD_SDMBF_0000145547": "趨勢圖",
      "P_YS_FED_FW_0000022291": "庫存組織",
      "P_YS_HR_HRPUBF_0001009191": "請輸入必填項",
      "P_YS_FED_FW_0000022055": "請輸入...",
      "P_YS_FED_FW_0000022298": "保存失敗！",
      "P_YS_FED_MDF-COMPS_0001623521": "打包中",
      "P_YS_FED_MDF-COMPS_0001623520": "下架狀態，將該版本設置為下架狀態",
      "P_YS_FED_MDF-COMPS_0001623523": "不帶默認壞境",
      "P_YS_FED_MDF-COMPS_0001623522": "工資條",
      "P_YS_FED_MDF-COMPS_0001623525": "當前應用不存在！",
      "P_YS_PF_GZTSYS_0001561352": "數據建模",
      "P_YS_FED_MDF-COMPS_0001623524": "確定刪除當前證書檔嗎?刪除後不可找回, 請謹慎操作",
      "P_YS_FED_MDF-COMPS_0001623527": "請輸入名稱或描述",
      "P_YS_FED_MDF-COMPS_0001623526": "獲取到期沙箱失敗",
      "P_YS_FED_MDF-COMPS_0001623529": "純文字卡片",
      "P_YS_FED_MDF-COMPS_0001623528": "本操作相當於重建沙箱，當前數據將被重置且操作無法撤回。",
      "RPA_L27_00050095": "机器人部署详情",
      "P_YS_FED_UIMG_0000025893": "請先將該欄位設為顯示！",
      "P_YS_OA_XTLCZX_0000030325": "上傳",
      "P_YS_FED_MDF-COMPS_0001623530": "用友雲隱私協議",
      "P_YS_FED_MDF-COMPS_0001623532": "更新描述",
      "P_YS_FED_MDF-COMPS_0001623531": "系統要求：",
      "P_YS_FED_MDF-COMPS_0001623534": "訪問應用構建服務失敗",
      "P_YS_FED_MDF-COMPS_0001623533": "測試沙箱",
      "P_YS_FED_MDF-COMPS_0001623536": "請選擇使用許可",
      "P_YS_FED_MDF-COMPS_0001623535": "頁面編碼不能以數字開頭!",
      "P_YS_FED_MDF-COMPS_0001623538": "樹形表",
      "P_YS_FED_MDF-COMPS_0001623537": "自主創建和維護的應用程式。",
      "P_YS_FED_MDF-COMPS_0001623539": "選擇時間段",
      "P_YS_FED_EXAMP_0000020286": "編碼",
      "P_YS_HR_HRXCF_0001135149": "賬號密碼",
      "P_YS_SD_SDMBF_0000145326": "購買數量",
      "P_YS_FED_FW_0000022030": "校驗成功",
      "P_YS_PF_PRINT_0000056963": "錶頭自定義項15",
      "P_YS_PF_GZTSYS_0000134433": "工作流",
      "P_YS_FED_MDF-COMPS_0001623741": "移動端頁面",
      "P_YS_PF_PRINT_0000056961": "錶頭自定義項16",
      "P_YS_FED_MDF-COMPS_0001623740": "普通上架",
      "P_YS_FED_MDF-COMPS_0001623501": "錯誤分析詳情",
      "P_YS_FED_MDF-COMPS_0001623743": "更新app異常",
      "P_YS_FED_MDF-COMPS_0001623500": "終端系統",
      "P_YS_FED_MDF-COMPS_0001623742": "名稱/手機號/郵箱",
      "P_YS_FED_MDF-COMPS_0001623503": "主題配置",
      "P_YS_FED_MDF-COMPS_0001623745": "鏈接網址",
      "P_YS_FED_MDF-COMPS_0001623502": "留下",
      "P_YS_FED_MDF-COMPS_0001623744": "添加常用",
      "P_YS_FED_MDF-COMPS_0001623505": "公共插件",
      "P_YS_FED_MDF-COMPS_0001623747": "應用編碼只能包含數字字母下劃線!",
      "P_YS_FED_MDF-COMPS_0001623504": "頁面編碼不能為空!",
      "P_YS_FED_MDF-COMPS_0001623746": "打開上次訪問的應用",
      "P_YS_FED_MDF-COMPS_0001623507": "菜單/類別",
      "P_YS_FED_MDF-COMPS_0001623506": "建造中",
      "P_YS_FED_MDF-COMPS_0001623509": "首頁地址",
      "P_YS_FED_FW_0001486020": "【提示】若基準語言（<%=enterprise%>）內容未維護，系統將預設按當前語言（<%=current%>）內容賦值處理",
      "P_YS_FED_MDF-COMPS_0001623508": "iOS 11以上",
      "P_YS_PF_PRINT_0000065438": "應收件數",
      "P_YS_OA_XTSPZX_0000034833": "時間",
      "P_YS_FED_FW_0000022040": "向下",
      "P_YS_FED_FW_0000022041": "運算符",
      "P_YS_OA_app_xtyyjm_0000035998": "創建者",
      "P_YS_PF_GZTSYS_0001206993": "銷售跟單",
      "P_YS_FED_MDF-COMPS_0001623510": "沙箱總數",
      "P_YS_FED_MDF-COMPS_0001623512": "切換後，當前應用許可權為所有人可操作",
      "P_YS_PF_PRINT_0000056970": "錶頭自定義項19",
      "P_YS_FED_MDF-COMPS_0001623511": "同時生成參照",
      "P_YS_FED_MDF-COMPS_0001623514": "應用顯示規則：",
      "P_YS_FED_MDF-COMPS_0001623513": "暫無可用許可，請聯繫銷售人員擴展額外許可",
      "P_YS_FED_MDF-COMPS_0001623516": "APP配置檔",
      "P_YS_FED_MDF-COMPS_0001623515": "對象建模",
      "P_YS_FED_MDF-COMPS_0001623518": "存在重複的包名（",
      "P_YS_FED_MDF-COMPS_0001623517": "友空間投屏",
      "P_YS_FED_MDF-COMPS_0001623519": "多環境",
      "P_YS_FED_FW_0000022279": "未知",
      "P_YS_PF_GZTSYS_0000134430": "社交溝通",
      "P_YS_HR_HRPUBF_0001009195": "請輸入編碼和名稱",
      "P_YS_FED_UCFBASEDOC_0000025464": "話題",
      "P_YS_OA_XTMENHUCMS_0000044938": "導航標題",
      "P_YS_PF_PRINT_0000057631": "表體自定義項7",
      "P_YS_FED_MDF-COMPS_0001623721": "確定要取消部署嗎?",
      "P_YS_PF_PRINT_0000057630": "表體自定義項8",
      "P_YS_FED_MDF-COMPS_0001623720": "說明：閃屏一般用於運營推廣；上傳圖片支持JPG/PNG/GIF格式，尺寸建議為1080*1920px，大小不超過300KB",
      "P_YS_FED_MDF-COMPS_0001623723": "APP配置嗎？刪除後該租戶下的APP配置將失效，請謹慎操作！",
      "P_YS_FED_MDF-COMPS_0001623722": "位元組長度應小於8",
      "P_YS_FED_MDF-COMPS_0001623725": "白名單控制：",
      "P_YS_FED_MDF-COMPS_0001623724": "針對已購買/已授權的應用,進行特性增強的應用程式。",
      "P_YS_FED_MDF-COMPS_0001623727": "離線工作臺",
      "P_YS_FED_MDF-COMPS_0001623726": "系統：",
      "P_YS_FED_MDF-COMPS_0001623729": "請輸入沙箱名稱",
      "P_YS_FED_MDF-COMPS_0001623728": "頁面編碼只能包含數字字母下劃線!",
      "P_YS_SD_SDMBF_0000145133": "上架時間",
      "P_YS_PF_GZTSYS_0000012497": "部門排班",
      "P_YS_FED_FW_0000021796": "確定要刪除",
      "P_YS_HR_HRJQF_0000054269": "選擇檔案",
      "P_YS_FED_FW_0000021314": "參照",
      "P_YS_OA_XTLCZX_0000030527": "表單分類",
      "P_YS_OA_XTLCZX_0000030780": "新建",
      "P_YS_PF_HELPCENTER-FE_0001135323": "請選擇領域",
      "P_YS_FED_MDF-COMPS_0001623730": "樹表參照",
      "P_YS_SCM_USTOCK-UI_0001025096": "版本信息",
      "P_YS_FED_MDF-COMPS_0001623732": "證書OU",
      "P_YS_PF_MANAGER-FE_0001087849": "確認停用【",
      "P_YS_FED_MDF-COMPS_0001623731": "未知租戶",
      "P_YS_FED_MDF-COMPS_0001623734": "點擊進入官方文檔",
      "P_YS_FED_MDF-COMPS_0001623733": "APP配置名稱",
      "P_YS_FED_MDF-COMPS_0001623736": "上架版本：",
      "P_YS_FED_MDF-COMPS_0001623735": "(網頁端)",
      "P_YS_FED_MDF-COMPS_0001623738": "生成列表頁面",
      "P_YS_FED_MDF-COMPS_0001623737": "數據模型111",
      "P_YS_FED_MDF-COMPS_0001623739": "調動",
      "P_YS_SD_SDMB_0000146708": "下載二維碼",
      "P_YS_FED_UIMG_0000025865": "配置",
      "P_YS_FED_IMP_IOT0000006019": "請搜索",
      "P_YS_PF_PRINT_0000065262": "物料名稱",
      "P_YS_OA_XTLCZX_0000030779": "請輸入標題",
      "P_YS_OA_app_xtyyjm_0000035788": "已發布",
      "P_YS_OA_XTLCZX_0000030553": "流程",
      "P_YS_PF_GZTSYS_0000012482": "開證明",
      "P_YS_SD_SDMA_0000123117": "微信小程序",
      "P_YS_FED_MDF-COMPS_0001623701": "下載離線包模版",
      "P_YS_FED_MDF-COMPS_0001623700": "APP打包",
      "P_YS_FED_MDF-COMPS_0001623703": "終端系統不能為空！",
      "P_YS_FED_MDF-COMPS_0001623702": "錯誤分析",
      "P_YS_FED_MDF-COMPS_0001623705": "新建app異常",
      "P_YS_FED_MDF-COMPS_0001623704": "行編輯表",
      "P_YS_FED_MDF-COMPS_0001623707": "上傳檔格式錯誤，支持上傳的檔格式為：png、jpeg、jpg、svg",
      "P_YS_FED_MDF-COMPS_0001623706": "員工資訊",
      "P_YS_FED_MDF-COMPS_0001623709": "同時生成列表",
      "P_YS_FED_MDF-COMPS_0001623708": "新建機器人",
      "P_YS_PF_METADATA_0000085783": "產品規格",
      "P_YS_YYGJFW_YBUC_0001568362": "擴",
      "P_YS_FED_FW_0000021774": "法語",
      "bpm.datatype.start.success": "啟用成功！",
      "P_YS_PF_PRINT_0000057628": "表體自定義項3",
      "P_YS_PF_PRINT_0000057627": "表體自定義項4",
      "P_YS_PF_MANAGER-FE_0001204334": "APP配置",
      "P_YS_PF_GZTSYS_0001050715": "我的合同",
      "P_YS_PF_PRINT_0000057624": "表體自定義項5",
      "P_YS_FED_FW_0000021792": "預覽",
      "P_YS_PF_BD-UI_0001049605": "單卡",
      "P_YS_PF_PRINT_0000057622": "表體自定義項6",
      "P_YS_PF_PRINT_0000057621": "表體自定義項1",
      "P_YS_PF_MANAGER-FE_0001204332": "APP名稱",
      "P_YS_PF_PRINT_0000057620": "表體自定義項2",
      "P_YS_FED_MDF-COMPS_0001623710": "銷售跟單",
      "P_YS_PF_METADATA_0001040427": "產地",
      "P_YS_FED_MDF-COMPS_0001623712": "描述檔不能為空！",
      "P_YS_FED_MDF-COMPS_0001623711": "組織模式",
      "P_YS_FED_MDF-COMPS_0001623714": "白名單控制",
      "P_YS_FED_MDF-COMPS_0001623713": "發佈應用",
      "P_YS_FED_MDF-COMPS_0001623716": "日常盤點",
      "P_YS_FED_MDF-COMPS_0001623715": "添加插件",
      "P_YS_FED_MDF-COMPS_0001623718": "開屏頁面",
      "P_YS_PF_GZTSYS_0000012489": "公告",
      "P_YS_FED_MDF-COMPS_0001623717": "請輸入正確網址",
      "P_YS_FED_MDF-COMPS_0001623719": "環境地址不能為空！",
      "P_YS_SD_SDMAUF_0000152718": "參照模板",
      "P_YS_SD_SDSD-FE_0001491115": "金額",
      "P_YS_FED_FW_0000022452": "意大利語",
      "P_YS_SD_UDHAR_0000053500": "可用",
      "P_YS_PF_GZTSYS_0001085814": "友空間投屏",
      "P_YS_PF_GZTSYS_0000012057": "公告設置",
      "P_YS_FED_UCFBASEDOC_0000025656": "請假",
      "P_YS_OA_XTMENHUCMS_0000044972": "圖片上傳成功",
      "P_YS_OA_XTMENHU_0000046825": "檔案上傳",
      "P_YS_PF_PROCENTER_0000027411": "倉庫檔案",
      "P_YS_FED_FW_0000022461": "欄目名稱/公式為必輸項！請填寫完整後重試！",
      "P_YS_FED_FW_0000021130": "在線",
      "P_YS_PF_GZTTMP_0000073639": "無限制",
      "P_YS_YC_BID-PROJECT_0001575935": "已打包",
      "P_YS_FED_EXAMP_0000020201": "角色",
      "P_YS_PF_METADATA_0000086463": "關聯關係",
      "P_YS_OA_XTMENHU_0000046819": "匯入用戶",
      "P_YS_FED_FW_0000022456": "設計",
      "P_YS_FED_UCFBASEDOC_0000025404": "修改成功",
      "P_YS_OA_XTLCZX_0000030733": "完成時間",
      "P_YS_PF_PROCENTER_0000023057": "庫存單位",
      "RPA_L23_00050024": "数据集下载",
      "P_YS_PF_GZTSYS_0000013133": "調班",
      "P_YS_FI_CM_0000026206": "已生成",
      "P_YS_PF_GZTSYS_0000012279": "銷售發貨",
      "P_YS_FED_UIMG_0000025836": "系統預置",
      "P_YS_PF_GZTSYS_0000013382": "表單模板",
      "P_YS_PF_PRINT_0000065047": "單據id",
      "P_YS_PF_PRINT_0000065042": "物料編碼",
      "P_YS_PF_GZTSYS_0001066094": "分析卡片",
      "P_YS_OA_XTSPZX_0000034668": "請輸入名稱",
      "OA_web_navsetting_000000021": "我",
      "P_YS_FED_FW_0000021100": "表",
      "P_YS_OA_XTRIZHI_0000036771": "說明",
      "P_YS_PF_YHTTNT-FE_0001045014": "備註名",
      "P_YS_PF_GZTSYS_0000012530": "智能發件櫃",
      "P_YS_PF_GZTSYS_0000013621": "批次號",
      "P_YS_PF_GZTSYS_0000012532": "投票",
      "P_YS_YYGJFW_YBUC_0001336704": "更新內容",
      "P_YS_FED_FW_0000021954": "系統項，不允許設計！",
      "P_YS_PF_METADATA_0000084513": "插件名稱",
      "P_YS_FI_FCWEB_0001047450": "成員類型",
      "OA_web_ad_0000000103": "失效時間",
      "P_YS_PF_GZTVPA_0001126369": "畫布",
      "P_YS_PF_GZTSYS_0000056369": "業務流",
      "P_YS_SD_SDMA_0000063372": "停用成功！",
      "P_YS_PF_GZTSYS_0000012545": "元數據",
      "P_YS_PF_GZTSYS_0000012786": "部門放休",
      "P_YS_OA_app_xtywlj_0000037486": "自建",
      "P_YS_PF_MANAGER-FE_0001620641": "取消完成",
      "P_YS_FED_IMP_IOT0000005448": "模板名稱",
      "P_YS_FED_FW_0000157504": "樹參照",
      "P_YS_PF_GUIDE_0001071547": "單組織",
      "P_YS_PF_GZTSYS_0000012548": "入職轉單",
      "P_YS_FED_FW_0000021723": "已啟用",
      "P_YS_FED_FW_0000021962": "是否顯示",
      "P_YS_PF_PROCENTER_0000027456": "手工輸入",
      "P_YS_FED_FW_0000021721": "】麼？",
      "P_YS_FED_UCFBASEDOC_0000025160": "權限",
      "P_YS_OA_app_xtywlj_0000037693": "重試次數",
      "P_YS_FED_FW_0000021935": "請選中一行後增加！",
      "P_YS_PF_GZTSYS_0000012753": "工作電郵",
      "P_YS_FED_UCFORG_0000031966": "上一步",
      "P_YS_YYGJFW_YBUC_0001336920": "你確定刪除",
      "P_YS_SD_UDHWN_0000120990": "文件上傳成功",
      "P_YS_PF_GZTSYS_0001106345": "數據中台",
      "P_YS_FED_UCFBASEDOC_0000025158": "假勤代申請",
      "P_YS_PF_GZTSYS_0000012759": "客戶檔案",
      "P_YS_PF_GZTSYS_0001106342": "查企業",
      "P_YS_FED_UIMG_0000111351": "表達式不能為空",
      "P_YS_PF_GZTPRE_0001118984": "請選擇元數據",
      "P_YS_PF_GZTSYS_0000012765": "我的團隊",
      "P_YS_FED_FW_0000021947": "多語言設置",
      "P_YS_FED_EXAMP_0000020662": "複製成功",
      "P_YS_OA_XTLCZX_0000030711": "單位",
      "P_YS_OA_XTRIZHI_0000036745": "所有人可見",
      "P_YS_PF_GZTSYS_0000012529": "轉正",
      "P_YS_FED_FW_0000021941": "德語",
      "P_YS_FED_UCFBASEDOC_0000025386": "作廢",
      "P_YS_FI_CM_0000029774": "已開通",
      "P_YS_FED_IMP_IOT_0000129734": "請輸入功能名稱",
      "P_YS_FI_FCWEB_0001048516": "聚合",
      "P_YS_PF_GZTSYS_0000012580": "個人信息",
      "P_YS_PF_PROCENTER_0000022808": "保質期單位",
      "P_YS_PF_GZTORG_0000174966": "請確認",
      "P_YS_FED_IMP_IOT0000005413": "操作",
      "P_YS_OA_XTTMCENTER_0000035086": "自建應用",
      "P_YS_PF_METADATA_0000085880": "可用數量",
      "P_YS_FED_FW_0001100234": "該操作，對必輸項目<%=confirmMSGFields%>設置了隱藏，請再次確認",
      "P_YS_FED_UCFBASEDOC_0000025138": "微郵",
      "P_YS_FED_FW_0001050721": "確定刪除欄目\"<%=cShowCaptionName%>\"麼?",
      "P_YS_FED_UCFBASEDOC_0000025134": "團隊目標",
      "P_YS_FED_FW_0001050720": "編輯<%=templateName%>",
      "P_YS_SD_SDMAUF_0000153152": "還剩",
      "P_YS_PF_PRINT_0000057169": "模板id",
      "P_YS_PF_GZTSYS_0000012594": "任務",
      "P_YS_PF_GZTSYS_0000012590": "我的目標",
      "P_YS_PF_GZTSYS_0001207021": "銷售下單",
      "P_YS_FED_FW_0000021769": "小數位",
      "P_YS_IPAAS_APIC-FE_0001577501": "提供者",
      "P_YS_OA_XTTMCENTER_0000035090": "創建人",
      "P_YS_FED_FW_0000021766": "返回",
      "P_YS_CTM_STCT-BE_0001315265": "沒有選中的資料",
      "P_YS_HR_HRJXF_0000059300": "設置失敗",
      "P_YS_PF_GZTSYS_0000012561": "倉庫",
      "P_YS_FED_UIMG_0001050970": "隱藏關聯欄位會對運行時產生影響，請謹慎操作",
      "P_YS_FED_FW_0000021735": "韓語",
      "P_YS_FED_FW_0000021971": "顯示名稱",
      "P_YS_FED_FW_0001050719": "查看<%=templateName%>",
      "P_YS_PF_GZTSYS_0000015829": "報表",
      "P_YS_IPAAS_UCG_0001112295": "部署",
      "P_YS_HR_HRJQ_0000031322": "格式錯誤",
      "RPA_L0_00050021": "设计器",
      "P_YS_HR_HRPUBF_0000053688": "設置成功",
      "P_YS_FED_UIMG_0001050967": "模板校驗失敗",
      "P_YS_IPAAS_UCG_0001072575": "API發布",
      "P_YS_FED_UIMG_0001050968": "當前搜索結果對應實體元數據的屬性，非UI元數據屬性",
      "P_YS_FED_UIMG_0001050969": "確定刪除欄目",
      "P_YS_SD_UDHMC_0001070581": "藍色主題",
      "P_YS_FED_FW_0000021981": "默認值",
      "P_YS_FED_UCFBASEDOC_0000025348": "企業介紹",
      "P_YS_OA_XTWENKU_0000035597": "新建成功",
      "P_YS_OA_LCGL_0000037122": "創建成功",
      "P_YS_FED_FW_0000021743": "資料型別",
      "P_YS_FI_CM_0000029174": "備註",
      "P_YS_OA_XTLCZX_0000032030": "前往",
      "P_YS_PF_GZTSYS_0000135427": "應用構建",
      "P_YS_SCM_USTOCK_0000064365": "到期",
      "P_YS_SD_SDMA_0000060271": "二維碼",
      "P_YS_FED_MDF-COMPS_0001378486": "基準語言",
      "P_YS_PF_MANAGER-FE_0001470871": "一級菜單",
      "P_YS_FED_UCFORG_0000025917": "啟用失敗",
      "P_YS_FED_EXAMP_0000020808": "名稱",
      "000206": "设置变量",
      "P_YS_FED_MDF-COMPS_0001378485": "【提示】若企業賬號語言（<%=enterprise%>）內容未維護，系統將預設按當前語言（<%=current%>）內容賦值處理",
      "P_YS_FED_MDF-COMPS_0001378484": "基準語言為空時，則預設取當前語言內容。",
      "P_YS_OA_XTTMCENTER_0000035056": "更新時間",
      "P_YS_PF_GZTSYS_0000012709": "離職",
      "P_YS_OA_LCGL_0000037147": "匯入成功",
      "P_YS_FED_MDF-COMPS_0001623383": "文庫",
      "P_YS_FED_FW_0000022195": "是否必輸",
      "P_YS_HR_HRJQF_0000053833": "不通過",
      "P_YS_FED_MDF-COMPS_0001623382": "系統應用管理",
      "P_YS_FED_MDF-COMPS_0001623385": "申請歷史",
      "P_YS_FED_MDF-COMPS_0001623384": "確定刪除當前描述檔嗎?刪除後不可找回, 請謹慎操作",
      "P_YS_FED_MDF-COMPS_0001623387": "應用引擎:",
      "P_YS_FED_MDF-COMPS_0001623386": "錯誤率",
      "P_YS_FED_MDF-COMPS_0001623389": "圖文卡片",
      "P_YS_FED_MDF-COMPS_0001623388": "當前租戶未開通專業版！",
      "P_YS_SD_UDHWN_0000120595": "正在",
      "P_YS_FED_MDF-COMPS_0001623390": "流程&自動化",
      "P_YS_FED_MDF-COMPS_0001623392": "指紋資訊",
      "P_YS_FED_MDF-COMPS_0001623391": "別名（alias）",
      "P_YS_SD_SDMB_0000146297": "用戶名/手機號/電郵",
      "P_YS_FED_MDF-COMPS_0001623394": "管理成員 (增刪) | 開發應用",
      "P_YS_FED_MDF-COMPS_0001623393": "部門排班",
      "P_YS_FED_MDF-COMPS_0001623396": "資產盤點移動應用",
      "P_YS_FED_MDF-COMPS_0001623395": "啟動閃屏",
      "P_YS_FED_MDF-COMPS_0001623398": "申請已提交",
      "P_YS_FED_MDF-COMPS_0001623397": "簽名證書",
      "P_YS_HR_HRJQ_0000031367": "暫無",
      "P_YS_FED_MDF-COMPS_0001623399": "請輸入應用編碼",
      "P_YS_PF_METADATA_0001162380": "堆棧信息",
      "RPA_L27_00050006": "重试配置",
      "P_YS_PF_METADATA_0001038878": "變量值",
      "P_YS_HR_HRPXF_0000056097": "掃碼",
      "P_YS_HR_HRJXF_0000059361": "參數配置",
      "000652": "报告设置",
      "P_YS_FED_MDF-COMPS_0001623361": "包名",
      "P_YS_FED_MDF-COMPS_0001623360": "頁面編碼長度應小於50個字元",
      "P_YS_FED_MDF-COMPS_0001623363": "佔用沙箱",
      "P_YS_FED_MDF-COMPS_0001623362": "證書使用中，不能刪除",
      "P_YS_FED_MDF-COMPS_0001623365": "未收集到日誌資訊，請嘗試重新打包",
      "P_YS_FED_MDF-COMPS_0001623364": "系統預置功能，包含工作臺、通訊錄、我的，需登錄後使用",
      "P_YS_FED_MDF-COMPS_0001623367": "導入用戶發生錯誤",
      "P_YS_FED_MDF-COMPS_0001623366": "切換後，當前應用許可權為僅成員可操作",
      "P_YS_FED_MDF-COMPS_0001623369": "確定新增PC頁面嗎?",
      "P_YS_FED_MDF-COMPS_0001623368": "長度應小於100個字元",
      "RPA_L27_00050017": "收件人邮箱",
      "P_YS_SD_SDMB_0000138699": "分組列表",
      "P_YS_FED_FW_0000021911": "替換圖片",
      "P_YS_SD_SDSD-FE_0001486259": "日期時間",
      "P_YS_PF_GZTSYS_0000011886": "文庫",
      "P_YS_FED_MDF-COMPS_0001573056": "日期時間測試",
      "P_YS_FED_MDF-COMPS_0001573057": "固定資產檔案導出測試",
      "P_YS_PF_PRINT_0000056908": "錶頭自定義項24",
      "P_YS_SD_SDSD-FE_0001491840": "單據日期",
      "P_YS_PF_PRINT_0000056906": "錶頭自定義項20",
      "P_YS_PF_PRINT_0000056905": "錶頭自定義項21",
      "P_YS_FED_MDF-COMPS_0001573058": "z0客戶3255830",
      "P_YS_FED_MDF-COMPS_0001623370": "app打包異常",
      "P_YS_FED_MDF-COMPS_0001573059": "表體-0528test",
      "P_YS_FED_MDF-COMPS_0001623372": "請輸入擴展行數",
      "P_YS_FED_MDF-COMPS_0001623371": "請使用友空間掃一掃功能預覽頁面",
      "P_YS_PF_PRINT_0000056911": "錶頭自定義項22",
      "P_YS_FED_MDF-COMPS_0001623374": "任務取消重試",
      "P_YS_PF_PRINT_0000056910": "錶頭自定義項23",
      "P_YS_FED_MDF-COMPS_0001623373": "編碼不能是特殊字段!",
      "P_YS_FED_MDF-COMPS_0001623376": "未查詢到打包的日誌資訊！",
      "P_YS_FED_MDF-COMPS_0001623375": "已選擇成員(",
      "P_YS_FED_MDF-COMPS_0001623378": "Tip:大小不超過20kb，尺寸122px*122px的PNG圖示",
      "P_YS_FED_MDF-COMPS_0001623377": "僅成員可見",
      "RPA_L27_00050026": "部署机器人",
      "P_YS_FED_MDF-COMPS_0001623379": "今日設備數",
      "P_YS_PF_GZTSYS_0000135219": "智能分析",
      "RPA_L27_00050027": "所属客户端",
      "P_YS_FED_MDF-COMPS_0001573060": "庫存組織",
      "P_YS_PF_GZTSYS_0000012745": "自定義檔案",
      "P_YS_PF_GZTSYS_0000012744": "員工問詢",
      "P_YS_FED_FW_0000021928": "金額小數位",
      "P_YS_FED_MDF-COMPS_0001573063": "產地",
      "P_YS_FED_MDF-COMPS_0001573064": "ycx檔案類型",
      "P_YS_PF_GZTSYS_0000011891": "視頻會議",
      "P_YS_FED_MDF-COMPS_0001573061": "庫管員",
      "P_YS_PF_GZTSYS_0000012741": "智能取件櫃",
      "P_YS_FED_MDF-COMPS_0001573062": "型號",
      "P_YS_PF_GZTSYS_0000012740": "日誌",
      "P_YS_FED_MDF-COMPS_0001573067": "您對必輸項設置了隱藏，請注意！",
      "P_YS_PF_PROCENTER_0000022885": "保質期",
      "P_YS_FED_MDF-COMPS_0001573068": "交易類型",
      "P_YS_FED_MDF-COMPS_0001573065": "NN表頭數值0630",
      "P_YS_FED_MDF-COMPS_0001573066": "庫存單位",
      "P_YS_FED_MDF-COMPS_0001573069": "表頭-0528test",
      "P_YS_FED_MDF-COMPS_0001623381": "確定新增移動頁面嗎?",
      "P_YS_PF_GZTSYS_0000012748": "團隊目標",
      "P_YS_FED_MDF-COMPS_0001623380": "增補成功",
      "P_YS_FED_MDF-COMPS_0001623581": "查看上架應用",
      "P_YS_FED_MDF-COMPS_0001623580": "最多可設置24個常用應用，按住拖拽可進行排序",
      "P_YS_FED_MDF-COMPS_0001623341": "嘟嘟雲總機",
      "P_YS_FED_MDF-COMPS_0001623583": "領域開通耗時10-20分鐘，請耐心等待",
      "P_YS_FED_MDF-COMPS_0001623340": "移動列表",
      "P_YS_FED_MDF-COMPS_0001623582": "記住密碼框",
      "P_YS_FED_UIMG_0001065827": "非數值類型的欄位不能合計",
      "P_YS_FED_MDF-COMPS_0001623343": "編輯沙箱",
      "P_YS_FED_MDF-COMPS_0001623585": "不超1MB",
      "P_YS_FED_MDF-COMPS_0001623342": "未勾選的定時規則，將不予不保存，是否繼續？",
      "P_YS_FED_MDF-COMPS_0001623584": "許可權發佈",
      "P_YS_OA_XTWENKU_0000035509": "檔案名",
      "P_YS_FED_MDF-COMPS_0001623345": "Android 6.0以上",
      "P_YS_FED_MDF-COMPS_0001623587": "已存在包名（",
      "P_YS_FED_MDF-COMPS_0001623344": "累計錯誤數",
      "P_YS_FED_MDF-COMPS_0001623586": "請輸入頁面編碼",
      "P_YS_FED_MDF-COMPS_0001623347": "點擊查看插件資訊",
      "P_YS_FED_MDF-COMPS_0001623589": "證書簽名",
      "P_YS_FED_MDF-COMPS_0001623346": "請輸入隱私協議地址！",
      "P_YS_FED_MDF-COMPS_0001623588": "請選擇組織模式",
      "P_YS_FED_MDF-COMPS_0001623349": "當前應用內的服務將全部停用，請謹慎操作。",
      "P_YS_OA_XTTMCENTER_0000035019": "用戶名",
      "P_YS_FED_MDF-COMPS_0001623348": "已關聯APP配置",
      "P_YS_SD_URETAIL-UI_0001150860": "計劃盤點",
      "P_YS_PF_BD-UI_0000157890": "機器人",
      "P_YS_PF_GZTVPA_0001190941": "可更新",
      "P_YS_OA_XTTMCENTER_0000035020": "操作時間",
      "P_YS_FI_FCWEB_0001047849": "模板生成成功",
      "P_YS_PF_METADATA_0000084937": "證書名稱",
      "P_YS_SD_SDMA_0000061789": "客戶端",
      "P_YS_SCM_STOCK_0000034250": "版本號",
      "P_YS_FED_MDF-COMPS_0001623590": "選擇上架，前一個上架版本將自動下架",
      "P_YS_FED_MDF-COMPS_0001623350": "應用主頁",
      "P_YS_FED_MDF-COMPS_0001623592": "登錄方式",
      "P_YS_FED_UCFBASEDOC_0000025199": "表單",
      "P_YS_FED_MDF-COMPS_0001623591": "圖片大小不能超過300KB",
      "P_YS_FED_MDF-COMPS_0001623352": "訪問APP設置",
      "P_YS_FED_MDF-COMPS_0001623594": "生成單卡頁面",
      "P_YS_PF_PROCENTER_0000027079": "用戶",
      "P_YS_FED_MDF-COMPS_0001623351": "），請到列表上進行修改",
      "P_YS_FED_MDF-COMPS_0001623593": "今日啟動次數",
      "P_YS_FED_MDF-COMPS_0001623354": "請選擇管理員",
      "P_YS_FED_MDF-COMPS_0001623596": "請輸入更新內容",
      "P_YS_FED_MDF-COMPS_0001623353": "請輸入PC端頁面名稱",
      "P_YS_FED_MDF-COMPS_0001623595": "新建微信小程式",
      "P_YS_FED_MDF-COMPS_0001623356": "選擇下架，上一個歷史上架版本將恢復上架狀態",
      "P_YS_FED_MDF-COMPS_0001623598": "不帶默認環境",
      "P_YS_FED_MDF-COMPS_0001623355": "PC端名稱",
      "P_YS_FED_MDF-COMPS_0001623597": "請上傳配置檔",
      "RPA_L27_00050048": "部署详情",
      "P_YS_FED_MDF-COMPS_0001623358": "顯示隱藏應用",
      "P_YS_FED_UIMG_0000080080": "關聯欄位不允許隱藏",
      "P_YS_OA_app_xtyyjm_0000035918": "關聯配置",
      "P_YS_FED_MDF-COMPS_0001623357": "請輸入移動端頁面名稱",
      "P_YS_FED_MDF-COMPS_0001623599": "】應用？",
      "RPA_L27_00050045": "待机",
      "P_YS_FED_MDF-COMPS_0001623359": "測試沙箱(",
      "P_YS_PF_GZTSYS_0000012963": "庫管員",
      "P_YS_FED_FW_0000021900": "確定刪除欄目【",
      "P_YS_PF_GZTSYS_0000011876": "日程",
      "P_YS_OA_XTTXL_0000045984": "離線",
      "P_YS_PF_GZTSYS_0000011872": "雲直播",
      "P_YS_SD_URETAIL-UI_0001150858": "日常盤點",
      "P_YS_PF_GZTSYS_0000012725": "員工信息",
      "P_YS_PF_GZTSYS_0000011877": "話題",
      "P_YS_PF_GZTSYS_0000011879": "微郵",
      "P_YS_SCM_STOCK_0000034341": "單價",
      "P_YS_FED_MDF-COMPS_0001623680": "刪除沙箱",
      "P_YS_FED_FW_0000022371": "當前語種",
      "P_YS_OA_XTLCZX_0000030494": "折疊",
      "P_YS_FED_MDF-COMPS_0001623440": "證書",
      "P_YS_FED_MDF-COMPS_0001623682": "請先啟用輪播圖再進行配置~",
      "P_YS_FED_MDF-COMPS_0001623681": "上下架規則",
      "P_YS_FED_MDF-COMPS_0001623442": "已添加用戶",
      "P_YS_FED_MDF-COMPS_0001623684": "友空間rrr",
      "P_YS_FED_MDF-COMPS_0001623441": "建議1080*1920px",
      "P_YS_FED_MDF-COMPS_0001623683": "菜單結構",
      "P_YS_FED_FW_0000022130": "系統項不允許進行刪除操作！",
      "P_YS_OA_app_xtyyjm_0000035805": "必選",
      "P_YS_FED_MDF-COMPS_0001623444": "小程式名稱",
      "P_YS_FED_MDF-COMPS_0001623686": "沒有選中的實體",
      "P_YS_FED_FW_0000021042": "完成",
      "P_YS_FED_MDF-COMPS_0001623443": "上傳檔格式錯誤，支持上傳的檔格式為：png、jpeg、jpg",
      "P_YS_FED_MDF-COMPS_0001623685": "包名不能為空！",
      "P_YS_FED_MDF-COMPS_0001623446": "確定要刪除這條嗎？",
      "P_YS_FED_MDF-COMPS_0001623688": "累計啟動次數",
      "P_YS_SD_SDSD-FE_0001486293": "個人信息",
      "P_YS_FED_MDF-COMPS_0001623445": "” 已複製到粘貼板",
      "P_YS_FED_MDF-COMPS_0001623687": "全部顯示(",
      "P_YS_FED_MDF-COMPS_0001623448": "(移動端)",
      "P_YS_FED_MDF-COMPS_0001623447": "請確認包名是否存在~",
      "P_YS_FED_MDF-COMPS_0001623689": "你確定刪除頁面嗎?",
      "P_YS_SD_SDSD-FE_0001486298": "創建人",
      "P_YS_PF_GZTTMP_0000077237": "型號",
      "P_YS_FED_MDF-COMPS_0001623449": "隱私協議名稱",
      "P_YS_PF_GZTSYS_0000134736": "報賬應用",
      "P_YS_PF_PRINT_0000065776": "物料sku名稱",
      "P_YS_FED_MDF-COMPS_0001573092": "請選擇操作行",
      "P_YS_FED_MDF-COMPS_0001573093": "z0客戶3627661",
      "P_YS_FED_MDF-COMPS_0001573090": "單據編號",
      "P_YS_FED_MDF-COMPS_0001573091": "likun-表頭自定義項[文本]",
      "P_YS_FED_MDF-COMPS_0001573096": "likun-表體自定義項[文本]",
      "P_YS_PF_PC-UI_0001072138": "顯示項，不用輸入",
      "P_YS_FED_MDF-COMPS_0001573097": "必輸，請精確到sku",
      "P_YS_FED_MDF-COMPS_0001573094": "保質期",
      "P_YS_YYGJFW_YBUC_0001337239": "頁面編碼",
      "P_YS_FED_MDF-COMPS_0001573095": "wjl測試自定義項",
      "P_YS_FED_MDF-COMPS_0001573098": "wjl測試自定義項",
      "P_YS_FED_MDF-COMPS_0001573099": "件數",
      "P_YS_FED_UCFBASEDOC_0000024885": "員工問詢",
      "P_YS_FED_FW_0000022127": "俄語",
      "P_YS_FED_MDF-COMPS_0001623691": "擴展",
      "P_YS_FED_MDF-COMPS_0001623690": "啟動次數",
      "P_YS_FED_MDF-COMPS_0001623451": "主子表列表",
      "P_YS_FED_MDF-COMPS_0001623693": "請輸入包名",
      "P_YS_FED_MDF-COMPS_0001623450": "長度應小於30個字元",
      "P_YS_FED_MDF-COMPS_0001623692": "錯誤次數",
      "P_YS_FED_MDF-COMPS_0001623453": "未限制",
      "P_YS_FED_MDF-COMPS_0001623695": "系統插件",
      "P_YS_OA_app_xtyyjm_0000035815": "請輸入頁面名稱",
      "P_YS_FED_MDF-COMPS_0001623452": "擴展應用",
      "P_YS_FED_MDF-COMPS_0001623694": "該插件已存在相同版本",
      "P_YS_FED_MDF-COMPS_0001623455": "RPA官網",
      "P_YS_FED_MDF-COMPS_0001623697": "請上傳默認圖示",
      "P_YS_FED_MDF-COMPS_0001623454": "請輸入APP配置名稱",
      "P_YS_FED_MDF-COMPS_0001623696": "打包資訊不能為空！",
      "P_YS_FED_MDF-COMPS_0001623457": "應用名稱不能為空!",
      "P_YS_FED_MDF-COMPS_0001623699": "擴展原廠頁面",
      "P_YS_FED_MDF-COMPS_0001623456": "結構/首頁",
      "P_YS_FED_MDF-COMPS_0001623698": "通用名稱",
      "P_YS_FED_MDF-COMPS_0001623459": "推薦主題",
      "P_YS_FED_MDF-COMPS_0001623458": "調度",
      "P_YS_FED_MDF-COMPS_0001209115": "版本版次规则使用",
      "P_YS_FED_MDF-COMPS_0001209116": "版本版次规则",
      "P_YS_FED_MDF-COMPS_0001209114": "研發建模服務-前端",
      "P_YS_PF_PROCENTER_0000022686": "單據日期",
      "P_YS_FED_FW_0000022137": "項目名稱",
      "P_YS_FED_UCFBASEDOC_0000025724": "API授權",
      "P_YS_FED_UCFBASEDOC_0000024878": "會議管理",
      "P_YS_FED_UCFBASEDOC_0000024874": "角色名稱",
      "P_YS_FED_MDF-COMPS_0001623660": "友報賬",
      "P_YS_FED_MDF-COMPS_0001623420": "複製下載鏈接",
      "P_YS_FED_MDF-COMPS_0001623662": "主題&導航",
      "P_YS_FED_MDF-COMPS_0001623661": "支持在離線狀態下使用工作臺",
      "P_YS_FED_MDF-COMPS_0001623422": "正在開發中,請等待。。。。。",
      "P_YS_FED_MDF-COMPS_0001623664": "打包資訊",
      "P_YS_FED_MDF-COMPS_0001623421": "選擇客戶端",
      "P_YS_FED_MDF-COMPS_0001623663": "報賬應用",
      "P_YS_FED_MDF-COMPS_0001623424": "格式錯誤，正確格式為：AA.BB.CC，第一個A不能為0，且只能包含數字和點，點分割開的數字可以只有一個數字；例如：2.06.9；範圍：1.0.0~99.99.99",
      "P_YS_FED_MDF-COMPS_0001623666": "刪除移動端",
      "P_YS_FED_MDF-COMPS_0001623423": "我的反饋",
      "P_YS_FED_MDF-COMPS_0001623665": "工作臺配置",
      "P_YS_FED_MDF-COMPS_0001623426": "運行分析",
      "P_YS_FED_MDF-COMPS_0001623668": "錯誤時間",
      "P_YS_FED_MDF-COMPS_0001623425": "普通上架，上架該版本，不強制更新",
      "P_YS_FED_MDF-COMPS_0001623667": "請查看校驗項",
      "P_YS_FED_MDF-COMPS_0001623428": "操作系統",
      "P_YS_FED_MDF-COMPS_0001623427": "]將被刪除",
      "P_YS_FED_MDF-COMPS_0001623669": "掃碼安裝APP",
      "P_YS_FED_MDF-COMPS_0001623429": "默認開通數位化建模和應用構建，領域開通需要申請。",
      "P_YS_FED_MDF-COMPS_0001573070": "NN物料查詢1",
      "P_YS_FED_MDF-COMPS_0001573071": "請重新選擇，輸入數字大於可選最大值",
      "P_YS_PF_EVENTCENTER-MD_0001414933": "應用類別",
      "P_YS_FED_MDF-COMPS_0001573074": "專案編碼",
      "P_YS_SD_SDMA_0000125259": "輪播圖",
      "P_YS_FED_MDF-COMPS_0001573075": "數值型字段",
      "P_YS_FED_MDF-COMPS_0001573072": "數值型字段",
      "P_YS_FED_MDF-COMPS_0001573073": "請重新選擇，輸入數字小於可選最小值",
      "P_YS_FED_MDF-COMPS_0001573078": "物料sku編碼",
      "P_YS_FED_FW_0000021015": "日期",
      "P_YS_FED_MDF-COMPS_0001573079": "自定義專案測試202008060001",
      "P_YS_FED_MDF-COMPS_0001573076": "計量單位",
      "P_YS_FED_MDF-COMPS_0001573077": "固定資產檔案導出測試",
      "P_YS_FED_FW_0000022105": "請選擇行再進行上移操作！",
      "P_YS_PF_ECON-FE_0001152750": "編輯應用",
      "P_YS_PF_GZTSYS_0000134745": "人力應用",
      "P_YS_FED_MDF-COMPS_0001623671": "公共引擎",
      "P_YS_FED_MDF-COMPS_0001623670": "啟用沙箱",
      "P_YS_FED_MDF-COMPS_0001623431": "鏈接目標",
      "P_YS_FED_MDF-COMPS_0001623673": "圖片尺寸錯誤，請上傳750*244以內的圖片",
      "P_YS_PF_GZTSYS_0000134741": "企業文化",
      "P_YS_FED_MDF-COMPS_0001623430": "開發沙箱",
      "P_YS_FED_MDF-COMPS_0001623672": "部署狀態",
      "P_YS_FED_FW_0000022361": "格式化",
      "P_YS_FED_MDF-COMPS_0001623433": "最後打包時間",
      "P_YS_FED_MDF-COMPS_0001623675": "及其下麵的服務嗎?",
      "P_YS_FED_FW_0000021031": "日期時間",
      "P_YS_FED_FW_0000022362": "編輯區",
      "P_YS_FED_MDF-COMPS_0001623432": "插件配置",
      "P_YS_FED_MDF-COMPS_0001623674": "啟動輪播圖",
      "P_YS_FED_MDF-COMPS_0001623435": "你確定刪除應用麼",
      "P_YS_FED_MDF-COMPS_0001623677": "確定切換許可權？",
      "P_YS_FED_MDF-COMPS_0001623434": "資產管理",
      "P_YS_FED_MDF-COMPS_0001623676": "主表列表",
      "P_YS_FED_MDF-COMPS_0001623437": "更換證書可能導致應用安裝衝突或提交應用市場失敗，如果您不了解相關機制請謹慎選擇，確定要更換證書嗎？",
      "P_YS_FED_MDF-COMPS_0001623679": "移動端頁面名稱",
      "P_YS_FED_MDF-COMPS_0001623436": "許可詳情>",
      "P_YS_FED_MDF-COMPS_0001623678": "機器人可以在流程、頁面中, 也可以通過API或定時調度任務啟動運行,",
      "P_YS_FED_MDF-COMPS_0001623439": "說明：建議上傳700*390圖片",
      "P_YS_FED_MDF-COMPS_0001623438": "添加證書",
      "P_YS_FED_MDF-COMPS_0001573081": "業務員",
      "P_YS_SD_SDMA_0000125244": "其他設置",
      "P_YS_FED_MDF-COMPS_0001573082": "時間H",
      "P_YS_FED_UCFBASEDOC_0000025709": "項目",
      "P_YS_FED_MDF-COMPS_0001573080": "物料sku名稱",
      "P_YS_FED_MDF-COMPS_0001573085": "自定義檔案",
      "P_YS_FED_MDF-COMPS_0001573086": "批次號",
      "P_YS_FED_MDF-COMPS_0001573083": "跟蹤號123",
      "P_YS_PF_GZTORG_0000035173": "修改人",
      "P_YS_FED_MDF-COMPS_0001573084": "likun-表頭自定義項[文本]",
      "P_YS_YYGJFW_YBUC_0001315675": "網頁端",
      "P_YS_FED_FW_0000022114": "會計主體",
      "P_YS_FED_MDF-COMPS_0001573089": "門店",
      "P_YS_FED_MDF-COMPS_0001573087": "會計主體",
      "P_YS_FED_MDF-COMPS_0001573088": "NN表頭數值0630",
      "P_YS_OA_app_xtyyjm_0000035830": "申請",
      "P_YS_OA_XTLCZX_0000030451": "審批人",
      "P_YS_OA_XTLCZX_0000030692": "進行中",
      "P_YS_FED_MDF-COMPS_0001623640": "用於測試更改或新應用程式而不會損壞您的生產數據或配置。",
      "P_YS_FED_MDF-COMPS_0001623400": "導入機器人",
      "P_YS_FED_MDF-COMPS_0001623642": "新建APP",
      "P_YS_FED_MDF-COMPS_0001623641": "沙箱列表",
      "P_YS_FED_MDF-COMPS_0001623402": "張張張",
      "P_YS_FED_MDF-COMPS_0001623644": "沙箱內數據將被完全刪除且操作無法撤回。",
      "P_YS_FED_MDF-COMPS_0001623401": "鏈接地址:",
      "P_YS_FED_MDF-COMPS_0001623643": "累計設備數",
      "P_YS_FED_MDF-COMPS_0001623404": "提交中",
      "P_YS_FED_MDF-COMPS_0001623646": "沙箱歷史",
      "P_YS_FED_MDF-COMPS_0001623403": "添加PC端",
      "P_YS_FED_MDF-COMPS_0001623645": "工作電郵",
      "P_YS_PLM_BASE-UI_0001105578": "左樹右表",
      "P_YS_FED_IMP_IOT_0001069279": "友戶通",
      "P_YS_FED_MDF-COMPS_0001623406": "手機型號",
      "P_YS_FED_MDF-COMPS_0001623648": "上次",
      "P_YS_FED_MDF-COMPS_0001623405": "主題&導航配置預覽",
      "P_YS_FED_MDF-COMPS_0001623647": "範本示例",
      "P_YS_FED_MDF-COMPS_0001623408": "下載安裝包",
      "P_YS_FED_MDF-COMPS_0001623407": "最新版本：",
      "P_YS_FED_MDF-COMPS_0001623649": "人力應用",
      "P_YS_FED_MDF-COMPS_0001623409": "跳過廣告",
      "P_YS_SD_SDSD-FE_0001184978": "頁面信息",
      "P_YS_FED_FW_0000022167": "當前模板無數據",
      "P_YS_FED_FW_0000021093": "累計",
      "P_YS_FED_MDF-COMPS_0001623651": "獲取沙箱失敗",
      "P_YS_FED_FW_0000022188": "更新",
      "P_YS_FED_MDF-COMPS_0001623650": ") 的配置,是否確認修改該配置？",
      "P_YS_FED_MDF-COMPS_0001623411": "禁用長按",
      "P_YS_FED_MDF-COMPS_0001623653": "同時生成",
      "P_YS_FED_MDF-COMPS_0001623410": "許可到期日",
      "P_YS_FED_MDF-COMPS_0001623652": "建議1024*1024px",
      "P_YS_FED_MDF-COMPS_0001623413": "導出配置檔",
      "P_YS_FED_MDF-COMPS_0001623655": "標題位元組長度應小於8",
      "P_YS_FED_MDF-COMPS_0001623412": "請輸入檔案名稱,別名搜索",
      "P_YS_FED_MDF-COMPS_0001623654": "新建證書需跳轉到證書管理頁面，當前頁面數據會以草稿態保存到 App 列表中。",
      "P_YS_FED_MDF-COMPS_0001623415": "保存並關閉",
      "P_YS_FED_MDF-COMPS_0001623657": "上傳插件",
      "P_YS_FED_MDF-COMPS_0001623414": "示例頁面",
      "P_YS_FED_MDF-COMPS_0001623656": "圖示大小不能超過300kb",
      "P_YS_FED_MDF-COMPS_0001623417": "應用編碼不能為空!",
      "P_YS_FED_MDF-COMPS_0001623659": "運行分析詳情",
      "P_YS_FED_MDF-COMPS_0001623416": "應用隱藏後將在APP工作臺中無法查看",
      "P_YS_FED_MDF-COMPS_0001623658": "配置參數",
      "P_YS_FED_MDF-COMPS_0001623419": "台賬列表",
      "P_YS_FED_MDF-COMPS_0001623418": "強制上架",
      "P_YS_OA_app_xtyyjm_0000035850": "未發布",
      "P_YS_FED_UCFORG_0000025923": "啟用成功",
      "P_YS_OA_app_xtyyjm_0000035868": "頁面",
      "P_YS_FED_MDF-COMPS_0001064728": "【提示】若系統語言（<%=enterprise%>）內容未維護，系統將默認按當前語言（<%=current%>）內容賦值處理",
      "P_YS_PF_PRINT_0000056601": "擴展欄位",
      "P_YS_FED_MDF-COMPS_0001623620": "申請提交失敗",
      "P_YS_FED_MDF-COMPS_0001623622": "進入沙箱",
      "P_YS_OA_XTMENHUCMS_0000045092": "編輯導航",
      "P_YS_FED_MDF-COMPS_0001623621": "添加描述檔",
      "P_YS_FED_MDF-COMPS_0001623624": "證書檔不能為空！",
      "P_YS_FED_MDF-COMPS_0001623623": "允許跳過",
      "P_YS_FED_MDF-COMPS_0001623626": "圖示大小不能超過20kb",
      "P_YS_FED_MDF-COMPS_0001623625": "當前應用內的服務將全部啟用，請謹慎操作。",
      "P_YS_FED_MDF-COMPS_0001623628": "請重新設定版本號，並且高於",
      "P_YS_FED_MDF-COMPS_0001623627": "請選擇實體字段",
      "P_YS_FED_MDF-COMPS_0001623629": "歷史上架",
      "P_YS_SD_SCMSA-UI_0001345888": "占用數量",
      "P_YS_PF_PC-UI_0000160768": "調用方式",
      "P_YS_SD_SDSD-FE_0001491421": "我的團隊",
      "P_YS_SCM_USTOCK-UI_0001077879": "顯示項，不需要輸入",
      "P_YS_HR_HRRCPDF_0000073196": "創建時間:",
      "P_YS_OA_XTLCZX_0000030484": "請輸入內容",
      "P_YS_FED_FW_0000022166": "是否合計",
      "P_YS_FED_MDF-COMPS_0001623631": "T+服務",
      "P_YS_FED_MDF-COMPS_0001623630": "添加簽名檔",
      "P_YS_FED_MDF-COMPS_0001623633": "請輸入6位數密碼",
      "P_YS_YYGJFW_YBUC_0001336150": "鏈接方式",
      "P_YS_FED_MDF-COMPS_0001623632": "請選擇需要從源環境同步到當前開發沙箱的用戶(不含授權資訊)",
      "P_YS_FED_MDF-COMPS_0001623635": "檔UUID",
      "P_YS_FED_MDF-COMPS_0001623634": "描述檔類型",
      "P_YS_FED_MDF-COMPS_0001623637": "瞭解詳情, 請移步",
      "P_YS_FED_MDF-COMPS_0001623636": "請檢查輸入項！",
      "P_YS_PF_GZTSYS_0000096443": "多組織",
      "P_YS_FED_MDF-COMPS_0001623639": "上架設置",
      "P_YS_FI_GL_0000088698": "“",
      "P_YS_FED_MDF-COMPS_0001623638": "頁面編碼必須以業務對象code為首碼 (",
      "P_YS_SD_SDSD-FE_0001184980": "空白模板",
      "P_YS_OA_app_xtyyjm_0000035873": "已有表單",
      "P_YS_PF_PC-UI_0000159776": "上架狀態",
      "P_YS_PF_GZTTMP_0000079010": "證書文件",
      "P_YS_PF_PRINT_0000057998": "上遊單據號",
      "P_YS_OA_XTLCZX_0000030652": "創建時間",
      "P_YS_FED_IMP_IOT_0000129813": "設備數",
      "P_YS_SCM_STOCK_0000034501": "賬號",
      "P_YS_FED_MDF-COMPS_0001623600": "白名單控制（在APP工作臺僅顯示以下應用）",
      "P_YS_OA_XTDYH_0000168902": "地址格式不正確！",
      "P_YS_FED_MDF-COMPS_0001623602": "選中圖示",
      "P_YS_FED_MDF-COMPS_0001623601": "友戶通令牌",
      "P_YS_FED_MDF-COMPS_0001623604": "APP標識",
      "P_YS_PES_PES-FE_0001108224": "申請說明",
      "P_YS_FED_MDF-COMPS_0001623603": "開發ing",
      "P_YS_FED_MDF-COMPS_0001623606": "暫無可申請領域",
      "P_YS_FED_MDF-COMPS_0001623605": "修改導航標題會同時作用於移動端底部導航標題及該導航對應的頁面頭部標題（動態頁面外），導航圖示只能上傳不超過20kb，尺寸是122px X 122px 的png圖示。",
      "P_YS_IPAAS_UCG_0001111718": "已部署",
      "P_YS_FED_MDF-COMPS_0001623608": "首頁配置",
      "P_YS_FED_MDF-COMPS_0001623607": "最多只能添加24個常用應用",
      "P_YS_PF_YHTTNT-FE_0001045644": "匯入用戶成功",
      "P_YS_FED_MDF-COMPS_0001623609": "無限制（隱藏應用後在移動APP工作臺中將無法查看）",
      "P_YS_HR_HRJQF_0000054381": "外勤打卡",
      "P_YS_YYGJFW_YBUC_0001336548": "版本號不能為空！",
      "P_YS_OA_app_xtyyjm_0000035882": "新建頁面",
      "P_YS_FED_FW_0000021437": "結束日期",
      "P_YS_SD_SDSD-FE_0001491485": "規格",
      "P_YS_HR_HRJQF_0000054146": "入職",
      "P_YS_SD_SDSD-FE_0001491490": "單價",
      "P_YS_FED_MDF-COMPS_0001623611": "管理員（創建人）",
      "P_YS_FED_MDF-COMPS_0001623610": "應用所在位置: 工作臺-",
      "P_YS_PF_GZTPRE_0000071064": "實體",
      "P_YS_FED_MDF-COMPS_0001623613": "頁面名稱長度不超過30個字元",
      "P_YS_FED_EXAMP_0000020130": "數量",
      "P_YS_FED_MDF-COMPS_0001623612": "請重新設定版本號，並且不小於",
      "P_YS_PF_PRINT_0000056670": "操作人",
      "P_YS_FED_MDF-COMPS_0001623615": "移動端名稱",
      "P_YS_FED_MDF-COMPS_0001623614": "強制上架，上架該版本，強制更新",
      "P_YS_FED_MDF-COMPS_0001623617": "正在處理，暫不支持操作",
      "P_YS_FED_MDF-COMPS_0001623616": "請先新建分組",
      "P_YS_FED_MDF-COMPS_0001623619": "用戶自建系統",
      "P_YS_FED_MDF-COMPS_0001623618": "配置成功",
      "P_YS_FED_EXAMP_0000020127": "上傳失敗",
      "P_YS_PF_GZTSYS_0000013478": "調動",
      "P_YS_FED_FW_0000021683": "英文",
      "P_YS_OA_XTLCZX_0000030656": "覆蓋",
      "P_YS_FI_CM_0000029869": "請選擇日期",
      "P_YS_FED_FW_0000021660": "單價小數位",
      "P_YS_FED_EXAMP_0000020383": "請輸入",
      "P_YS_PF_YHTTNT-FE_0001045672": "邀請時間",
      "P_YS_PF_GZTSYS_0000012596": "請假",
      "P_YS_PF_PRINT_0000065599": "組號",
      "P_YS_FED_UCFBASEDOC_0000025558": "手機驗證碼",
      "P_YS_HR_HRXC_0000177247": "最新版本",
      "P_YS_FED_FW_0000021651": "已選",
      "P_YS_OA_XTLCZX_0000030666": "描述",
      "P_YS_PF_PRINT_0000056891": "失效日期",
      "P_YS_SD_SDMAUF_0000151755": "定金",
      "P_YS_PF_GZTBDM_0000036680": "當前租戶",
      "P_YS_PF_GZTSYS_0000012366": "掃碼入職",
      "P_YS_FED_FW_0000022511": "移除",
      "P_YS_PF_PRINT_0000065360": "項目編碼",
      "P_YS_HR_HRJQF_0000054355": "申請時間",
      "P_YS_OA_XTNEIBUQUN_0000046144": "取消管理員",
      "P_YS_OA_XTGONGGAO_0000045866": "請輸入分類名稱",
      "P_YS_PF_PRINT_0000057797": "錶頭自定義項4",
      "P_YS_FED_FW_0000021482": "基本信息",
      "P_YS_PF_PRINT_0000057795": "錶頭自定義項5",
      "P_YS_PF_PRINT_0000057794": "錶頭自定義項6",
      "P_YS_PF_PRINT_0000057793": "錶頭自定義項7",
      "P_YS_PF_PRINT_0000057792": "錶頭自定義項1",
      "P_YS_PF_PRINT_0000057791": "錶頭自定義項2",
      "P_YS_PF_PRINT_0000057790": "錶頭自定義項3",
      "P_YS_PF_PROCENTER_0001131746": "打印模板",
      "P_YS_YYGJFW_YBUC_0001315646": "成員管理",
      "P_YS_FED_UCFBASEDOC_0000025539": "權益",
      "P_YS_PF_PRINT_0000065174": "主計量",
      "P_YS_FED_FW_0000022323": "回車方向",
      "P_YS_PF_PROCENTER_0000027306": "編碼:",
      "P_YS_FED_UIMG_0001088007": "關聯tips",
      "P_YS_FED_EXAMP_0000020559": "版本",
      "P_YS_FED_FW_0000021239": "開始日期",
      "P_YS_FED_UCFBASEDOC_0000025533": "提交成功",
      "P_YS_FED_EXAMP_0000020319": "請輸入密碼",
      "P_YS_YYGJFW_YBUC_0001315649": "打包",
      "P_YS_FED_FW_0000022342": "校驗",
      "P_YS_PF_GZTSYS_0001085920": "集成配置",
      "P_YS_PF_GZTSYS_0000012188": "發布管理",
      "P_YS_IPAAS_UCG_0001072751": "開發應用",
      "P_YS_PF_PRINT_0000065185": "件數",
      "P_YS_FED_FW_0000021007": "數值",
      "P_YS_FED_EXAMP_0000020328": "狀態",
      "P_YS_FED_FW_0000021005": "時間",
      "P_YS_FED_IMP-DFM-FE_0000020891": "數據綁定",
      "P_YS_YYGJFW_YBUC_0001315625": "打包人",
      "P_YS_PF_GZTSYS_0000013006": "嘟嘟雲總機",
      "P_YS_SD_SDSD-FE_0001184912": "自定義鏈接",
      "P_YS_FED_FW_0000021452": "）",
      "P_YS_SD_UDHWS_0000070903": "備註信息",
      "P_YS_YYGJFW_YBUC_0001315628": "打包時間",
      "P_YS_PF_GZTSYS_0000108693": "預發環境",
      "P_YS_PF_GZTSYS_0000013023": "工作彙報",
      "P_YS_PF_GZTSYS_0000013020": "友報賬",
      "P_YS_SD_SDMA_0000123026": "停用失敗！",
      "P_YS_FED_FW_0000022310": "點擊上傳",
      "P_YS_FED_UCFBASEDOC_0000024895": "任務",
      "P_YS_OA_app_xtywlj_0000037593": "屬性",
      "P_YS_FED_FW_0000020987": "二",
      "P_YS_OA_XTMENHUCMS_0001072305": "大會地點",
      "P_YS_PF_GZTSYS_0000012657": "員工",
      "P_YS_OA_XTMENHUCMS_0001072304": "大會時間",
      "P_YS_OA_XTMENHUCMS_0001072301": "雲會務",
      "P_YS_OA_XTMENHUCMS_0001072302": "大會主題",
      "P_YS_FED_FW_0000020982": "成功",
      "P_YS_FED_FW_0000020985": "星期日",
      "P_YS_FED_FW_0000020999": "星期三",
      "P_YS_PF_GZTSYS_0000015934": "移動簽到",
      "P_YS_PF_GZTSYS_0000012423": "移動首頁排序",
      "P_YS_OA_XTMENHUCMS_0001072316": "英國倫敦",
      "P_YS_OA_XTMENHUCMS_0001072315": "EASL國際肝病大會",
      "P_YS_OA_XTMENHUCMS_0001072312": "yyyy年MM月dd日",
      "P_YS_FED_FW_0000020991": "五",
      "P_YS_PF_GZTSYS_0000015937": "移動門戶設置",
      "P_YS_OA_XTMENHU_0001025398": "建議調整圖片尺寸",
      "P_YS_FED_UCFBASEDOC_0000025041": "輸入名稱",
      "P_YS_PF_GZTSYS_0000012873": "簽到",
      "P_YS_OA_XTMENHU_0001025399": "添加啟動圖",
      "P_YS_FED_EXAMP_0000020538": "確 定",
      "P_YS_PF_PROCENTER_0000027553": "提示",
      "P_YS_MF_MR-UI_0001142058": "按組織",
      "P_YS_FED_FW_0000020970": "六",
      "P_YS_FED_UCFBASEDOC_0000025029": "下一步",
      "P_YS_FED_FW_0000020972": "星期四",
      "P_YS_OA_XTMENHUCMS_0000045309": "上傳圖片大小不能超過5MB",
      "P_YS_OA_XTMENHUCMS_0000045308": "營員簽到",
      "P_YS_HR_HRJQ_0000031659": "遲到",
      "P_YS_OA_XTMENHUCMS_1420844201648586752": "場景化APP可以多選左右拖動，上下排序",
      "P_YS_OA_XTMENHUCMS_0001368770": "屬性介面地址",
      "P_YS_OA_XTMENHUCMS_0000045306": "上傳圖片大小不能超過 1MB",
      "P_YS_OA_XTMENHUCMS_0000045305": "3.可以添加除本組織外額外可見的組織／成員",
      "P_YS_OA_XTMENHUCMS_0000045304": "雲審批公積金提取申請",
      "P_YS_OA_XTMENHUCMS_0000045303": "加載出錯，請稍後再試",
      "P_YS_OA_XTMENHUCMS_0000045302": "從已有門戶頁面創建",
      "P_YS_OA_XTMENHUCMS_0000045301": "刪除一級菜單，會同時刪除關聯的二級菜單，是否刪除",
      "P_YS_OA_XTMENHUCMS_0000045300": "請輸入名字",
      "P_YS_PF_GZTSYS_0000012471": "內部群文檔",
      "P_YS_FED_IMP_IOT0000005525": "屬性名稱",
      "P_YS_OA_XTMENHUCMS_0001473589": "消息分頁",
      "P_YS_OA_XTMENHUCMS_0001035139": "編輯啟動圖",
      "P_YS_OA_XTMENHUCMS_0001035138": "上傳失敗，請上傳 jpg、jpeg、png 類型圖片",
      "P_YS_OA_XTMENHUCMS_0001035137": "上傳圖片尺寸建議為1080*1920，大小不超過300KB",
      "P_YS_OA_XTMENHUCMS_0001035136": "默認啟動圖",
      "P_YS_OA_XTMENHUCMS_0001035135": "上傳失敗，請上傳300K以內的圖片",
      "P_YS_OA_XTRIZHI_0000036838": "可見範圍",
      "P_YS_OA_XTMENHUCMS_0001035134": "啟動圖設置",
      "P_YS_OA_XTMENHUCMS_0001035133": "生效時間",
      "P_YS_OA_XTMENHUCMS_0001035132": "失效時間",
      "P_YS_OA_XTMENHUCMS_0001035131": "生效時間不能晚於失效時間",
      "P_YS_OA_XTMENHUCMS_0001035130": "自定義啟動圖",
      "P_YS_OA_XTMENHUCMS_0001035371": "拖拽布局至此",
      "P_YS_OA_XTMENHUCMS_0001035370": "確認變更模板?",
      "P_YS_HR_HRSBF_0001287444": "編輯元件",
      "P_YS_OA_XTRIZHI_0000036822": "不超過20個字",
      "P_YS_OA_XTMENHUCMS_0001035146": "添加啟動圖",
      "P_YS_OA_XTMENHUCMS_0001035145": "建議調整圖片尺寸",
      "P_YS_OA_XTMENHUCMS_0001035144": "點擊修改",
      "P_YS_OA_XTMENHUCMS_0001035143": "設定在企業紀念日、重大通知時展示的啟動畫面",
      "P_YS_OA_XTMENHUCMS_0001035142": "請上傳啟動圖",
      "P_YS_OA_XTMENHUCMS_0001035141": "刪除後無法恢復，請確認",
      "P_YS_OA_XTMENHUCMS_0001035140": "溫馨提示：在未設置自定義啟動圖的時間段，移動端啟動圖將使用默認圖",
      "P_YS_SD_UDHWN_0000121778": "介面地址",
      "P_YS_HR_HRJQ_0000055421": "類型",
      "P_YS_OA_XTMENHUCMS_0000045101": "您添加的空間有些存在，已自動去重",
      "P_YS_OA_XTMENHUCMS_0000045343": "推薦移動端首頁",
      "P_YS_OA_XTMENHUCMS_0000045342": "上傳logo",
      "P_YS_OA_XTMENHUCMS_0000045341": "請輸入pcClient端正確主頁地址",
      "P_YS_OA_app_xtyyjm_0000036050": "應用LOGO",
      "P_YS_OA_XTMENHUCMS_0000045109": "自定義菜單地址",
      "P_YS_OA_XTMENHUCMS_0000045108": "下午4點開會",
      "P_YS_OA_XTMENHUCMS_0000045107": "標準(60px)",
      "P_YS_OA_XTMENHUCMS_0000045349": "企業自建",
      "P_YS_OA_XTMENHUCMS_0000045106": "兩行圖標時背景圖尺寸為 690×350 PX",
      "P_YS_OA_XTMENHUCMS_0000045348": "請輸入web端正確網址",
      "P_YS_OA_XTMENHUCMS_0000045105": "您確定要停用",
      "P_YS_OA_XTMENHUCMS_0000045347": "使用友空間原生導航",
      "P_YS_OA_XTMENHUCMS_0000045104": "還沒有審批需要處理哦",
      "P_YS_OA_XTMENHUCMS_0000045346": "不能超過200個字哦",
      "P_YS_OA_XTMENHUCMS_0000045103": "日均待出勤",
      "P_YS_OA_XTMENHUCMS_0000045345": "本地應用請輸入**://**規則的地址，其他類型地址請以http://或者https://開頭",
      "P_YS_OA_XTMENHUCMS_0000045102": "新建導航",
      "P_YS_FED_FW_0000020919": "一",
      "P_YS_OA_XTRIZHI_0000036806": "網路錯誤",
      "P_YS_OA_XTMENHUCMS_0000045332": "應用來源",
      "P_YS_OA_XTMENHUCMS_0000045331": "pcClient主頁",
      "P_YS_OA_XTMENHUCMS_0000045330": "IOS下載地址必填且不得超過500個",
      "P_YS_FED_FW_0000020917": "確認",
      "P_YS_OA_XTMENHUCMS_0000045339": "web端當前頁打開是http協議，跳轉時可能會造成頁面打不開，是否修改web端為https協議?",
      "P_YS_OA_XTMENHUCMS_0000045338": "web端應用排序",
      "P_YS_OA_XTMENHUCMS_0000045337": "不能超過12個字哦",
      "P_YS_SD_SDMBF_0000141321": "綁定成功",
      "P_YS_OA_XTMENHUCMS_0000045336": "請上傳logo",
      "P_YS_OA_XTMENHUCMS_0000045335": "可見範圍必填",
      "P_YS_OA_XTMENHUCMS_0000045334": "WEB端主頁",
      "P_YS_OA_XTMENHUCMS_0000045333": "刪除後，與該應用相關的數據將會消失，請確認您已做好數據遷移",
      "P_YS_PF_MANAGER-FE_1420836367628238848": "請先選擇角色化工作臺",
      "P_YS_OA_XTMENHUCMS_0000045321": "網站自適應屏幕寬度展示",
      "P_YS_OA_XTMENHUCMS_0001473597": "直接創建工作台",
      "P_YS_OA_XTMENHUCMS_0000045320": "同比增減",
      "P_YS_OA_XTMENHUCMS_0001473598": "通過服務創建工作台",
      "P_YS_OA_XTMENHUCMS_0001473595": "將某個現有服務創建為工作台",
      "P_YS_OA_XTMENHUCMS_0001473596": "按部門授權",
      "P_YS_OA_XTMENHUCMS_0001473599": "從已有工作台創建",
      "P_YS_OA_XTMENHUCMS_0000045329": "請選擇應用分類",
      "P_YS_OA_XTMENHUCMS_0000045328": "圖片發生錯誤",
      "P_YS_OA_XTMENHUCMS_0001473590": "請選擇已有工作台",
      "P_YS_OA_XTMENHUCMS_0000045327": "android下載地址必填且不得超過500個",
      "P_YS_OA_XTMENHUCMS_0000045326": "全部應用排序",
      "P_YS_OA_XTMENHUCMS_0000045325": "summer包id必填",
      "P_YS_OA_XTMENHUCMS_0001473593": "以現有工作台為模板創建工作台",
      "P_YS_OA_XTMENHUCMS_0000045324": "瀏覽器新頁打開應用",
      "P_YS_OA_XTMENHUCMS_0001473594": "設計人員分配",
      "P_YS_OA_XTMENHUCMS_0000045323": "圖標樣式修改",
      "P_YS_OA_XTMENHUCMS_0001473591": "按員工授權",
      "P_YS_OA_XTMENHUCMS_0000045322": "負責",
      "P_YS_SD_SDMBF_0000141358": "複選",
      "P_YS_OA_XTMENHUCMS_0001473592": "以最通常的方式創建全新工作台",
      "P_YS_HR_HRYGF_0000055799": "已處理",
      "P_YS_OA_XTMENHUCMS_0000045319": "明會議",
      "P_YS_OA_XTMENHUCMS_0000045310": "知會",
      "P_YS_OA_XTMENHUCMS_0000045318": "網絡請求慢",
      "P_YS_OA_XTMENHUCMS_0000045317": "線性導航",
      "P_YS_OA_XTMENHUCMS_0000045316": "圖文公告2(左右)",
      "P_YS_OA_XTMENHUCMS_0000045315": "文字選中顏色",
      "P_YS_OA_XTMENHUCMS_0000045314": "搜索中",
      "P_YS_OA_XTMENHUCMS_0000045313": "鏈接頁面不能為空",
      "P_YS_OA_XTMENHUCMS_0000045145": "保存成功!",
      "P_YS_OA_XTMENHUCMS_0001256783": "工作臺狀態",
      "P_YS_OA_XTTMCENTER_0000035124": "新增廣告圖",
      "P_YS_OA_XTMENHUCMS_0000045144": "幹部任免決定",
      "P_YS_OA_XTMENHUCMS_0001256784": "創建資訊",
      "P_YS_FED_FW_0000020946": "星期五",
      "P_YS_OA_XTMENHUCMS_0001256781": "工作臺名稱",
      "P_YS_OA_XTMENHUCMS_0000045142": "頭部設置",
      "P_YS_OA_XTMENHUCMS_0001256782": "更新資訊",
      "P_YS_OA_XTMENHUCMS_0000045141": "已恢復到最後一次修改",
      "P_YS_OA_XTMENHUCMS_0000045383": "安裝包本地路徑如: html/app_sign/sign.html",
      "P_YS_OA_XTMENHUCMS_0001256780": "工作臺圖示",
      "P_YS_OA_XTMENHUCMS_0000045381": "android跳轉參數不得超過200個",
      "P_YS_OA_XTMENHUCMS_0000045380": "web端左側排序",
      "P_YS_OA_XTMENHUCMS_0000045149": "請填寫應用名稱",
      "P_YS_OA_XTMENHUCMS_0001256787": "員工",
      "P_YS_OA_XTMENHUCMS_0000045148": "圖片尺寸 690×150 PX",
      "P_YS_OA_XTMENHUCMS_0001256788": "組織",
      "P_YS_OA_XTMENHUCMS_0000045147": "請選擇正確的文字顏色",
      "P_YS_OA_XTMENHUCMS_0001256785": "工作臺使用權限",
      "P_YS_OA_XTMENHUCMS_0000045146": "目標容器只能添加四個組件",
      "P_YS_OA_XTMENHUCMS_0001256786": "工作臺設計許可權",
      "P_YS_HR_HRJQ_0000031027": "不能為空",
      "P_YS_OA_XTLCZX_0000030184": "編輯成功",
      "P_YS_OA_XTMENHUCMS_0000045134": "請輸入20個字符以內",
      "P_YS_OA_XTMENHUCMS_0000045133": "本主題應用於",
      "P_YS_OA_XTMENHUCMS_0000045132": "提交人:",
      "P_YS_OA_XTMENHUCMS_0000045374": "請謹慎重置，重置後當前企業內已經接入的應用需要修改，是否重置?",
      "P_YS_OA_XTMENHUCMS_0000045131": "錯誤提示",
      "P_YS_OA_XTMENHUCMS_0000045373": "移動端首頁",
      "P_YS_OA_XTMENHUCMS_0000045130": "自定義門戶",
      "P_YS_OA_XTMENHUCMS_0000045372": "原生應用",
      "P_YS_OA_XTMENHUCMS_0000045371": "應用名稱重名",
      "P_YS_OA_XTMENHUCMS_0000045370": "僅支持上傳到iuap平台的業務安裝包",
      "P_YS_OA_XTMENHUCMS_0000045139": "確定刪除",
      "P_YS_OA_XTMENHUCMS_0000045138": "新頁打開",
      "P_YS_OA_XTMENHUCMS_0000045137": "操作失敗，請稍後再試!",
      "P_YS_OA_XTMENHUCMS_0000045379": "請輸入移動端正確網址",
      "P_YS_OA_XTMENHUCMS_0000045136": "寬高請傳",
      "P_YS_OA_XTMENHUCMS_0000045378": "IOS跳轉參數不得超過200個",
      "P_YS_OA_XTMENHUCMS_0000045135": "保存失敗稍後重試!",
      "P_YS_OA_XTMENHUCMS_0000045377": "應用描述",
      "P_YS_OA_XTLCZX_0000030198": "標題文字顏色",
      "P_YS_HR_HRJXF_0001118178": "確定要停用嗎？",
      "P_YS_OA_XTTMCENTER_0000035137": "廣告圖",
      "P_YS_OA_XTMENHUCMS_0000045123": "網站固定最大定1400像素寬度展示",
      "P_YS_OA_XTMENHUCMS_0000045365": "確定要停用嗎?",
      "P_YS_OA_XTTMCENTER_0000035145": "尺寸的圖片",
      "P_YS_OA_XTMENHUCMS_0000045122": "逗樂樂樂樂樂",
      "P_YS_OA_XTMENHUCMS_0000045364": "以http://或https://開頭",
      "P_YS_OA_XTMENHUCMS_0000045363": "pcClient端",
      "P_YS_OA_XTMENHUCMS_0000045120": "公共會議室使用管理規定",
      "P_YS_FED_FW_0000020926": "三",
      "P_YS_OA_XTMENHUCMS_0000045361": "發布客戶端必選",
      "P_YS_OA_XTMENHUCMS_0000045360": "應用名稱不能為空",
      "P_YS_OA_XTMENHUCMS_0000045129": "集財字",
      "P_YS_OA_XTMENHUCMS_0000045128": "此組件正在使用，確定刪除麼？",
      "P_YS_OA_XTMENHUCMS_0000045127": "確定要刪除”",
      "P_YS_OA_XTMENHUCMS_0000045369": "應用名稱未通過檢測",
      "P_YS_OA_XTMENHUCMS_0001538954": "注：該內部群最多還可導入",
      "P_YS_OA_XTMENHUCMS_0000045126": "請選擇門戶使用者",
      "P_YS_OA_XTMENHUCMS_0000045368": "後台",
      "P_YS_FED_FW_0000020920": "星期六",
      "P_YS_OA_XTMENHUCMS_0000045125": "新增磁貼拖拽至此處",
      "P_YS_OA_XTMENHUCMS_0000045367": "打開本地應用",
      "P_YS_OA_XTMENHUCMS_0000045124": "營員手冊",
      "P_YS_OA_XTMENHUCMS_0000045112": "您確定要刪除",
      "P_YS_OA_XTMENHUCMS_0000045354": "移動端或web端網址錯誤",
      "P_YS_OA_XTMENHUCMS_0000045596": "清空所有布局",
      "P_YS_OA_XTMENHUCMS_0001561834": "重複人數",
      "P_YS_OA_XTMENHUCMS_0000045111": "添加二級菜單",
      "P_YS_OA_XTMENHUCMS_0000045110": "圖文公告(左右)",
      "P_YS_PF_GZTSYS_0000011996": "內部群",
      "P_YS_FED_FW_0000020937": "星期二",
      "P_YS_OA_XTMENHUCMS_0000045350": "移動端所有分類排序",
      "P_YS_OA_XTMENHUCMS_0000045119": "刪除失敗，稍後重試",
      "P_YS_OA_XTMENHUCMS_0000045118": "啟用門戶",
      "P_YS_OA_XTMENHUCMS_0000045117": "標題區背景區",
      "P_YS_OA_XTMENHUCMS_0000045116": "?",
      "P_YS_OA_XTMENHUCMS_0000045358": "添加描述",
      "P_YS_OA_XTMENHUCMS_0000045115": "受控範圍必選",
      "P_YS_OA_XTMENHUCMS_0000045357": "不能超過500個字哦",
      "P_YS_OA_XTMENHUCMS_0000045599": "保存並且啟用成功，需要退出賬號，重新登錄門戶修改才生效",
      "P_YS_OA_XTMENHUCMS_0000045114": "門戶名稱不能超過20個字符",
      "P_YS_OA_XTMENHUCMS_0000045356": "pc客戶端內打開應用",
      "P_YS_OA_XTMENHUCMS_0000045598": "添加失敗~",
      "P_YS_OA_XTMENHUCMS_0000045113": "圖文公告(上下)",
      "P_YS_OA_XTMENHUCMS_0000045355": "summer包id",
      "P_YS_OA_XTMENHUCMS_0000045597": "請輸入布局配比和等於12的組合，請用 空格區隔每個模塊，例如 3 3 3 3",
      "P_YS_OA_XTMENHUCMS_0000045192": "關聯內容不能為空",
      "P_YS_OA_XTMENHUCMS_0000045191": "兼容挑戰任務測試2018_666666666",
      "P_YS_OA_XTMENHUCMS_0000045190": "自適應屏幕寬度",
      "P_YS_OA_XTMENHUCMS_0000045189": "底部設置",
      "P_YS_OA_XTMENHUCMS_0000045188": "門戶自適應樣式",
      "P_YS_OA_XTMENHUCMS_0000045187": "說明：修改導航標題會同時作用於移動端底部導航標題及該導航對應的頁面頭部標題（動態頁面除外）",
      "P_YS_OA_XTMENHUCMS_0000045186": "我的組件庫裡不能重名",
      "P_YS_OA_XTMENHUCMS_0000045185": "上傳圖片只能是JPG/PNG格式!",
      "P_YS_OA_XTMENHUCMS_0000045184": "審批第一季度交通費",
      "P_YS_OA_XTSPZX_0000034655": "排序成功",
      "P_YS_OA_XTMENHUCMS_0000045183": "門戶管理者",
      "P_YS_OA_XTMENHUCMS_0000045182": "參與",
      "P_YS_PF_MANAGER-FE_1420076596501413915": "待選場景化APP",
      "P_YS_FED_FW_0000022486": "顯示停用",
      "P_YS_FED_FW_0000022245": "樣式",
      "P_YS_OA_app_xtyyjm_0000035936": "打開方式",
      "P_YS_OA_XTMENHUCMS_0000045181": "顯示到導航",
      "P_YS_OA_XTMENHUCMS_0000045180": "選擇組織",
      "P_YS_OA_XTMENHUCMS_0000045178": "請輸入10個漢字以內",
      "P_YS_PF_GZTACT_0001061469": "身份類型",
      "P_YS_OA_XTMENHUCMS_0000045177": "組件添加失敗！請重試",
      "P_YS_OA_XTMENHUCMS_0000045176": "網絡正在請求，請稍後",
      "P_YS_OA_XTMENHUCMS_0000045175": "導航名稱",
      "P_YS_OA_XTMENHUCMS_0000045173": "成員機構發文",
      "P_YS_OA_XTMENHUCMS_0000045172": "平平",
      "P_YS_OA_XTMENHUCMS_0000045171": "最多只能添加24個應用",
      "P_YS_PF_PROCENTER_0000022566": "停用",
      "P_YS_PF_MANAGER-FE_1420076596501413921": "場景化APP綁定角色工作臺",
      "P_YS_OA_XTMENHUCMS_0000045179": "下午5點開會",
      "P_YS_OA_XTMENHUCMS_0000045170": "股幹字",
      "P_YS_OA_XTMENHUCMS_0001314260": "最多填寫三位整數(單位px)",
      "P_YS_OA_XTMENHUCMS_0000045167": "幹部任免建議書",
      "P_YS_OA_XTMENHUCMS_0001314253": "新增纯文字档案型別",
      "P_YS_OA_XTMENHUCMS_0000045166": "全局範圍不允許設置，選擇區不顯示全局範圍，請選擇下級組織",
      "P_YS_OA_XTMENHUCMS_0001314252": "工作台使用者員工",
      "P_YS_OA_XTMENHUCMS_0000045165": "修改二級菜單",
      "P_YS_OA_XTMENHUCMS_0001314255": "確定不允許員工設置自己的預設工作台？",
      "P_YS_OA_XTMENHUCMS_0000045164": "審批來源:",
      "P_YS_OA_XTMENHUCMS_0001314254": "停用工作台",
      "P_YS_OA_XTMENHUCMS_0000045163": "門戶配置設計器",
      "P_YS_OA_XTMENHUCMS_0001314257": "主題刪除成功",
      "P_YS_OA_XTMENHUCMS_0000045162": "保存自定義組件",
      "P_YS_OA_XTMENHUCMS_0001314256": "請輸入主題名稱6個字符以內",
      "P_YS_OA_XTMENHUCMS_0000045161": "點擊查看組件開發文檔",
      "P_YS_OA_XTMENHUCMS_0001314259": "確認刪除主題?",
      "P_YS_OA_XTMENHUCMS_0000045160": "您添加的部門有些存在，已自動去重",
      "P_YS_OA_XTMENHUCMS_0001314258": "請選擇預設主題色",
      "P_YS_FED_FW_0000022223": "使用",
      "P_YS_FED_UCFBASEDOC_0000024984": "提交",
      "P_YS_OA_XTMENHUCMS_0000045169": "產業基地建設與資產經營管理中心",
      "P_YS_OA_XTLCZX_0000030167": "建議尺寸",
      "P_YS_PF_GZTLOG_0000025973": "開啟",
      "P_YS_OA_XTMENHUCMS_0000045156": "沒有發現相關公文喲",
      "P_YS_OA_XTTMCENTER_0000035113": "應用分類",
      "P_YS_PF_MANAGER-FE_1420076596501413903": "請輸入角色編碼/名稱",
      "P_YS_OA_XTMENHUCMS_0000045155": "新聞",
      "P_YS_OA_XTMENHUCMS_0001258356": "角色",
      "P_YS_OA_XTMENHUCMS_0000045154": "自定義卡片模版",
      "P_YS_OA_XTMENHUCMS_0001258355": "工作臺描述",
      "P_YS_OA_XTMENHUCMS_0000045153": "請輸入按鈕名字",
      "P_YS_OA_XTMENHUCMS_0001258354": "工作臺資訊",
      "P_YS_PF_MANAGER-FE_1420076596501413900": "請選擇要綁定的角色工作臺",
      "P_YS_OA_XTMENHUCMS_0000045152": "請選擇正確的圖片",
      "P_YS_OA_XTMENHUCMS_0000045151": "暫無權限訪問",
      "P_YS_PF_MANAGER-FE_1420076596501413906": "場景化APP",
      "P_YS_OA_XTMENHUCMS_0000045150": "圖片格式建議PNG(尺寸為120px*120px)",
      "P_YS_OA_XTMENHUCMS_0000045159": "還未收到公告消息哦!",
      "P_YS_OA_XTWENKU_0000035710": "關注",
      "P_YS_OA_XTMENHUCMS_0000045157": "獲取修改信息失敗",
      "P_YS_OA_XTMENHUCMS_0001548827": "點擊下載導入異常記錄",
      "P_YS_SD_UDHAR_0000051719": "副本",
      "P_YS_OA_XTMENHUCMS_0001503919": "加入二級導航",
      "P_YS_PF_PROCENTER_0000023464": "啟用",
      "P_YS_OA_app_xtyyjm_0000035733": "保存並發布",
      "P_YS_OA_app_xtyyjm_0000035745": "創建",
      "P_YS_PF_GZTEVENT_0000025946": "獲取用戶信息失敗",
      "P_YS_OA_app_xtyyjm_0000035758": "當前頁打開",
      "P_YS_OA_XTMENHUCMS_0001503922": "修改二級導航",
      "P_YS_OA_XTMENHUCMS_0001503923": "修改一級導航",
      "P_YS_OA_XTMENHUCMS_0001503924": "設置為虛擬分組後，該導航不需要關聯任何功能，只用做虛擬分組或分類展示",
      "P_YS_OA_XTMENHUCMS_0001503920": "虛擬分組",
      "P_YS_OA_XTMENHUCMS_0001503921": "加入一級導航",
      "P_YS_OA_app_xtywlj_0000037823": "流程節點",
      "P_YS_OA_XTMENHUCMS_0000045199": "發送郵件服務器",
      "P_YS_OA_XTMENHUCMS_0000045198": "Banner屬性",
      "P_YS_OA_XTMENHUCMS_0000045197": "下午6點開會",
      "P_YS_OA_XTMENHUCMS_0000045196": "待辦組件",
      "P_YS_OA_XTMENHUCMS_0000045195": "操作頻繁，稍後再試",
      "P_YS_FI_FA_0000033363": "分類名稱",
      "P_YS_OA_XTMENHUCMS_0000045194": "工作手機權限",
      "P_YS_OA_XTMENHUCMS_0000045193": "刪除主題",
      "P_YS_OA_XTMENHUCMS_0000044939": "夏令營投票",
      "P_YS_OA_XTMENHUCMS_0000044937": "集團審計監察部",
      "P_YS_OA_XTMENHUCMS_0000044936": "請輸入高",
      "P_YS_SCM_USTOCK-UI_0000177858": "菜單名稱",
      "P_YS_FED_UIMG_0000025856": "重命名",
      "P_YS_PF_GZTSYS_0000012499": "組織",
      "P_YS_OA_XTMENHUCMS_0000044933": "廈門旅遊攻略",
      "P_YS_OA_XTMENHUCMS_0000044931": "導航名稱不能為空",
      "P_YS_OA_XTMENHUCMS_0000044930": "點擊查看開發文檔",
      "P_YS_OA_XTLCZX_0000030769": "來自",
      "P_YS_OA_XTMENHUCMS_0000044928": "接收郵件服務器",
      "P_YS_OA_XTMENHUCMS_0000044927": "提交時間:",
      "P_YS_OA_XTMENHUCMS_0000044926": "提交者",
      "P_YS_OA_XTMENHUCMS_0000044925": "恢復默認值",
      "P_YS_OA_XTMENHUCMS_0000044924": "集經管字",
      "P_YS_OA_XTMENHUCMS_0000044923": "您添加的人員有些存在，已自動去重",
      "P_YS_OA_XTMENHUCMS_0000044922": "沒有與你相關的任務喲!",
      "P_YS_OA_XTMENHUCMS_0000044921": "導航樣式",
      "P_YS_OA_XTMENHUCMS_0000044920": "標題公告",
      "P_YS_HR_HRXCF_0001116208": "按用戶授權",
      "P_YS_OA_XTMENHUCMS_0000044919": "內容區背景區",
      "P_YS_OA_XTMENHUCMS_0000044918": "Bann屬性",
      "P_YS_OA_XTMENHUCMS_0000044917": "複製門戶",
      "P_YS_OA_XTMENHUCMS_0000044916": "輸入關鍵字搜索",
      "P_YS_OA_XTMENHUCMS_0000044915": "北土城小仙女",
      "P_YS_OA_XTMENHUCMS_0000044914": "恢復默認模板",
      "P_YS_PF_GZTVPA_0001137668": "授權設置",
      "P_YS_OA_XTMENHUCMS_0000044913": "內容區頭部設置",
      "P_YS_OA_XTMENHUCMS_0000044912": "漸變方向",
      "P_YS_SCM_USTOCK_0001131722": "新增",
      "P_YS_OA_XTMENHUCMS_0000044911": "沒有搜索到“",
      "P_YS_OA_XTMENHUCMS_0000044910": "註：該服務屬於增值服務，請聯繫客服進行開通。",
      "P_YS_SCM_USTOCK_0001131725": "保存",
      "P_YS_OA_XTMENHUCMS_0000044908": "啟用提示",
      "P_YS_OA_XTMENHUCMS_0000044907": "使用SSL連接",
      "P_YS_OA_XTMENHUCMS_0000044906": "不支持您所上傳的圖片格式",
      "P_YS_OA_XTMENHUCMS_0000044905": "短廣告位",
      "P_YS_OA_XTMENHUCMS_0000044904": "選擇默認主題色",
      "P_YS_OA_XTMENHUCMS_0000044903": "保存自定義底部成功",
      "P_YS_PF_MANAGER-FE_0001087862": "分組管理",
      "P_YS_FED_EXAMP_0000020272": "預定",
      "P_YS_OA_XTMENHUCMS_0000044909": "標題文字選中顏色",
      "P_YS_OA_XTMENHUCMS_0001368767": "發布工作台",
      "P_YS_OA_XTMENHUCMS_0001387022": "商家身份型別",
      "P_YS_OA_XTMENHUCMS_0001368768": "是否同步logo",
      "P_YS_OA_XTMENHUCMS_0001368765": "是否同步名稱",
      "P_YS_OA_XTMENHUCMS_0001387024": "初始-安全保密員",
      "P_YS_OA_XTMENHUCMS_0001368766": "您確定要發布",
      "P_YS_OA_XTMENHUCMS_0001387023": "初始-系統管理員",
      "P_YS_SD_UDHWS_0000117730": "請輸入分組名稱",
      "P_YS_OA_XTMENHU_0000046633": "搜索應用的名稱",
      "P_YS_OA_XTMENHUCMS_0001368769": "是否同步角色",
      "P_YS_OA_XTMENHUCMS_0001368763": "是否同步身份型別",
      "P_YS_OA_XTMENHUCMS_0001387026": "初始-安全審計員",
      "P_YS_OA_XTMENHUCMS_0001368764": "是否同步模板",
      "P_YS_OA_XTMENHUCMS_0001387025": "安全審計員",
      "P_YS_OA_XTMENHUCMS_0001387028": "安全保密員",
      "P_YS_OA_XTMENHUCMS_0001387027": "三員身份型別",
      "P_YS_OA_XTMENHUCMS_0000044979": "關鍵詞搜索",
      "P_YS_OA_XTMENHUCMS_0000044978": "還可以看",
      "P_YS_FI_TP_0000071994": "是否多選",
      "P_YS_OA_XTMENHUCMS_0000044977": "查看權限",
      "P_YS_OA_XTMENHUCMS_0000044976": "上午兩點開會",
      "P_YS_OA_XTMENHUCMS_0000044975": "門戶名稱",
      "P_YS_OA_XTMENHUCMS_0001623249": "工作臺名稱重複",
      "P_YS_OA_XTMENHUCMS_0000044974": "關聯系統",
      "P_YS_OA_XTMENHUCMS_0000044973": "項目組件測試結果反饋",
      "P_YS_OA_XTMENHUCMS_0000044971": "上傳圖片大小不能超過20kb!",
      "P_YS_OA_XTMENHUCMS_0000044970": "關聯門戶",
      "P_YS_OA_XTMENHUCMS_0000044969": "您確定要啟用",
      "P_YS_OA_XTMENHUCMS_0000044968": "您確定要繼續創建嗎？",
      "P_YS_OA_XTMENHUCMS_0000044967": "受控範圍",
      "P_YS_OA_XTTXL_0000046015": "新建分組",
      "P_YS_OA_XTMENHUCMS_0000044966": "logo顯示方式",
      "P_YS_OA_XTMENHUCMS_0000044965": "二級導航名稱",
      "P_YS_OA_XTMENHUCMS_0000044964": "磁貼",
      "P_YS_OA_XTMENHUCMS_0000044963": "僅顯示啟用",
      "P_YS_OA_XTMENHUCMS_0000044962": "請等待圖片上傳完畢",
      "P_YS_OA_XTMENHUCMS_0000044961": "您的修改未保存,確定要離開嗎？",
      "P_YS_OA_XTMENHUCMS_0000044960": "部門統計-日",
      "P_YS_FED_EXAMP_0000020208": "圖片",
      "P_YS_OA_XTMENHUCMS_0000044959": "沒有發現相關日程喲!",
      "P_YS_OA_XTMENHUCMS_0000044958": "刪除提醒",
      "P_YS_OA_XTMENHUCMS_0000044957": "對不起，您沒有設計門戶的權限",
      "P_YS_OA_XTMENHUCMS_0000044956": "設置為虛菜單後，該菜單不需要關聯任何功能，只用做虛擬分組或分類展示",
      "P_YS_PF_GZTSYS_0000012039": "友情鏈接",
      "P_YS_OA_XTMENHUCMS_0000044955": "應用名稱文字顏色設置",
      "P_YS_OA_XTMENHUCMS_0000044954": "用友知多少",
      "P_YS_OA_XTMENHUCMS_0000044953": "還未設置應用喲!",
      "P_YS_OA_XTMENHUCMS_0000044952": "佛山",
      "P_YS_OA_XTMENHUCMS_0000044951": "夏5.8彈幕",
      "P_YS_FED_FW_0000021338": "浙江",
      "P_YS_OA_XTMENHUCMS_0000044950": "最多填寫三位填寫整數(單位px)",
      "P_YS_OA_XTMENHUCMS_0000044949": "圖片固定大小不複製和拉伸!",
      "P_YS_OA_XTMENHUCMS_0000044948": "新成員",
      "P_YS_OA_XTMENHUCMS_0000044947": "請選擇鏈接頁面",
      "P_YS_OA_XTMENHUCMS_0000044946": "門戶名稱不能為空",
      "P_YS_OA_XTMENHUCMS_0000044945": "沒有發現相關內容喲",
      "P_YS_OA_XTMENHUCMS_1420420649154248704": "getAppmenus介面報錯",
      "P_YS_FED_UIMG_0000025842": "所屬組織",
      "P_YS_OA_XTMENHUCMS_0000044944": "常用應用已達12個上限",
      "P_YS_OA_XTMENHUCMS_0000044943": "集團發文",
      "P_YS_OA_XTMENHUCMS_0000044942": "居中顯示",
      "P_YS_OA_XTMENHUCMS_0000044941": "沒有發現相關統計數據喲!",
      "P_YS_OA_XTMENHUCMS_0000044940": "排行榜",
      "P_YS_OA_XTRIZHI_0000036782": "不超過10個字",
      "P_YS_OA_XTMENHUCMS_1420420649154248707": "associateAppMenus介面報錯",
      "P_YS_PF_MES-FE_0001269116": "一鍵全部已讀",
      "P_DIWORK_OA_XTMENHUCMS_0000065678": "門戶使用者部門",
      "P_YS_OA_XTMENHUCMS_1420430312830664704": "可以多選左右拖動、上下排序",
      "P_YS_FED_EXAMP_0000020639": "取 消",
      "P_YS_PF_AUTHF_0000096121": "身份類型",
      "P_YS_PF_GZTSYS_0001236869": "組件設置-web廣告圖",
      "OA_web_ad_0000000102": "生效時間",
      "P_YS_PF_GZTSYS_0000012792": "待我審批",
      "P_YS_PF_GZTSYS_0000012303": "企業文檔",
      "P_YS_OA_XTMENHUCMS_0000045615": "請輸入保存模板名稱",
      "P_YS_OA_XTMENHUCMS_0000045614": "總和應等於於12,數字必須是整數",
      "P_YS_OA_XTMENHUCMS_0000045613": "無模板數據，請從左側拖拽添加行布局",
      "P_YS_OA_XTMENHUCMS_0000045612": "請輸入正整數",
      "P_YS_FED_EXAMP_0000020405": "生日",
      "P_YS_OA_XTMENHUCMS_0000045611": "返回提示!",
      "P_YS_OA_XTMENHUCMS_0000045610": "輸入比例不對~",
      "P_YS_OA_XTMENHUCMS_0001537390": "導出",
      "P_YS_OA_XTMENHUCMS_0001537391": "編輯",
      "P_YS_OA_XTRIZHI_0000036750": "控件",
      "P_YS_OA_XTMENHUCMS_0000045609": "1:1:1布局",
      "P_YS_FED_UCFBASEDOC_0000025163": "預置",
      "P_YS_OA_XTMENHUCMS_0000045608": "變更模版布局",
      "P_YS_OA_XTMENHUCMS_0001537394": "您確定要移除",
      "P_YS_OA_XTMENHUCMS_0000045607": "確定要清空當前布局嗎?",
      "P_YS_OA_XTMENHUCMS_0000045606": "添加行布局",
      "P_YS_OA_XTMENHUCMS_0000045605": "2:1布局",
      "P_YS_SD_UDHWN_0000177855": "嗎？",
      "P_YS_OA_XTMENHUCMS_0001319032": "廣告更新失敗",
      "P_YS_OA_XTMENHUCMS_0001319031": "確定刪除廣告位嗎?",
      "P_YS_OA_XTMENHUCMS_0001319030": "廣告更新成功",
      "P_YS_OA_XTMENHUCMS_0000044991": "更多>顏色",
      "P_YS_OA_XTMENHUCMS_0001319029": "確定刪除廣告圖嗎?",
      "P_YS_OA_XTMENHUCMS_0000044990": "獲取組件列表失敗",
      "P_YS_OA_XTMENHUCMS_0001319028": "廣告新增成功",
      "P_YS_PF_AUTHF_0000096106": "請選擇身份類型",
      "P_YS_OA_XTMENHUCMS_0001319027": "廣告新增失敗",
      "P_YS_OA_XTMENHUCMS_0000044999": "模版名稱重複",
      "P_YS_OA_XTMENHUCMS_0000045604": "個人門戶設置成功!",
      "P_YS_OA_XTMENHUCMS_0000044998": "關聯應用",
      "P_YS_OA_XTMENHUCMS_0000045603": "請輸入自定義布局數字",
      "P_YS_OA_XTMENHUCMS_0000044997": "確定要啟用嗎？啟用後重新登錄生效",
      "P_YS_OA_XTMENHUCMS_0000045602": "另存為布局模板",
      "P_YS_OA_XTMENHUCMS_0001537389": "導入",
      "P_YS_OA_XTMENHUCMS_0000044996": "養老金事業部(2016)",
      "P_YS_OA_XTMENHUCMS_0000045601": "自定義布局添加",
      "P_YS_OA_XTMENHUCMS_0000044995": "虛菜單",
      "P_YS_OA_XTMENHUCMS_0000045600": "確定要刪除這個模板嗎?",
      "P_YS_OA_XTMENHUCMS_0000044994": "常用應用已達24個上限",
      "P_YS_OA_XTMENHUCMS_0000044993": "設置模板失敗！請稍後再試",
      "P_YS_OA_XTMENHUCMS_0000044992": "個性控件",
      "P_YS_HR_HRJQ_0000031763": "早退",
      "P_YS_OA_XTMENHUCMS_0000044989": "停用門戶",
      "P_YS_OA_XTMENHUCMS_0000044980": "元素庫",
      "P_YS_FED_IMP_IOT0000005427": "處理狀態",
      "P_YS_OA_XTMENHUCMS_0000044988": "吳德倫",
      "P_YS_OA_XTMENHUCMS_0000044987": "返回提示",
      "P_YS_OA_XTMENHUCMS_0000044986": "請求中請稍後重試!",
      "P_YS_PF_MES-FE_0001269119": "來源：",
      "P_YS_OA_XTMENHUCMS_0000044984": "保存到我的組件",
      "P_YS_OA_XTMENHUCMS_0000044983": "建議圖標尺寸120*120px",
      "P_YS_OA_XTMENHUCMS_0000044982": "組織權限",
      "P_YS_OA_XTMENHUCMS_0000044981": "系統主題",
      "P_YS_OA_XTMENHUCMS_0001073711": "在線人數：",
      "P_YS_OA_XTMENHUCMS_0001073710": "用友網絡科技股份有限公司 版權所有",
      "P_YS_OA_XTTMCENTER_0000035084": "已下架",
      "P_YS_HR_HRJQ_0000054657": "所屬組織",
      "P_YS_SD_SDMAUF_0000152050": "添加圖標",
      "P_YS_PF_GZTSYS_0000015860": "發文",
      "P_YS_PF_GZTSYS_0000012104": "啟動圖設置",
      "P_YS_OA_XTTMCENTER_0000035096": "請填寫標題",
      "P_YS_OA_XTTMCENTER_0000035095": "已上架",
      "P_YS_OA_XTMENHUCMS_0001537350": "請輸入內容",
      "P_YS_OA_XTMENHUCMS_0001537351": "刪除",
      "P_YS_OA_XTMENHU_0000046561": "組件類型",
      "P_YS_FED_EXAMP_0000019871": "刪除",
      "P_YS_OA_XTWENKU_0000035581": "個檔案",
      "P_YS_OA_XTMENHUCMS_0001537343": "取消",
      "P_YS_OA_XTMENHUCMS_0001537344": "群簡介",
      "P_YS_OA_XTMENHUCMS_0001537345": "請輸入",
      "P_YS_OA_XTMENHUCMS_0001537346": "群名稱不能為空",
      "P_YS_FED_EXAMP_0000020614": "刪除成功",
      "P_YS_OA_XTMENHUCMS_0001537347": "修改成功",
      "P_YS_HR_HRXCF_0001116185": "按角色授權",
      "P_YS_OA_XTHUATI_0000046180": "單選",
      "P_YS_OA_XTMENHUCMS_0001537331": "部門/組織",
      "P_YS_OA_XTMENHUCMS_0001540743": "確定要批量刪除${num}個嗎？",
      "P_YS_OA_XTMENHUCMS_0000045222": "應用集合",
      "P_YS_OA_XTMENHUCMS_0000045221": "內容區設置幫助",
      "P_YS_PF_GZTSYS_0001010844": "系統管理員",
      "P_YS_OA_XTMENHUCMS_0000045220": "官方應用導航模版",
      "P_YS_OA_XTMENHUCMS_0000045229": "請輸入自定義底部鏈接",
      "P_YS_OA_XTMENHUCMS_0000045228": "2018年用友集團內部控制體系建設工作組織與職責",
      "P_YS_OA_XTMENHUCMS_0001537323": "工作郵箱",
      "P_YS_OA_XTMENHUCMS_0000045227": "新雲審批",
      "P_YS_OA_XTMENHUCMS_0000045226": "Tip:大小不超過20kb，尺寸122px*122px",
      "P_YS_OA_XTMENHUCMS_0001256468": "自定義底部高度",
      "P_YS_OA_XTMENHUCMS_0001537325": "添加成功",
      "P_YS_OA_XTMENHUCMS_0000045225": "導航設置幫助",
      "P_YS_OA_XTMENHUCMS_0000045224": "珍珠白",
      "P_YS_OA_XTMENHUCMS_0000045223": "請填寫新建自定義應用名稱",
      "P_YS_OA_XTMENHUCMS_0001537328": "名稱",
      "P_YS_OA_XTMENHUCMS_0001537340": "新成員加入需要管理員審核",
      "P_YS_OA_XTMENHUCMS_0001537342": "保存",
      "P_YS_PF_MANAGER-FE_0001470878": "關聯服務",
      "P_YS_OA_XTMENHUCMS_0000045211": "僅支持JPG/PNG格式圖片，文件小於5M",
      "P_YS_OA_XTMENHUCMS_0001540739": "確定要批量刪除",
      "P_YS_OA_XTMENHUCMS_0000045210": "添加分類",
      "P_YS_OA_XTMENHUCMS_0001314200": "工作台LOGO",
      "P_YS_OA_XTMENHUCMS_0001256471": "設為默認後，所有員工的默認工作臺將變為此工作臺，建議提前通知所有員工！",
      "P_YS_OA_XTMENHUCMS_0001314202": "請填寫屬性名稱",
      "P_YS_OA_XTMENHUCMS_0001314201": "廣告位刪除成功",
      "P_YS_SD_UDHWN_0000121432": "請輸入主題名稱",
      "P_YS_OA_XTMENHUCMS_0001314204": "已讀通知",
      "P_YS_OA_XTMENHUCMS_0001314203": "平台服務程式碼",
      "P_YS_OA_XTWENKU_0000035576": "服務器忙",
      "P_YS_OA_XTMENHUCMS_0000045219": "點擊色塊編輯",
      "P_YS_OA_XTMENHUCMS_0001314206": "未讀通知",
      "P_YS_OA_XTMENHUCMS_0000045218": "從上到下",
      "P_YS_OA_XTMENHUCMS_0001314205": "js大小不超過3M",
      "P_YS_OA_XTMENHUCMS_0001537333": "上傳成功",
      "P_YS_OA_XTMENHUCMS_0000045217": "請輸入應用集合名稱",
      "P_YS_OA_XTMENHUCMS_0001314208": "元件圖片",
      "P_YS_OA_XTMENHUCMS_0001537334": "點擊上傳",
      "P_YS_OA_XTMENHUCMS_0000045216": "用友汽車公司雲產品備案清單",
      "P_YS_OA_XTMENHUCMS_0001314207": "新增樹結構",
      "P_YS_OA_XTMENHUCMS_0001537335": "僅支持JPG格式圖片，檔小於5M",
      "P_YS_OA_XTMENHUCMS_0000045215": "嗎?啟用後重新登錄生效!",
      "P_YS_OA_XTMENHUCMS_0001537336": "編輯內部群",
      "P_YS_OA_XTMENHUCMS_0000045214": "日誌2.0",
      "P_YS_OA_XTMENHUCMS_0001314209": "廣告位資源可在“元件設置-web廣告圖”中設置",
      "P_YS_OA_XTMENHUCMS_0001537337": "群頭像",
      "P_YS_OA_XTMENHUCMS_0000045213": "袁三六",
      "P_YS_OA_XTMENHUCMS_0000045212": "系統控件",
      "P_YS_OA_XTMENHUCMS_0001537339": "管理員",
      "P_YS_OA_XTMENHUCMS_1417407282912165888": "是否授權給全員",
      "P_YS_OA_XTMENHUCMS_0000045209": "刪除失敗請稍後重試!",
      "P_YS_OA_XTMENHUCMS_0000045200": "名稱不能為空!",
      "P_YS_OA_XTMENHUCMS_0001537309": "內部群名稱",
      "P_YS_OA_XTMENHUCMS_0000045208": "參與人數",
      "P_YS_OA_XTMENHUCMS_0000045207": "導航塊顏色",
      "P_YS_OA_XTMENHUCMS_0000045205": "啟用自定義底部成功",
      "P_YS_OA_XTMENHUCMS_0000045204": "放大(80px)",
      "P_YS_OA_XTMENHUCMS_0000045203": "請至少選擇其中一個使用",
      "P_YS_OA_XTMENHUCMS_0000045202": "啟用橫向導航",
      "P_YS_OA_XTMENHUCMS_0000045201": "官方自有主頁",
      "P_YS_OA_XTMENHUCMS_1422527639852154883": "暂无场景化APP",
      "P_YS_OA_XTMENHUCMS_1422527639852154880": "请先选择场景化APP",
      "P_YS_OA_XTMENHUCMS_0001537320": "工作電話",
      "P_YS_OA_XTMENHUCMS_0001537318": "刪除成功",
      "P_YS_PF_METADATA_0001038634": "版本版次規則使用",
      "P_YS_OA_XTMENHUCMS_0001537312": "內部群ID",
      "P_YS_OA_XTMENHUCMS_0001073709": " 京ICP備15057199號",
      "P_YS_OA_XTMENHUCMS_0001537314": "群主",
      "P_YS_OA_XTMENHUCMS_0001073708": "京公網安備 11010802020548號",
      "P_YS_OA_XTMENHUCMS_1422527639852154886": "已关联场景化APP",
      "P_YS_OA_XTLCZX_0000030299": "添加成功",
      "P_YS_PF_GZTTMP_0000078402": "所屬分組",
      "P_YS_OA_XTMENHUCMS_0001314240": "檔案大小最大為",
      "P_YS_OA_XTMENHUCMS_0000045024": "不能超過4個字哦",
      "P_YS_OA_XTMENHUCMS_0000045266": "建議0-10px",
      "P_YS_OA_XTMENHUCMS_0001314231": "元件回調函數名",
      "P_YS_OA_XTMENHUCMS_0000045023": "新建主題",
      "P_YS_OA_XTMENHUCMS_0000045265": "您確定要複製",
      "P_YS_OA_XTMENHUCMS_0001314230": "新建元件",
      "P_YS_OA_XTMENHUCMS_0000045022": "添加一級菜單",
      "P_YS_OA_XTMENHUCMS_0000045264": "1400像素固定寬度",
      "P_YS_PF_METADATA_0001038628": "版本版次規則",
      "P_YS_OA_XTMENHUCMS_0001314233": "請填寫屬性鍵值",
      "P_YS_OA_XTMENHUCMS_0000045021": "關聯內容",
      "P_YS_OA_XTMENHUCMS_0000045263": "返回上一頁",
      "P_YS_OA_XTMENHUCMS_0001314232": "刪除工作台",
      "P_YS_OA_XTMENHUCMS_0000045020": "沒有發現相關常用應用喲!",
      "P_YS_OA_XTMENHUCMS_0000045262": "累計金額(萬元)",
      "P_YS_OA_XTMENHUCMS_0001314235": "從儀表板選擇",
      "P_YS_OA_LCGL_0000037192": "個人統計",
      "P_YS_OA_XTMENHUCMS_0000045261": "請輸入自定義底部高度",
      "P_YS_OA_XTMENHUCMS_0001314234": "工作台管理者",
      "P_YS_PF_GZTSYS_0000012730": "導航設置",
      "P_YS_OA_XTMENHUCMS_0000045260": "請大於1不超過12個字節的名稱",
      "P_YS_OA_XTMENHUCMS_0001314237": "版心為核心信息放置區域",
      "P_YS_OA_XTMENHUCMS_0001314236": "允許員工設置預設工作台",
      "P_YS_OA_XTMENHUCMS_0001314239": "個人工作台",
      "P_YS_OA_XTMENHUCMS_0001314238": "恢復預設模板提示!",
      "P_YS_OA_XTMENHUCMS_0000045029": "當前數據為空",
      "P_YS_OA_XTMENHUCMS_0000045028": "本組織",
      "P_YS_OA_XTMENHUCMS_0000045027": "類別號",
      "P_YS_OA_XTMENHUCMS_0000045269": "上傳文件中大小不得大於",
      "P_YS_OA_XTMENHUCMS_0000045026": "像素/px",
      "P_YS_OA_XTMENHUCMS_0000045268": "幹部任命決定",
      "P_YS_OA_XTMENHUCMS_0000045025": "上傳圖標",
      "P_YS_OA_XTMENHUCMS_0000045267": "下午8點開會",
      "P_YS_OA_XTMENHUCMS_1420420752232939520": "編輯場景化APP",
      "OA_web_ad_000000003": "廣告位名稱",
      "P_YS_OA_XTMENHUCMS_0001314251": "更換儀表板",
      "P_YS_OA_XTMENHUCMS_0001314250": "變更模板布局",
      "P_YS_OA_XTMENHUCMS_0000045013": "Menu Name",
      "P_YS_OA_XTMENHUCMS_0000045255": "最多添加24個應用!",
      "P_YS_OA_XTMENHUCMS_0001314242": "頭部背景圖片",
      "P_YS_OA_XTMENHUCMS_0000045012": "請輸入三位以內正整數",
      "P_YS_OA_XTMENHUCMS_0000045254": "點擊使用該布局",
      "P_YS_OA_XTMENHUCMS_0001314241": "請選擇元件型別",
      "P_YS_OA_XTMENHUCMS_0000045011": "逕向",
      "P_YS_OA_XTMENHUCMS_0000045253": "翡翠綠",
      "P_YS_OA_XTMENHUCMS_0001314244": "建議50-100px",
      "P_YS_OA_XTMENHUCMS_0000045010": "應用顯示名稱",
      "P_YS_OA_XTMENHUCMS_0000045252": "請輸入顯示行數",
      "P_YS_OA_XTMENHUCMS_0001314243": "請輸入元件回調函數名",
      "P_YS_OA_XTMENHUCMS_0000045251": "當前網絡不可用,請檢查你的網絡",
      "P_YS_OA_XTMENHUCMS_0001314246": "點擊確定恢復預設模板",
      "P_YS_OA_XTMENHUCMS_0000045250": "應用名稱12個字節以內",
      "P_YS_OA_XTMENHUCMS_0001314245": "啟用工作台",
      "P_YS_OA_XTMENHUCMS_0001314248": "新增企業自定義元件",
      "P_YS_OA_XTMENHUCMS_0001314247": "選擇服務",
      "P_YS_OA_XTMENHUCMS_0001314249": "樹結構選擇",
      "P_YS_OA_XTMENHUCMS_0000045019": "我的組件庫",
      "P_YS_OA_XTMENHUCMS_0000045018": "下午2點開會",
      "P_YS_OA_XTMENHUCMS_0000045017": "企業自有門戶模版",
      "P_YS_OA_XTMENHUCMS_0000045259": "NC審批",
      "P_YS_OA_XTMENHUCMS_0000045016": "二級導航",
      "P_YS_OA_XTMENHUCMS_0000045258": "1.被限制的組織內成員，只能看到本組織的通訊錄",
      "P_YS_OA_XTMENHUCMS_0000045015": "啊白測試",
      "P_YS_OA_XTMENHUCMS_0000045257": "保存並發布成功",
      "P_YS_OA_XTMENHUCMS_0000045014": "請輸入主題名稱12個字節以內",
      "P_YS_OA_XTMENHUCMS_0000045256": "下午7點開會",
      "OA_web_ad_000000004": "請選擇要設置的廣告位",
      "P_YS_OA_XTWENKU_0000035505": "刪除成功",
      "P_YS_OA_XTMENHUCMS_0000045002": "”相關內容",
      "P_YS_OA_XTMENHUCMS_0000045244": "組件已添加!",
      "P_YS_OA_XTMENHUCMS_0000045001": "對角",
      "P_YS_OA_XTMENHUCMS_0000045243": "工作台H5測試接口聯調",
      "P_YS_OA_XTMENHUCMS_0000045000": "結束色",
      "P_YS_OA_XTMENHUCMS_0000045242": "集人字",
      "P_YS_OA_XTMENHUCMS_0001314211": "新增廣告位",
      "P_YS_OA_XTMENHUCMS_0000045241": "工時排名TOP5",
      "P_YS_OA_XTMENHUCMS_0001314210": "工作台自適應樣式",
      "P_YS_OA_XTMENHUCMS_0000045240": "添加主題成功!",
      "P_YS_OA_XTMENHUCMS_0001314213": "廣告刪除失敗",
      "P_YS_OA_XTMENHUCMS_0001314212": "新增廣告位成功",
      "P_YS_OA_XTMENHUCMS_0001314215": "元件信息",
      "P_YS_OA_XTMENHUCMS_0001314214": "確定要啟用嗎？",
      "P_YS_OA_XTMENHUCMS_0001314217": "請輸入元件屬性函數名",
      "P_YS_OA_XTMENHUCMS_0001438086": "該分組名稱已存在，請輸入其他名稱！",
      "P_YS_OA_XTMENHUCMS_0000045009": "應用設置，拖動可以排序",
      "P_YS_OA_XTMENHUCMS_0001314216": "請輸入工作台名稱!",
      "P_YS_OA_XTMENHUCMS_0001438087": "請選擇所屬分組",
      "P_YS_PF_MANAGER-FE_1420076596501413894": "已選場景化APP",
      "P_YS_OA_XTMENHUCMS_0000045008": "自定義底部鏈接",
      "P_YS_OA_XTMENHUCMS_0001314219": "建議尺寸：230 x 190",
      "P_YS_OA_XTMENHUCMS_0001438088": "+ 新建分組",
      "P_YS_OA_XTMENHUCMS_0000045007": "業績快報",
      "P_YS_OA_XTMENHUCMS_0000045249": "關於醫療公司業務整體合併到股份公司整合發展的通知",
      "P_YS_OA_XTMENHUCMS_0001314218": "廣告刪除成功",
      "P_YS_OA_XTMENHUCMS_0001438089": "請將該分組中的工作台移動到其他分組，否則無法執行刪除操作！",
      "P_YS_OA_XTMENHUCMS_0000045006": "頭部修飾顏色",
      "P_YS_OA_XTMENHUCMS_0000045248": "空白門戶布局",
      "P_YS_OA_XTMENHUCMS_0000045005": "還未有項目哦!",
      "P_YS_OA_XTMENHUCMS_0000045247": "電郵後綴",
      "P_YS_OA_XTMENHUCMS_0000045004": "企業組件庫",
      "P_YS_OA_XTMENHUCMS_0000045246": "獲取當前空間失敗",
      "P_YS_OA_XTMENHUCMS_0000045003": "已出勤",
      "P_YS_OA_XTMENHUCMS_0000045245": "為保證移動端頁面視覺效果，導航圖示只能上傳不超過20kb，尺寸是122px X 122px 的png圖示。",
      "P_YS_OA_XTMENHUCMS_0001438085": "請選擇分組!",
      "P_YS_OA_app_xtyyjm_0000035910": "web端",
      "P_YS_OA_XTMENHUCMS_0001009159": "添加水印",
      "P_YS_OA_XTMENHUCMS_0000045233": "縱向平鋪",
      "P_YS_OA_XTMENHUCMS_0001314220": "版心",
      "P_YS_OA_XTMENHUCMS_0000045232": "中國紅",
      "P_YS_OA_XTMENHUCMS_0000045231": "操作太快，稍等重試",
      "P_YS_OA_XTMENHUCMS_0001314222": "圖片大小不超過500KB",
      "P_YS_OA_XTMENHUCMS_0000045230": "停用提示",
      "P_YS_OA_XTMENHUCMS_0001314221": "工作台使用者部門",
      "P_YS_OA_XTMENHUCMS_0001314224": "請填寫廣告位名稱",
      "P_YS_OA_XTMENHUCMS_0001314223": "請選擇關聯應用",
      "P_YS_OA_XTMENHUCMS_0001314226": "廣告位尺寸",
      "P_YS_OA_XTMENHUCMS_0001314225": "禁止員工設置預設工作台",
      "P_YS_OA_XTMENHUCMS_0001314228": "選擇身份型別",
      "P_YS_OA_XTMENHUCMS_0001314227": "配置值",
      "P_YS_OA_XTMENHUCMS_0000045239": "劉豪-三十而立贏天下",
      "P_YS_OA_XTMENHUCMS_0000045238": "漏打卡",
      "P_YS_OA_XTMENHUCMS_0001314229": "請上傳元件源檔案!",
      "P_YS_OA_XTMENHUCMS_0000045237": "刪除門戶",
      "P_YS_OA_XTMENHUCMS_0001009162": "關閉成功",
      "P_YS_OA_XTMENHUCMS_0000045236": "色塊導航",
      "P_YS_OA_XTMENHUCMS_0001009161": "開啟成功",
      "P_YS_OA_XTMENHUCMS_0000045235": "創建門戶",
      "P_YS_OA_XTMENHUCMS_0001009160": "動態分類管理",
      "P_YS_OA_XTMENHUCMS_0000045234": "網絡錯誤請稍後再試!",
      "P_YS_OA_XTWENKU_0000035606": "項目文檔",
      "P_YS_OA_XTMENHUCMS_0000045071": "設置保存成功!",
      "P_YS_OA_XTMENHUCMS_0000045070": "頭部文字顏色",
      "P_YS_OA_XTMENHUCMS_0001314161": "法語",
      "P_YS_OA_XTMENHUCMS_0001314160": "日語",
      "P_YS_OA_XTMENHUCMS_0001314163": "樹結構",
      "P_YS_OA_XTMENHUCMS_0001314162": "清單樹結構",
      "P_YS_OA_XTMENHUCMS_0000045068": "自定義應用",
      "P_YS_OA_XTMENHUCMS_0001314154": "葡萄牙語",
      "P_YS_OA_XTMENHUCMS_0000045067": "重置成功",
      "P_YS_OA_XTMENHUCMS_0001314153": "【提示】若系統語言（<%=enterprise%>）內容未維護，系統將默認按當前語言（<%=current%>）內容賦值處理",
      "P_YS_OA_XTMENHUCMS_0000045066": "聯絡員",
      "P_YS_OA_XTMENHUCMS_0001314156": "研發建模服務-前端",
      "P_YS_OA_XTMENHUCMS_0000045065": "2.可以設置不受此規則限制的成員",
      "P_YS_OA_XTMENHUCMS_0001314155": "德語",
      "P_YS_OA_XTMENHUCMS_0000045064": "營員信息",
      "P_YS_OA_XTMENHUCMS_0001314158": "請輸入...",
      "P_YS_OA_XTMENHUCMS_0000045063": "三行圖標時背景圖尺寸為690×515 PX",
      "P_YS_OA_XTMENHUCMS_0001314157": "俄語",
      "P_YS_OA_XTMENHUCMS_0000045062": "同期金額(萬元)",
      "P_YS_OA_XTMENHUCMS_0000045061": "建議70-100px",
      "P_YS_OA_XTMENHUCMS_0001314159": "當前語言",
      "P_YS_OA_XTMENHUCMS_0000045069": "自定義底部高度",
      "P_YS_OA_XTLCZX_0000030265": "加載中",
      "P_YS_OA_XTMENHUCMS_0000045060": "恢復成功",
      "P_YS_OA_XTMENHUCMS_0001314170": "分類下存在廣告，不允許刪除",
      "P_YS_OA_XTMENHUCMS_0001314172": "請輸入元件名稱",
      "P_YS_OA_XTMENHUCMS_0001314171": "關聯工作台",
      "P_YS_OA_XTMENHUCMS_0001314174": "請填寫介面地址",
      "P_YS_OA_XTMENHUCMS_0001314173": "元件屬性函數名",
      "P_YS_OA_XTMENHUCMS_0000045057": "執行中",
      "P_YS_OA_XTMENHUCMS_0001314165": "新增單選型別",
      "P_YS_OA_XTMENHUCMS_0000045056": "高亮圖標",
      "P_YS_OA_XTMENHUCMS_0001314164": "意大利語",
      "P_YS_OA_XTMENHUCMS_0001438090": "請輸入工作台名稱",
      "P_YS_OA_XTMENHUCMS_0000045055": "白宇宇fiona",
      "P_YS_OA_XTMENHUCMS_0000045297": "處罰決定",
      "P_YS_OA_XTMENHUCMS_0001314167": "空白工作台布局",
      "P_YS_OA_XTMENHUCMS_0000045054": "保存失敗請稍後重試!",
      "P_YS_OA_XTMENHUCMS_0000045296": "確定刪除這條限制規則嗎？",
      "P_YS_OA_XTMENHUCMS_0001314166": "服務創建工作台",
      "P_YS_OA_XTMENHUCMS_0000045053": "保存失敗請稍後重試",
      "P_YS_OA_XTMENHUCMS_0000045295": "(可拖動排序)",
      "P_YS_OA_XTMENHUCMS_0000045052": "自定義底部",
      "P_YS_OA_XTMENHUCMS_0000045294": "測試圖片",
      "P_YS_OA_XTMENHUCMS_0001314168": "工作台名稱不能超過20個字符",
      "P_YS_OA_XTMENHUCMS_0000045051": "普通圖標",
      "P_YS_OA_XTMENHUCMS_0000045293": "新添日程",
      "P_YS_OA_XTMENHUCMS_0000045050": "”主題嗎?",
      "P_YS_OA_XTMENHUCMS_0000045292": "修改一級菜單",
      "P_YS_OA_XTMENHUCMS_0001538941": "1.導入前，請您先下載範本，並按照指定格式編輯導入用戶資訊，然後導入數據；",
      "P_YS_OA_XTLCZX_0000030259": "關閉成功",
      "P_YS_OA_XTMENHUCMS_0001538943": "2.請您認真填寫資訊，否則可能導入失敗；",
      "P_YS_OA_XTMENHUCMS_0000045059": "允許個人設計",
      "P_YS_OA_XTMENHUCMS_0001538945": "3.不建議導入同一個檔多次；",
      "P_YS_OA_XTMENHUCMS_0000045058": "自定義應用副本",
      "P_YS_FED_FW_0000022352": "布局",
      "P_YS_FED_FW_0000021022": "日",
      "P_YS_OA_XTMENHUCMS_0000045291": "從左到右",
      "P_YS_OA_XTMENHUCMS_0000045046": "新添任務",
      "P_YS_OA_XTMENHUCMS_0000045288": "起始色",
      "P_YS_OA_XTMENHUCMS_0000045045": "邊框圓角值",
      "P_YS_OA_XTMENHUCMS_0000045044": "工作電郵權限",
      "P_YS_OA_XTMENHUCMS_0000045286": "啟用橫向導航成功",
      "P_YS_OA_XTMENHUCMS_0000045043": "首屏",
      "P_YS_OA_XTMENHUCMS_0000045042": "確定要刪除該導航嗎",
      "P_YS_OA_XTMENHUCMS_0000045283": "個人詳情頁顯示欄位",
      "P_YS_OA_XTMENHUCMS_0000045040": "最多支持添加6個一級菜單！",
      "P_YS_OA_XTMENHUCMS_0000045282": "刪除成功!",
      "P_YS_OA_XTMENHUCMS_0000045281": "分類LOGO",
      "P_YS_FED_FW_0000021499": "重置",
      "P_YS_FED_FW_0000021018": "四",
      "P_YS_OA_XTMENHUCMS_0000045049": "目前自由布局僅支持 Chrome 瀏覽器;請更換瀏覽器後重試!",
      "P_YS_OA_XTMENHUCMS_0000045048": "友空間工作台首頁",
      "P_YS_OA_XTMENHUCMS_0000045047": "控件屬性",
      "P_YS_OA_XTMENHUCMS_0000045289": "確定刪除這個組件嗎?",
      "P_YS_OA_XTMENHUCMS_0000045280": "移動端定制",
      "P_YS_OA_XTMENHUCMS_0001314150": "研發建模服務-前端",
      "P_YS_OA_XTMENHUCMS_0001314152": "系統語言",
      "P_YS_OA_XTMENHUCMS_0001314151": "韓語",
      "P_YS_OA_XTMENHUCMS_0000045035": "門戶LOGO",
      "P_YS_OA_XTMENHUCMS_0000045277": "布拉特",
      "P_YS_OA_XTMENHUCMS_0001314143": "默認語種",
      "P_YS_OA_XTMENHUCMS_0000045034": "一次最多只允許上傳",
      "P_YS_OA_XTMENHUCMS_0000045276": "關閉橫向導航成功",
      "P_YS_OA_XTMENHUCMS_0000045033": "趙子健",
      "P_YS_OA_XTMENHUCMS_0000045275": "打開中...",
      "P_YS_OA_XTMENHUCMS_0001314145": "當前",
      "P_YS_OA_XTMENHUCMS_0000045032": "張c",
      "P_YS_OA_XTMENHUCMS_0000045274": "寶石青",
      "P_YS_OA_XTMENHUCMS_0001314144": "當前語種",
      "P_YS_OA_XTMENHUCMS_0000045031": "停用後重新登錄生效",
      "P_YS_OA_XTMENHUCMS_0000045273": "鏈接頁面",
      "P_YS_OA_XTMENHUCMS_0001314147": "默認",
      "P_YS_OA_XTMENHUCMS_0000045030": "海洋藍",
      "P_YS_OA_XTMENHUCMS_0000045272": "(拖動可排序)",
      "P_YS_OA_XTMENHUCMS_0001314146": "【提示】若“系統語言”為空，則按照“當前語言”進行保存。",
      "P_YS_OA_XTMENHUCMS_0000045271": "部門統計-月",
      "P_YS_OA_XTMENHUCMS_0001314149": "簡體中文",
      "P_YS_OA_XTMENHUCMS_0000045270": "工作台後台管理接口聯調測試",
      "P_YS_OA_XTMENHUCMS_0001314148": "多語言設置",
      "P_YS_OA_XTMENHUCMS_0000045039": "完成率",
      "P_YS_OA_XTMENHUCMS_0000045038": "沒有發現相關文件喲",
      "P_YS_OA_XTMENHUCMS_0000045037": "自定義主題",
      "P_YS_OA_XTMENHUCMS_0000045279": "線性導航顏色",
      "P_YS_OA_XTMENHUCMS_0000045036": "集規劃字",
      "P_YS_OA_XTMENHUCMS_0000045278": "預覽效果",
      "P_YS_OA_XTMENHUCMS_0001314198": "元件屬性配置",
      "P_YS_OA_XTMENHUCMS_0001314197": "創建工作台",
      "P_YS_OA_XTMENHUCMS_0001314199": "工作台使用者角色",
      "P_YS_OA_XTMENHU_0001025410": "自定義啟動圖",
      "P_YS_OA_XTMENHU_0001025411": "生效時間不能晚於失效時間",
      "P_YS_OA_XTLCZX_0000030460": "小時",
      "P_YS_FED_EXAMP_0000020173": "全部應用",
      "P_YS_OA_XTMENHUCMS_0001320648": "工作台設計",
      "P_YS_OA_XTMENHUCMS_0000045099": "關聯功能",
      "P_YS_OA_XTMENHUCMS_0000045098": "故事數",
      "P_YS_OA_XTMENHUCMS_0000045097": "請輸入正確格式的網址",
      "P_YS_OA_XTMENHU_0001025409": "上傳失敗，請上傳300K以內的圖片",
      "P_YS_OA_XTMENHUCMS_0000045096": "用友產業園公共會議室使用管理規定",
      "P_YS_OA_XTMENHUCMS_0000045095": "一級導航",
      "P_YS_OA_XTMENHU_0001025407": "默認啟動圖",
      "P_YS_OA_XTMENHUCMS_0000045094": "頭部背景顏色",
      "P_YS_OA_XTMENHU_0001025408": "上傳圖片尺寸建議為1080*1920，大小不超過300KB",
      "P_YS_OA_XTMENHU_0001025405": "上傳失敗，請上傳 jpg、jpeg、png 類型圖片",
      "P_YS_OA_XTMENHU_0001025406": "編輯啟動圖",
      "P_YS_OA_XTMENHU_0001025403": "溫馨提示：在未設置自定義啟動圖的時間段，移動端啟動圖將使用默認圖",
      "P_YS_OA_XTMENHU_0001025404": "刪除後無法恢復，請確認",
      "P_YS_OA_XTMENHU_0001025401": "請上傳啟動圖",
      "P_YS_OA_XTMENHU_0001025402": "設定在企業紀念日、重大通知時展示的啟動畫面",
      "P_YS_OA_XTMENHU_0001025400": "點擊修改",
      "P_YS_FED_FW_0000022154": "恢復",
      "P_YS_OA_XTMENHUCMS_0000045093": "邏輯排序",
      "P_YS_OA_XTMENHUCMS_0000045091": "簽到數據測試結果反饋",
      "P_YS_OA_XTMENHUCMS_0001314181": "請輸入平台服務程式碼(選填)",
      "P_YS_OA_XTMENHUCMS_0000045090": "確定要停用嗎？停用後重新登錄生效",
      "P_YS_SD_SDMAUF_0000151788": "分頁1",
      "P_YS_OA_XTMENHUCMS_0001314183": "新增複選型別",
      "P_YS_OA_XTMENHUCMS_0001314182": "從已有工作台頁面創建",
      "P_YS_OA_XTMENHUCMS_0001314185": "該類工作台，其啟/停用狀態和使用權均由對應的服務進行控制，此處不受控制",
      "P_YS_OA_XTMENHUCMS_0001314184": "請選擇工作台使用者",
      "P_YS_OA_XTMENHUCMS_0001314176": "通過服務創建",
      "P_YS_OA_XTMENHUCMS_0000045089": "導航選中顏色",
      "P_YS_OA_XTMENHUCMS_0001314175": "請選擇服務!",
      "P_YS_OA_XTMENHUCMS_0000045088": "門戶使用者",
      "P_YS_OA_XTMENHUCMS_0001314178": "門戶",
      "P_YS_OA_XTMENHUCMS_0000045087": "您添加的內部群有些存在，已自動去重",
      "P_YS_OA_XTMENHUCMS_0001314177": "名稱長度應小於等於20個字符",
      "P_YS_OA_XTMENHUCMS_0000045086": "鏈接企業自有門戶",
      "P_YS_OA_XTMENHUCMS_0000045085": "上傳圖片只能是JPG/PNG格式",
      "P_YS_OA_XTMENHUCMS_0001314179": "輸入比例錯誤~",
      "P_YS_OA_XTMENHUCMS_0000045084": "下午3點開會",
      "P_YS_OA_XTMENHUCMS_0000045083": "請選擇所屬機構",
      "P_YS_OA_XTMENHUCMS_0000045082": "取消自定義底部成功",
      "P_YS_OA_XTMENHUCMS_0001314190": "請選擇要設置的資源尺寸",
      "P_YS_OA_XTMENHUCMS_0000045081": "請輸入6個字符以內",
      "P_YS_OA_XTMENHUCMS_0000045080": "最多添加4個待辦組件",
      "P_YS_OA_XTMENHUCMS_0001314192": "廣告位刪除失敗",
      "P_YS_OA_XTMENHUCMS_0001314191": "頁簽1",
      "P_YS_OA_XTMENHUCMS_0001314194": "屬性鍵值",
      "P_YS_OA_XTMENHUCMS_0001314193": "工作台名稱不能為空",
      "P_YS_OA_XTMENHUCMS_0001314196": "%c加載資源為前端合包資源-portal",
      "P_YS_OA_XTMENHUCMS_0001314195": "新增下拉型別",
      "P_YS_OA_XTMENHUCMS_0001314187": "複製工作台",
      "P_YS_OA_XTMENHUCMS_0000045078": "圖標修改僅對本控件有效",
      "P_YS_OA_XTMENHUCMS_0001314186": "搜索介面key值",
      "P_YS_OA_XTMENHUCMS_0000045077": "年假是申請15天",
      "P_YS_OA_XTMENHUCMS_0001314189": "請輸入Url",
      "P_YS_OA_XTMENHUCMS_0000045076": "總體收款",
      "P_YS_OA_XTMENHUCMS_0001314188": "元件源檔案",
      "P_YS_OA_XTMENHUCMS_0000045075": "個人設計",
      "P_YS_OA_XTMENHUCMS_0000045074": "熱門話題",
      "P_YS_OA_XTMENHUCMS_0000045073": "夏令營集顏值",
      "P_YS_OA_XTMENHUCMS_0000045072": "賬戶類型",
      "P_YS_PF_GZTSYS_0000011913": "動態分類管理",
      "P_YS_OA_XTLCZX_0000030653": "常用",
      "P_YS_FED_FW_0000022521": "名稱不能為空",
      "P_YS_IPAAS_UCG_0001156621": "下拉選擇",
      "P_YS_SD_SDMA_0000037949": "菜單名稱不能為空",
      "P_YS_OA_XTGONGGAO_0000045838": "請選擇所屬組織",
      "P_YS_OA_XTMENHUCMS_0001539173": "總導入人數",
      "P_YS_OA_XTMENHUCMS_0001539174": "失敗人數",
      "P_YS_OA_XTMENHUCMS_0001539177": "成功人數",
      "P_YS_OA_XTMENHUCMS_0001539170": "返回",
      "P_YS_FED_UCFBASEDOC_0000025313": "停用成功",
      "P_YS_FED_FW_0000021412": "排名",
      "P_YS_FED_IMP-DFM-FE_0000020868": "操作",
      "P_YS_FED_EXAMP_0000020394": "全部",
      "P_YS_OA_XTHUIYI_0000046420": "待處理",
      "P_YS_OA_XTLCZX_0000030677": "拖拽可排序",
      "P_YS_FED_IMP-DFM-FE_0000020875": "編輯",
      "P_YS_FED_FW_0000021476": "山西",
      "P_YS_SD_SDMB_0000147720": "回調函數",
      "P_YS_FED_IMP_IOT0000006584": "刪除成功",
      "gwportal.erp.xiaoyou": "小友",
      "P_YS_OA_XTYUNHUIWU_0000162483": "組織",
      "P_YS_FED_FW_0000021003": "星期一",
      "P_YS_OA_app_xtywlj_0000037530": "暫無內容",
      "P_YS_YYGJFW_YBUC_0001302338": "元件圖標",
      "P_YS_OA_XTMENHUCMS_0001095593": "中文最多4個字符，英文最多8個字符"
  },
  "zhcn": {
      "P_YS_FI_CM_0000026181": "有",
      "P_YS_PF_PROCENTER_0000027341": "应用",
      "P_YS_PF_WORKBENCH-FE_0001133355": "产品模块定制",
      "P_YS_PF_WORKBENCH-FE_0001133356": "日过期",
      "P_YS_PF_WORKBENCH-FE_0001133353": "让我能够运筹帷幄，时刻掌握公司的经营情况",
      "P_YS_PF_WORKBENCH-FE_0001133354": "修改业务日期",
      "P_YS_PF_WORKBENCH-FE_0001133359": "我的首选",
      "P_YS_PF_WORKBENCH-FE_0001228612": "页面属性设置",
      "P_YS_PF_WORKBENCH-FE_0001228611": "图片模糊",
      "P_YS_PF_WORKBENCH-FE_0001133357": "首页设置",
      "P_YS_PF_WORKBENCH-FE_0001228610": "图片蒙版",
      "P_YS_PF_WORKBENCH-FE_0001133358": "门户",
      "P_YS_PF_WORKBENCH-FE_0001133362": "最多打开20个页签，请关闭不需要的页签。",
      "P_YS_PF_WORKBENCH-FE_0001228609": "卡片间距",
      "P_YS_PF_GZTSYS_0000012650": "应用管理",
      "P_YS_PF_WORKBENCH-FE_0001133363": "能够有精力更关注每一位员工，赋能与激活团队",
      "P_YS_PF_WORKBENCH-FE_0001228608": "页面宽度",
      "P_YS_PF_WORKBENCH-FE_0001133360": "退出后，您在当前团队下的应用将不能再使用，相应的数据也将被删除，请确认数据已备份",
      "P_YS_PF_WORKBENCH-FE_0001228607": "固定宽度",
      "P_YS_PF_WORKBENCH-FE_0001133361": "未知搜索结果",
      "P_YS_PF_WORKBENCH-FE_1420197920737591296": "切换布局类型，则画布中已存在的组件会丢失，您确定要切换吗？",
      "P_YS_FED_UCFBASEDOC_0000025292": "未添加",
      "P_YS_PF_WORKBENCH-FE_0001133366": "用友网络科技股份有限公司 © Copyright 2018 京ICP备05007539号-24 京ICP证100714号",
      "P_YS_PF_WORKBENCH-FE_0001133367": "搜索您想查找的功能、通讯录等 信息。",
      "P_YS_PF_WORKBENCH-FE_0001133364": "星空蓝",
      "P_YS_PF_WORKBENCH-FE_0001133365": "专属化定制",
      "P_YS_PF_WORKBENCH-FE_0001228600": "友空间欢迎您!",
      "P_YS_PF_WORKBENCH-FE_0001133368": "功能服务发布",
      "P_YS_PF_WORKBENCH-FE_0001133369": "邮箱号",
      "P_YS_PF_WORKBENCH-FE_0001133370": "电话沟通",
      "P_YS_FED_FW_0000021848": "大于",
      "P_YS_PF_WORKBENCH-FE_0001133373": "· 企业级统一基础档案与数据管控",
      "P_YS_FED_IMP_IOT0000005567": "确认",
      "P_YS_PF_WORKBENCH-FE_0001133374": "退出全屏",
      "P_YS_FED_EXAMP_0000020762": "企业",
      "P_YS_PF_WORKBENCH-FE_0001133371": "查看您的使用记录",
      "P_YS_OA_app_xtywlj_0000037365": "忽略",
      "P_YS_PF_WORKBENCH-FE_0001133372": "“我是一名研发工程师”",
      "P_YS_PF_WORKBENCH-FE_0001663982": "布局比例",
      "P_YS_PF_WORKBENCH-FE_0001663983": "角色工作台预览状态，关闭该浏览器页签结束预览",
      "P_YS_PF_WORKBENCH-FE_0001663984": "LOGO栏设置",
      "P_YS_PF_WORKBENCH-FE_0001663985": "组件设置",
      "P_YS_FED_UCFBASEDOC_0000025043": "添加快捷方式至首页",
      "P_YS_PF_WORKBENCH-FE_0001133377": "专家团队，根据企业需求，提供产品方\b案",
      "country66": "泰国",
      "P_YS_PF_WORKBENCH-FE_0001133378": "移交团队",
      "country65": "新加坡",
      "P_YS_PF_WORKBENCH-FE_0001133375": "免费咨询",
      "P_YS_PF_WORKBENCH-FE_0001133376": "· 企业级应用市场提供全方位数字化服务入口",
      "country63": "菲律宾",
      "country62": "印度尼西亚",
      "country61": "澳大利亚",
      "P_YS_PF_WORKBENCH-FE_0001133379": "请先输入菜单名称",
      "country60": "马来西亚",
      "P_YS_PF_WORKBENCH-FE_0001133380": "淡雅蓝",
      "P_YS_PF_WORKBENCH-FE_0001133381": "点击确定后即将刷新页面，是否继续？",
      "P_YS_FED_FW_0000020965": "条",
      "P_YS_PF_WORKBENCH-FE_0001196918": "Copyright ©2021",
      "P_YS_FED_FW_0000021813": "不等于",
      "P_YS_PF_WORKBENCH-FE_0001133384": "“我是一名人力资源经理”",
      "P_YS_PF_WORKBENCH-FE_0001133385": "提供产品部署方案，可随意选择需要的产品",
      "P_YS_PF_WORKBENCH-FE_0001133382": "需要切换到对应的企业查看详情，是否切换?",
      "P_YS_PF_WORKBENCH-FE_0001133383": "管理：默认企业账号管理员可见，可通过角色授权给其他用户",
      "P_YS_PF_YS-LOGIN_0001079475": "接口未返回数据",
      "P_YS_PF_WORKBENCH-FE_0001245170": "“门户管理”改为“工作台管理”，且入口从“协同配置”处移动到“系统管理”处",
      "P_YS_FED_FW_0000020962": "简体中文",
      "P_YS_FED_FW_0000020961": "保存成功",
      "P_YS_PF_WORKBENCH-FE_0001237311": "多设备登录提示",
      "P_YS_PF_WORKBENCH-FE_0001237312": "搜索服务名称",
      "P_YS_PF_WORKBENCH-FE_0001237313": "登录并在其他设备退出",
      "P_YS_PF_WORKBENCH-FE_0001237314": "未找到相关结果",
      "P_YS_PF_WORKBENCH-FE_0001237315": "你的账号已在其它设备或浏览器中登录，是否强制登录?",
      "P_YS_PF_WORKBENCH-FE_0001237316": "不登录",
      "P_YS_PF_WORKBENCH-FE_0001237317": "登录且不退出其他设备",
      "P_YS_PF_WORKBENCH-FE_0001237318": "你的账号已在其它设备或浏览器中登录，是否继续登录?",
      "P_YS_PF_WORKBENCH-FE_0001237319": "登录管理提示",
      "P_YS_HR_HRPXF_0000055966": "请输入团队名称",
      "P_YS_PF_WORKBENCH-FE_0001133388": "产品模块可部署到专属机房，免去您的安全担忧",
      "P_YS_PF_MANAGER-FE_0001087904": "禁止用户申请加入",
      "P_YS_PF_WORKBENCH-FE_0001133389": "收费咨询",
      "P_YS_PF_WORKBENCH-FE_0001133386": "PC客户端",
      "P_YS_PF_WORKBENCH-FE_0001203344": "关闭其它",
      "P_YS_PF_WORKBENCH-FE_0001133387": "解散后，当期团队下的应用将不能再使用，相应的数据也将被删除，请确认数据已备份",
      "P_YS_PF_WORKBENCH-FE_0001228620": "企业组件",
      "P_YS_PF_WORKBENCH-FE_0001133391": "“我是一位企业家”",
      "P_YS_PF_WORKBENCH-FE_0001133392": "mdf加载完毕，可以执行打开",
      "P_YS_PF_WORKBENCH-FE_0001271682": "用户调研",
      "P_YS_PF_WORKBENCH-FE_0001133390": "请搜索被移交用户",
      "P_YS_PF_WORKBENCH-FE_0001228613": "蒙版透明度",
      "P_YS_PF_WORKBENCH-FE_0001271683": "【温馨提示】用户调研点击这里使用，期待您的参与",
      "P_YS_PF_WORKBENCH-FE_0001133395": "未加入任何企业",
      "P_YS_PF_WORKBENCH-FE_0001133396": "页面中有未保存的数据，点击确定后将重新打开此页面，是否继续？",
      "P_YS_PF_WORKBENCH-FE_0001133393": "申请服务",
      "P_YS_PF_WORKBENCH-FE_0001133394": "售前人员将与您取得联系，和您讨论定制需求",
      "P_YS_HR_HRXCF_0000056202": "邮箱格式错误",
      "P_YS_FI_CM_0000029891": "请输入验证码",
      "P_YS_PF_WORKBENCH-FE_0001133319": "退出团队",
      "P_YS_PF_WORKBENCH-FE_0001133317": "工作效率越来越高，和团队间沟通、协作的更便捷。",
      "P_YS_PF_WORKBENCH-FE_0001133318": "专家咨询团队提供一对一解决方案",
      "P_YS_PF_YS-LOGIN_0001196671": "京ICP 备05007539号-24",
      "P_YS_PF_GZTTMP_0000075890": "手机端",
      "P_YS_PF_WORKBENCH-FE_0001133311": "· 基础假勤与薪资查询服务",
      "P_YS_OA_XTMENHU_0000046660": "发起时间：",
      "P_YS_PF_MANAGER-FE_0001087915": "移动端主页、summerId选填一个",
      "P_YS_PF_WORKBENCH-FE_0001133312": "马上定制",
      "P_YS_PF_WORKBENCH-FE_0001133310": "地址：北京市海淀区北清路68号用友产业园",
      "P_YS_PF_MANAGER-FE_0001087912": "运行环境版本",
      "P_YS_PF_WORKBENCH-FE_0001133315": "历史记录：",
      "P_YS_PF_WORKBENCH-FE_0001133316": "用友云，提供的人力共享服务",
      "P_YS_PF_WORKBENCH-FE_0001133313": "不切换",
      "P_YS_PF_WORKBENCH-FE_0001133314": "扫描下载客户端",
      "P_YS_PF_WORKBENCH-FE_0001274545": "暂无新成员",
      "P_YS_PF_GZTSYS_0000015967": "设置",
      "P_YS_PF_WORKBENCH-FE_0001274544": "未知部门",
      "P_YS_PF_WORKBENCH-FE_0001274543": "请输入组件链接",
      "P_YS_PF_WORKBENCH-FE_0001274542": "快捷應用",
      "P_YS_PF_MANAGER-FE_0001087908": "确认移除所选用户？",
      "P_YS_IPAAS_UCG_0001063993": "水利、环境和公共设施管理业",
      "P_YS_IPAAS_UCG_0001063994": "信息传输、计算机服务和软件业",
      "P_YS_OA_XTMENHUCMS_0000045307": "主题名称",
      "P_YS_FED_EXAMP_0000020713": "手机号",
      "P_YS_PF_WORKBENCH-FE_0001274549": "内容名称",
      "P_YS_OA_LCGL_0000037233": "用户名称",
      "P_YS_PF_WORKBENCH-FE_0001274548": "按提交时间最早",
      "P_YS_FED_UCFBASEDOC_0000025257": "搜索内容...",
      "P_YS_FED_FW_0000021874": "类型",
      "P_YS_PF_WORKBENCH-FE_0001274547": "单据类型/提交时间",
      "P_YS_PF_WORKBENCH-FE_0001274546": "最大显示数量",
      "P_YS_FED_UCFBASEDOC_0000025253": "立即注册",
      "P_YS_PF_WORKBENCH-FE_0001133328": "典雅灰",
      "P_YS_PF_MANAGER-FE_0001087920": "免登策略",
      "P_YS_PF_WORKBENCH-FE_0001133329": "展开标签栏",
      "P_YS_PF_PROCENTER_0000027376": "高级设置",
      "P_YS_FED_UCFBASEDOC_0000025251": "请输入手机号",
      "P_YS_PF_WORKBENCH-FE_0001133322": "· 更严格的企业成员管理",
      "P_YS_PF_MANAGER-FE_0001087926": "上传本地图标",
      "P_YS_PF_WORKBENCH-FE_0001133323": "成员权限",
      "P_YS_PF_WORKBENCH-FE_0001133320": "“我是一名财务经理”",
      "P_YS_PF_WORKBENCH-FE_0001133321": "业务：必须通过角色授权",
      "P_YS_PF_MANAGER-FE_0001087923": "web端打开方式",
      "P_YS_PF_WORKBENCH-FE_0001133326": "切换企业",
      "P_YS_PF_MANAGER-FE_0001087922": "请输入服务名称",
      "P_YS_PF_WORKBENCH-FE_0001133327": "热线电话：010-62438888",
      "P_YS_PF_MANAGER-FE_0001087925": "图标尺寸必须为120*120的JPG、GIF、PNG图片且尺寸不大于5M",
      "P_YS_PF_WORKBENCH-FE_0001133324": "分组名称,最多12个字符",
      "P_YS_PF_WORKBENCH-FE_0001133325": "数据正在加载中....",
      "P_YS_PF_WORKBENCH-FE_0001133330": "· 基于管理角色的应用权限管理",
      "P_YS_FI_FCWEB_0001047535": "全屏显示",
      "P_YS_PF_WORKBENCH-FE_0001324054": "权限受限，无法查看",
      "P_YS_OA_LCGL_0000037221": "用户编码",
      "P_YS_PF_WORKBENCH-FE_0001133339": "升级为企业后您可以获得更多权限",
      "P_YS_FED_UCFBASEDOC_0000025483": "已添加",
      "P_YS_HR_HRYGF_0000058856": "个性化设置",
      "P_YS_PF_WORKBENCH-FE_0001220704": "动态",
      "P_YS_PF_GZTSYS_0000109035": "我的常用",
      "P_YS_PF_WORKBENCH-FE_0001133333": "将链接发给小伙伴就可以啦",
      "P_YS_PF_MANAGER-FE_0001087937": "请选择规模范围",
      "P_YS_PF_WORKBENCH-FE_0001133334": "资料",
      "P_YS_PF_WORKBENCH-FE_0001133331": "关闭全部页签",
      "P_YS_PF_WORKBENCH-FE_0001528930": "升级提醒",
      "P_YS_PF_WORKBENCH-FE_0001133332": "升级企业",
      "P_YS_PF_WORKBENCH-FE_0001133337": "莫兰迪黄",
      "P_YS_PF_WORKBENCH-FE_0001528932": "去升级",
      "P_YS_PF_MANAGER-FE_0001087933": "管理员邀请",
      "P_YS_PF_WORKBENCH-FE_0001133338": "您的企业新增了",
      "P_YS_PF_WORKBENCH-FE_0001528931": "租户产品形态有更新，请进行产品升级。",
      "P_YS_PF_WORKBENCH-FE_0001133335": "应用续费",
      "P_YS_PF_WORKBENCH-FE_0001133336": "请填写图形验证码",
      "P_YS_PF_GZTSYS_0000012679": "团队管理",
      "P_YS_PF_WORKBENCH-FE_0001133340": "团队应用",
      "P_YS_OA_XTMENHU_0000046686": "提交人：",
      "P_YS_PF_WORKBENCH-FE_0001133341": "根据您的需求，我们可以提供专家团队上门解决方案",
      "P_YS_YYGJFW_YBUC_0001335998": "深色",
      "P_YS_FED_IMP_IOT0000005514": "下一步",
      "P_YS_IPAAS_UCG_0001063976": "采矿业",
      "P_YS_FED_FW_0000021612": "加载失败",
      "P_YS_HR_HRXCF_0000072788": "请选择数据来源",
      "P_YS_FED_FW_0000021852": "刷新",
      "P_YS_FED_EXAMP_0000020736": "收起",
      "P_YS_PF_MANAGER-FE_0001087941": "退出后，您在当前企业下的应用将不能再使用，相应的数据也将被删除，请确认数据已备份",
      "P_YS_SD_SDMAF_0000137211": "恢复默认设置",
      "P_YS_PF_MANAGER-FE_0001087943": "手机号格式错误",
      "P_YS_PF_WORKBENCH-FE_0001497250": "分钟】，您已超时，请重新登录。",
      "P_YS_OA_XTRIZHI_0000036826": "我的收藏",
      "P_YS_PF_WORKBENCH-FE_0001133344": "电话：010-62438888",
      "P_YS_PF_WORKBENCH-FE_0001133345": "服务流程",
      "P_YS_PF_WORKBENCH-FE_0001133342": "用友云，让我们每天都可以跟踪公司的实时财务数据，真正使财务成为业务的一部分",
      "P_YS_FI_CM_0000026177": "已审批",
      "P_YS_PF_WORKBENCH-FE_0001133343": "功能图标",
      "P_YS_PF_MANAGER-FE_0001087945": "请输入在友空间桌面端打开时跳转地址",
      "P_YS_PF_WORKBENCH-FE_0001133348": "· 企业级组织架构管理",
      "P_YS_PF_WORKBENCH-FE_0001133349": "请输入图形验证码",
      "P_YS_PF_WORKBENCH-FE_0001133346": "注销",
      "P_YS_PF_WORKBENCH-FE_0001133347": "菜单路径",
      "P_YS_PF_WORKBENCH-FE_0001133351": "确定设置当前用户为管理员？",
      "P_YS_OA_XTMENHU_0000046675": "送祝福",
      "P_YS_PF_WORKBENCH-FE_0001133352": "我的门户",
      "P_YS_PF_WORKBENCH-FE_0001133350": "用友集团",
      "P_YS_FED_IMP_IOT0000005503": "查询",
      "P_YS_PF_WORKBENCH-FE_0001497249": "温馨提示：您所在的企业帐号",
      "P_YS_PF_WORKBENCH-FE_0001497248": "）启用了无操作时长限制【",
      "OA_web_statistics_000000106": "产品名称",
      "P_YS_PF_WORKBENCH-FE_0001312270": "分页设置",
      "P_YS_PF_WORKBENCH-FE_1422621673865084940": "1. 个人中心升级为通栏形式，将拥有更多的便捷入口",
      "P_YS_FED_FW_0000022099": "小于",
      "P_YS_PF_WORKBENCH-FE_1420197852018638911": "建议图片尺寸750 * 88，大小不超2M，支持类型jpg、png。",
      "P_YS_PF_WORKBENCH-FE_1422621673865084946": "4. 工作台设计器优化，个人工作台设置按钮调整",
      "P_YS_PF_WORKBENCH-FE_1422621673865084943": "5. 角色工作台快捷菜单交互体验优化",
      "P_YS_PF_WORKBENCH-FE_0001301136": "颜色选择",
      "P_YS_PF_WORKBENCH-FE_1422621673865084949": "支持拖拽排序，可将三个常用工作台拖拽至虚线上方并常驻显示，实现快速切换",
      "P_YS_PF_WORKBENCH-FE_0001301137": "红色-#FF5735",
      "P_YS_PF_WORKBENCH-FE_0001301138": "蓝色-#588CE9",
      "P_YS_PF_WORKBENCH-FE_0001301139": "分组条件设置",
      "P_YS_PF_WORKBENCH-FE_0001312273": "/单据概要",
      "P_YS_PF_WORKBENCH-FE_0001312272": "按接收时间最晚",
      "P_YS_PF_WORKBENCH-FE_0001312271": "按接收时间最早",
      "P_YS_PF_WORKBENCH-FE_0001301140": "报表来源",
      "P_YS_OA_XTMENHUCMS_0000045100": "背景设置",
      "P_YS_PF_WORKBENCH-FE_0001301141": "指标卡设置",
      "P_YS_OA_XTMENHUCMS_0000045340": "最前",
      "P_YS_PF_WORKBENCH-FE_0001301142": "暂无指标",
      "P_YS_PF_WORKBENCH-FE_0001301143": "确认删除此条指标卡",
      "P_YS_FED_EXAMP_0000019931": "邮箱",
      "P_YS_PF_WORKBENCH-FE_0001312269": "/单据类型",
      "P_YS_PF_WORKBENCH-FE_0001312268": "选择过滤条件",
      "P_YS_IPAAS_UCG_0001064402": "房地产业",
      "P_YS_PF_WORKBENCH-FE_1420197852018638914": "主题颜色设置",
      "P_YS_PF_GZTSYS_0000012812": "待办",
      "P_YS_PF_WORKBENCH-FE_1420197852018638917": "建议图片尺寸750 * 88",
      "P_YS_OA_XTMENHUCMS_0000045344": "IOS跳转参数",
      "P_YS_PF_GZTSYS_0000012814": "团队",
      "000322": "请输入url地址",
      "P_YS_PF_WORKBENCH-FE_1422621673865084931": "2. 工作台切换处支持拖拽排序，前三个常驻显示，首个即为默认工作台",
      "P_YS_PF_WORKBENCH-FE_1422621673865084934": "3. 全局导航新增领域云并支持五级导航，支持抽屉形式展开收起",
      "P_YS_PF_WORKBENCH-FE_1420197852018638920": "小装饰修饰",
      "P_YS_YYGJFW_YBUC_0001337063": "显示图标",
      "P_YS_PF_WORKBENCH-FE_1422621673865084937": "定义“企业自定义”",
      "P_YS_FED_EXAMP_0000019939": "确定",
      "P_YS_IPAAS_UCG_0001064652": "制造业",
      "P_YS_PF_WORKBENCH-FE_1420197852018638926": "收藏和历史",
      "P_YS_IPAAS_UCG_0001064416": "环境和公共设施管理业、社会保障和社会组织",
      "P_YS_PF_WORKBENCH-FE_1420197852018638923": "允许用户修改",
      "P_YS_PF_WORKBENCH-FE_1420197852018638929": "商务蓝",
      "P_YS_PF_WORKBENCH-FE_1420197852018638932": "标签栏-默认logo",
      "000357": "删除文件",
      "P_YS_PF_WORKBENCH-FE_1422621673865084928": "自建子领域暂时统一归于“企业自定义”中，管理员可在“菜单管理”中找到该子领域，自主将其归类于某个特定的领域云中",
      "P_YS_PF_ANALYTIC-FE_0001652738": "编辑主题",
      "P_YS_OA_LCSJ_0001130313": "之前",
      "P_YS_PF_WORKBENCH-FE_0001301366": "用户社区",
      "P_YS_PF_WORKBENCH-FE_1420197852018638935": "纵向分割线",
      "P_YS_FI_IA_0000054929": "单据类型",
      "P_YS_PF_WORKBENCH-FE_1420197852018638938": "头部背景",
      "P_YS_HR_HRYGF_0000058823": "升级",
      "P_YS_PF_WORKBENCH-FE_1420197852018638944": "图标选中",
      "P_YS_PF_WORKBENCH-FE_1420197852018638941": "标题选中",
      "P_YS_PF_WORKBENCH-FE_0001312267": "/提交人",
      "P_YS_PF_WORKBENCH-FE_0001301129": "绿色-#18B681",
      "P_YS_PF_PC-UI_0000167466": "过滤区",
      "P_YS_PF_WORKBENCH-FE_0001274556": "”调用失败，DomID：",
      "P_YS_SD_UDHWN_0000122417": "白色",
      "P_YS_PF_WORKBENCH-FE_0001274555": "\"磁贴的渲染函数“",
      "P_YS_PF_WORKBENCH-FE_0001274554": "按提交时间最晚",
      "P_YS_PF_WORKBENCH-FE_0001301130": "请填写统计名称",
      "P_YS_PF_WORKBENCH-FE_0001274553": "磁贴的属性渲染失败“",
      "P_YS_PF_WORKBENCH-FE_0001301131": "单据选择",
      "P_YS_PF_AUTHF_0000096078": "20条/页",
      "P_YS_PF_WORKBENCH-FE_0001274552": "请输入最大显示数量",
      "P_YS_PF_WORKBENCH-FE_0001301132": "深灰色-#333333",
      "P_YS_PF_WORKBENCH-FE_0001274551": "请输入请求地址",
      "P_YS_PF_WORKBENCH-FE_0001301133": "统计名称",
      "P_YS_IPAAS_UCG_0001064631": "科学研究、技术服务和地质勘查业",
      "P_YS_PF_WORKBENCH-FE_0001274550": "按单据类型",
      "P_YS_PF_WORKBENCH-FE_0001301134": "黄色-#FFA600",
      "P_YS_PF_WORKBENCH-FE_0001301135": "公告通知历史",
      "P_YS_SD_SDSD-FE_0001301389": "企业自定义",
      "P_YS_PF_WORKBENCH-FE_1420197852018638947": "Logo更换",
      "P_YS_PF_WORKBENCH-FE_0001238259": "待办通知",
      "P_YS_PF_WORKBENCH-FE_0001274558": "接口返回格式",
      "P_YS_OA_XTMENHUCMS_0000045311": "统一审批中心",
      "P_YS_PF_WORKBENCH-FE_0001274557": "请输入内容名称",
      "P_YS_PF_WORKBENCH-FE_0001133399": "移交团队后您将不再有管理员权限。",
      "P_YS_PF_WORKBENCH-FE_0001133397": "链接复制成功，赶快发送给你的小伙伴吧!",
      "P_YS_PF_WORKBENCH-FE_0001133398": "数字化工作入口",
      "country84": "越南",
      "P_YS_OA_XTTMCENTER_0000035122": "管理员",
      "P_YS_OA_XTMENHUCMS_0000045143": "快捷应用",
      "P_YS_OA_XTMENHUCMS_0000045382": "Android下载地址",
      "P_YS_OA_XTMENHUCMS_0000045140": "流式布局",
      "P_YS_FED_IMP_IOT0000011425": "關閉",
      "P_YS_SD_SDMA_0000060355": "帮助中心",
      "P_YS_IPAAS_UCG_0001064207": "居民服务和其他服务业",
      "P_YS_FI_CM_0000029021": "跳至",
      "P_YS_FED_UCFBASEDOC_0000025096": "您确认要删除服务",
      "country95": "缅甸",
      "P_YS_SCM_PU_0000028725": "普通",
      "P_YS_OA_XTMENHUCMS_0000045376": "服务器地址",
      "P_YS_FED_FW_0000020955": "停用",
      "P_YS_OA_XTMENHUCMS_0000045375": "移动端主页",
      "P_YS_PF_GZTSYS_0000012860": "动态",
      "P_YS_PF_WORKBENCH-FE_0001663980": "切换布局类型，则画布中已存在的磁贴会丢失，您确定要切换吗？",
      "P_YS_PF_WORKBENCH-FE_0001663981": "页签栏设置",
      "P_YS_FI_CM_0000030025": "到期时间",
      "P_YS_FED_EXAMP_0000019906": "更多",
      "P_YS_PF_WORKBENCH-FE_0001217140": "请至少选择一个客户端",
      "P_YS_OA_XTMENHUCMS_0000045121": "暂无搜索结果",
      "P_YS_OA_XTMENHUCMS_0000045362": "Android跳转参数",
      "P_YS_PF_WORKBENCH-FE_0001663979": "当前页面为",
      "P_YS_OA_XTTMCENTER_0000035140": "退出",
      "P_YS_PF_WORKBENCH-FE_0001625479": "取消设置",
      "P_YS_PF_WORKBENCH-FE_0001663975": "固定宽度（1400px）",
      "P_YS_PF_WORKBENCH-FE_0001663976": "组件间距",
      "P_YS_PF_WORKBENCH-FE_0001663977": "画布设置",
      "P_YS_PF_WORKBENCH-FE_0001663978": "组件头部设置",
      "P_YS_HR_HRJQ_0000031030": "最后",
      "P_YS_OA_XTMENHUCMS_0000045366": "在当前页面打开",
      "P_YS_FED_UCFBASEDOC_0000025079": "发布失败",
      "P_YS_PF_WORKBENCH-FE_1422621673865084952": "6. 支持企业自建场景化APP并绑定至指定工作台",
      "P_YS_SCM_PU_0000028707": "商家",
      "P_YS_SD_UDHWN_0000122693": "最近一周",
      "P_YS_PF_WORKBENCH-FE_0001565488": "青啤绿",
      "P_YS_PF_WORKBENCH-FE_0001625482": "工作台版本",
      "P_YS_OA_XTMENHUCMS_0000045353": "IOS下载地址",
      "P_YS_PF_WORKBENCH-FE_0001625481": "支持个人工作台",
      "P_YS_OA_XTMENHUCMS_0000045352": "发布客户端",
      "P_YS_PF_WORKBENCH-FE_0001625484": "｜正在编辑：",
      "P_YS_OA_XTMENHUCMS_0000045351": "请输入应用名称",
      "P_YS_PF_WORKBENCH-FE_0001625483": "工作台设计器",
      "P_YS_PF_GZTSYS_0000011990": "门户页面管理",
      "P_YS_PF_WORKBENCH-FE_0001625480": "默认业务单元",
      "P_YS_PF_WORKBENCH-FE_1420197852018638902": "拖动至第一位则为默认工作台",
      "P_YS_OA_XTMENHUCMS_0000045359": "在新页面打开",
      "P_YS_PF_WORKBENCH-FE_1420197852018638908": "选中主题不允许停用",
      "P_YS_PF_WORKBENCH-FE_0001625485": "搜索组件名称",
      "P_YS_PF_WORKBENCH-FE_1420197852018638905": "背景类型",
      "P_YS_PF_WORKBENCH-FE_0001229381": "选择磁贴",
      "P_YS_PF_WORKBENCH-FE_0001229380": "拖拽进行排序",
      "P_YS_PF_WORKBENCH-FE_0001229385": "建议为87的整数倍",
      "P_YS_PF_WORKBENCH-FE_0001229384": "自定义高度",
      "P_YS_PF_WORKBENCH-FE_0001229383": "内容设置",
      "P_YS_PF_WORKBENCH-FE_0001229382": "富文本内容设置",
      "P_YS_OA_XTLCZX_0000030376": "更换",
      "P_YS_PF_WORKBENCH-FE_0001229388": "显示姓名",
      "P_YS_PF_WORKBENCH-FE_0001229387": "显示通知",
      "P_YS_PF_WORKBENCH-FE_0001229386": "组件高度",
      "P_YS_PF_GZTSYS_0000013194": "工作台",
      "P_YS_PF_GZTSYS_0001025942": "门户广告位设置",
      "P_YS_IPAAS_UCG_0001064361": "农、林、牧、渔业",
      "P_YS_OA_XTTXL_0000045990": "管理",
      "P_YS_OA_XTSPZX_0000034652": "排序",
      "P_YS_FED_UCFBASEDOC_0000025619": "添加文件夹",
      "P_YS_IPAAS_UCG_0001064369": "国际组织",
      "P_YS_FED_FW_0000022488": "本月",
      "P_YS_FED_UCFBASEDOC_0000025614": "手机",
      "P_YS_FED_UCFORG_0000066189": "显示内容",
      "P_YS_OA_XTLCZX_0000030141": "选择布局",
      "P_YS_PF_WORKBENCH-FE_0001229374": "广告位资源可在“门户广告位设置”中设置",
      "P_YS_PF_WORKBENCH-FE_0001229373": "存在多张广告图时，设定图片播放间隔",
      "P_YS_PF_WORKBENCH-FE_0001229377": "是否启用自定义",
      "P_YS_PF_WORKBENCH-FE_0001229376": "关联广告",
      "P_YS_PF_WORKBENCH-FE_0001229375": "图片轮播间隔",
      "P_YS_PF_WORKBENCH-FE_0001229379": "搜索应用名称",
      "P_YS_OA_XTMENHUCMS_0000045174": "广告位",
      "P_YS_IPAAS_UCG_0001064138": "电力、热力、燃气及水的生产和供应业",
      "P_YS_FED_UCFBASEDOC_0000024755": "删除文件夹",
      "P_YS_FED_UCFBASEDOC_0000025601": "请输入邮箱",
      "P_YS_FED_UCFBASEDOC_0000024752": "您确认要删除此项服务？",
      "P_YS_SD_SDMAUF_0000152315": "之后",
      "P_YS_PF_GZTLOG_0000025988": "解锁",
      "P_YS_FED_UCFBASEDOC_0000024748": "您确认要批量删除吗？",
      "P_YS_FED_FW_0000022466": "默认",
      "P_YS_FED_FW_0000021376": "中国",
      "P_YS_OA_XTSPZX_0000034639": "领域",
      "P_YS_FED_FW_0000021379": "北京",
      "P_YS_FED_UCFBASEDOC_0000024982": "不保存",
      "P_YS_OA_XTMENHUCMS_0000045168": "消息中心",
      "P_YS_SCM_STOCK_0000034455": "未读",
      "P_YS_PF_WORKBENCH-FE_0001241019": "工作台",
      "P_YS_PF_ANALYTIC-FE_0001651354": "表格设置",
      "P_YS_FED_FW_0000022483": "：",
      "P_YS_OA_XTTMCENTER_0000035108": "用友云市场",
      "P_YS_PF_MANAGER-FE_0001120439": "错误的参数：applicationId",
      "P_YS_PF_WORKBENCH-FE_0001302733": "过滤区渲染失败",
      "P_YS_PF_GZTLOG_0000025976": "锁定",
      "P_YS_PF_GZTSYS_0000012092": "租户升级",
      "P_YS_PF_GZTLOG_0000025975": "自定义",
      "P_YS_FED_FW_0000022475": "所属行业",
      "P_YS_OA_XTMENHUCMS_0000045158": "工作门户",
      "P_YS_FI_CM_0000029097": "今天",
      "P_YS_PF_WORKBENCH-FE_1420197852018638950": "更多个性化设置",
      "P_YS_FI_FA_0000056249": "新增卡片",
      "P_YS_OA_XTLCZX_0000030333": "接收时间",
      "P_YS_PF_WORKBENCH-FE_1420197852018638953": "文字选中",
      "P_YS_FED_FW_0000022290": "小于等于",
      "P_YS_YYGJFW_YYSJQ-FE_0001661128": "颜色背景",
      "P_YS_PF_WORKBENCH-FE_1420197852018638956": "logo更换",
      "P_YS_FED_UCFBASEDOC_0000024960": "发布",
      "P_YS_FED_FW_0000022064": "暂无数据",
      "P_YS_PF_WORKBENCH-FE_0001202943": "固定导航",
      "P_YS_PF_WORKBENCH-FE_0001202944": "菜单锁定",
      "P_YS_PF_WORKBENCH-FE_0001202945": "导航切换",
      "P_YS_PF_WORKBENCH-FE_0001202946": "解除固定",
      "P_YS_PF_WORKBENCH-FE_0001202941": "快捷导航",
      "P_YS_PF_WORKBENCH-FE_0001202942": "全局导航",
      "P_YS_SCM_USTOCK-UI_0000173476": "业务设置",
      "country886": "中国台湾",
      "P_YS_FI_FCWEB_0001048065": "工作台设置",
      "P_YS_OA_XTSPZX_0000034846": "展開",
      "P_YS_SD_SDMB_0000146348": "服务支持",
      "P_YS_PF_WORKBENCH-FE_0001202947": "快捷导航设置",
      "P_YS_FED_UCFBASEDOC_0000024951": "无内容的分组在首页不可见",
      "P_YS_PF_GZTSYS_0000012902": "其它",
      "P_YS_PF_PROCENTER_0000022591": "校验失败",
      "P_YS_PF_WORKBENCH-FE_0001212762": "快捷搜索",
      "P_YS_OA_XTLCZX_0000030351": "显示",
      "P_YS_FED_FW_0000022274": "研发中心",
      "P_YS_SD_SDMAUF_0000152518": "还有",
      "P_YS_PF_WORKBENCH-FE_0001153504": "不再提醒",
      "P_YS_SD_SDMB_0000138761": "经典蓝",
      "P_YS_PF_WORKBENCH-FE_0001137268": "忽略，不再提醒",
      "P_YS_FED_UCFBASEDOC_0000024702": "请选择单据",
      "P_YS_FED_UCFBASEDOC_0000024942": "添加",
      "P_YS_OA_XTLCZX_0000030589": "颜色",
      "P_YS_OA_app_xtyyjm_0000035755": "待审批",
      "P_YS_OA_XTSPZX_0000034819": "確認",
      "P_YS_PF_YHTT_0001204706": "我的语言",
      "P_YS_FED_EXAMP_0000020075": "操作成功",
      "P_YS_FED_UCFBASEDOC_0000024938": "删除服务",
      "P_YS_FED_UCFBASEDOC_0000024930": "请输入联系人姓名",
      "P_YS_OA_app_xtyyjm_0000035750": "成员",
      "P_YS_PF_WORKBENCH-FE_0001212760": "”相关结果，请重新输入",
      "P_YS_PF_WORKBENCH-FE_0001212761": "未搜索到“",
      "P_YS_SD_SDMB_0000139191": "消息详情",
      "P_YS_PF_MANAGER-FE_0001087832": "申请权限",
      "P_YS_OA_XTMENHUCMS_0000044935": "发言",
      "P_YS_PF_GZTSYS_0000109255": "获取验证码失败",
      "P_YS_OA_XTMENHUCMS_0000044934": "长广告位",
      "P_YS_OA_YBMOB_0000036991": "审批详情",
      "P_YS_SD_SDMA_0000123136": "主页",
      "P_YS_PF_MANAGER-FE_0001087834": "请输入在友空间APP打开时跳转地址",
      "P_YS_PF_MANAGER-FE_0001087836": "请输入IOS下载地址",
      "P_YS_FED_EXAMP_0000020236": "复制链接",
      "P_YS_OA_XTMENHU_0000046620": "链接名称",
      "P_YS_OA_app_xtywlj_0000037874": "属性设置",
      "P_YS_SD_UDHMC_0000051143": "请输入企业名称",
      "P_YS_OA_XTMENHUCMS_0000044932": "活力橙",
      "P_YS_FED_UCFBASEDOC_0000025215": "登录",
      "P_YS_OA_app_xtywlj_0000037879": "所属分类",
      "P_YS_PF_GZTSYS_0001127465": "仪表板",
      "P_YS_PF_MANAGER-FE_0001087842": "跳转参数",
      "P_YS_PF_MANAGER-FE_0001087841": "全员邀请",
      "P_YS_OA_XTMENHUCMS_0000044929": "主题设置",
      "P_YS_PF_MANAGER-FE_0001087844": "请选择是否原生",
      "P_YS_PF_MANAGER-FE_0001087843": "请输入上传到iUAP平台的安装包ID",
      "P_YS_FED_FW_0000055346": "请选择数据",
      "P_YS_PF_MANAGER-FE_0001087840": "Web端主页",
      "P_YS_FI_CM_0000029500": "页",
      "P_YS_FI_FP_0000034914": "本年",
      "P_YS_PF_MANAGER-FE_0001087846": "是否离线应用",
      "P_YS_PF_MANAGER-FE_0001087848": "邀请规则",
      "P_YS_PF_MANAGER-FE_0001087847": "请输入在Web浏览器打开时跳转地址",
      "P_YS_FED_EXAMP_0000020005": "模板",
      "P_YS_FED_EXAMP_0000020004": "共",
      "P_YS_FI_CM_0000028899": "退出登录",
      "P_YS_OA_XTLCZX_0000030776": "正常",
      "P_YS_FED_UCFBASEDOC_0000025203": "获取验证码",
      "P_YS_PF_WORKBENCH-FE_0001233465": "开始了解",
      "P_YS_OA_XTMENHU_0000046618": "我的项目",
      "P_YS_FED_IMP_IOT_0000724713": "查詢",
      "P_YS_FED_UCFBASEDOC_0000025200": "移动",
      "P_YS_PF_MANAGER-FE_0001087852": "请上传服务图标",
      "P_YS_HR_HRSBF_0001183870": "个人设置",
      "P_YS_OA_XTLCZX_0000030555": "平铺",
      "P_YS_PF_WORKBENCH-FE_0001232171": "剩余数",
      "P_YS_HR_HRJQ_0000030940": "补考勤",
      "P_YS_PF_WORKBENCH-FE_0001232170": "更早",
      "P_YS_PF_MANAGER-FE_0001087858": "置顶优先级",
      "P_YS_FED_EXAMP_0000020016": "首页",
      "P_YS_OA_XTMENHU_0000046646": "链接邀请",
      "P_YS_SD_SDMA_0000098308": "页面设置",
      "P_YS_PF_WORKBENCH-FE_0001245211": "左右排列",
      "P_YS_PF_GZTSYS_0000013325": "标签",
      "P_YS_OA_XTMENHU_0000046644": "空间管理",
      "P_YS_PF_METADATA_0000085782": "产品编码",
      "P_YS_OA_app_xtyyjm_0000035782": "选择报表",
      "P_YS_PF_WORKBENCH-FE_0001232168": "获取数据异常，请刷新",
      "P_YS_FED_FW_0000021777": "不包含",
      "P_YS_OA_app_xtywlj_0000037618": "业务",
      "P_YS_PF_WORKBENCH-FE_0001232169": "占用率",
      "P_YS_PF_WORKBENCH-FE_0001232166": "授权占用明细",
      "P_YS_IMP_DFM-UI_0001273087": "宽松",
      "P_YS_PF_WORKBENCH-FE_0001232167": "授权数",
      "P_YS_PF_WORKBENCH-FE_0001232164": "消息分类",
      "P_YS_PF_MANAGER-FE_0001087863": "相关操作",
      "P_YS_PF_WORKBENCH-FE_0001133309": "系统：仅企业账号管理员可见",
      "P_YS_PF_WORKBENCH-FE_0001232165": "最近三天",
      "P_YS_PF_MANAGER-FE_0001087865": "pc客户端内",
      "P_YS_PF_WORKBENCH-FE_0001232163": "部署费",
      "P_YS_SD_SDMAUF_0000151876": "请填写",
      "P_YS_PF_MANAGER-FE_0001087868": "请选择所属行业",
      "P_YS_PF_MANAGER-FE_0001087867": "图标尺寸须为120*120，且不大于5M！",
      "P_YS_PF_WORKBENCH-FE_0001245212": "上下排列",
      "P_YS_OA_app_xtywlj_0001081532": "来源租户",
      "P_YS_SD_SDMB-UI_0001160783": "显示头像",
      "P_YS_PF_WORKBENCH-FE_0001145762": "已知晓，不再提示",
      "P_YS_FED_FW_0000021785": "包含",
      "P_YS_PF_WORKBENCH-FE_0001145764": "您购买的产品即将过期",
      "P_YS_PF_WORKBENCH-FE_0001145763": "您购买的产品已经到期",
      "P_YS_SD_SDMB_0000146962": "返回顶部",
      "P_YS_PF_WORKBENCH-FE_0001145765": "立即查看",
      "P_YS_PF_MANAGER-FE_0001087877": "中国香港特别行政区",
      "P_YS_PF_MANAGER-FE_0001087870": "规模范围",
      "P_YS_PF_MANAGER-FE_0001087873": "禁止邀请",
      "P_YS_FI_CM_0000029930": "我知道了",
      "P_YS_PF_MANAGER-FE_0001087872": "不能多于6个汉字或者12个字母",
      "P_YS_PF_PROCENTER_0000023062": "其他",
      "P_YS_HR_HRPXF_0000055999": "发荣耀",
      "P_YS_OA_XTSPZX_0000034691": "提交人",
      "OA_web_footer_000000023": "京公网安备 11010802021935号",
      "P_YS_FED_UCFBASEDOC_0000025417": "重命名文件夹",
      "P_YS_FED_FW_0000022200": "当前",
      "P_YS_PF_PROCENTER_0000026339": "联系人信息",
      "P_YS_HR_HRJQF_0000054222": "日期范围",
      "P_YS_FED_FW_0000022205": "下移",
      "P_YS_PF_MANAGER-FE_0001087885": "企业头像",
      "P_YS_PF_MANAGER-FE_0001087882": "下载地址",
      "P_YS_PF_MANAGER-FE_0001087884": "禁止",
      "P_YS_PF_MANAGER-FE_0001087883": "推荐到移动端首页",
      "P_YS_SD_SDMAUF_0000151808": "∨",
      "P_YS_PF_METADATA_0000084287": "占用数",
      "P_YS_PF_WORKBENCH-FE_0001371281": "绑定服务",
      "P_YS_OA_XTSPZX_0000034688": "数据来源",
      "P_YS_FED_EXAMP_0000020207": "通过",
      "P_YS_FED_FW_0000022215": "分组",
      "P_YS_FED_UCFBASEDOC_0000025400": "您确认要删除此项？",
      "P_YS_PF_MANAGER-FE_0001087899": "上传图标失败！",
      "P_YS_PF_MANAGER-FE_0001087893": "最多60个字符",
      "P_YS_PF_MANAGER-FE_0001087895": "集成免登策略",
      "P_YS_OA_XTMENHU_0000046601": "最多添加19个应用",
      "P_YS_OA_XTSPZX_0000034674": "添加应用",
      "P_YS_OA_XTMENHU_0000046840": "暂无记录，快去手机端打卡吧~",
      "P_YS_PF_GZTLOG_0000028817": "请求失败",
      "P_YS_OA_XTSPZX_0000034678": "企业账号",
      "P_YS_PF_MANAGER-FE_0001087891": "根据组织机构负责人显示上下级",
      "P_YS_OA_XTRIZHI_0000036793": "取消编辑",
      "P_YS_OA_XTMENHU_0000046605": "待办审批",
      "P_YS_PF_GZTTMP_0000079164": "消息状态",
      "P_YS_YYGJFW_YYSJQ-FE_0001661175": "图片背景",
      "P_YS_PF_METADATA_0000088627": "问题反馈",
      "P_YS_OA_XTSPZX_0000034661": "移动端",
      "P_YS_PF_WORKBENCH-FE_0001204986": "请输入服务名称(最多15个汉字)",
      "P_YS_FED_EXAMP_0000020462": "清空",
      "P_YS_FI_GL_0000088728": "允许",
      "P_YS_FED_FW_0001097161": "页面中有未保存的数据，点击确定后将关闭页面，是否继续？",
      "P_YS_PF_WORKBENCH-FE_0001620597": "请检查页面中有无未保存的数据",
      "P_YS_PF_WORKBENCH-FE_0001620596": "点击确定后将关闭页面，是否继续？",
      "P_YS_SD_SDMAF_0000136493": "发邮件",
      "P_YS_PF_MES-FE_0001269113": "单据标题",
      "P_YS_SD_SDMAUF_0000153186": "企业地址",
      "P_YS_OA_XTMENHU_0000046582": "最晚打卡：",
      "P_YS_SD_SDMA-UI_0001140473": "组件链接",
      "P_YS_FI_ETL_0000111653": "本地",
      "P_YS_PF_WORKBENCH-FE_0001233073": "菜单结构有所改动",
      "P_YS_PF_WORKBENCH-FE_0001233072": "切换旧版",
      "P_YS_PF_WORKBENCH-FE_0001233071": "新菜单",
      "P_YS_PF_WORKBENCH-FE_0001233070": "个性化配置与租户切换",
      "P_YS_FED_FW_0000021969": "上传图片",
      "P_YS_FED_IMP_IOT0000005442": "删除成功",
      "P_YS_OA_XTMENHU_0000046575": "组件地址",
      "P_YS_PF_PRINT_0000072986": "暂无相关内容",
      "P_YS_PF_GUIDE_0001071542": "必须是一个图片",
      "P_YS_PF_YS-LOGIN_0001079584": "你点击了这个图标；调用参数为：",
      "P_YS_PF_WORKBENCH-FE_0001233069": "此处可进行菜单的切换",
      "P_YS_PF_WORKBENCH-FE_0001233068": "租户切换",
      "P_YS_FED_UCFBASEDOC_0000025165": "添加分组",
      "P_YS_PF_WORKBENCH-FE_1421570875756380169": "导航菜单添加领域云概念，原有一级改为子领域，分别置于领域云下；领域云下含有多个子领域，可以抽屉形式进行收起",
      "P_YS_FED_UCFBASEDOC_0000025164": "拖动下方磁贴至右侧所需位置",
      "P_YS_PES_PES-FE_0001108409": "用友红",
      "P_YS_SD_SDMAF_0000137369": "自适应",
      "P_YS_PF_WORKBENCH-FE_1421570875756380163": "个人中心体验提升",
      "P_YS_PF_WORKBENCH-FE_1421570875756380166": "定义“未分组”",
      "P_YS_OA_XTLCZX_0000030707": "同意",
      "P_YS_PF_WORKBENCH-FE_1421570875756380160": "工作台切换体验优化",
      "P_YS_FED_IMP_IOT0000008702": "複製",
      "P_YS_FED_IMP_IOT_0000129791": "功能名称",
      "P_YS_PF_GZTTMP_0000079071": "单据时间",
      "P_YS_PF_MES-FE_0001269123": "单据概要",
      "P_YS_PF_MES-FE_0001269122": "接口错误",
      "P_YS_PF_WORKBENCH-FE_1421570875756380175": "功能位置调整",
      "P_YS_PF_WORKBENCH-FE_1421570875756380172": "定义全局菜单级次",
      "P_YS_PF_WORKBENCH-FE_1421570875756380178": "个人中心重新设计，为通顶形式，添加更多个人用户的配置项",
      "P_YS_HR_HRCOREF_0000058490": "下载文件",
      "P_YS_PF_GZTSYS_0000012764": "审批中心",
      "P_YS_PF_PFRPT_0001631363": "标题文字",
      "P_YS_FED_IMP_IOT0000005424": "编辑",
      "P_YS_FED_EXAMP_0000020660": "我的",
      "P_YS_PF_AUTHF_0000096113": "5条/页",
      "P_YS_FED_FW_0000021948": "分类",
      "P_YS_FED_IMP_IOT0000006999": "默认筛选",
      "P_YS_FED_FW_0000177597": "立即升级",
      "P_YS_OA_XTMENHUCMS_0000044985": "组件",
      "P_YS_PF_WORKBENCH-FE_0001133438": "用友云，让我的工作越来越有章法",
      "P_YS_PF_WORKBENCH-FE_0001133439": "菜单入口：",
      "P_YS_PF_WORKBENCH-FE_0001133432": "“我是一名IT运维经理”",
      "P_YS_FI_FCWEB_0001048519": "最近使用",
      "P_YS_PF_WORKBENCH-FE_0001133433": "未购买开通任何服务",
      "P_YS_OA_XTMENHU_0000046780": "暂无审批",
      "P_YS_PF_WORKBENCH-FE_0001133430": "添加首页",
      "P_YS_PF_WORKBENCH-FE_0001133431": "缺少必须参数：serviceCode",
      "P_YS_OA_XTMENHU_0000046540": "文字模式",
      "P_YS_PF_WORKBENCH-FE_0001133436": "mdf正在加载中",
      "P_YS_PF_WORKBENCH-FE_0001133437": "专家团队上门",
      "P_YS_PF_WORKBENCH-FE_0001133434": "智能通讯",
      "P_YS_PF_WORKBENCH-FE_0001133435": "正在升级企业...",
      "P_YS_PF_GZTSYS_0000012336": "开放平台",
      "P_YS_FED_FW_0000021759": "标题",
      "P_YS_OA_XTTMCENTER_0000035088": "用友官网",
      "P_YS_SD_SDMB_0000146914": "北京总部",
      "P_YS_PF_WORKBENCH-FE_0001133440": "解散团队",
      "P_YS_OA_XTMENHU_0000046542": "模式选择",
      "P_YS_FED_FW_0000021994": "设为默认",
      "P_YS_PF_MANAGER-FE_0001087800": "移除成员",
      "P_YS_PF_WORKBENCH-FE_0001133449": "收起标签栏",
      "P_YS_PF_MANAGER-FE_0001087806": "浏览器新页",
      "P_YS_PF_WORKBENCH-FE_0001133443": "点击不保存，则最新修改将丢失",
      "P_YS_PF_WORKBENCH-FE_0001133444": "每到月底和年底，我们总是加班加点，才勉强完成 各种各样的财务报表",
      "P_YS_PF_WORKBENCH-FE_0001133441": "录入您的个人信息，方便我们与您取得联系",
      "P_YS_PF_MANAGER-FE_0001087807": "允许用户退出",
      "P_YS_PF_WORKBENCH-FE_0001133442": "返回官网",
      "P_YS_PF_WORKBENCH-FE_0001133447": "移除成功!",
      "P_YS_PF_WORKBENCH-FE_0001133448": "个人主页",
      "P_YS_PF_MANAGER-FE_0001087804": "服务名称不能多于6个汉字或者12个字母",
      "P_YS_PF_WORKBENCH-FE_0001133445": "您当前所在的网络环境可能使您无法访问帮助中心，请连接到公网再尝试。",
      "P_YS_PF_WORKBENCH-FE_0001133446": "在这里可以找到全部的功能",
      "P_YS_PF_GZTSYS_0000012589": "审批",
      "P_YS_FED_EXAMP_0000020600": "是",
      "P_YS_PF_WORKBENCH-FE_0001133450": "团队头像",
      "P_YS_PF_WORKBENCH-FE_0001133451": "确认移除所选用户?",
      "P_YS_OA_XTMENHU_0000046775": "选择好友",
      "P_YS_OA_XTMENHU_0000046539": "生日祝福",
      "P_YS_PF_MANAGER-FE_0001087811": ">2000人",
      "P_YS_HR_HRJXF_0000059541": "验证失败",
      "P_YS_PF_MANAGER-FE_0001087817": "请输入企业地址",
      "P_YS_PF_WORKBENCH-FE_0001133454": "上门演示",
      "P_YS_PF_WORKBENCH-FE_0001133452": "个应用即将到期，",
      "P_YS_PF_GZTPRE_0000071076": "所属应用",
      "P_YS_PF_WORKBENCH-FE_0001133453": "经营企业，我不再焦虑",
      "P_YS_SD_UDHWS_0000070064": "企业名称",
      "P_YS_PF_MANAGER-FE_0001087814": "是否使用原生导航",
      "P_YS_OA_XTMENHU_0000046569": "工作通知",
      "P_YS_PF_MANAGER-FE_0001087809": "中国澳门特别行政区",
      "P_YS_OA_XTWENKU_0000035585": "暫無數據",
      "P_YS_FED_FW_0000021973": "过滤条件",
      "P_YS_PF_MANAGER-FE_0001087820": "1001－2000",
      "P_YS_PF_MANAGER-FE_0001087827": "是否原生",
      "P_YS_PF_MANAGER-FE_0001087829": "app原生导航",
      "P_YS_PF_MANAGER-FE_0001087823": "选择默认图标",
      "P_YS_PF_GZTSYS_0001010812": "企业认证",
      "P_YS_OA_XTMENHU_0000046557": "未读微邮",
      "P_YS_PF_WORKBENCH-FE_0001291199": "生日之时不要忘记母亲，工作当中不要忘记朋友，烦恼之时多想想快乐之事。生日快乐，愿你日日都快乐！",
      "P_YS_PF_PRINT_0000106816": "垂直居中",
      "P_YS_OA_XTMENHU_0000046556": "请输入自定义组件的网络地址",
      "P_YS_FED_UCFBASEDOC_0000025589": "发布成功",
      "P_YS_FED_FW_0000021744": "大于等于",
      "P_YS_FED_UCFBASEDOC_0000025345": "移动到",
      "P_YS_SD_SDMBF_0000141689": "到期时间：",
      "P_YS_PF_ECON-FE_0001309288": "顯示",
      "P_YS_SCM_USTOCK-UI_0000173932": "本季",
      "P_YS_PF_WORKBENCH-FE_0001208723": "界面放大",
      "P_YS_PF_WORKBENCH-FE_0001228574": "友小秘",
      "P_YS_PF_WORKBENCH-FE_0001208722": "超大",
      "P_YS_SD_SDMAUF_0000153108": "云客服",
      "P_YS_OA_XTLCZX_0000030093": "提交时间",
      "P_YS_OA_XTTMCENTER_0000035047": "友空间",
      "P_YS_OA_XTTMCENTER_0000035043": "链接",
      "P_YS_SD_SDMBF_0000144965": "展示方式",
      "P_YS_SD_SDMBF_0000142527": "请点击",
      "P_YS_HR_HRJQ_0000031105": "未处理",
      "P_YS_PF_WORKBENCH-FE_0001228566": "广告详情",
      "P_YS_PF_WORKBENCH-FE_0001228565": "数字内容",
      "P_YS_OA_XTTMCENTER_0000035058": "用友云官网",
      "P_YS_PF_WORKBENCH-FE_0001274451": "sed 执行报错",
      "P_YS_PF_WORKBENCH-FE_0001274450": "临时文件夹位置",
      "P_YS_HR_HRRCPDF_0000071164": "标准",
      "P_YS_FED_FW_0000022192": "上移",
      "P_YS_PF_WORKBENCH-FE_0001228592": "大",
      "P_YS_FED_FW_0000022197": "展开",
      "P_YS_PF_WORKBENCH-FE_0001228597": "确定要恢复默认设置吗?",
      "P_YS_PF_WORKBENCH-FE_0001228599": "卡片编辑",
      "P_YS_PF_WORKBENCH-FE_0001228598": "恢复提示",
      "P_YS_OA_XTTMCENTER_0000035064": "下载",
      "P_YS_FED_EXAMP_0000019832": "、",
      "P_YS_OA_XTTMCENTER_0000035062": "客户端下载",
      "P_YS_OA_XTMENHUCMS_0000045206": "头部高度",
      "P_YS_PF_WORKBENCH-FE_0001274449": "删除目录",
      "P_YS_PF_WORKBENCH-FE_0001274448": "捕获多语地址",
      "P_YS_PF_WORKBENCH-FE_0001243713": "功能引导",
      "P_YS_PF_MANAGER-FE_0001116216": "是否全员可见",
      "P_YS_PF_METADATA_0000087187": "所属领域",
      "P_YS_PF_METADATA_0000086098": "用户信息",
      "P_YS_PF_WORKBENCH-FE_0001228589": "卡片属性设置",
      "P_YS_PF_SOCD-S_0000183662": "企业管理",
      "P_YS_SD_UDHMC_0000050938": "跳过",
      "P_YS_SD_UDHWN_0000121225": "最近一月",
      "P_YS_IPAAS_UCG_0001064325": "建筑业",
      "P_YS_PF_GZTSYS_0000011889": "荣耀",
      "P_YS_PF_WORKBENCH-FE_0001421901": "搜索人员、服务等",
      "P_YS_HR_HRJQ_0000031143": "无",
      "P_YS_OA_XTTXL_0000045969": "发消息",
      "P_YS_IPAAS_UCG_0001064571": "租赁和商务服务业",
      "P_YS_FED_FW_0000021923": "知道了",
      "P_YS_SD_UDHWN_0000120386": "立即申请",
      "P_YS_IPAAS_UCG_0001064578": "金融、保险业",
      "P_YS_PF_GZTSYS_0000012505": "基础设置",
      "P_YS_IPAAS_UCG_0001009417": "钉钉",
      "P_YS_PF_WORKBENCH-FE_0001232883": "切换新版",
      "P_YS_PF_WORKBENCH-FE_0001232882": "个人工作台",
      "P_YS_PF_WORKBENCH-FE_0001469084": "关闭右侧",
      "P_YS_PF_WORKBENCH-FE_0001473568": "%c磁贴运行出现错误:",
      "P_YS_OA_XTTMCENTER_0000035034": "联系我们",
      "P_YS_HR_HRRCPDF_0000071186": "用友网络科技股份有限公司",
      "P_YS_HR_HRPXF_0000056053": "团队名称",
      "P_YS_PF_MANAGER-FE_0001087797": "所有用户都可申请加入",
      "P_YS_PF_WORKBENCH-FE_1420197852018638872": "斑马线",
      "P_YS_PF_MANAGER-FE_0001087794": "姓名、手机号码、邮箱",
      "P_YS_PF_WORKBENCH-FE_1420197852018638878": "工作台编辑",
      "P_YS_PF_MANAGER-FE_0001087793": "通讯录显示水印",
      "P_YS_PF_MANAGER-FE_0001087796": "pcClient打开方式",
      "P_YS_OA_XTLCZX_0000030256": "抄送的",
      "P_YS_PF_MANAGER-FE_0001087795": "根据导入的上下级关系显示上下级",
      "P_YS_PF_WORKBENCH-FE_1420197852018638875": "建议图片尺寸50-100PX",
      "P_YS_SD_SDMAF_0001008026": "创建成功！",
      "P_YS_FED_FW_0000021036": "筛选",
      "P_YS_FED_IMP_IOT0000008165": "删除",
      "P_YS_IPAAS_UCG_0001064009": "住宿、餐饮业",
      "P_YS_FED_FW_0000022366": "修改成功！",
      "P_YS_DMP_DATAD-FE_0001665639": "新增主题",
      "P_YS_PF_WORKBENCH-FE_1420197852018638884": "推荐高度22px，默认为png格式",
      "P_YS_PF_WORKBENCH-FE_1420197852018638887": "马尔斯绿",
      "P_YS_PF_WORKBENCH-FE_1420197852018638881": "开发调试",
      "P_YS_IPAAS_UCG_0001064490": "文化、体育、娱乐业",
      "P_YS_IPAAS_UCG_0001064251": "卫生、社会保障和社会服务业",
      "P_YS_OA_XTMENHUCMS_0000045299": "渐变",
      "P_YS_OA_XTMENHUCMS_0000045298": "图片样式",
      "P_YS_OA_XTMENHUCMS_0001314169": "更多颜色",
      "P_YS_PF_WORKBENCH-FE_1420197852018638896": "磁贴设置",
      "P_YS_FED_UCFBASEDOC_0000024870": "查看详情",
      "P_YS_PF_WORKBENCH-FE_1420197852018638893": "#008c8c_马尔斯绿",
      "P_YS_PF_AUTHF_0000080113": "10条/页",
      "P_YS_PF_WORKBENCH-FE_1420197852018638899": "请输入查询条件 支持首字母与拼音",
      "P_YS_PF_WORKBENCH-FE_0001473777": "选择分析卡片",
      "P_YS_FED_FW_0000021020": "新增",
      "P_YS_OA_XTMENHUCMS_0000045290": "背景颜色",
      "P_YS_PF_WORKBENCH-FE_0001413028": "磁贴样式报错,对应磁贴为:",
      "P_YS_PF_WORKBENCH-FE_0001413029": "纵向排列",
      "P_YS_PF_WORKBENCH-FE_1420197852018638890": "当前底色",
      "P_YS_PF_WORKBENCH-FE_0001413035": "使用默认样式",
      "P_YS_OA_XTMENHUCMS_0000045287": "常用应用",
      "P_YS_PF_WORKBENCH-FE_0001413036": "不显示图标",
      "P_YS_PF_WORKBENCH-FE_0001413037": "链接-新增",
      "P_YS_PF_WORKBENCH-FE_0001413038": "填充方式",
      "P_YS_OA_XTMENHUCMS_0000045285": "文字颜色",
      "P_YS_OA_XTMENHUCMS_0000045284": "图标大小设置",
      "P_YS_PF_WORKBENCH-FE_0001413031": "表格排列",
      "P_YS_OA_XTMENHUCMS_0000045041": "组件名称",
      "P_YS_PF_WORKBENCH-FE_0001413032": "设置网址LOGO",
      "P_YS_PF_WORKBENCH-FE_0001413033": "自定义图片样式",
      "P_YS_PF_WORKBENCH-FE_0001413034": "自定义颜色",
      "P_YS_PF_GZTLOG_0000080270": "Web端",
      "P_YS_FED_UCFBASEDOC_0000024866": "是否保存最新修改？",
      "P_YS_FED_FW_0000021014": "启用",
      "P_YS_PF_WORKBENCH-FE_0001413030": "图片透明度",
      "P_YS_PLM_GDS-FE_0001246638": "文件上传中",
      "P_YS_PF_WORKBENCH-FE_0001537178": "2017年3月15日",
      "P_YS_PF_WORKBENCH-FE_0001537177": "帮助中心问号",
      "P_YS_PF_WORKBENCH-FE_0001537176": "问号旁的倒三角",
      "P_YS_SD_SDMBF_0000144334": "请上传图片",
      "P_YS_FED_EXAMP_0000020397": "姓名",
      "P_YS_OA_XTLCZX_0000030206": "隐藏",
      "P_YS_FED_FW_0000022168": "请输入关键字",
      "P_YS_PF_ECON-FE_0001309239": "條",
      "P_YS_PF_AUTHF_0000080120": "15条/页",
      "P_YS_OA_XTLCZX_0000030221": "系统",
      "P_YS_IPAAS_UCG_0001064293": "批发和零售业",
      "P_YS_OA_XTMENHUCMS_0001320647": "预览-",
      "P_YS_PF_WORKBENCH-FE_1420197852018638848": "紧凑",
      "P_YS_PF_GZTSYS_0000011939": "我的门户管理",
      "P_YS_FED_FW_0000022179": "对齐方式",
      "P_YS_PF_ECON-FE_0001309228": "頁",
      "P_YS_OA_XTLCZX_0000030219": "搜索结果",
      "P_YS_SD_SDMAUF_0000152873": "历史记录",
      "P_YS_PF_WORKBENCH-FE_1420197852018638851": "标签栏-反白logo",
      "P_YS_SCM_STOCK_0000034523": "已读",
      "P_YS_PF_WORKBENCH-FE_1420197852018638854": "拖至虚线上方可固定至标题区",
      "P_YS_FED_EXAMP_0000020183": "关闭",
      "P_YS_OA_XTMENHUCMS_0001314180": "您当前处于预览状态",
      "P_YS_PF_WORKBENCH-FE_0001480260": "上新回顾",
      "P_YS_FED_UCFBASEDOC_0000024820": "到",
      "P_YS_PF_WORKBENCH-FE_1420197852018638857": "oppo绿",
      "P_YS_PF_WORKBENCH-FE_1420197852018638863": "舒眼绿",
      "P_YS_PF_WORKBENCH-FE_1420197852018638860": "风格设置",
      "P_YS_PF_WORKBENCH-FE_1420197852018638866": "建议图片尺寸0-10",
      "P_YS_OA_XTLCZX_0000030480": "小",
      "P_YS_OA_XTMENHUCMS_0000045079": "自由布局",
      "P_YS_FED_EXAMP_0000020188": "驳回",
      "P_YS_OA_XTLCZX_0000030238": "背景图片",
      "P_YS_PF_WORKBENCH-FE_0001561051": "url地址",
      "P_YS_PF_WORKBENCH-FE_1420197852018638869": "标签区设置",
      "P_YS_PF_WORKBENCH-FE_0001561052": "请输入独立APPurl",
      "P_YS_PF_WORKBENCH-FE_0001561050": "独立APP",
      "P_YS_PF_WORKBENCH-FE_0001233364": "工作台个性化配置能力增强",
      "P_YS_PF_WORKBENCH-FE_0001300847": "待办任务显示方式",
      "P_YS_PF_WORKBENCH-FE_0001233363": "支持更多磁贴、更灵活布局、更丰富的展示效果",
      "P_YS_PF_WORKBENCH-FE_0001300848": "审批中心按钮（同意）",
      "P_YS_PF_WORKBENCH-FE_0001233362": "门户合并到工作台啦，支持多工作台切换，角色化工作台帮助您更好完成工作任务",
      "P_YS_PF_WORKBENCH-FE_0001300849": "预警和通知",
      "P_YS_PF_WORKBENCH-FE_0001233361": "企业帐号换位置啦",
      "P_YS_SD_UDHWN_0000049711": "主题颜色",
      "P_YS_FED_UCFBASEDOC_0000025581": "重命名分组",
      "P_YS_PF_WORKBENCH-FE_0001233360": "数字化工作入口升级啦",
      "P_YS_PLM_BASE-FE_0001160596": "顶部对齐",
      "P_YS_PF_MANAGER-FE_0001087957": "请输入Android下载地址",
      "P_YS_FED_EXAMP_0000020115": "切换",
      "P_YS_OA_XTMENHU_0000046746": "预警通知",
      "P_YS_OA_XTMENHU_0000046742": "考勤审批",
      "P_YS_PF_WORKBENCH-FE_0001233359": "菜单导航优化",
      "P_YS_PF_WORKBENCH-FE_0001233358": "企业帐号转移到用户下拉面板啦",
      "P_YS_PF_WORKBENCH-FE_0001470672": "添加快捷应用",
      "P_YS_PF_WORKBENCH-FE_0001233357": "工作台配置能力更强，菜单导航更高效",
      "P_YS_PF_WORKBENCH-FE_0001233356": "支持多工作台",
      "P_YS_PF_PTDESIGN-FE_0001184496": "边框圆角",
      "P_YS_PF_WORKBENCH-FE_0001233355": "全新的菜单面板，新增搜索功能，支持快速收藏和查看最近使用",
      "P_YS_PF_WORKBENCH-FE_0001133407": "全部历史",
      "P_YS_PF_WORKBENCH-FE_0001133408": "用友云，完全公有云化的企业服务，让我从传统的运维 工作解放出来，成为公司数据资产的建设者",
      "P_YS_PF_WORKBENCH-FE_0001133405": "开启录制",
      "P_YS_PF_MANAGER-FE_0001087964": "PC客户端主页",
      "P_YS_PF_WORKBENCH-FE_0001133406": "搜索人员信息、服务及其他内容",
      "P_YS_OA_XTWEIYOU_0000046270": "同时发送邮件",
      "P_YS_FED_FW_0000021451": "（",
      "P_YS_PF_MANAGER-FE_0001087961": "是否常用应用",
      "P_YS_PF_WORKBENCH-FE_0001133409": "移除首页",
      "P_YS_PF_WORKBENCH-FE_0001300850": "列表内容配置",
      "P_YS_PF_WORKBENCH-FE_0001133400": "个应用已到期，",
      "P_YS_PF_WORKBENCH-FE_0001300851": "审批中心按钮（驳回）",
      "P_YS_PF_WORKBENCH-FE_0001300852": "按领域应用",
      "P_YS_PF_WORKBENCH-FE_0001133403": "团队设置",
      "P_YS_PF_MANAGER-FE_0001087966": "上下级显示",
      "P_YS_PF_WORKBENCH-FE_0001133404": "让我从很多重复性的事务工作中解放出来",
      "P_YS_PF_WORKBENCH-FE_0001133401": "背景色定义",
      "P_YS_PF_WORKBENCH-FE_0001133402": "更多结果",
      "P_YS_PF_WORKBENCH-FE_0001242095": "还未添加收藏,快去收藏吧",
      "P_YS_PF_WORKBENCH-FE_0001242096": "暂不了解",
      "P_YS_PF_GZTSYS_0000013477": "保存",
      "P_YS_PF_METADATA_0000084364": "请求地址",
      "P_YS_PF_GZTSYS_0000015896": "团队成员",
      "P_YS_OA_XTMENHU_0000046739": "我的任务",
      "P_YS_OA_XTNEIBUQUN_0000046122": "设置管理员",
      "P_YS_PF_WORKBENCH-FE_0001133418": "每天维护公司的上百台服务器，总是怕出现一些问题， 影响公司业务发展",
      "P_YS_PF_WORKBENCH-FE_0001133419": "填写信息",
      "P_YS_PF_WORKBENCH-FE_0001133416": "发布到菜单",
      "P_YS_PF_WORKBENCH-FE_0001133417": "邮箱：diwork@yonyou.com",
      "OA_web_ad_000000095": "未分组",
      "P_YS_PF_WORKBENCH-FE_0001133410": "“相信美好，立刻开始数字化工作”",
      "P_YS_PF_WORKBENCH-FE_0001133411": "关闭录制",
      "P_YS_PF_WORKBENCH-FE_0001133414": "中查看。",
      "P_YS_PF_WORKBENCH-FE_0001133415": "当前租户已开启登录控制策略。控制方式为【验证码】",
      "P_YS_PF_WORKBENCH-FE_0001133412": "帐号管理",
      "P_YS_OA_YBMOB_0000036890": "文件",
      "P_YS_PF_WORKBENCH-FE_0001133413": "如需购买或了解产品详情请拨打热线",
      "P_YS_OA_XTMENHU_0000046524": "待办中心",
      "P_YS_FED_FW_0000022505": "本周",
      "P_YS_OA_XTMENHU_0000046523": "图文模式",
      "P_YS_FED_EXAMP_0000020377": "等于",
      "P_YS_FED_UCFBASEDOC_0000025319": "验证码",
      "P_YS_HR_HRJQF_0000054364": "导入完成",
      "P_YS_HR_HRCOREF_0000058092": "当前人数",
      "P_YS_PF_WORKBENCH-FE_0001133429": "扫描二维码直接进入",
      "P_YS_PF_WORKBENCH-FE_0001133427": "升级为企业",
      "P_YS_PF_WORKBENCH-FE_0001133428": "菜单名称：",
      "P_YS_OA_XTLCZX_0000030680": "我发起的",
      "P_YS_OA_XTLCZX_0000030202": "大",
      "P_YS_PF_WORKBENCH-FE_0001133421": "用友云提供了企业所需要的各种数字化服务",
      "P_YS_PF_WORKBENCH-FE_0001133422": "全局搜索：",
      "P_YS_FED_EXAMP_0000020395": "搜索",
      "P_YS_OA_XTMENHU_0000046750": "最早打卡：",
      "P_YS_PF_WORKBENCH-FE_0001133420": "联系邮箱：diwork@yonyou.com",
      "P_YS_IPAAS_UCG_0001064071": "交通运输、仓储业和邮政业",
      "P_YS_PF_WORKBENCH-FE_0001133425": "定制服务",
      "P_YS_PF_WORKBENCH-FE_0001133426": "遍布全国的专业售前团队，整装待命",
      "P_YS_FED_EXAMP_0000020391": "加载中...",
      "P_YS_PF_WORKBENCH-FE_0001133423": "菜单位置：",
      "P_YS_PF_WORKBENCH-FE_0001133424": "个应用，",
      "P_YS_PF_YHTTNT-FE_0001045676": "只显示管理员",
      "P_YS_PF_WORKBENCH-FE_0001309411": "暂无汇总项",
      "P_YS_FED_EXAMP_0000020385": "取消",
      "P_YS_PF_WORKBENCH-FE_0001309412": "汇总项",
      "P_YS_PF_WORKBENCH-FE_0001309413": "保存过滤条件",
      "P_YS_FED_FW_0000022510": "恢复默认",
      "P_YS_OA_XTLCZX_0000030679": "中",
      "P_YS_FED_FW_0000022512": "没有搜索结果",
      "P_YS_PF_WORKBENCH-FE_0001233366": "支持回到旧版，可以随时切换回到新版工作台",
      "P_YS_PF_WORKBENCH-FE_0001233365": "切换旧版工作台",
      "P_YS_PF_WORKBENCH-FE_0001291208": "最好喝的酒也许不是最贵的那瓶，因为虽然可以买到酒，但买不到心情，最好的朋友也许不是联络最多的那个而是在平凡的日子里一直惦记着你的人！祝生日快乐",
      "P_YS_PF_WORKBENCH-FE_0001291209": "生日快乐！虽然我只是你远方的朋友，然而我的祝福却一直伴在你身边！愿你生活愉快！",
      "P_YS_FED_UCFBASEDOC_0000024690": "分组名称,最多4个字符",
      "P_YS_PF_WORKBENCH-FE_1421570875756380184": "将我的语言、默认业务单元、修改业务日期、工作台版本切换调整至此处",
      "P_YS_PF_ANALYTIC-FE_0001651904": "页签设置",
      "P_YS_PF_WORKBENCH-FE_1421570875756380187": "将帮助中心、功能引导、上新回顾、用户社区调整至此处",
      "P_YS_FED_IMP_IOT0000005499": "全部",
      "P_YS_PF_WORKBENCH-FE_1421570875756380181": "客户自建子领域暂时统一归于“未分组”中，后续将支持自由归类于其他领域云",
      "P_YS_SCM_PU_0000028165": "处理中",
      "P_YS_PF_WORKBENCH-FE_0001291202": "生日快乐，亲爱的朋友，愿你天天快乐，年年好运，一生幸福！",
      "P_YS_PF_WORKBENCH-FE_0001291203": "在你生日来临之际，祝事业正当午，身体壮如虎，金钱不胜数，干活不辛苦，悠闲像老鼠，浪漫似乐谱，快乐莫你属！",
      "P_YS_PF_WORKBENCH-FE_0001291200": "祈望你心灵深处芳草永绿，青春常驻，笑口常开。祝你生日快乐，健康幸福！",
      "P_YS_PF_WORKBENCH-FE_0001291201": "愿你的容颜像春天般绚烂，衷心祝福你——可爱的女孩，生日快乐！",
      "P_YS_OA_XTLCZX_0000030607": "拉伸",
      "P_YS_PF_WORKBENCH-FE_0001291206": "生日快乐!祝你今天抬头看见丘比特，出门遇上大美人，低头捡捆佰元钞，回家踩上臭狗屎！哈哈！",
      "P_YS_PF_WORKBENCH-FE_0001291207": "显示部门",
      "P_YS_PF_WORKBENCH-FE_0001291204": "又是一个美好的开始，愿我虔诚的祝福，带给你成功的一年，祝你生日快乐！",
      "P_YS_OA_XTLCZX_0000030605": "排列方式",
      "P_YS_PF_WORKBENCH-FE_0001291205": "日月轮转永不断，情苦真挚长相伴，不论你身在天涯海角，我将永远记住这一天。祝你生日快乐!",
      "P_YS_FED_IMP-DFM-FE_0000020888": "序号",
      "P_YS_FED_IMP-DFM-FE_0000020886": "复制",
      "P_YS_PF_GZTTMP_0000073996": "教育",
      "P_YS_OA_XTNEIBUQUN_0000046136": "邀请成员",
      "P_YS_FED_EXAMP_0000020566": "微信",
      "P_YS_FED_FW_0001515909": "图片展示",
      "P_YS_PF_WORKBENCH-FE_1421570875756380190": "支持拖拽排序，可将按个常用工作台拖拽至虚线上方并常驻显示，实现快速切换",
      "P_YS_FED_UCFBASEDOC_0000025523": "分组名称已存在！",
      "P_YS_PF_WORKBENCH-FE_0001155883": "您购买的产品中，有应用即将到期，请及时安排续费！",
      "P_YS_PF_WORKBENCH-FE_0001155882": "您购买的产品中，有应用已到期，请尽快安排续费！",
      "P_YS_PF_GZTSYS_0000013012": "应用设置",
      "P_YS_FED_IMP-DFM-FE_0000020898": "剪切",
      "P_YS_YYGJFW_YBUC_0001336566": "帮助信息",
      "P_YS_OA_XTMENHU_0000046725": "添加链接",
      "P_YS_SD_SDMA_0000041132": "已过期",
      "P_YS_PF_AUTHF_0000112877": "输入服务名称",
      "P_YS_OA_XTMENHU_0000046721": "祝你生日快乐，永远十八岁！",
      "P_YS_PF_GZTSYS_0000013245": "服务类型",
      "P_YS_FED_IMP_IOT0000005471": "提示",
      "P_YS_PF_MANAGER-FE_0001087788": "退出企业",
      "P_YS_OA_XTMENHU_0000046711": "二维码邀请",
      "P_YS_FED_EXAMP_0000020345": "请选择",
      "P_YS_FI_CM_0000029607": "错误",
      "P_YS_PF_WORKBENCH-FE_0001470623": "上传本地图片",
      "P_YS_FED_EXAMP_0000020344": "否",
      "P_YS_OA_XTMENHU_0000046710": "个人生日哦",
      "P_YS_PF_WORKBENCH-FE_0001470622": "选择默认图片",
      "P_YS_PF_AUTHF_0000112885": "暂无结果",
      "P_YS_PF_WORKBENCH-FE_0001582371": "是否单点",
      "P_YS_PF_GZTSYS_0000109539": "繁體中文",
      "P_YS_FED_UCFBASEDOC_0000025061": "角色编码",
      "P_YS_FED_UIMG_0000157462": "【提示】若“系统语言”为空，则按照“当前语言”进行保存。",
      "P_YS_FED_UIMG_0000157460": "当前语言",
      "P_YS_FED_UIMG_0000157461": "系统语言",
      "P_YS_PF_GZTSYS_0001155401": "资产盘点移动应用",
      "P_YS_FED_FW_0000020988": "详情",
      "message.common.lang0000522": "部署成功",
      "P_YS_OA_XTLCZX_0000030807": "选择模板",
      "P_YS_HR_HRCM-UI_0001380153": "页面建模",
      "P_YS_HR_HRXCF_0000056236": "重新上传",
      "P_YS_FED_FW_0000020984": "文本",
      "P_YS_FED_UIMG_0000082816": "只读状态不可进行删除操作",
      "P_YS_FED_UCFBASEDOC_0000025290": "部門放休",
      "P_YS_PF_GZTSYS_0000012671": "计量单位",
      "000723": "请输入网址",
      "P_YS_PF_PRINT_0000057241": "本币无税单价",
      "P_YS_PF_GZTSYS_0000012661": "假勤代申请",
      "P_YS_PF_YS-LOGIN_0001079461": "沙箱",
      "P_YS_FED_FW_0000020994": "整型",
      "P_YS_PF_GZTVPA_0001126493": "机器人名称",
      "P_YS_FED_UCFBASEDOC_0000025044": "離職",
      "P_YS_SD_UDHWN_0000050202": "帮助",
      "P_YS_PF_PRINT_0000057698": "本币无税金额",
      "P_YS_FED_UIMG_0000101674": "系统预制的必输字段不允许修改为非必输",
      "P_YS_AM_AMC-UI_0000170775": "树",
      "P_YS_FED_FW_0000021815": "向右",
      "P_YS_OA_app_xtywlj_0000037579": "请选择数据类型",
      "P_YS_HR_HRPXF_0000055973": "发布到",
      "P_YS_PF_GZTSYS_0000012631": "交易类型",
      "P_YS_SD_SDMA_0000062157": "未上架",
      "P_YS_PF_GZTSYS_0000015903": "门店",
      "P_YS_PF_TRS-FE_0000103851": "日常环境",
      "P_YS_PF_MANAGER-FE_0001087905": "标签模式",
      "P_YS_FED_IMP_IOT0000007727": "导出",
      "P_YS_PF_MANAGER-FE_0001087903": "确认启用【",
      "P_YS_FED_EXAMP_0000020302": "上传成功",
      "P_YS_FED_FW_0000021827": "删行",
      "P_YS_FED_FW_0000020973": "不能为空",
      "P_YS_FED_FW_0000021821": "默认语种",
      "P_YS_PF_GZTSYS_0000015916": "分析",
      "P_YS_OA_XTGONGWEN_0000045558": "请选择类型",
      "P_YS_SD_UDHMC_0001070473": "红色主题",
      "P_YS_PF_GZTSYS_0000109292": "生产环境",
      "P_YS_PF_GZTSYS_0000013547": "采购入库",
      "P_YS_SD_UDHWN_0000120893": "重新",
      "P_YS_PF_GZTSYS_0000012697": "项目",
      "P_YS_FED_FW_0000021631": "例：YYYY-MM-DD HH:mm:ss",
      "P_YS_FED_UCFBASEDOC_0000025019": "持續績效",
      "P_YS_FED_UCFBASEDOC_0000025016": "请输入账号",
      "P_YS_FED_FW_0000021875": "宽度",
      "P_YS_OA_app_xtyyjm_0000036018": "请选择表单",
      "P_YS_FED_MDF-COMPS_0001573108": "物料名稱",
      "P_YS_FED_MDF-COMPS_0001573109": "表体-0528test",
      "P_YS_FI_FINBD-UI_0000161964": "表参照",
      "P_YS_FED_MDF-COMPS_0001573106": "組號",
      "P_YS_FED_MDF-COMPS_0001573107": "移到",
      "P_YS_FED_FW_0000021890": "日语",
      "P_YS_OA_XTWENKU_0000035495": "修改時間",
      "P_YS_FED_MDF-COMPS_0001573100": "ycx文本",
      "P_YS_FED_MDF-COMPS_0001573101": "表头-0528test",
      "P_YS_SD_SDMA_0000062368": "PC端",
      "P_YS_FED_MDF-COMPS_0001573104": "應收件數",
      "P_YS_FED_UCFBASEDOC_0000025006": "轉正",
      "P_YS_FED_MDF-COMPS_0001573105": "客户分类3",
      "P_YS_FED_MDF-COMPS_0001573102": "仓档案",
      "P_YS_FED_UCFBASEDOC_0000025488": "HR日曆",
      "P_YS_FED_MDF-COMPS_0001573103": "主計量",
      "P_YS_FED_UCFBASEDOC_0000025485": "榮耀",
      "P_YS_FED_MDF-COMPS_0001573315": "么?",
      "P_YS_PF_PTDESIGN-FE_0001184798": "卡片设置",
      "P_YS_PF_GZTORG_0000035405": "修改时间",
      "P_YS_SD_SDMAUF_0000153043": "版本列表",
      "P_YS_PF_PRINT_0000057017": "生效日期",
      "P_YS_FI_OMC_0001094475": "当前环境",
      "P_YS_FED_FW_0000021617": "数量精度",
      "P_YS_FED_FW_0000021615": "草稿",
      "P_YS_FED_FW_0000021618": "公式编辑器",
      "P_YS_FED_EXAMP_0000020734": "，",
      "P_YS_HR_HRJXF_0000059668": "取消成功",
      "P_YS_PF_PROCENTER_0000027111": "修改",
      "P_YS_SCM_PU_0000028543": "获取数据失败",
      "P_YS_PF_GZTBDM_0000036354": "刷新成功",
      "P_YS_PF_GZTSYS_0000012688": "加班",
      "P_YS_PF_GZTSYS_0000012687": "考勤中心",
      "P_YS_FED_FW_0000021868": "没有数据",
      "P_YS_AM_RMM-UI_0001217391": "申请内容",
      "P_YS_PF_GZTSYS_0000012208": "云会务",
      "P_YS_FED_MDF-COMPS_0001623284": "沙箱许可",
      "P_YS_FED_MDF-COMPS_0001623283": "移動供應鏈",
      "P_YS_FED_MDF-COMPS_0001623286": "空页面",
      "000572": "数据集",
      "P_YS_FED_MDF-COMPS_0001623285": "点击条目快速预览",
      "P_YS_FED_MDF-COMPS_0001623288": "请选择发布数据",
      "P_YS_FED_MDF-COMPS_0001623287": "确定发布当前参照吗?",
      "P_YS_FED_FW_0000022097": "替换",
      "P_YS_FED_MDF-COMPS_0001623289": "独立应用和小程序",
      "P_YS_HR_HRYGF_0000058837": "枚举",
      "P_YS_PF_GZTSYS_0000012811": "办公协作",
      "P_YS_PF_METADATA_0001038547": "左树右卡",
      "P_YS_FED_MDF-COMPS_0001623291": "请选择系统预置",
      "P_YS_PF_GZTSYS_0000011965": "HR日历",
      "P_YS_FED_MDF-COMPS_0001623290": "收件人名称",
      "P_YS_FED_MDF-COMPS_0001623293": "包名必须包含至少两个段，由一个或多个点隔开。例如：com.abc，tencent.qq.mm；每个段的字符必须是小写字母、数字或下划线[a-z0-9_]组成；每个段必须以字母开头；",
      "P_YS_FED_MDF-COMPS_0001623292": "在APP工作台中动态显示应用管理中的应用。",
      "P_YS_FED_MDF-COMPS_0001623295": "错误数",
      "P_YS_FED_MDF-COMPS_0001623294": "企業文化",
      "P_YS_FED_MDF-COMPS_0001623297": "沙箱名称",
      "P_YS_FED_MDF-COMPS_0001623296": "keystore密码",
      "P_YS_FI_CM_0000030058": "单据编号",
      "P_YS_FED_MDF-COMPS_0001623299": "进入应用构建专业版",
      "P_YS_FED_MDF-COMPS_0001623298": "已选择插件",
      "P_YS_IPAAS_UCG_0001009331": "开发者",
      "P_YS_OA_XTWENKU_0000035693": "大小",
      "P_YS_PF_TRS_0000034730": "应用编码",
      "P_YS_HR_HRRCPDF_0000073224": "创建人:",
      "P_YS_YYGJFW_YBUC_0001333705": "环境配置",
      "P_YS_FED_MDF-COMPS_0001573117": "提交時間",
      "P_YS_FED_MDF-COMPS_0001573118": "自定義項目測試202008060001",
      "P_YS_FED_MDF-COMPS_0001623266": "应用编码不能以数字或者下划线开头!",
      "P_YS_FED_MDF-COMPS_0001623265": "掃碼入職",
      "P_YS_FI_CM_0000029070": "函数",
      "P_YS_FED_MDF-COMPS_0001623268": "重新打包",
      "P_YS_FED_MDF-COMPS_0001623267": "暂无可用沙箱",
      "P_YS_FED_MDF-COMPS_0001623269": "请选择表单分类",
      "P_YS_HR_HRYGF_0000058815": "模板类型",
      "P_YS_FI_AR_0000058963": "到期日",
      "P_YS_SD_UDHWN_0000120238": "未设置",
      "P_YS_SD_SDSD-FE_0001486556": "備註",
      "P_YS_SD_SDMA_0000038452": "业务员",
      "P_YS_PF_GZTSYS_0000135330": "T+服务",
      "P_YS_FED_MDF-COMPS_0001573111": "ycx檔案類型",
      "P_YS_FED_MDF-COMPS_0001573112": "likun-表体自定义项[文本]",
      "P_YS_FED_MDF-COMPS_0001573110": "枚舉",
      "P_YS_SD_SDMA_0000040767": "下单时间",
      "P_YS_FED_MDF-COMPS_0001573115": "上游單據號",
      "P_YS_FI_FCWEB_0001047524": "拖拽调整顺序",
      "P_YS_FED_MDF-COMPS_0001573116": "保質期單位",
      "P_YS_FED_MDF-COMPS_0001573113": "物料編碼",
      "P_YS_FED_MDF-COMPS_0001623271": "我上传的插件",
      "P_YS_FED_MDF-COMPS_0001573114": "倉庫",
      "P_YS_FED_MDF-COMPS_0001623270": "开发沙箱(",
      "P_YS_FED_MDF-COMPS_0001623273": "请上传选中图标",
      "P_YS_FED_MDF-COMPS_0001623272": "普通发布",
      "P_YS_FED_MDF-COMPS_0001623275": "系统自建",
      "P_YS_FED_MDF-COMPS_0001623274": "視頻會議",
      "P_YS_FED_MDF-COMPS_0001623277": "指定页面",
      "P_YS_PF_GZTSYS_0000012809": "资产管理",
      "P_YS_FED_MDF-COMPS_0001623276": "请输入申请说明",
      "P_YS_FED_MDF-COMPS_0001623279": "请输入打包信息",
      "P_YS_FED_MDF-COMPS_0001623278": "打包序号",
      "P_YS_FED_EXAMP_0000019958": "金额",
      "P_YS_OA_app_xtyyjm_0000036087": "导入文件",
      "P_YS_OA_XTWENKU_0000035676": "創建時間",
      "P_YS_FED_MDF-COMPS_0001623280": "青啤",
      "P_YS_PF_GZTSYS_0000012801": "权益",
      "P_YS_OA_XTMENHUCMS_0000045312": "默认图标",
      "P_YS_FED_MDF-COMPS_0001623282": "请选择申请领域",
      "P_YS_FED_EXAMP_0000020703": "规格",
      "P_YS_FED_MDF-COMPS_0001623281": "新建移动页面",
      "P_YS_FED_MDF-COMPS_0001623482": "该应用已关联",
      "P_YS_FED_MDF-COMPS_0001623481": "扩展行数范围1-10",
      "P_YS_FED_MDF-COMPS_0001623484": "分析模板",
      "P_YS_FED_MDF-COMPS_0001623483": "PC端页面",
      "P_YS_SCM_STOCK_0000034389": "行",
      "P_YS_FED_MDF-COMPS_0001623486": "适用组织职能",
      "P_YS_FED_MDF-COMPS_0001623485": "描述文件",
      "P_YS_FED_MDF-COMPS_0001623488": "别名(alias)",
      "P_YS_FED_MDF-COMPS_0001623487": "打包需要约30分钟,请稍后刷新页面",
      "P_YS_FED_MDF-COMPS_0001623489": "工作彙報",
      "P_YS_IPAAS_APIC-FE_0001577482": "权限说明",
      "P_YS_OA_PACK_0001100778": "端配置",
      "P_YS_PF_PRINT_0000065739": "物料sku编码",
      "P_YS_PF_GZTSYS_0000012611": "我的反馈",
      "P_YS_FI_FCWEB_0001047707": "生成模板",
      "P_YS_FED_FW_0000020949": "繁体中文",
      "P_YS_YYGJFW_YBUC_0001333755": "环境地址",
      "P_YS_YYGJFW_YBUC_0001335933": "设为管理员",
      "P_YS_HR_HRJXF_0000059247": "添加成员",
      "P_YS_FED_MDF-COMPS_0001623491": "闪屏",
      "P_YS_FI_YYFI-UI_0000167698": "其他入库单列表区域",
      "P_YS_PF_GZTSYS_0000012858": "会议管理",
      "P_YS_FED_MDF-COMPS_0001623490": "引用已有",
      "P_YS_FED_MDF-COMPS_0001623493": "智能取件櫃",
      "P_YS_FED_MDF-COMPS_0001623492": "入職",
      "P_YS_PF_GZTSYS_0001146583": "移动工作台",
      "P_YS_FED_MDF-COMPS_0001623495": "立即开始",
      "P_YS_HR_HRYGF_0000058801": "选择实体",
      "P_YS_FED_MDF-COMPS_0001623494": "採購入庫",
      "P_YS_FED_MDF-COMPS_0001623497": "管理应用 (删改) | 管理成员 (增删) | 开发应用",
      "P_YS_PF_PTDESIGN-FE_0001184504": "页面名称不能为空",
      "P_YS_FED_MDF-COMPS_0001623496": "友空间扫码",
      "P_YS_FED_MDF-COMPS_0001623499": "请输入自定义链接",
      "P_YS_SD_SDMA-UI_0001101601": "申请状态",
      "P_YS_FED_MDF-COMPS_0001623498": "提供人",
      "P_YS_SD_SDSD-FE_0001491730": "數量",
      "P_YS_PF_GZTSYS_0000012867": "持续绩效",
      "P_YS_OA_XTLCZX_0000030199": "第三方",
      "P_YS_FED_MDF-COMPS_0001623460": "描述文件名称",
      "P_YS_FED_MDF-COMPS_0001623462": "計劃盤點",
      "P_YS_FED_EXAMP_0000019909": "业务类型",
      "P_YS_FED_MDF-COMPS_0001623461": "下载插件模版",
      "P_YS_SD_SDMB_0000151538": "自",
      "P_YS_FED_MDF-COMPS_0001623464": "请选择应用引擎",
      "P_YS_FED_MDF-COMPS_0001623463": "请选中分组",
      "P_YS_FED_MDF-COMPS_0001623466": "获取当前登陆用户id失败",
      "P_YS_FED_MDF-COMPS_0001623465": "小图卡片",
      "P_YS_FED_MDF-COMPS_0001623468": "请搜索登录名",
      "P_YS_FED_MDF-COMPS_0001623467": "展示时长",
      "P_YS_FED_EXAMP_0000019901": "更新成功",
      "P_YS_FED_MDF-COMPS_0001623469": "确定删除插件吗？",
      "P_YS_FED_FW_0001573430": "! 重复的control对象为:%o",
      "P_YS_SCM_UIT-UI_0000174857": "企业画像",
      "P_YS_FED_FW_0001573427": "{\\\"1\\\":\\\"年\\\",\\\"2\\\":\\\"月\\\",\\\"6\\\":\\\"天\\\"}",
      "P_YS_FED_IMP_IOT0000011205": "请输入描述",
      "P_YS_FED_FW_0001573428": "[{\\\"key\\\":\\\"1\\\",\\\"nameType\\\":\\\"text\\\",\\\"value\\\":\\\"年\\\"},{\\\"key\\\":\\\"2\\\",\\\"nameType\\\":\\\"text\\\",\\\"value\\\":\\\"月\\\"},{\\\"key\\\":\\\"6\\\",\\\"nameType\\\":\\\"text\\\",\\\"value\\\":\\\"天\\\"}]",
      "P_YS_FED_FW_0001573429": "获取删除之后的数据",
      "P_YS_FED_MDF-COMPS_0001623471": "沙箱是特殊的企业租户。",
      "P_YS_FED_MDF-COMPS_0001623470": "申请领域",
      "P_YS_FED_MDF-COMPS_0001623473": "列表结构",
      "P_YS_FED_MDF-COMPS_0001623472": "銷售下單",
      "P_YS_FED_MDF-COMPS_0001623475": "添加移动端",
      "P_YS_FED_UIMG_0001071360": "设计字段不能设为必输",
      "P_YS_FED_MDF-COMPS_0001623474": "删除成员后，该成员创建的内容仍将保留",
      "P_YS_FED_MDF-COMPS_0001623477": "请先启用闪屏再进行配置~",
      "P_YS_FED_MDF-COMPS_0001623476": "使用许可",
      "P_YS_FED_MDF-COMPS_0001623479": "应用图标大小不能超过1M",
      "P_YS_FED_MDF-COMPS_0001623478": "发布终端",
      "P_YS_PF_BD-UI_0001049486": "一主多子",
      "P_YS_FED_FW_0001573431": "[bill-setting] [BillModal] [updateGridModelDataValueByCRefRetId()] 获取参照返回值ERROR:[",
      "P_YS_FED_FW_0001573432": "[bill-setting-new] [BillModal] [updateGridModelDataValueByCRefRetId()] 获取参照返回值ERROR:[",
      "P_YS_FED_FW_0001573433": "%c[bill-setting] [redux billmodal] [mergeDirtyDataInModalDataOriginal()] 重复的cName",
      "P_YS_PF_GZTSYS_0000012602": "企业介绍",
      "P_YS_FED_FW_0000020935": "失败",
      "P_YS_PF_GZTSYS_0000135360": "资产盘点",
      "P_YS_FED_FW_0001573434": ",阻止执行",
      "P_YS_PF_GZTSYS_0000012601": "出差",
      "P_YS_PF_TRS_0000034719": "应用名称",
      "P_YS_FED_FW_0001573435": "渲染表格方法与组件",
      "P_YS_PF_PROCENTER_0000022745": "调用",
      "P_YS_FED_FW_0001573436": "参照缺少必要配置cRefRetId、cRefType",
      "P_YS_FED_FW_0001573437": "获取下标为",
      "P_YS_PF_GZTORG_0000035493": "开通状态",
      "P_YS_SD_UDHLIB_0000133243": "开通中",
      "P_YS_FED_MDF-COMPS_0001623480": "抽取开发数据",
      "P_YS_PF_GZTSYS_0000012605": "部门加班",
      "P_YS_PF_PRINT_0000056946": "表头自定义项14",
      "P_YS_OA_app_xtyyjm_0000035922": "页面名称",
      "P_YS_FED_MDF-COMPS_0001623561": "应用卡片预览",
      "P_YS_PF_PRINT_0000056943": "表头自定义项10",
      "P_YS_FED_MDF-COMPS_0001623560": "雲直播",
      "P_YS_FED_MDF-COMPS_0001623321": "未选择用户",
      "P_YS_FED_MDF-COMPS_0001623563": "需跳转到证书管理页面",
      "P_YS_FED_MDF-COMPS_0001623320": "基本属性",
      "P_YS_FED_MDF-COMPS_0001623562": "插件描述",
      "P_YS_FED_MDF-COMPS_0001623323": "描述文件被使用中，无法删除",
      "P_YS_FED_MDF-COMPS_0001623565": "请输入来源租户",
      "P_YS_FED_MDF-COMPS_0001623322": "帮助你自动完成重复耗时的任务, 提高工作效率。",
      "P_YS_FED_MDF-COMPS_0001623564": "工作台地址",
      "P_YS_FED_MDF-COMPS_0001623325": "可用沙箱",
      "P_YS_FED_MDF-COMPS_0001623567": "关联已有APP或小程序，关联后将采用关联对象配置。",
      "P_YS_FED_MDF-COMPS_0001623324": "请选择创建自",
      "P_YS_FED_MDF-COMPS_0001623566": "应用显示规则",
      "P_YS_FED_MDF-COMPS_0001623327": "在APP工作台中固定显示白名单中的应用。",
      "P_YS_FED_MDF-COMPS_0001623569": "用友云小程序",
      "P_YS_FED_MDF-COMPS_0001623326": "可选服务",
      "P_YS_FED_MDF-COMPS_0001623568": "新建参照",
      "P_YS_FED_MDF-COMPS_0001623329": "已扩展",
      "P_YS_FED_MDF-COMPS_0001623328": "请输入文件名称搜索",
      "P_YS_PF_GZTTMP_0000078208": "往来件数",
      "P_YS_YYGJFW_YBUC_0001336020": "请选择应用",
      "P_YS_SD_UDHWS_0000066531": "主计量数量",
      "P_YS_FED_FW_0000022004": "查看",
      "P_YS_FED_FW_0000022489": "今日",
      "P_YS_FI_FCWEB_0001324088": "未连接多语中心的词条:",
      "P_YS_FED_FW_0000022003": "请选择行再进行下移操作！",
      "P_YS_FI_FCWEB_0001324089": "不存在,请检查词条!",
      "P_YS_PF_PRINT_0000056949": "表头自定义项12",
      "P_YS_OA_XTWENKU_0000035721": "項目名稱",
      "P_YS_PF_PRINT_0000056947": "表头自定义项13",
      "P_YS_HR_HRSSC_0000135821": "取消失败",
      "P_YS_FED_MDF-COMPS_0001623570": "页面发布",
      "P_YS_PF_PRINT_0000056956": "表头自定义项18",
      "P_YS_FED_MDF-COMPS_0001623330": "隐私协议地址",
      "P_YS_FED_MDF-COMPS_0001623572": "启用失败！",
      "P_YS_PF_PRINT_0000057801": "表头自定义项8",
      "P_YS_SCM_STOCK_0000034476": "单据",
      "P_YS_FED_MDF-COMPS_0001623571": "智能發件櫃",
      "P_YS_PF_PRINT_0000057800": "表头自定义项9",
      "P_YS_FED_MDF-COMPS_0001623332": "調班",
      "P_YS_FED_MDF-COMPS_0001623574": "工作台地址不能为空！",
      "P_YS_FED_MDF-COMPS_0001623331": "不超2MB",
      "P_YS_FED_MDF-COMPS_0001623573": "别名密码",
      "P_YS_FED_MDF-COMPS_0001623334": "今日错误数",
      "P_YS_FED_MDF-COMPS_0001623576": "未找到相关插件",
      "P_YS_PF_PRINT_0000056950": "表头自定义项11",
      "P_YS_FED_MDF-COMPS_0001623333": "PC端页面名称",
      "P_YS_FED_MDF-COMPS_0001623575": "客戶檔案",
      "P_YS_FED_MDF-COMPS_0001623336": "启用状态不允许删除，请停用后删除",
      "P_YS_FED_MDF-COMPS_0001623578": "只查看上架数据",
      "P_YS_FED_MDF-COMPS_0001623335": "没有选中",
      "P_YS_FED_MDF-COMPS_0001623577": "删除pc端",
      "P_YS_PF_METADATA_0000087362": "应用图标",
      "RPA_L27_00050068": "机器人运行状态",
      "P_YS_FED_MDF-COMPS_0001623338": "銷售發貨",
      "P_YS_FED_MDF-COMPS_0001623337": "应用code不存在，请联系管理员！",
      "P_YS_FED_MDF-COMPS_0001623579": "确定要删除服务吗?",
      "P_YS_FED_MDF-COMPS_0001623339": "下载日志",
      "P_YS_PF_GZTSYS_0000134847": "工资条",
      "P_YS_OA_XTSPZX_0000034640": "WEB端",
      "Running": "运行中",
      "P_YS_SD_SDMA-UI_0001101654": "终端",
      "P_YS_FED_FW_0000022255": "增加",
      "P_YS_FED_FW_0000022256": "未选中任何行！",
      "P_YS_FED_UCFBASEDOC_0000024994": "部門加班",
      "P_YS_PF_PRINT_0000056958": "表头自定义项17",
      "P_YS_FED_UCFBASEDOC_0000024990": "密码",
      "P_YS_SCM_USTOCK-UI_0001025485": "必输，请精确到sku",
      "P_YS_OA_XTLCZX_0000030159": "审批时间",
      "P_YS_FED_MDF-COMPS_0001623541": "请搜素",
      "P_YS_PF_GZTLOG_0000025980": "列表",
      "P_YS_OA_app_xtyyjm_0000035947": "新建应用",
      "P_YS_FED_MDF-COMPS_0001623540": "无限制：",
      "P_YS_FED_MDF-COMPS_0001623301": "社交溝通",
      "P_YS_FED_MDF-COMPS_0001623543": "]将被刷新",
      "P_YS_FED_MDF-COMPS_0001623300": "搜索领域名称",
      "P_YS_FED_MDF-COMPS_0001623542": "说明：上传图片尺寸建议为750*244px，图片支持JPG/PNG格式，大小不超过300KB",
      "P_YS_PF_METADATA_0000085152": "系统版本",
      "P_YS_FED_MDF-COMPS_0001623303": "），继续保存将覆盖已有APP。",
      "P_YS_FED_MDF-COMPS_0001623545": "穿梭表参照",
      "P_YS_FED_MDF-COMPS_0001623302": "确定带数据权限发布当前参照吗?",
      "P_YS_FED_MDF-COMPS_0001623544": "长度应小于",
      "P_YS_FED_MDF-COMPS_0001623305": "匿名",
      "P_YS_FED_MDF-COMPS_0001623547": "请选择沙箱许可",
      "P_YS_FED_MDF-COMPS_0001623304": "开屏页面大小不能超过2M",
      "P_YS_FED_MDF-COMPS_0001623546": "生成示例应用",
      "P_YS_FED_MDF-COMPS_0001623307": "应用引擎",
      "P_YS_FED_MDF-COMPS_0001623549": "主子孙",
      "P_YS_FED_MDF-COMPS_0001623306": "新建沙箱",
      "P_YS_FED_MDF-COMPS_0001623548": "开发租户",
      "P_YS_FED_MDF-COMPS_0001623309": "首页地址不能为空！",
      "P_YS_FED_MDF-COMPS_0001623308": "扩展成功",
      "P_YS_OA_XTYUNHUIWU_0000168712": "已复制到粘贴板",
      "P_YS_FED_UCFBASEDOC_0000024747": "辦公協作",
      "P_YS_FED_UCFBASEDOC_0000024986": "导入",
      "P_YS_FED_FW_0000022228": "栏目名称",
      "P_YS_FED_UCFBASEDOC_0000024742": "開證明",
      "P_YS_PF_GZTSYS_0001122252": "移动供应链",
      "P_YS_FED_MDF-COMPS_0001623550": "刷新沙箱",
      "P_YS_FED_MDF-COMPS_0001623310": "添加证书文件",
      "P_YS_FED_MDF-COMPS_0001623552": "按结构默认生成",
      "P_YS_FED_MDF-COMPS_0001623551": "查企業",
      "P_YS_FED_MDF-COMPS_0001623312": "权限说明：管理沙箱(编辑、删除、导入用户)",
      "P_YS_FED_MDF-COMPS_0001623554": "业务对象（",
      "P_YS_SCM_STOCK_0000034216": "有效期至",
      "P_YS_FED_MDF-COMPS_0001623311": "创建自",
      "P_YS_FED_MDF-COMPS_0001623553": "图片尺寸错误，请上传122*122以内的图片",
      "P_YS_FED_MDF-COMPS_0001623314": "打包日志",
      "P_YS_FED_MDF-COMPS_0001623556": "请选择要写入cookie的环境",
      "P_YS_PF_GZTSYS_0000012096": "购买记录",
      "P_YS_FED_MDF-COMPS_0001623313": "颁发者",
      "P_YS_FED_MDF-COMPS_0001623555": "应用显示规则是控制当前租户下应用在APP工作台的显示规则。",
      "P_YS_FED_MDF-COMPS_0001623316": "上架版本",
      "P_YS_FED_MDF-COMPS_0001623558": "(参照)",
      "RPA_L27_00050089": "取消部署",
      "P_YS_FED_MDF-COMPS_0001623315": "应用编码长度应小于50个字符",
      "P_YS_FED_MDF-COMPS_0001623557": "仅发布",
      "P_YS_PF_GZTTMP_0000078679": "证书密码",
      "P_YS_FED_MDF-COMPS_0001623318": "需跳转到证书管理页面，当前页面数据会以草稿态保存到 App 列表中。",
      "P_YS_FED_MDF-COMPS_0001623317": "证书下有描述文件被使用中，无法删除",
      "P_YS_FED_MDF-COMPS_0001623559": "雲會務",
      "P_YS_FED_MDF-COMPS_0001623319": "页面列表",
      "P_YS_FED_UCFBASEDOC_0000024737": "我的目標",
      "P_YS_FED_FW_0000022236": "导航",
      "P_YS_FED_FW_0000022234": "葡萄牙语",
      "P_YS_SD_SDMBF_0000145547": "趋势图",
      "P_YS_FED_FW_0000022291": "库存组织",
      "P_YS_HR_HRPUBF_0001009191": "请输入必填项",
      "P_YS_FED_FW_0000022055": "请输入...",
      "P_YS_FED_FW_0000022298": "保存失败！",
      "P_YS_FED_MDF-COMPS_0001623521": "打包中",
      "P_YS_FED_MDF-COMPS_0001623520": "下架状态，将该版本设置为下架状态",
      "P_YS_FED_MDF-COMPS_0001623523": "不带默认坏境",
      "P_YS_FED_MDF-COMPS_0001623522": "工資條",
      "P_YS_FED_MDF-COMPS_0001623525": "当前应用不存在！",
      "P_YS_PF_GZTSYS_0001561352": "数据建模",
      "P_YS_FED_MDF-COMPS_0001623524": "确定删除当前证书文件吗?删除后不可找回, 请谨慎操作",
      "P_YS_FED_MDF-COMPS_0001623527": "请输入名称或描述",
      "P_YS_FED_MDF-COMPS_0001623526": "获取到期沙箱失败",
      "P_YS_FED_MDF-COMPS_0001623529": "纯文字卡片",
      "P_YS_FED_MDF-COMPS_0001623528": "本操作相当于重建沙箱，当前数据将被重置且操作无法撤回。",
      "RPA_L27_00050095": "机器人部署详情",
      "P_YS_FED_UIMG_0000025893": "请先将该字段设为显示！",
      "P_YS_OA_XTLCZX_0000030325": "上传",
      "P_YS_FED_MDF-COMPS_0001623530": "用友云隐私协议",
      "P_YS_FED_MDF-COMPS_0001623532": "更新描述",
      "P_YS_FED_MDF-COMPS_0001623531": "系统要求：",
      "P_YS_FED_MDF-COMPS_0001623534": "访问应用构建服务失败",
      "P_YS_FED_MDF-COMPS_0001623533": "测试沙箱",
      "P_YS_FED_MDF-COMPS_0001623536": "请选择使用许可",
      "P_YS_FED_MDF-COMPS_0001623535": "页面编码不能以数字开头!",
      "P_YS_FED_MDF-COMPS_0001623538": "树形表",
      "P_YS_FED_MDF-COMPS_0001623537": "自主创建和维护的应用程序。",
      "P_YS_FED_MDF-COMPS_0001623539": "选择时间段",
      "P_YS_FED_EXAMP_0000020286": "编码",
      "P_YS_HR_HRXCF_0001135149": "账号密码",
      "P_YS_SD_SDMBF_0000145326": "购买数量",
      "P_YS_FED_FW_0000022030": "校验成功",
      "P_YS_PF_PRINT_0000056963": "表头自定义项15",
      "P_YS_PF_GZTSYS_0000134433": "工作流",
      "P_YS_FED_MDF-COMPS_0001623741": "移动端页面",
      "P_YS_PF_PRINT_0000056961": "表头自定义项16",
      "P_YS_FED_MDF-COMPS_0001623740": "普通上架",
      "P_YS_FED_MDF-COMPS_0001623501": "错误分析详情",
      "P_YS_FED_MDF-COMPS_0001623743": "更新app异常",
      "P_YS_FED_MDF-COMPS_0001623500": "终端系统",
      "P_YS_FED_MDF-COMPS_0001623742": "名称/手机号/邮箱",
      "P_YS_FED_MDF-COMPS_0001623503": "主题配置",
      "P_YS_FED_MDF-COMPS_0001623745": "链接网址",
      "P_YS_FED_MDF-COMPS_0001623502": "留下",
      "P_YS_FED_MDF-COMPS_0001623744": "添加常用",
      "P_YS_FED_MDF-COMPS_0001623505": "公共插件",
      "P_YS_FED_MDF-COMPS_0001623747": "应用编码只能包含数字字母下划线!",
      "P_YS_FED_MDF-COMPS_0001623504": "页面编码不能为空!",
      "P_YS_FED_MDF-COMPS_0001623746": "打开上次访问的应用",
      "P_YS_FED_MDF-COMPS_0001623507": "菜单/类别",
      "P_YS_FED_MDF-COMPS_0001623506": "建造中",
      "P_YS_FED_MDF-COMPS_0001623509": "首页地址",
      "P_YS_FED_FW_0001486020": "【提示】若基准语言（<%=enterprise%>）内容未维护，系统将默认按当前语言（<%=current%>）内容赋值处理",
      "P_YS_FED_MDF-COMPS_0001623508": "iOS 11以上",
      "P_YS_PF_PRINT_0000065438": "应收件数",
      "P_YS_OA_XTSPZX_0000034833": "時間",
      "P_YS_FED_FW_0000022040": "向下",
      "P_YS_FED_FW_0000022041": "运算符",
      "P_YS_OA_app_xtyyjm_0000035998": "创建者",
      "P_YS_PF_GZTSYS_0001206993": "销售跟单",
      "P_YS_FED_MDF-COMPS_0001623510": "沙箱总数",
      "P_YS_FED_MDF-COMPS_0001623512": "切换后，当前应用权限为所有人可操作",
      "P_YS_PF_PRINT_0000056970": "表头自定义项19",
      "P_YS_FED_MDF-COMPS_0001623511": "同时生成参照",
      "P_YS_FED_MDF-COMPS_0001623514": "应用显示规则：",
      "P_YS_FED_MDF-COMPS_0001623513": "暂无可用许可，请联系销售人员扩展额外许可",
      "P_YS_FED_MDF-COMPS_0001623516": "APP配置文件",
      "P_YS_FED_MDF-COMPS_0001623515": "对象建模",
      "P_YS_FED_MDF-COMPS_0001623518": "存在重复的包名（",
      "P_YS_FED_MDF-COMPS_0001623517": "友空間投屏",
      "P_YS_FED_MDF-COMPS_0001623519": "多环境",
      "P_YS_FED_FW_0000022279": "未知",
      "P_YS_PF_GZTSYS_0000134430": "社交沟通",
      "P_YS_HR_HRPUBF_0001009195": "请输入编码和名称",
      "P_YS_FED_UCFBASEDOC_0000025464": "話題",
      "P_YS_OA_XTMENHUCMS_0000044938": "导航标题",
      "P_YS_PF_PRINT_0000057631": "表体自定义项7",
      "P_YS_FED_MDF-COMPS_0001623721": "确定要取消部署吗?",
      "P_YS_PF_PRINT_0000057630": "表体自定义项8",
      "P_YS_FED_MDF-COMPS_0001623720": "说明：闪屏一般用于运营推广；上传图片支持JPG/PNG/GIF格式，尺寸建议为1080*1920px，大小不超过300KB",
      "P_YS_FED_MDF-COMPS_0001623723": "APP配置吗？删除后该租户下的APP配置将失效，请谨慎操作！",
      "P_YS_FED_MDF-COMPS_0001623722": "字节长度应小于8",
      "P_YS_FED_MDF-COMPS_0001623725": "白名单控制：",
      "P_YS_FED_MDF-COMPS_0001623724": "针对已购买/已授权的应用,进行特性增强的应用程序。",
      "P_YS_FED_MDF-COMPS_0001623727": "离线工作台",
      "P_YS_FED_MDF-COMPS_0001623726": "系统：",
      "P_YS_FED_MDF-COMPS_0001623729": "请输入沙箱名称",
      "P_YS_FED_MDF-COMPS_0001623728": "页面编码只能包含数字字母下划线!",
      "P_YS_SD_SDMBF_0000145133": "上架时间",
      "P_YS_PF_GZTSYS_0000012497": "部门排班",
      "P_YS_FED_FW_0000021796": "确定要删除",
      "P_YS_HR_HRJQF_0000054269": "选择文件",
      "P_YS_FED_FW_0000021314": "参照",
      "P_YS_OA_XTLCZX_0000030527": "表单分类",
      "P_YS_OA_XTLCZX_0000030780": "新建",
      "P_YS_PF_HELPCENTER-FE_0001135323": "请选择领域",
      "P_YS_FED_MDF-COMPS_0001623730": "树表参照",
      "P_YS_SCM_USTOCK-UI_0001025096": "版本信息",
      "P_YS_FED_MDF-COMPS_0001623732": "证书OU",
      "P_YS_PF_MANAGER-FE_0001087849": "确认停用【",
      "P_YS_FED_MDF-COMPS_0001623731": "未知租户",
      "P_YS_FED_MDF-COMPS_0001623734": "点击进入官方文档",
      "P_YS_FED_MDF-COMPS_0001623733": "APP配置名称",
      "P_YS_FED_MDF-COMPS_0001623736": "上架版本：",
      "P_YS_FED_MDF-COMPS_0001623735": "(网页端)",
      "P_YS_FED_MDF-COMPS_0001623738": "生成列表页面",
      "P_YS_FED_MDF-COMPS_0001623737": "数据模型111",
      "P_YS_FED_MDF-COMPS_0001623739": "調動",
      "P_YS_SD_SDMB_0000146708": "下载二维码",
      "P_YS_FED_UIMG_0000025865": "配置",
      "P_YS_FED_IMP_IOT0000006019": "请搜索",
      "P_YS_PF_PRINT_0000065262": "物料名称",
      "P_YS_OA_XTLCZX_0000030779": "请输入标题",
      "P_YS_OA_app_xtyyjm_0000035788": "已发布",
      "P_YS_OA_XTLCZX_0000030553": "流程",
      "P_YS_PF_GZTSYS_0000012482": "开证明",
      "P_YS_SD_SDMA_0000123117": "微信小程序",
      "P_YS_FED_MDF-COMPS_0001623701": "下载离线包模版",
      "P_YS_FED_MDF-COMPS_0001623700": "APP打包",
      "P_YS_FED_MDF-COMPS_0001623703": "终端系统不能为空！",
      "P_YS_FED_MDF-COMPS_0001623702": "错误分析",
      "P_YS_FED_MDF-COMPS_0001623705": "新建app异常",
      "P_YS_FED_MDF-COMPS_0001623704": "行编辑表",
      "P_YS_FED_MDF-COMPS_0001623707": "上传文件格式错误，支持上传的文件格式为：png、jpeg、jpg、svg",
      "P_YS_FED_MDF-COMPS_0001623706": "員工信息",
      "P_YS_FED_MDF-COMPS_0001623709": "同时生成列表",
      "P_YS_FED_MDF-COMPS_0001623708": "新建机器人",
      "P_YS_PF_METADATA_0000085783": "产品规格",
      "P_YS_YYGJFW_YBUC_0001568362": "扩",
      "P_YS_FED_FW_0000021774": "法语",
      "bpm.datatype.start.success": "启用成功！",
      "P_YS_PF_PRINT_0000057628": "表体自定义项3",
      "P_YS_PF_PRINT_0000057627": "表体自定义项4",
      "P_YS_PF_MANAGER-FE_0001204334": "APP配置",
      "P_YS_PF_GZTSYS_0001050715": "我的合同",
      "P_YS_PF_PRINT_0000057624": "表体自定义项5",
      "P_YS_FED_FW_0000021792": "预览",
      "P_YS_PF_BD-UI_0001049605": "单卡",
      "P_YS_PF_PRINT_0000057622": "表体自定义项6",
      "P_YS_PF_PRINT_0000057621": "表体自定义项1",
      "P_YS_PF_MANAGER-FE_0001204332": "APP名称",
      "P_YS_PF_PRINT_0000057620": "表体自定义项2",
      "P_YS_FED_MDF-COMPS_0001623710": "銷售跟單",
      "P_YS_PF_METADATA_0001040427": "产地",
      "P_YS_FED_MDF-COMPS_0001623712": "描述文件不能为空！",
      "P_YS_FED_MDF-COMPS_0001623711": "组织模式",
      "P_YS_FED_MDF-COMPS_0001623714": "白名单控制",
      "P_YS_FED_MDF-COMPS_0001623713": "发布应用",
      "P_YS_FED_MDF-COMPS_0001623716": "日常盤點",
      "P_YS_FED_MDF-COMPS_0001623715": "添加插件",
      "P_YS_FED_MDF-COMPS_0001623718": "开屏页面",
      "P_YS_PF_GZTSYS_0000012489": "公告",
      "P_YS_FED_MDF-COMPS_0001623717": "请输入正确网址",
      "P_YS_FED_MDF-COMPS_0001623719": "环境地址不能为空！",
      "P_YS_SD_SDMAUF_0000152718": "参照模板",
      "P_YS_SD_SDSD-FE_0001491115": "金額",
      "P_YS_FED_FW_0000022452": "意大利语",
      "P_YS_SD_UDHAR_0000053500": "可用",
      "P_YS_PF_GZTSYS_0001085814": "友空间投屏",
      "P_YS_PF_GZTSYS_0000012057": "公告设置",
      "P_YS_FED_UCFBASEDOC_0000025656": "請假",
      "P_YS_OA_XTMENHUCMS_0000044972": "图片上传成功",
      "P_YS_OA_XTMENHU_0000046825": "文件上传",
      "P_YS_PF_PROCENTER_0000027411": "仓库档案",
      "P_YS_FED_FW_0000022461": "栏目名称/公式为必输项！请填写完整后重试！",
      "P_YS_FED_FW_0000021130": "在线",
      "P_YS_PF_GZTTMP_0000073639": "无限制",
      "P_YS_YC_BID-PROJECT_0001575935": "已打包",
      "P_YS_FED_EXAMP_0000020201": "角色",
      "P_YS_PF_METADATA_0000086463": "关联关系",
      "P_YS_OA_XTMENHU_0000046819": "导入用户",
      "P_YS_FED_FW_0000022456": "设计",
      "P_YS_FED_UCFBASEDOC_0000025404": "修改成功",
      "P_YS_OA_XTLCZX_0000030733": "完成时间",
      "P_YS_PF_PROCENTER_0000023057": "库存单位",
      "RPA_L23_00050024": "数据集下载",
      "P_YS_PF_GZTSYS_0000013133": "调班",
      "P_YS_FI_CM_0000026206": "已生成",
      "P_YS_PF_GZTSYS_0000012279": "销售发货",
      "P_YS_FED_UIMG_0000025836": "系统预置",
      "P_YS_PF_GZTSYS_0000013382": "表单模板",
      "P_YS_PF_PRINT_0000065047": "单据id",
      "P_YS_PF_PRINT_0000065042": "物料编码",
      "P_YS_PF_GZTSYS_0001066094": "分析卡片",
      "P_YS_OA_XTSPZX_0000034668": "请输入名称",
      "OA_web_navsetting_000000021": "我",
      "P_YS_FED_FW_0000021100": "表",
      "P_YS_OA_XTRIZHI_0000036771": "说明",
      "P_YS_PF_YHTTNT-FE_0001045014": "备注名",
      "P_YS_PF_GZTSYS_0000012530": "智能发件柜",
      "P_YS_PF_GZTSYS_0000013621": "批次号",
      "P_YS_PF_GZTSYS_0000012532": "投票",
      "P_YS_YYGJFW_YBUC_0001336704": "更新内容",
      "P_YS_FED_FW_0000021954": "系统项，不允许设计！",
      "P_YS_PF_METADATA_0000084513": "插件名称",
      "P_YS_FI_FCWEB_0001047450": "成员类型",
      "OA_web_ad_0000000103": "失效时间",
      "P_YS_PF_GZTVPA_0001126369": "画布",
      "P_YS_PF_GZTSYS_0000056369": "业务流",
      "P_YS_SD_SDMA_0000063372": "停用成功！",
      "P_YS_PF_GZTSYS_0000012545": "元数据",
      "P_YS_PF_GZTSYS_0000012786": "部门放休",
      "P_YS_OA_app_xtywlj_0000037486": "自建",
      "P_YS_PF_MANAGER-FE_0001620641": "取消完成",
      "P_YS_FED_IMP_IOT0000005448": "模板名称",
      "P_YS_FED_FW_0000157504": "树参照",
      "P_YS_PF_GUIDE_0001071547": "单组织",
      "P_YS_PF_GZTSYS_0000012548": "入职转单",
      "P_YS_FED_FW_0000021723": "已启用",
      "P_YS_FED_FW_0000021962": "是否显示",
      "P_YS_PF_PROCENTER_0000027456": "手工输入",
      "P_YS_FED_FW_0000021721": "】么？",
      "P_YS_FED_UCFBASEDOC_0000025160": "权限",
      "P_YS_OA_app_xtywlj_0000037693": "重试次数",
      "P_YS_FED_FW_0000021935": "请选中一行后增加！",
      "P_YS_PF_GZTSYS_0000012753": "工作邮箱",
      "P_YS_FED_UCFORG_0000031966": "上一步",
      "P_YS_YYGJFW_YBUC_0001336920": "你确定删除",
      "P_YS_SD_UDHWN_0000120990": "文件上传成功",
      "P_YS_PF_GZTSYS_0001106345": "数据中台",
      "P_YS_FED_UCFBASEDOC_0000025158": "假勤代申請",
      "P_YS_PF_GZTSYS_0000012759": "客户档案",
      "P_YS_PF_GZTSYS_0001106342": "查企业",
      "P_YS_FED_UIMG_0000111351": "表达式不能为空",
      "P_YS_PF_GZTPRE_0001118984": "请选择元数据",
      "P_YS_PF_GZTSYS_0000012765": "我的团队",
      "P_YS_FED_FW_0000021947": "多语言设置",
      "P_YS_FED_EXAMP_0000020662": "复制成功",
      "P_YS_OA_XTLCZX_0000030711": "单位",
      "P_YS_OA_XTRIZHI_0000036745": "所有人可见",
      "P_YS_PF_GZTSYS_0000012529": "转正",
      "P_YS_FED_FW_0000021941": "德语",
      "P_YS_FED_UCFBASEDOC_0000025386": "作废",
      "P_YS_FI_CM_0000029774": "已开通",
      "P_YS_FED_IMP_IOT_0000129734": "请输入功能名称",
      "P_YS_FI_FCWEB_0001048516": "聚合",
      "P_YS_PF_GZTSYS_0000012580": "个人信息",
      "P_YS_PF_PROCENTER_0000022808": "保质期单位",
      "P_YS_PF_GZTORG_0000174966": "请确认",
      "P_YS_FED_IMP_IOT0000005413": "操作",
      "P_YS_OA_XTTMCENTER_0000035086": "自建应用",
      "P_YS_PF_METADATA_0000085880": "可用数量",
      "P_YS_FED_FW_0001100234": "该操作，对必输项目<%=confirmMSGFields%>设置了隐藏，请再次确认",
      "P_YS_FED_UCFBASEDOC_0000025138": "微郵",
      "P_YS_FED_FW_0001050721": "确定删除栏目\"<%=cShowCaptionName%>\"么?",
      "P_YS_FED_UCFBASEDOC_0000025134": "團隊目標",
      "P_YS_FED_FW_0001050720": "编辑<%=templateName%>",
      "P_YS_SD_SDMAUF_0000153152": "还剩",
      "P_YS_PF_PRINT_0000057169": "模板id",
      "P_YS_PF_GZTSYS_0000012594": "任务",
      "P_YS_PF_GZTSYS_0000012590": "我的目标",
      "P_YS_PF_GZTSYS_0001207021": "销售下单",
      "P_YS_FED_FW_0000021769": "精度",
      "P_YS_IPAAS_APIC-FE_0001577501": "提供者",
      "P_YS_OA_XTTMCENTER_0000035090": "创建人",
      "P_YS_FED_FW_0000021766": "返回",
      "P_YS_CTM_STCT-BE_0001315265": "没有选中的数据",
      "P_YS_HR_HRJXF_0000059300": "设置失败",
      "P_YS_PF_GZTSYS_0000012561": "仓库",
      "P_YS_FED_UIMG_0001050970": "隐藏关联字段会对运行时产生影响，请谨慎操作",
      "P_YS_FED_FW_0000021735": "韩语",
      "P_YS_FED_FW_0000021971": "显示名称",
      "P_YS_FED_FW_0001050719": "查看<%=templateName%>",
      "P_YS_PF_GZTSYS_0000015829": "报表",
      "P_YS_IPAAS_UCG_0001112295": "部署",
      "P_YS_HR_HRJQ_0000031322": "格式错误",
      "RPA_L0_00050021": "设计器",
      "P_YS_HR_HRPUBF_0000053688": "设置成功",
      "P_YS_FED_UIMG_0001050967": "模板校验失败",
      "P_YS_IPAAS_UCG_0001072575": "API发布",
      "P_YS_FED_UIMG_0001050968": "当前搜索结果对应实体元数据的属性，非UI元数据属性",
      "P_YS_FED_UIMG_0001050969": "确定删除栏目",
      "P_YS_SD_UDHMC_0001070581": "蓝色主题",
      "P_YS_FED_FW_0000021981": "默认值",
      "P_YS_FED_UCFBASEDOC_0000025348": "企業介紹",
      "P_YS_OA_XTWENKU_0000035597": "新建成功",
      "P_YS_OA_LCGL_0000037122": "创建成功",
      "P_YS_FED_FW_0000021743": "数据类型",
      "P_YS_FI_CM_0000029174": "备注",
      "P_YS_OA_XTLCZX_0000032030": "前往",
      "P_YS_PF_GZTSYS_0000135427": "应用构建",
      "P_YS_SCM_USTOCK_0000064365": "到期",
      "P_YS_SD_SDMA_0000060271": "二维码",
      "P_YS_FED_MDF-COMPS_0001378486": "基准语言",
      "P_YS_PF_MANAGER-FE_0001470871": "一级菜单",
      "P_YS_FED_UCFORG_0000025917": "启用失败",
      "P_YS_FED_EXAMP_0000020808": "名称",
      "000206": "设置变量",
      "P_YS_FED_MDF-COMPS_0001378485": "【提示】若企业账号语言（<%=enterprise%>）内容未维护，系统将默认按当前语言（<%=current%>）内容赋值处理",
      "P_YS_FED_MDF-COMPS_0001378484": "基准语言为空时，则默认取当前语言内容。",
      "P_YS_OA_XTTMCENTER_0000035056": "更新时间",
      "P_YS_PF_GZTSYS_0000012709": "离职",
      "P_YS_OA_LCGL_0000037147": "导入成功",
      "P_YS_FED_MDF-COMPS_0001623383": "文庫",
      "P_YS_FED_FW_0000022195": "是否必输",
      "P_YS_HR_HRJQF_0000053833": "不通过",
      "P_YS_FED_MDF-COMPS_0001623382": "系统应用管理",
      "P_YS_FED_MDF-COMPS_0001623385": "申请历史",
      "P_YS_FED_MDF-COMPS_0001623384": "确定删除当前描述文件吗?删除后不可找回, 请谨慎操作",
      "P_YS_FED_MDF-COMPS_0001623387": "应用引擎:",
      "P_YS_FED_MDF-COMPS_0001623386": "错误率",
      "P_YS_FED_MDF-COMPS_0001623389": "图文卡片",
      "P_YS_FED_MDF-COMPS_0001623388": "当前租户未开通专业版！",
      "P_YS_SD_UDHWN_0000120595": "正在",
      "P_YS_FED_MDF-COMPS_0001623390": "流程&自动化",
      "P_YS_FED_MDF-COMPS_0001623392": "指纹信息",
      "P_YS_FED_MDF-COMPS_0001623391": "别名（alias）",
      "P_YS_SD_SDMB_0000146297": "用户名/手机号/邮箱",
      "P_YS_FED_MDF-COMPS_0001623394": "管理成员 (增删) | 开发应用",
      "P_YS_FED_MDF-COMPS_0001623393": "部門排班",
      "P_YS_FED_MDF-COMPS_0001623396": "資產盤點移動應用",
      "P_YS_FED_MDF-COMPS_0001623395": "启动闪屏",
      "P_YS_FED_MDF-COMPS_0001623398": "申请已提交",
      "P_YS_FED_MDF-COMPS_0001623397": "签名证书",
      "P_YS_HR_HRJQ_0000031367": "暂无",
      "P_YS_FED_MDF-COMPS_0001623399": "请输入应用编码",
      "P_YS_PF_METADATA_0001162380": "堆栈信息",
      "RPA_L27_00050006": "重试配置",
      "P_YS_PF_METADATA_0001038878": "变量值",
      "P_YS_HR_HRPXF_0000056097": "扫码",
      "P_YS_HR_HRJXF_0000059361": "参数配置",
      "000652": "报告设置",
      "P_YS_FED_MDF-COMPS_0001623361": "包名",
      "P_YS_FED_MDF-COMPS_0001623360": "页面编码长度应小于50个字符",
      "P_YS_FED_MDF-COMPS_0001623363": "占用沙箱",
      "P_YS_FED_MDF-COMPS_0001623362": "证书使用中，不能删除",
      "P_YS_FED_MDF-COMPS_0001623365": "未收集到日志信息，请尝试重新打包",
      "P_YS_FED_MDF-COMPS_0001623364": "系统预置功能，包含工作台、通讯录、我的，需登录后使用",
      "P_YS_FED_MDF-COMPS_0001623367": "导入用户发生错误",
      "P_YS_FED_MDF-COMPS_0001623366": "切换后，当前应用权限为仅成员可操作",
      "P_YS_FED_MDF-COMPS_0001623369": "确定新增PC页面吗?",
      "P_YS_FED_MDF-COMPS_0001623368": "长度应小于100个字符",
      "RPA_L27_00050017": "收件人邮箱",
      "P_YS_SD_SDMB_0000138699": "分组列表",
      "P_YS_FED_FW_0000021911": "替换图片",
      "P_YS_SD_SDSD-FE_0001486259": "日期時間",
      "P_YS_PF_GZTSYS_0000011886": "文库",
      "P_YS_FED_MDF-COMPS_0001573056": "日期时间测试",
      "P_YS_FED_MDF-COMPS_0001573057": "固定資產檔案導出測試",
      "P_YS_PF_PRINT_0000056908": "表头自定义项24",
      "P_YS_SD_SDSD-FE_0001491840": "單據日期",
      "P_YS_PF_PRINT_0000056906": "表头自定义项20",
      "P_YS_PF_PRINT_0000056905": "表头自定义项21",
      "P_YS_FED_MDF-COMPS_0001573058": "z0客户3255830",
      "P_YS_FED_MDF-COMPS_0001623370": "app打包异常",
      "P_YS_FED_MDF-COMPS_0001573059": "表體-0528test",
      "P_YS_FED_MDF-COMPS_0001623372": "请输入扩展行数",
      "P_YS_FED_MDF-COMPS_0001623371": "请使用友空间扫一扫功能预览页面",
      "P_YS_PF_PRINT_0000056911": "表头自定义项22",
      "P_YS_FED_MDF-COMPS_0001623374": "任务取消重试",
      "P_YS_PF_PRINT_0000056910": "表头自定义项23",
      "P_YS_FED_MDF-COMPS_0001623373": "编码不能是特殊字段!",
      "P_YS_FED_MDF-COMPS_0001623376": "未查询到打包的日志信息！",
      "P_YS_FED_MDF-COMPS_0001623375": "已选择成员(",
      "P_YS_FED_MDF-COMPS_0001623378": "Tip:大小不超过20kb，尺寸122px*122px的PNG图标",
      "P_YS_FED_MDF-COMPS_0001623377": "仅成员可见",
      "RPA_L27_00050026": "部署机器人",
      "P_YS_FED_MDF-COMPS_0001623379": "今日设备数",
      "P_YS_PF_GZTSYS_0000135219": "智能分析",
      "RPA_L27_00050027": "所属客户端",
      "P_YS_FED_MDF-COMPS_0001573060": "庫存組織",
      "P_YS_PF_GZTSYS_0000012745": "自定义档案",
      "P_YS_PF_GZTSYS_0000012744": "员工问询",
      "P_YS_FED_FW_0000021928": "金额精度",
      "P_YS_FED_MDF-COMPS_0001573063": "產地",
      "P_YS_FED_MDF-COMPS_0001573064": "ycx档案类型",
      "P_YS_PF_GZTSYS_0000011891": "视频会议",
      "P_YS_FED_MDF-COMPS_0001573061": "庫管員",
      "P_YS_PF_GZTSYS_0000012741": "智能取件柜",
      "P_YS_FED_MDF-COMPS_0001573062": "型號",
      "P_YS_PF_GZTSYS_0000012740": "日志",
      "P_YS_FED_MDF-COMPS_0001573067": "您对必输项设置了隐藏，请注意！",
      "P_YS_PF_PROCENTER_0000022885": "保质期",
      "P_YS_FED_MDF-COMPS_0001573068": "交易類型",
      "P_YS_FED_MDF-COMPS_0001573065": "NN表頭數值0630",
      "P_YS_FED_MDF-COMPS_0001573066": "庫存單位",
      "P_YS_FED_MDF-COMPS_0001573069": "表頭-0528test",
      "P_YS_FED_MDF-COMPS_0001623381": "确定新增移动页面吗?",
      "P_YS_PF_GZTSYS_0000012748": "团队目标",
      "P_YS_FED_MDF-COMPS_0001623380": "增补成功",
      "P_YS_FED_MDF-COMPS_0001623581": "查看上架应用",
      "P_YS_FED_MDF-COMPS_0001623580": "最多可设置24个常用应用，按住拖拽可进行排序",
      "P_YS_FED_MDF-COMPS_0001623341": "嘟嘟雲總機",
      "P_YS_FED_MDF-COMPS_0001623583": "领域开通耗时10-20分钟，请耐心等待",
      "P_YS_FED_MDF-COMPS_0001623340": "移动列表",
      "P_YS_FED_MDF-COMPS_0001623582": "记住密码框",
      "P_YS_FED_UIMG_0001065827": "非数值类型的字段不能合计",
      "P_YS_FED_MDF-COMPS_0001623343": "编辑沙箱",
      "P_YS_FED_MDF-COMPS_0001623585": "不超1MB",
      "P_YS_FED_MDF-COMPS_0001623342": "未勾选的定时规则，将不予不保存，是否继续？",
      "P_YS_FED_MDF-COMPS_0001623584": "权限发布",
      "P_YS_OA_XTWENKU_0000035509": "文件名",
      "P_YS_FED_MDF-COMPS_0001623345": "Android 6.0以上",
      "P_YS_FED_MDF-COMPS_0001623587": "已存在包名（",
      "P_YS_FED_MDF-COMPS_0001623344": "累计错误数",
      "P_YS_FED_MDF-COMPS_0001623586": "请输入页面编码",
      "P_YS_FED_MDF-COMPS_0001623347": "点击查看插件信息",
      "P_YS_FED_MDF-COMPS_0001623589": "证书签名",
      "P_YS_FED_MDF-COMPS_0001623346": "请输入隐私协议地址！",
      "P_YS_FED_MDF-COMPS_0001623588": "请选择组织模式",
      "P_YS_FED_MDF-COMPS_0001623349": "当前应用内的服务将全部停用，请谨慎操作。",
      "P_YS_OA_XTTMCENTER_0000035019": "用户名",
      "P_YS_FED_MDF-COMPS_0001623348": "已关联APP配置",
      "P_YS_SD_URETAIL-UI_0001150860": "计划盘点",
      "P_YS_PF_BD-UI_0000157890": "机器人",
      "P_YS_PF_GZTVPA_0001190941": "可更新",
      "P_YS_OA_XTTMCENTER_0000035020": "操作时间",
      "P_YS_FI_FCWEB_0001047849": "模板生成成功",
      "P_YS_PF_METADATA_0000084937": "证书名称",
      "P_YS_SD_SDMA_0000061789": "客户端",
      "P_YS_SCM_STOCK_0000034250": "版本号",
      "P_YS_FED_MDF-COMPS_0001623590": "选择上架，前一个上架版本将自动下架",
      "P_YS_FED_MDF-COMPS_0001623350": "应用主页",
      "P_YS_FED_MDF-COMPS_0001623592": "登录方式",
      "P_YS_FED_UCFBASEDOC_0000025199": "表单",
      "P_YS_FED_MDF-COMPS_0001623591": "图片大小不能超过300KB",
      "P_YS_FED_MDF-COMPS_0001623352": "访问APP设置",
      "P_YS_FED_MDF-COMPS_0001623594": "生成单卡页面",
      "P_YS_PF_PROCENTER_0000027079": "用户",
      "P_YS_FED_MDF-COMPS_0001623351": "），请到列表上进行修改",
      "P_YS_FED_MDF-COMPS_0001623593": "今日启动次数",
      "P_YS_FED_MDF-COMPS_0001623354": "请选择管理员",
      "P_YS_FED_MDF-COMPS_0001623596": "请输入更新内容",
      "P_YS_FED_MDF-COMPS_0001623353": "请输入PC端页面名称",
      "P_YS_FED_MDF-COMPS_0001623595": "新建微信小程序",
      "P_YS_FED_MDF-COMPS_0001623356": "选择下架，上一个历史上架版本将恢复上架状态",
      "P_YS_FED_MDF-COMPS_0001623598": "不带默认环境",
      "P_YS_FED_MDF-COMPS_0001623355": "PC端名称",
      "P_YS_FED_MDF-COMPS_0001623597": "请上传配置文件",
      "RPA_L27_00050048": "部署详情",
      "P_YS_FED_MDF-COMPS_0001623358": "显示隐藏应用",
      "P_YS_FED_UIMG_0000080080": "关联字段不允许隐藏",
      "P_YS_OA_app_xtyyjm_0000035918": "关联配置",
      "P_YS_FED_MDF-COMPS_0001623357": "请输入移动端页面名称",
      "P_YS_FED_MDF-COMPS_0001623599": "】应用？",
      "RPA_L27_00050045": "待机",
      "P_YS_FED_MDF-COMPS_0001623359": "测试沙箱(",
      "P_YS_PF_GZTSYS_0000012963": "库管员",
      "P_YS_FED_FW_0000021900": "确定删除栏目【",
      "P_YS_PF_GZTSYS_0000011876": "日程",
      "P_YS_OA_XTTXL_0000045984": "离线",
      "P_YS_PF_GZTSYS_0000011872": "云直播",
      "P_YS_SD_URETAIL-UI_0001150858": "日常盘点",
      "P_YS_PF_GZTSYS_0000012725": "员工信息",
      "P_YS_PF_GZTSYS_0000011877": "话题",
      "P_YS_PF_GZTSYS_0000011879": "微邮",
      "P_YS_SCM_STOCK_0000034341": "单价",
      "P_YS_FED_MDF-COMPS_0001623680": "删除沙箱",
      "P_YS_FED_FW_0000022371": "当前语种",
      "P_YS_OA_XTLCZX_0000030494": "折叠",
      "P_YS_FED_MDF-COMPS_0001623440": "证书",
      "P_YS_FED_MDF-COMPS_0001623682": "请先启用轮播图再进行配置~",
      "P_YS_FED_MDF-COMPS_0001623681": "上下架规则",
      "P_YS_FED_MDF-COMPS_0001623442": "已添加用户",
      "P_YS_FED_MDF-COMPS_0001623684": "友空间rrr",
      "P_YS_FED_MDF-COMPS_0001623441": "建议1080*1920px",
      "P_YS_FED_MDF-COMPS_0001623683": "菜单结构",
      "P_YS_FED_FW_0000022130": "系统项不允许进行删除操作！",
      "P_YS_OA_app_xtyyjm_0000035805": "必选",
      "P_YS_FED_MDF-COMPS_0001623444": "小程序名称",
      "P_YS_FED_MDF-COMPS_0001623686": "没有选中的实体",
      "P_YS_FED_FW_0000021042": "完成",
      "P_YS_FED_MDF-COMPS_0001623443": "上传文件格式错误，支持上传的文件格式为：png、jpeg、jpg",
      "P_YS_FED_MDF-COMPS_0001623685": "包名不能为空！",
      "P_YS_FED_MDF-COMPS_0001623446": "确定要删除这条吗？",
      "P_YS_FED_MDF-COMPS_0001623688": "累计启动次数",
      "P_YS_SD_SDSD-FE_0001486293": "個人信息",
      "P_YS_FED_MDF-COMPS_0001623445": "” 已复制到粘贴板",
      "P_YS_FED_MDF-COMPS_0001623687": "全部显示(",
      "P_YS_FED_MDF-COMPS_0001623448": "(移动端)",
      "P_YS_FED_MDF-COMPS_0001623447": "请确认包名是否存在~",
      "P_YS_FED_MDF-COMPS_0001623689": "你确定删除页面吗?",
      "P_YS_SD_SDSD-FE_0001486298": "創建人",
      "P_YS_PF_GZTTMP_0000077237": "型号",
      "P_YS_FED_MDF-COMPS_0001623449": "隐私协议名称",
      "P_YS_PF_GZTSYS_0000134736": "报账应用",
      "P_YS_PF_PRINT_0000065776": "物料sku名称",
      "P_YS_FED_MDF-COMPS_0001573092": "请选择操作行",
      "P_YS_FED_MDF-COMPS_0001573093": "z0客户3627661",
      "P_YS_FED_MDF-COMPS_0001573090": "單據編號",
      "P_YS_FED_MDF-COMPS_0001573091": "likun-表頭自定義項[文本]",
      "P_YS_FED_MDF-COMPS_0001573096": "likun-表體自定義項[文本]",
      "P_YS_PF_PC-UI_0001072138": "显示项，不用输入",
      "P_YS_FED_MDF-COMPS_0001573097": "必輸，請精確到sku",
      "P_YS_FED_MDF-COMPS_0001573094": "保質期",
      "P_YS_YYGJFW_YBUC_0001337239": "页面编码",
      "P_YS_FED_MDF-COMPS_0001573095": "wjl测试自定义项",
      "P_YS_FED_MDF-COMPS_0001573098": "wjl測試自定義項",
      "P_YS_FED_MDF-COMPS_0001573099": "件數",
      "P_YS_FED_UCFBASEDOC_0000024885": "員工問詢",
      "P_YS_FED_FW_0000022127": "俄语",
      "P_YS_FED_MDF-COMPS_0001623691": "扩展",
      "P_YS_FED_MDF-COMPS_0001623690": "启动次数",
      "P_YS_FED_MDF-COMPS_0001623451": "主子表列表",
      "P_YS_FED_MDF-COMPS_0001623693": "请输入包名",
      "P_YS_FED_MDF-COMPS_0001623450": "长度应小于30个字符",
      "P_YS_FED_MDF-COMPS_0001623692": "错误次数",
      "P_YS_FED_MDF-COMPS_0001623453": "未限制",
      "P_YS_FED_MDF-COMPS_0001623695": "系统插件",
      "P_YS_OA_app_xtyyjm_0000035815": "请输入页面名称",
      "P_YS_FED_MDF-COMPS_0001623452": "扩展应用",
      "P_YS_FED_MDF-COMPS_0001623694": "该插件已存在相同版本",
      "P_YS_FED_MDF-COMPS_0001623455": "RPA官网",
      "P_YS_FED_MDF-COMPS_0001623697": "请上传默认图标",
      "P_YS_FED_MDF-COMPS_0001623454": "请输入APP配置名称",
      "P_YS_FED_MDF-COMPS_0001623696": "打包信息不能为空！",
      "P_YS_FED_MDF-COMPS_0001623457": "应用名称不能为空!",
      "P_YS_FED_MDF-COMPS_0001623699": "扩展原厂页面",
      "P_YS_FED_MDF-COMPS_0001623456": "结构/首页",
      "P_YS_FED_MDF-COMPS_0001623698": "通用名称",
      "P_YS_FED_MDF-COMPS_0001623459": "推荐主题",
      "P_YS_FED_MDF-COMPS_0001623458": "调度",
      "P_YS_FED_MDF-COMPS_0001209115": "版本版次规则使用",
      "P_YS_FED_MDF-COMPS_0001209116": "版本版次规则",
      "P_YS_FED_MDF-COMPS_0001209114": "研发建模服务-前端",
      "P_YS_PF_PROCENTER_0000022686": "单据日期",
      "P_YS_FED_FW_0000022137": "项目名称",
      "P_YS_FED_UCFBASEDOC_0000025724": "API授权",
      "P_YS_FED_UCFBASEDOC_0000024878": "會議管理",
      "P_YS_FED_UCFBASEDOC_0000024874": "角色名称",
      "P_YS_FED_MDF-COMPS_0001623660": "友報賬",
      "P_YS_FED_MDF-COMPS_0001623420": "复制下载链接",
      "P_YS_FED_MDF-COMPS_0001623662": "主题&导航",
      "P_YS_FED_MDF-COMPS_0001623661": "支持在离线状态下使用工作台",
      "P_YS_FED_MDF-COMPS_0001623422": "正在开发中,请等待。。。。。",
      "P_YS_FED_MDF-COMPS_0001623664": "打包信息",
      "P_YS_FED_MDF-COMPS_0001623421": "选择客户端",
      "P_YS_FED_MDF-COMPS_0001623663": "報賬應用",
      "P_YS_FED_MDF-COMPS_0001623424": "格式错误，正确格式为：AA.BB.CC，第一个A不能为0，且只能包含数字和点，点分割开的数字可以只有一个数字；例如：2.06.9；范围：1.0.0~99.99.99",
      "P_YS_FED_MDF-COMPS_0001623666": "删除移动端",
      "P_YS_FED_MDF-COMPS_0001623423": "我的反饋",
      "P_YS_FED_MDF-COMPS_0001623665": "工作台配置",
      "P_YS_FED_MDF-COMPS_0001623426": "运行分析",
      "P_YS_FED_MDF-COMPS_0001623668": "错误时间",
      "P_YS_FED_MDF-COMPS_0001623425": "普通上架，上架该版本，不强制更新",
      "P_YS_FED_MDF-COMPS_0001623667": "请查看校验项",
      "P_YS_FED_MDF-COMPS_0001623428": "操作系统",
      "P_YS_FED_MDF-COMPS_0001623427": "]将被删除",
      "P_YS_FED_MDF-COMPS_0001623669": "扫码安装APP",
      "P_YS_FED_MDF-COMPS_0001623429": "默认开通数字化建模和应用构建，领域开通需要申请。",
      "P_YS_FED_MDF-COMPS_0001573070": "NN物料查询1",
      "P_YS_FED_MDF-COMPS_0001573071": "请重新选择，输入数字大于可选最大值",
      "P_YS_PF_EVENTCENTER-MD_0001414933": "应用类别",
      "P_YS_FED_MDF-COMPS_0001573074": "專案編碼",
      "P_YS_SD_SDMA_0000125259": "轮播图",
      "P_YS_FED_MDF-COMPS_0001573075": "数值型字段",
      "P_YS_FED_MDF-COMPS_0001573072": "數值型字段",
      "P_YS_FED_MDF-COMPS_0001573073": "请重新选择，输入数字小于可选最小值",
      "P_YS_FED_MDF-COMPS_0001573078": "物料sku編碼",
      "P_YS_FED_FW_0000021015": "日期",
      "P_YS_FED_MDF-COMPS_0001573079": "自定义项目测试202008060001",
      "P_YS_FED_MDF-COMPS_0001573076": "計量單位",
      "P_YS_FED_MDF-COMPS_0001573077": "固定资产档案导出测试",
      "P_YS_FED_FW_0000022105": "请选择行再进行上移操作！",
      "P_YS_PF_ECON-FE_0001152750": "编辑应用",
      "P_YS_PF_GZTSYS_0000134745": "人力应用",
      "P_YS_FED_MDF-COMPS_0001623671": "公共引擎",
      "P_YS_FED_MDF-COMPS_0001623670": "启用沙箱",
      "P_YS_FED_MDF-COMPS_0001623431": "链接目标",
      "P_YS_FED_MDF-COMPS_0001623673": "图片尺寸错误，请上传750*244以内的图片",
      "P_YS_PF_GZTSYS_0000134741": "企业文化",
      "P_YS_FED_MDF-COMPS_0001623430": "开发沙箱",
      "P_YS_FED_MDF-COMPS_0001623672": "部署状态",
      "P_YS_FED_FW_0000022361": "格式化",
      "P_YS_FED_MDF-COMPS_0001623433": "最后打包时间",
      "P_YS_FED_MDF-COMPS_0001623675": "及其下面的服务吗?",
      "P_YS_FED_FW_0000021031": "日期时间",
      "P_YS_FED_FW_0000022362": "编辑区",
      "P_YS_FED_MDF-COMPS_0001623432": "插件配置",
      "P_YS_FED_MDF-COMPS_0001623674": "启动轮播图",
      "P_YS_FED_MDF-COMPS_0001623435": "你确定删除应用么",
      "P_YS_FED_MDF-COMPS_0001623677": "确定切换权限？",
      "P_YS_FED_MDF-COMPS_0001623434": "資產管理",
      "P_YS_FED_MDF-COMPS_0001623676": "主表列表",
      "P_YS_FED_MDF-COMPS_0001623437": "更换证书可能导致应用安装冲突或提交应用市场失败，如果您不了解相关机制请谨慎选择，确定要更换证书吗？",
      "P_YS_FED_MDF-COMPS_0001623679": "移动端页面名称",
      "P_YS_FED_MDF-COMPS_0001623436": "许可详情>",
      "P_YS_FED_MDF-COMPS_0001623678": "机器人可以在流程、页面中, 也可以通过API或定时调度任务启动运行,",
      "P_YS_FED_MDF-COMPS_0001623439": "说明：建议上传700*390图片",
      "P_YS_FED_MDF-COMPS_0001623438": "添加证书",
      "P_YS_FED_MDF-COMPS_0001573081": "業務員",
      "P_YS_SD_SDMA_0000125244": "其他设置",
      "P_YS_FED_MDF-COMPS_0001573082": "时间H",
      "P_YS_FED_UCFBASEDOC_0000025709": "項目",
      "P_YS_FED_MDF-COMPS_0001573080": "物料sku名稱",
      "P_YS_FED_MDF-COMPS_0001573085": "自定義檔案",
      "P_YS_FED_MDF-COMPS_0001573086": "批次號",
      "P_YS_FED_MDF-COMPS_0001573083": "跟踪号123",
      "P_YS_PF_GZTORG_0000035173": "修改人",
      "P_YS_FED_MDF-COMPS_0001573084": "likun-表头自定义项[文本]",
      "P_YS_YYGJFW_YBUC_0001315675": "网页端",
      "P_YS_FED_FW_0000022114": "会计主体",
      "P_YS_FED_MDF-COMPS_0001573089": "門店",
      "P_YS_FED_MDF-COMPS_0001573087": "會計主體",
      "P_YS_FED_MDF-COMPS_0001573088": "NN表头数值0630",
      "P_YS_OA_app_xtyyjm_0000035830": "申请",
      "P_YS_OA_XTLCZX_0000030451": "审批人",
      "P_YS_OA_XTLCZX_0000030692": "进行中",
      "P_YS_FED_MDF-COMPS_0001623640": "用于测试更改或新应用程序而不会损坏您的生产数据或配置。",
      "P_YS_FED_MDF-COMPS_0001623400": "导入机器人",
      "P_YS_FED_MDF-COMPS_0001623642": "新建APP",
      "P_YS_FED_MDF-COMPS_0001623641": "沙箱列表",
      "P_YS_FED_MDF-COMPS_0001623402": "张张张",
      "P_YS_FED_MDF-COMPS_0001623644": "沙箱内数据将被完全删除且操作无法撤回。",
      "P_YS_FED_MDF-COMPS_0001623401": "链接地址:",
      "P_YS_FED_MDF-COMPS_0001623643": "累计设备数",
      "P_YS_FED_MDF-COMPS_0001623404": "提交中",
      "P_YS_FED_MDF-COMPS_0001623646": "沙箱历史",
      "P_YS_FED_MDF-COMPS_0001623403": "添加PC端",
      "P_YS_FED_MDF-COMPS_0001623645": "工作電郵",
      "P_YS_PLM_BASE-UI_0001105578": "左树右表",
      "P_YS_FED_IMP_IOT_0001069279": "友户通",
      "P_YS_FED_MDF-COMPS_0001623406": "手机型号",
      "P_YS_FED_MDF-COMPS_0001623648": "上次",
      "P_YS_FED_MDF-COMPS_0001623405": "主题&导航配置预览",
      "P_YS_FED_MDF-COMPS_0001623647": "模板示例",
      "P_YS_FED_MDF-COMPS_0001623408": "下载安装包",
      "P_YS_FED_MDF-COMPS_0001623407": "最新版本：",
      "P_YS_FED_MDF-COMPS_0001623649": "人力應用",
      "P_YS_FED_MDF-COMPS_0001623409": "跳过广告",
      "P_YS_SD_SDSD-FE_0001184978": "页面信息",
      "P_YS_FED_FW_0000022167": "当前模板无数据",
      "P_YS_FED_FW_0000021093": "累计",
      "P_YS_FED_MDF-COMPS_0001623651": "获取沙箱失败",
      "P_YS_FED_FW_0000022188": "更新",
      "P_YS_FED_MDF-COMPS_0001623650": ") 的配置,是否确认修改该配置？",
      "P_YS_FED_MDF-COMPS_0001623411": "禁用长按",
      "P_YS_FED_MDF-COMPS_0001623653": "同时生成",
      "P_YS_FED_MDF-COMPS_0001623410": "许可到期日",
      "P_YS_FED_MDF-COMPS_0001623652": "建议1024*1024px",
      "P_YS_FED_MDF-COMPS_0001623413": "导出配置文件",
      "P_YS_FED_MDF-COMPS_0001623655": "标题字节长度应小于8",
      "P_YS_FED_MDF-COMPS_0001623412": "请输入文件名称,别名搜索",
      "P_YS_FED_MDF-COMPS_0001623654": "新建证书需跳转到证书管理页面，当前页面数据会以草稿态保存到 App 列表中。",
      "P_YS_FED_MDF-COMPS_0001623415": "保存并关闭",
      "P_YS_FED_MDF-COMPS_0001623657": "上传插件",
      "P_YS_FED_MDF-COMPS_0001623414": "示例页面",
      "P_YS_FED_MDF-COMPS_0001623656": "图标大小不能超过300kb",
      "P_YS_FED_MDF-COMPS_0001623417": "应用编码不能为空!",
      "P_YS_FED_MDF-COMPS_0001623659": "运行分析详情",
      "P_YS_FED_MDF-COMPS_0001623416": "应用隐藏后将在APP工作台中无法查看",
      "P_YS_FED_MDF-COMPS_0001623658": "配置参数",
      "P_YS_FED_MDF-COMPS_0001623419": "台账列表",
      "P_YS_FED_MDF-COMPS_0001623418": "强制上架",
      "P_YS_OA_app_xtyyjm_0000035850": "未发布",
      "P_YS_FED_UCFORG_0000025923": "启用成功",
      "P_YS_OA_app_xtyyjm_0000035868": "页面",
      "P_YS_FED_MDF-COMPS_0001064728": "【提示】若系统语言（<%=enterprise%>）内容未维护，系统将默认按当前语言（<%=current%>）内容赋值处理",
      "P_YS_PF_PRINT_0000056601": "扩展字段",
      "P_YS_FED_MDF-COMPS_0001623620": "申请提交失败",
      "P_YS_FED_MDF-COMPS_0001623622": "进入沙箱",
      "P_YS_OA_XTMENHUCMS_0000045092": "编辑导航",
      "P_YS_FED_MDF-COMPS_0001623621": "添加描述文件",
      "P_YS_FED_MDF-COMPS_0001623624": "证书文件不能为空！",
      "P_YS_FED_MDF-COMPS_0001623623": "允许跳过",
      "P_YS_FED_MDF-COMPS_0001623626": "图标大小不能超过20kb",
      "P_YS_FED_MDF-COMPS_0001623625": "当前应用内的服务将全部启用，请谨慎操作。",
      "P_YS_FED_MDF-COMPS_0001623628": "请重新设定版本号，并且高于",
      "P_YS_FED_MDF-COMPS_0001623627": "请选择实体字段",
      "P_YS_FED_MDF-COMPS_0001623629": "历史上架",
      "P_YS_SD_SCMSA-UI_0001345888": "占用数量",
      "P_YS_PF_PC-UI_0000160768": "调用方式",
      "P_YS_SD_SDSD-FE_0001491421": "我的團隊",
      "P_YS_SCM_USTOCK-UI_0001077879": "显示项，不需要输入",
      "P_YS_HR_HRRCPDF_0000073196": "创建时间:",
      "P_YS_OA_XTLCZX_0000030484": "请输入内容",
      "P_YS_FED_FW_0000022166": "是否合计",
      "P_YS_FED_MDF-COMPS_0001623631": "T+服務",
      "P_YS_FED_MDF-COMPS_0001623630": "添加签名文件",
      "P_YS_FED_MDF-COMPS_0001623633": "请输入6位数密码",
      "P_YS_YYGJFW_YBUC_0001336150": "链接方式",
      "P_YS_FED_MDF-COMPS_0001623632": "请选择需要从源环境同步到当前开发沙箱的用户(不含授权信息)",
      "P_YS_FED_MDF-COMPS_0001623635": "文件UUID",
      "P_YS_FED_MDF-COMPS_0001623634": "描述文件类型",
      "P_YS_FED_MDF-COMPS_0001623637": "了解详情, 请移步",
      "P_YS_FED_MDF-COMPS_0001623636": "请检查输入项！",
      "P_YS_PF_GZTSYS_0000096443": "多组织",
      "P_YS_FED_MDF-COMPS_0001623639": "上架设置",
      "P_YS_FI_GL_0000088698": "“",
      "P_YS_FED_MDF-COMPS_0001623638": "页面编码必须以业务对象code为前缀 (",
      "P_YS_SD_SDSD-FE_0001184980": "空白模板",
      "P_YS_OA_app_xtyyjm_0000035873": "已有表单",
      "P_YS_PF_PC-UI_0000159776": "上架状态",
      "P_YS_PF_GZTTMP_0000079010": "证书文件",
      "P_YS_PF_PRINT_0000057998": "上游单据号",
      "P_YS_OA_XTLCZX_0000030652": "创建时间",
      "P_YS_FED_IMP_IOT_0000129813": "设备数",
      "P_YS_SCM_STOCK_0000034501": "账号",
      "P_YS_FED_MDF-COMPS_0001623600": "白名单控制（在APP工作台仅显示以下应用）",
      "P_YS_OA_XTDYH_0000168902": "地址格式不正确！",
      "P_YS_FED_MDF-COMPS_0001623602": "选中图标",
      "P_YS_FED_MDF-COMPS_0001623601": "友户通令牌",
      "P_YS_FED_MDF-COMPS_0001623604": "APP标识",
      "P_YS_PES_PES-FE_0001108224": "申请说明",
      "P_YS_FED_MDF-COMPS_0001623603": "开发ing",
      "P_YS_FED_MDF-COMPS_0001623606": "暂无可申请领域",
      "P_YS_FED_MDF-COMPS_0001623605": "修改导航标题会同时作用于移动端底部导航标题及该导航对应的页面头部标题（动态页面外），导航图标只能上传不超过20kb，尺寸是122px X 122px 的png图标。",
      "P_YS_IPAAS_UCG_0001111718": "已部署",
      "P_YS_FED_MDF-COMPS_0001623608": "首页配置",
      "P_YS_FED_MDF-COMPS_0001623607": "最多只能添加24个常用应用",
      "P_YS_PF_YHTTNT-FE_0001045644": "导入用户成功",
      "P_YS_FED_MDF-COMPS_0001623609": "无限制（隐藏应用后在移动APP工作台中将无法查看）",
      "P_YS_HR_HRJQF_0000054381": "外勤打卡",
      "P_YS_YYGJFW_YBUC_0001336548": "版本号不能为空！",
      "P_YS_OA_app_xtyyjm_0000035882": "新建页面",
      "P_YS_FED_FW_0000021437": "结束日期",
      "P_YS_SD_SDSD-FE_0001491485": "規格",
      "P_YS_HR_HRJQF_0000054146": "入职",
      "P_YS_SD_SDSD-FE_0001491490": "單價",
      "P_YS_FED_MDF-COMPS_0001623611": "管理员（创建人）",
      "P_YS_FED_MDF-COMPS_0001623610": "应用所在位置: 工作台-",
      "P_YS_PF_GZTPRE_0000071064": "实体",
      "P_YS_FED_MDF-COMPS_0001623613": "页面名称长度不超过30个字符",
      "P_YS_FED_EXAMP_0000020130": "数量",
      "P_YS_FED_MDF-COMPS_0001623612": "请重新设定版本号，并且不小于",
      "P_YS_PF_PRINT_0000056670": "操作人",
      "P_YS_FED_MDF-COMPS_0001623615": "移动端名称",
      "P_YS_FED_MDF-COMPS_0001623614": "强制上架，上架该版本，强制更新",
      "P_YS_FED_MDF-COMPS_0001623617": "正在处理，暂不支持操作",
      "P_YS_FED_MDF-COMPS_0001623616": "请先新建分组",
      "P_YS_FED_MDF-COMPS_0001623619": "用户自建系统",
      "P_YS_FED_MDF-COMPS_0001623618": "配置成功",
      "P_YS_FED_EXAMP_0000020127": "上传失败",
      "P_YS_PF_GZTSYS_0000013478": "调动",
      "P_YS_FED_FW_0000021683": "英文",
      "P_YS_OA_XTLCZX_0000030656": "覆盖",
      "P_YS_FI_CM_0000029869": "请选择日期",
      "P_YS_FED_FW_0000021660": "单价精度",
      "P_YS_FED_EXAMP_0000020383": "请输入",
      "P_YS_PF_YHTTNT-FE_0001045672": "邀请时间",
      "P_YS_PF_GZTSYS_0000012596": "请假",
      "P_YS_PF_PRINT_0000065599": "组号",
      "P_YS_FED_UCFBASEDOC_0000025558": "手机验证码",
      "P_YS_HR_HRXC_0000177247": "最新版本",
      "P_YS_FED_FW_0000021651": "已选",
      "P_YS_OA_XTLCZX_0000030666": "描述",
      "P_YS_PF_PRINT_0000056891": "失效日期",
      "P_YS_SD_SDMAUF_0000151755": "定金",
      "P_YS_PF_GZTBDM_0000036680": "当前租户",
      "P_YS_PF_GZTSYS_0000012366": "扫码入职",
      "P_YS_FED_FW_0000022511": "移除",
      "P_YS_PF_PRINT_0000065360": "项目编码",
      "P_YS_HR_HRJQF_0000054355": "申请时间",
      "P_YS_OA_XTNEIBUQUN_0000046144": "取消管理员",
      "P_YS_OA_XTGONGGAO_0000045866": "请输入分类名称",
      "P_YS_PF_PRINT_0000057797": "表头自定义项4",
      "P_YS_FED_FW_0000021482": "基本信息",
      "P_YS_PF_PRINT_0000057795": "表头自定义项5",
      "P_YS_PF_PRINT_0000057794": "表头自定义项6",
      "P_YS_PF_PRINT_0000057793": "表头自定义项7",
      "P_YS_PF_PRINT_0000057792": "表头自定义项1",
      "P_YS_PF_PRINT_0000057791": "表头自定义项2",
      "P_YS_PF_PRINT_0000057790": "表头自定义项3",
      "P_YS_PF_PROCENTER_0001131746": "打印模板",
      "P_YS_YYGJFW_YBUC_0001315646": "成员管理",
      "P_YS_FED_UCFBASEDOC_0000025539": "權益",
      "P_YS_PF_PRINT_0000065174": "主计量",
      "P_YS_FED_FW_0000022323": "回车方向",
      "P_YS_PF_PROCENTER_0000027306": "编码:",
      "P_YS_FED_UIMG_0001088007": "关联tips",
      "P_YS_FED_EXAMP_0000020559": "版本",
      "P_YS_FED_FW_0000021239": "开始日期",
      "P_YS_FED_UCFBASEDOC_0000025533": "提交成功",
      "P_YS_FED_EXAMP_0000020319": "请输入密码",
      "P_YS_YYGJFW_YBUC_0001315649": "打包",
      "P_YS_FED_FW_0000022342": "校验",
      "P_YS_PF_GZTSYS_0001085920": "集成配置",
      "P_YS_PF_GZTSYS_0000012188": "发布管理",
      "P_YS_IPAAS_UCG_0001072751": "开发应用",
      "P_YS_PF_PRINT_0000065185": "件数",
      "P_YS_FED_FW_0000021007": "数值",
      "P_YS_FED_EXAMP_0000020328": "状态",
      "P_YS_FED_FW_0000021005": "时间",
      "P_YS_FED_IMP-DFM-FE_0000020891": "数据绑定",
      "P_YS_YYGJFW_YBUC_0001315625": "打包人",
      "P_YS_PF_GZTSYS_0000013006": "嘟嘟云总机",
      "P_YS_SD_SDSD-FE_0001184912": "自定义链接",
      "P_YS_FED_FW_0000021452": "）",
      "P_YS_SD_UDHWS_0000070903": "备注信息",
      "P_YS_YYGJFW_YBUC_0001315628": "打包时间",
      "P_YS_PF_GZTSYS_0000108693": "预发环境",
      "P_YS_PF_GZTSYS_0000013023": "工作汇报",
      "P_YS_PF_GZTSYS_0000013020": "友报账",
      "P_YS_SD_SDMA_0000123026": "停用失败！",
      "P_YS_FED_FW_0000022310": "点击上传",
      "P_YS_FED_UCFBASEDOC_0000024895": "任務",
      "P_YS_OA_app_xtywlj_0000037593": "属性",
      "P_YS_FED_FW_0000020987": "二",
      "P_YS_OA_XTMENHUCMS_0001072305": "大会地点",
      "P_YS_PF_GZTSYS_0000012657": "员工",
      "P_YS_OA_XTMENHUCMS_0001072304": "大会时间",
      "P_YS_OA_XTMENHUCMS_0001072301": "云会务",
      "P_YS_OA_XTMENHUCMS_0001072302": "大会主题",
      "P_YS_FED_FW_0000020982": "成功",
      "P_YS_FED_FW_0000020985": "星期日",
      "P_YS_FED_FW_0000020999": "星期三",
      "P_YS_PF_GZTSYS_0000015934": "移动签到",
      "P_YS_PF_GZTSYS_0000012423": "移动首页排序",
      "P_YS_OA_XTMENHUCMS_0001072316": "英国伦敦",
      "P_YS_OA_XTMENHUCMS_0001072315": "EASL国际肝病大会",
      "P_YS_OA_XTMENHUCMS_0001072312": "yyyy年MM月dd日",
      "P_YS_FED_FW_0000020991": "五",
      "P_YS_PF_GZTSYS_0000015937": "移动门户设置",
      "P_YS_OA_XTMENHU_0001025398": "建议调整图片尺寸",
      "P_YS_FED_UCFBASEDOC_0000025041": "输入名称",
      "P_YS_PF_GZTSYS_0000012873": "签到",
      "P_YS_OA_XTMENHU_0001025399": "添加启动图",
      "P_YS_FED_EXAMP_0000020538": "确 定",
      "P_YS_PF_PROCENTER_0000027553": "提示",
      "P_YS_MF_MR-UI_0001142058": "按组织",
      "P_YS_FED_FW_0000020970": "六",
      "P_YS_FED_UCFBASEDOC_0000025029": "下一步",
      "P_YS_FED_FW_0000020972": "星期四",
      "P_YS_OA_XTMENHUCMS_0000045309": "上传图片大小不能超过5MB",
      "P_YS_OA_XTMENHUCMS_0000045308": "营员签到",
      "P_YS_HR_HRJQ_0000031659": "迟到",
      "P_YS_OA_XTMENHUCMS_1420844201648586752": "场景化APP可以多选左右拖动，上下排序",
      "P_YS_OA_XTMENHUCMS_0001368770": "属性接口地址",
      "P_YS_OA_XTMENHUCMS_0000045306": "上传图片大小不能超过 1MB",
      "P_YS_OA_XTMENHUCMS_0000045305": "3.可以添加除本组织外额外可见的组织／成员",
      "P_YS_OA_XTMENHUCMS_0000045304": "云审批公积金提取申请",
      "P_YS_OA_XTMENHUCMS_0000045303": "加载出错，请稍后再试",
      "P_YS_OA_XTMENHUCMS_0000045302": "从已有门户页面创建",
      "P_YS_OA_XTMENHUCMS_0000045301": "删除一级菜单，会同时删除关联的二级菜单，是否删除",
      "P_YS_OA_XTMENHUCMS_0000045300": "请输入名字",
      "P_YS_PF_GZTSYS_0000012471": "内部群文档",
      "P_YS_FED_IMP_IOT0000005525": "属性名称",
      "P_YS_OA_XTMENHUCMS_0001473589": "消息页签",
      "P_YS_OA_XTMENHUCMS_0001035139": "编辑启动图",
      "P_YS_OA_XTMENHUCMS_0001035138": "上传失败，请上传 jpg、jpeg、png 类型图片",
      "P_YS_OA_XTMENHUCMS_0001035137": "上传图片尺寸建议为1080*1920，大小不超过300KB",
      "P_YS_OA_XTMENHUCMS_0001035136": "默认启动图",
      "P_YS_OA_XTMENHUCMS_0001035135": "上传失败，请上传300K以内的图片",
      "P_YS_OA_XTRIZHI_0000036838": "可见范围",
      "P_YS_OA_XTMENHUCMS_0001035134": "启动图设置",
      "P_YS_OA_XTMENHUCMS_0001035133": "生效时间",
      "P_YS_OA_XTMENHUCMS_0001035132": "失效时间",
      "P_YS_OA_XTMENHUCMS_0001035131": "生效时间不能晚于失效时间",
      "P_YS_OA_XTMENHUCMS_0001035130": "自定义启动图",
      "P_YS_OA_XTMENHUCMS_0001035371": "拖拽布局至此",
      "P_YS_OA_XTMENHUCMS_0001035370": "确认变更模板?",
      "P_YS_HR_HRSBF_0001287444": "编辑组件",
      "P_YS_OA_XTRIZHI_0000036822": "不超过20个字",
      "P_YS_OA_XTMENHUCMS_0001035146": "添加启动图",
      "P_YS_OA_XTMENHUCMS_0001035145": "建议调整图片尺寸",
      "P_YS_OA_XTMENHUCMS_0001035144": "点击修改",
      "P_YS_OA_XTMENHUCMS_0001035143": "设定在企业纪念日、重大通知时展示的启动画面",
      "P_YS_OA_XTMENHUCMS_0001035142": "请上传启动图",
      "P_YS_OA_XTMENHUCMS_0001035141": "删除后无法恢复，请确认",
      "P_YS_OA_XTMENHUCMS_0001035140": "温馨提示：在未设置自定义启动图的时间段，移动端启动图将使用默认图",
      "P_YS_SD_UDHWN_0000121778": "接口地址",
      "P_YS_HR_HRJQ_0000055421": "类型",
      "P_YS_OA_XTMENHUCMS_0000045101": "您添加的空间有些存在，已自动去重",
      "P_YS_OA_XTMENHUCMS_0000045343": "推荐移动端首页",
      "P_YS_OA_XTMENHUCMS_0000045342": "上传logo",
      "P_YS_OA_XTMENHUCMS_0000045341": "请输入pcClient端正确主页地址",
      "P_YS_OA_app_xtyyjm_0000036050": "应用LOGO",
      "P_YS_OA_XTMENHUCMS_0000045109": "自定义菜单地址",
      "P_YS_OA_XTMENHUCMS_0000045108": "下午4点开会",
      "P_YS_OA_XTMENHUCMS_0000045107": "标准(60px)",
      "P_YS_OA_XTMENHUCMS_0000045349": "企业自建",
      "P_YS_OA_XTMENHUCMS_0000045106": "两行图标时背景图尺寸为 690×350 PX",
      "P_YS_OA_XTMENHUCMS_0000045348": "请输入web端正确网址",
      "P_YS_OA_XTMENHUCMS_0000045105": "您确定要停用",
      "P_YS_OA_XTMENHUCMS_0000045347": "使用友空间原生导航",
      "P_YS_OA_XTMENHUCMS_0000045104": "还没有审批需要处理哦",
      "P_YS_OA_XTMENHUCMS_0000045346": "不能超过200个字哦",
      "P_YS_OA_XTMENHUCMS_0000045103": "日均待出勤",
      "P_YS_OA_XTMENHUCMS_0000045345": "本地应用请输入**://**规则的地址，其他类型地址请以http://或者https://开头",
      "P_YS_OA_XTMENHUCMS_0000045102": "新建导航",
      "P_YS_FED_FW_0000020919": "一",
      "P_YS_OA_XTRIZHI_0000036806": "网络错误",
      "P_YS_OA_XTMENHUCMS_0000045332": "应用来源",
      "P_YS_OA_XTMENHUCMS_0000045331": "pcClient主页",
      "P_YS_OA_XTMENHUCMS_0000045330": "IOS下载地址必填且不得超过500个",
      "P_YS_FED_FW_0000020917": "确认",
      "P_YS_OA_XTMENHUCMS_0000045339": "web端当前页打开是http协议，跳转时可能会造成页面打不开，是否修改web端为https协议?",
      "P_YS_OA_XTMENHUCMS_0000045338": "web端应用排序",
      "P_YS_OA_XTMENHUCMS_0000045337": "不能超过12个字哦",
      "P_YS_SD_SDMBF_0000141321": "绑定成功",
      "P_YS_OA_XTMENHUCMS_0000045336": "请上传logo",
      "P_YS_OA_XTMENHUCMS_0000045335": "可见范围必填",
      "P_YS_OA_XTMENHUCMS_0000045334": "WEB端主页",
      "P_YS_OA_XTMENHUCMS_0000045333": "删除后，与该应用相关的数据将会消失，请确认您已做好数据迁移",
      "P_YS_PF_MANAGER-FE_1420836367628238848": "请先选择角色化工作台",
      "P_YS_OA_XTMENHUCMS_0000045321": "网站自适应屏幕宽度展示",
      "P_YS_OA_XTMENHUCMS_0001473597": "直接创建工作台",
      "P_YS_OA_XTMENHUCMS_0000045320": "同比增减",
      "P_YS_OA_XTMENHUCMS_0001473598": "通过服务创建工作台",
      "P_YS_OA_XTMENHUCMS_0001473595": "将某个现有服务创建为工作台",
      "P_YS_OA_XTMENHUCMS_0001473596": "按部门授权",
      "P_YS_OA_XTMENHUCMS_0001473599": "从已有工作台创建",
      "P_YS_OA_XTMENHUCMS_0000045329": "请选择应用分类",
      "P_YS_OA_XTMENHUCMS_0000045328": "图片发生错误",
      "P_YS_OA_XTMENHUCMS_0001473590": "请选择已有工作台",
      "P_YS_OA_XTMENHUCMS_0000045327": "android下载地址必填且不得超过500个",
      "P_YS_OA_XTMENHUCMS_0000045326": "全部应用排序",
      "P_YS_OA_XTMENHUCMS_0000045325": "summer包id必填",
      "P_YS_OA_XTMENHUCMS_0001473593": "以现有工作台为模板创建工作台",
      "P_YS_OA_XTMENHUCMS_0000045324": "浏览器新页打开应用",
      "P_YS_OA_XTMENHUCMS_0001473594": "设计人员分配",
      "P_YS_OA_XTMENHUCMS_0000045323": "图标样式修改",
      "P_YS_OA_XTMENHUCMS_0001473591": "按员工授权",
      "P_YS_OA_XTMENHUCMS_0000045322": "负责",
      "P_YS_SD_SDMBF_0000141358": "复选",
      "P_YS_OA_XTMENHUCMS_0001473592": "以最通常的方式创建全新工作台",
      "P_YS_HR_HRYGF_0000055799": "已处理",
      "P_YS_OA_XTMENHUCMS_0000045319": "明会议",
      "P_YS_OA_XTMENHUCMS_0000045310": "知会",
      "P_YS_OA_XTMENHUCMS_0000045318": "网络请求慢",
      "P_YS_OA_XTMENHUCMS_0000045317": "线性导航",
      "P_YS_OA_XTMENHUCMS_0000045316": "图文公告2(左右)",
      "P_YS_OA_XTMENHUCMS_0000045315": "文字选中颜色",
      "P_YS_OA_XTMENHUCMS_0000045314": "搜索中",
      "P_YS_OA_XTMENHUCMS_0000045313": "链接页面不能为空",
      "P_YS_OA_XTMENHUCMS_0000045145": "保存成功!",
      "P_YS_OA_XTMENHUCMS_0001256783": "工作台状态",
      "P_YS_OA_XTTMCENTER_0000035124": "新增广告图",
      "P_YS_OA_XTMENHUCMS_0000045144": "干部任免决定",
      "P_YS_OA_XTMENHUCMS_0001256784": "创建信息",
      "P_YS_FED_FW_0000020946": "星期五",
      "P_YS_OA_XTMENHUCMS_0001256781": "工作台名称",
      "P_YS_OA_XTMENHUCMS_0000045142": "头部设置",
      "P_YS_OA_XTMENHUCMS_0001256782": "更新信息",
      "P_YS_OA_XTMENHUCMS_0000045141": "已恢复到最后一次修改",
      "P_YS_OA_XTMENHUCMS_0000045383": "安装包本地路径如: html/app_sign/sign.html",
      "P_YS_OA_XTMENHUCMS_0001256780": "工作台图标",
      "P_YS_OA_XTMENHUCMS_0000045381": "android跳转参数不得超过200个",
      "P_YS_OA_XTMENHUCMS_0000045380": "web端左侧排序",
      "P_YS_OA_XTMENHUCMS_0000045149": "请填写应用名称",
      "P_YS_OA_XTMENHUCMS_0001256787": "员工",
      "P_YS_OA_XTMENHUCMS_0000045148": "图片尺寸 690×150 PX",
      "P_YS_OA_XTMENHUCMS_0001256788": "组织",
      "P_YS_OA_XTMENHUCMS_0000045147": "请选择正确的文字颜色",
      "P_YS_OA_XTMENHUCMS_0001256785": "工作台使用权限",
      "P_YS_OA_XTMENHUCMS_0000045146": "目标容器只能添加四个组件",
      "P_YS_OA_XTMENHUCMS_0001256786": "工作台设计权限",
      "P_YS_HR_HRJQ_0000031027": "不能為空",
      "P_YS_OA_XTLCZX_0000030184": "编辑成功",
      "P_YS_OA_XTMENHUCMS_0000045134": "请输入20个字符以内",
      "P_YS_OA_XTMENHUCMS_0000045133": "本主题应用于",
      "P_YS_OA_XTMENHUCMS_0000045132": "提交人:",
      "P_YS_OA_XTMENHUCMS_0000045374": "请谨慎重置，重置后当前企业内已经接入的应用需要修改，是否重置?",
      "P_YS_OA_XTMENHUCMS_0000045131": "错误提示",
      "P_YS_OA_XTMENHUCMS_0000045373": "移动端首页",
      "P_YS_OA_XTMENHUCMS_0000045130": "自定义门户",
      "P_YS_OA_XTMENHUCMS_0000045372": "原生应用",
      "P_YS_OA_XTMENHUCMS_0000045371": "应用名称重名",
      "P_YS_OA_XTMENHUCMS_0000045370": "仅支持上传到iuap平台的业务安装包",
      "P_YS_OA_XTMENHUCMS_0000045139": "确定删除",
      "P_YS_OA_XTMENHUCMS_0000045138": "新页打开",
      "P_YS_OA_XTMENHUCMS_0000045137": "操作失败，请稍后再试!",
      "P_YS_OA_XTMENHUCMS_0000045379": "请输入移动端正确网址",
      "P_YS_OA_XTMENHUCMS_0000045136": "宽高请传",
      "P_YS_OA_XTMENHUCMS_0000045378": "IOS跳转参数不得超过200个",
      "P_YS_OA_XTMENHUCMS_0000045135": "保存失败稍后重试!",
      "P_YS_OA_XTMENHUCMS_0000045377": "应用描述",
      "P_YS_OA_XTLCZX_0000030198": "标题文字颜色",
      "P_YS_HR_HRJXF_0001118178": "确定要停用吗？",
      "P_YS_OA_XTTMCENTER_0000035137": "广告图",
      "P_YS_OA_XTMENHUCMS_0000045123": "网站固定最大定1400像素宽度展示",
      "P_YS_OA_XTMENHUCMS_0000045365": "确定要停用吗?",
      "P_YS_OA_XTTMCENTER_0000035145": "尺寸的图片",
      "P_YS_OA_XTMENHUCMS_0000045122": "逗乐乐乐乐乐",
      "P_YS_OA_XTMENHUCMS_0000045364": "以http://或https://开头",
      "P_YS_OA_XTMENHUCMS_0000045363": "pcClient端",
      "P_YS_OA_XTMENHUCMS_0000045120": "公共会议室使用管理规定",
      "P_YS_FED_FW_0000020926": "三",
      "P_YS_OA_XTMENHUCMS_0000045361": "发布客户端必选",
      "P_YS_OA_XTMENHUCMS_0000045360": "应用名称不能为空",
      "P_YS_OA_XTMENHUCMS_0000045129": "集财字",
      "P_YS_OA_XTMENHUCMS_0000045128": "此组件正在使用，确定删除么？",
      "P_YS_OA_XTMENHUCMS_0000045127": "确定要删除”",
      "P_YS_OA_XTMENHUCMS_0000045369": "应用名称未通过检测",
      "P_YS_OA_XTMENHUCMS_0001538954": "注：该内部群最多还可导入",
      "P_YS_OA_XTMENHUCMS_0000045126": "请选择门户使用者",
      "P_YS_OA_XTMENHUCMS_0000045368": "后台",
      "P_YS_FED_FW_0000020920": "星期六",
      "P_YS_OA_XTMENHUCMS_0000045125": "新增磁贴拖拽至此处",
      "P_YS_OA_XTMENHUCMS_0000045367": "打开本地应用",
      "P_YS_OA_XTMENHUCMS_0000045124": "营员手册",
      "P_YS_OA_XTMENHUCMS_0000045112": "您确定要删除",
      "P_YS_OA_XTMENHUCMS_0000045354": "移动端或web端网址错误",
      "P_YS_OA_XTMENHUCMS_0000045596": "清空所有布局",
      "P_YS_OA_XTMENHUCMS_0001561834": "重复人数",
      "P_YS_OA_XTMENHUCMS_0000045111": "添加二级菜单",
      "P_YS_OA_XTMENHUCMS_0000045110": "图文公告(左右)",
      "P_YS_PF_GZTSYS_0000011996": "内部群",
      "P_YS_FED_FW_0000020937": "星期二",
      "P_YS_OA_XTMENHUCMS_0000045350": "移动端所有分类排序",
      "P_YS_OA_XTMENHUCMS_0000045119": "删除失败，稍后重试",
      "P_YS_OA_XTMENHUCMS_0000045118": "启用门户",
      "P_YS_OA_XTMENHUCMS_0000045117": "标题区背景区",
      "P_YS_OA_XTMENHUCMS_0000045116": "?",
      "P_YS_OA_XTMENHUCMS_0000045358": "添加描述",
      "P_YS_OA_XTMENHUCMS_0000045115": "受控范围必选",
      "P_YS_OA_XTMENHUCMS_0000045357": "不能超过500个字哦",
      "P_YS_OA_XTMENHUCMS_0000045599": "保存并且启用成功，需要退出账号，重新登录门户修改才生效",
      "P_YS_OA_XTMENHUCMS_0000045114": "门户名称不能超过20个字符",
      "P_YS_OA_XTMENHUCMS_0000045356": "pc客户端内打开应用",
      "P_YS_OA_XTMENHUCMS_0000045598": "添加失败~",
      "P_YS_OA_XTMENHUCMS_0000045113": "图文公告(上下)",
      "P_YS_OA_XTMENHUCMS_0000045355": "summer包id",
      "P_YS_OA_XTMENHUCMS_0000045597": "请输入布局配比和等于12的组合，请用 空格区隔每个模块，例如 3 3 3 3",
      "P_YS_OA_XTMENHUCMS_0000045192": "关联内容不能为空",
      "P_YS_OA_XTMENHUCMS_0000045191": "兼容挑战任务测试2018_666666666",
      "P_YS_OA_XTMENHUCMS_0000045190": "自适应屏幕宽度",
      "P_YS_OA_XTMENHUCMS_0000045189": "底部设置",
      "P_YS_OA_XTMENHUCMS_0000045188": "门户自适应样式",
      "P_YS_OA_XTMENHUCMS_0000045187": "说明：修改导航标题会同时作用于移动端底部导航标题及该导航对应的页面头部标题（动态页面除外）",
      "P_YS_OA_XTMENHUCMS_0000045186": "我的组件库里不能重名",
      "P_YS_OA_XTMENHUCMS_0000045185": "上传图片只能是JPG/PNG格式!",
      "P_YS_OA_XTMENHUCMS_0000045184": "审批第一季度交通费",
      "P_YS_OA_XTSPZX_0000034655": "排序成功",
      "P_YS_OA_XTMENHUCMS_0000045183": "门户管理者",
      "P_YS_OA_XTMENHUCMS_0000045182": "参与",
      "P_YS_PF_MANAGER-FE_1420076596501413915": "待选场景化APP",
      "P_YS_FED_FW_0000022486": "显示停用",
      "P_YS_FED_FW_0000022245": "样式",
      "P_YS_OA_app_xtyyjm_0000035936": "打开方式",
      "P_YS_OA_XTMENHUCMS_0000045181": "显示到导航",
      "P_YS_OA_XTMENHUCMS_0000045180": "选择组织",
      "P_YS_OA_XTMENHUCMS_0000045178": "请输入10个汉字以内",
      "P_YS_PF_GZTACT_0001061469": "身份类型",
      "P_YS_OA_XTMENHUCMS_0000045177": "组件添加失败！请重试",
      "P_YS_OA_XTMENHUCMS_0000045176": "网络正在请求，请稍后",
      "P_YS_OA_XTMENHUCMS_0000045175": "导航名称",
      "P_YS_OA_XTMENHUCMS_0000045173": "成员机构发文",
      "P_YS_OA_XTMENHUCMS_0000045172": "平平",
      "P_YS_OA_XTMENHUCMS_0000045171": "最多只能添加24个应用",
      "P_YS_PF_PROCENTER_0000022566": "停用",
      "P_YS_PF_MANAGER-FE_1420076596501413921": "场景化APP绑定角色工作台",
      "P_YS_OA_XTMENHUCMS_0000045179": "下午5点开会",
      "P_YS_OA_XTMENHUCMS_0000045170": "股干字",
      "P_YS_OA_XTMENHUCMS_0001314260": "最多填写三位整数(单位px)",
      "P_YS_OA_XTMENHUCMS_0000045167": "干部任免建议书",
      "P_YS_OA_XTMENHUCMS_0001314253": "新增文本类型",
      "P_YS_OA_XTMENHUCMS_0000045166": "全局范围不允许设置，选择区不显示全局范围，请选择下级组织",
      "P_YS_OA_XTMENHUCMS_0001314252": "工作台使用者员工",
      "P_YS_OA_XTMENHUCMS_0000045165": "修改二级菜单",
      "P_YS_OA_XTMENHUCMS_0001314255": "确定不允许员工设置自己的默认工作台？",
      "P_YS_OA_XTMENHUCMS_0000045164": "审批来源:",
      "P_YS_OA_XTMENHUCMS_0001314254": "停用工作台",
      "P_YS_OA_XTMENHUCMS_0000045163": "门户配置设计器",
      "P_YS_OA_XTMENHUCMS_0001314257": "主题删除成功",
      "P_YS_OA_XTMENHUCMS_0000045162": "保存自定义组件",
      "P_YS_OA_XTMENHUCMS_0001314256": "请输入主题名称6个字符以内",
      "P_YS_OA_XTMENHUCMS_0000045161": "点击查看组件开发文档",
      "P_YS_OA_XTMENHUCMS_0001314259": "确认删除主题?",
      "P_YS_OA_XTMENHUCMS_0000045160": "您添加的部门有些存在，已自动去重",
      "P_YS_OA_XTMENHUCMS_0001314258": "请选择默认主题色",
      "P_YS_FED_FW_0000022223": "使用",
      "P_YS_FED_UCFBASEDOC_0000024984": "提交",
      "P_YS_OA_XTMENHUCMS_0000045169": "产业基地建设与资产经营管理中心",
      "P_YS_OA_XTLCZX_0000030167": "建议尺寸",
      "P_YS_PF_GZTLOG_0000025973": "开启",
      "P_YS_OA_XTMENHUCMS_0000045156": "没有发现相关公文哟",
      "P_YS_OA_XTTMCENTER_0000035113": "应用分类",
      "P_YS_PF_MANAGER-FE_1420076596501413903": "请输入角色编码/名称",
      "P_YS_OA_XTMENHUCMS_0000045155": "新闻",
      "P_YS_OA_XTMENHUCMS_0001258356": "角色",
      "P_YS_OA_XTMENHUCMS_0000045154": "自定义卡片模版",
      "P_YS_OA_XTMENHUCMS_0001258355": "工作台描述",
      "P_YS_OA_XTMENHUCMS_0000045153": "请输入按钮名字",
      "P_YS_OA_XTMENHUCMS_0001258354": "工作台信息",
      "P_YS_PF_MANAGER-FE_1420076596501413900": "请选择要绑定的角色工作台",
      "P_YS_OA_XTMENHUCMS_0000045152": "请选择正确的图片",
      "P_YS_OA_XTMENHUCMS_0000045151": "暂无权限访问",
      "P_YS_PF_MANAGER-FE_1420076596501413906": "场景化APP",
      "P_YS_OA_XTMENHUCMS_0000045150": "图片格式建议PNG(尺寸为120px*120px)",
      "P_YS_OA_XTMENHUCMS_0000045159": "还未收到公告消息哦!",
      "P_YS_OA_XTWENKU_0000035710": "关注",
      "P_YS_OA_XTMENHUCMS_0000045157": "获取修改信息失败",
      "P_YS_OA_XTMENHUCMS_0001548827": "点击下载导入异常记录",
      "P_YS_SD_UDHAR_0000051719": "副本",
      "P_YS_OA_XTMENHUCMS_0001503919": "添加二级导航",
      "P_YS_PF_PROCENTER_0000023464": "启用",
      "P_YS_OA_app_xtyyjm_0000035733": "保存并发布",
      "P_YS_OA_app_xtyyjm_0000035745": "创建",
      "P_YS_PF_GZTEVENT_0000025946": "获取用户信息失败",
      "P_YS_OA_app_xtyyjm_0000035758": "当前页打开",
      "P_YS_OA_XTMENHUCMS_0001503922": "修改二级导航",
      "P_YS_OA_XTMENHUCMS_0001503923": "修改一级导航",
      "P_YS_OA_XTMENHUCMS_0001503924": "设置为虚拟分组后，该导航不需要关联任何功能，只用做虚拟分组或分类展示",
      "P_YS_OA_XTMENHUCMS_0001503920": "虚拟分组",
      "P_YS_OA_XTMENHUCMS_0001503921": "添加一级导航",
      "P_YS_OA_app_xtywlj_0000037823": "流程节点",
      "P_YS_OA_XTMENHUCMS_0000045199": "发送邮件服务器",
      "P_YS_OA_XTMENHUCMS_0000045198": "Banner属性",
      "P_YS_OA_XTMENHUCMS_0000045197": "下午6点开会",
      "P_YS_OA_XTMENHUCMS_0000045196": "待办组件",
      "P_YS_OA_XTMENHUCMS_0000045195": "操作频繁，稍后再试",
      "P_YS_FI_FA_0000033363": "分类名称",
      "P_YS_OA_XTMENHUCMS_0000045194": "工作手机权限",
      "P_YS_OA_XTMENHUCMS_0000045193": "删除主题",
      "P_YS_OA_XTMENHUCMS_0000044939": "夏令营投票",
      "P_YS_OA_XTMENHUCMS_0000044937": "集团审计监察部",
      "P_YS_OA_XTMENHUCMS_0000044936": "请输入高",
      "P_YS_SCM_USTOCK-UI_0000177858": "菜单名称",
      "P_YS_FED_UIMG_0000025856": "重命名",
      "P_YS_PF_GZTSYS_0000012499": "组织",
      "P_YS_OA_XTMENHUCMS_0000044933": "厦门旅游攻略",
      "P_YS_OA_XTMENHUCMS_0000044931": "导航名称不能为空",
      "P_YS_OA_XTMENHUCMS_0000044930": "点击查看开发文档",
      "P_YS_OA_XTLCZX_0000030769": "来自",
      "P_YS_OA_XTMENHUCMS_0000044928": "接收邮件服务器",
      "P_YS_OA_XTMENHUCMS_0000044927": "提交时间:",
      "P_YS_OA_XTMENHUCMS_0000044926": "提交者",
      "P_YS_OA_XTMENHUCMS_0000044925": "恢复默认值",
      "P_YS_OA_XTMENHUCMS_0000044924": "集经管字",
      "P_YS_OA_XTMENHUCMS_0000044923": "您添加的人员有些存在，已自动去重",
      "P_YS_OA_XTMENHUCMS_0000044922": "没有与你相关的任务哟!",
      "P_YS_OA_XTMENHUCMS_0000044921": "导航样式",
      "P_YS_OA_XTMENHUCMS_0000044920": "标题公告",
      "P_YS_HR_HRXCF_0001116208": "按用户授权",
      "P_YS_OA_XTMENHUCMS_0000044919": "内容区背景区",
      "P_YS_OA_XTMENHUCMS_0000044918": "Bann属性",
      "P_YS_OA_XTMENHUCMS_0000044917": "复制门户",
      "P_YS_OA_XTMENHUCMS_0000044916": "输入关键字搜索",
      "P_YS_OA_XTMENHUCMS_0000044915": "北土城小仙女",
      "P_YS_OA_XTMENHUCMS_0000044914": "恢复默认模板",
      "P_YS_PF_GZTVPA_0001137668": "授权设置",
      "P_YS_OA_XTMENHUCMS_0000044913": "内容区头部设置",
      "P_YS_OA_XTMENHUCMS_0000044912": "渐变方向",
      "P_YS_SCM_USTOCK_0001131722": "新增",
      "P_YS_OA_XTMENHUCMS_0000044911": "没有搜索到“",
      "P_YS_OA_XTMENHUCMS_0000044910": "注：该服务属于增值服务，请联系客服进行开通。",
      "P_YS_SCM_USTOCK_0001131725": "保存",
      "P_YS_OA_XTMENHUCMS_0000044908": "启用提示",
      "P_YS_OA_XTMENHUCMS_0000044907": "使用SSL连接",
      "P_YS_OA_XTMENHUCMS_0000044906": "不支持您所上传的图片格式",
      "P_YS_OA_XTMENHUCMS_0000044905": "短广告位",
      "P_YS_OA_XTMENHUCMS_0000044904": "选择默认主题色",
      "P_YS_OA_XTMENHUCMS_0000044903": "保存自定义底部成功",
      "P_YS_PF_MANAGER-FE_0001087862": "分组管理",
      "P_YS_FED_EXAMP_0000020272": "预定",
      "P_YS_OA_XTMENHUCMS_0000044909": "标题文字选中颜色",
      "P_YS_OA_XTMENHUCMS_0001368767": "发布工作台",
      "P_YS_OA_XTMENHUCMS_0001387022": "商家身份类型",
      "P_YS_OA_XTMENHUCMS_0001368768": "是否同步logo",
      "P_YS_OA_XTMENHUCMS_0001368765": "是否同步名称",
      "P_YS_OA_XTMENHUCMS_0001387024": "初始-安全保密员",
      "P_YS_OA_XTMENHUCMS_0001368766": "您确定要发布",
      "P_YS_OA_XTMENHUCMS_0001387023": "初始-系统管理员",
      "P_YS_SD_UDHWS_0000117730": "请输入分组名称",
      "P_YS_OA_XTMENHU_0000046633": "搜索应用的名称",
      "P_YS_OA_XTMENHUCMS_0001368769": "是否同步角色",
      "P_YS_OA_XTMENHUCMS_0001368763": "是否同步身份类型",
      "P_YS_OA_XTMENHUCMS_0001387026": "初始-安全审计员",
      "P_YS_OA_XTMENHUCMS_0001368764": "是否同步模板",
      "P_YS_OA_XTMENHUCMS_0001387025": "安全审计员",
      "P_YS_OA_XTMENHUCMS_0001387028": "安全保密员",
      "P_YS_OA_XTMENHUCMS_0001387027": "三员身份类型",
      "P_YS_OA_XTMENHUCMS_0000044979": "关键词搜索",
      "P_YS_OA_XTMENHUCMS_0000044978": "还可以看",
      "P_YS_FI_TP_0000071994": "是否多选",
      "P_YS_OA_XTMENHUCMS_0000044977": "查看权限",
      "P_YS_OA_XTMENHUCMS_0000044976": "上午两点开会",
      "P_YS_OA_XTMENHUCMS_0000044975": "门户名称",
      "P_YS_OA_XTMENHUCMS_0001623249": "工作台名称重复",
      "P_YS_OA_XTMENHUCMS_0000044974": "关联系统",
      "P_YS_OA_XTMENHUCMS_0000044973": "项目组件测试结果反馈",
      "P_YS_OA_XTMENHUCMS_0000044971": "上传图片大小不能超过20kb!",
      "P_YS_OA_XTMENHUCMS_0000044970": "关联门户",
      "P_YS_OA_XTMENHUCMS_0000044969": "您确定要启用",
      "P_YS_OA_XTMENHUCMS_0000044968": "您确定要继续创建吗？",
      "P_YS_OA_XTMENHUCMS_0000044967": "受控范围",
      "P_YS_OA_XTTXL_0000046015": "新建分组",
      "P_YS_OA_XTMENHUCMS_0000044966": "logo显示方式",
      "P_YS_OA_XTMENHUCMS_0000044965": "二级导航名称",
      "P_YS_OA_XTMENHUCMS_0000044964": "磁贴",
      "P_YS_OA_XTMENHUCMS_0000044963": "仅显示启用",
      "P_YS_OA_XTMENHUCMS_0000044962": "请等待图片上传完毕",
      "P_YS_OA_XTMENHUCMS_0000044961": "您的修改未保存,确定要离开吗？",
      "P_YS_OA_XTMENHUCMS_0000044960": "部门统计-日",
      "P_YS_FED_EXAMP_0000020208": "图片",
      "P_YS_OA_XTMENHUCMS_0000044959": "没有发现相关日程哟!",
      "P_YS_OA_XTMENHUCMS_0000044958": "删除提醒",
      "P_YS_OA_XTMENHUCMS_0000044957": "对不起，您没有设计门户的权限",
      "P_YS_OA_XTMENHUCMS_0000044956": "设置为虚菜单后，该菜单不需要关联任何功能，只用做虚拟分组或分类展示",
      "P_YS_PF_GZTSYS_0000012039": "友情链接",
      "P_YS_OA_XTMENHUCMS_0000044955": "应用名称文字颜色设置",
      "P_YS_OA_XTMENHUCMS_0000044954": "用友知多少",
      "P_YS_OA_XTMENHUCMS_0000044953": "还未设置应用哟!",
      "P_YS_OA_XTMENHUCMS_0000044952": "佛山",
      "P_YS_OA_XTMENHUCMS_0000044951": "夏5.8弹幕",
      "P_YS_FED_FW_0000021338": "浙江",
      "P_YS_OA_XTMENHUCMS_0000044950": "最多填写三位填写整数(单位px)",
      "P_YS_OA_XTMENHUCMS_0000044949": "图片固定大小不复制和拉伸!",
      "P_YS_OA_XTMENHUCMS_0000044948": "新成员",
      "P_YS_OA_XTMENHUCMS_0000044947": "请选择链接页面",
      "P_YS_OA_XTMENHUCMS_0000044946": "门户名称不能为空",
      "P_YS_OA_XTMENHUCMS_0000044945": "没有发现相关内容哟",
      "P_YS_OA_XTMENHUCMS_1420420649154248704": "getAppmenus接口报错",
      "P_YS_FED_UIMG_0000025842": "所属组织",
      "P_YS_OA_XTMENHUCMS_0000044944": "常用应用已达12个上限",
      "P_YS_OA_XTMENHUCMS_0000044943": "集团发文",
      "P_YS_OA_XTMENHUCMS_0000044942": "居中显示",
      "P_YS_OA_XTMENHUCMS_0000044941": "没有发现相关统计数据哟!",
      "P_YS_OA_XTMENHUCMS_0000044940": "排行榜",
      "P_YS_OA_XTRIZHI_0000036782": "不超过10个字",
      "P_YS_OA_XTMENHUCMS_1420420649154248707": "associateAppMenus接口报错",
      "P_YS_PF_MES-FE_0001269116": "一键全部已读",
      "P_DIWORK_OA_XTMENHUCMS_0000065678": "门户使用者部门",
      "P_YS_OA_XTMENHUCMS_1420430312830664704": "可以多选左右拖动、上下排序",
      "P_YS_FED_EXAMP_0000020639": "取 消",
      "P_YS_PF_AUTHF_0000096121": "身份类型",
      "P_YS_PF_GZTSYS_0001236869": "组件设置-web广告图",
      "OA_web_ad_0000000102": "生效时间",
      "P_YS_PF_GZTSYS_0000012792": "待我审批",
      "P_YS_PF_GZTSYS_0000012303": "企业文档",
      "P_YS_OA_XTMENHUCMS_0000045615": "请输入保存模板名称",
      "P_YS_OA_XTMENHUCMS_0000045614": "总和应等于于12,数字必须是整数",
      "P_YS_OA_XTMENHUCMS_0000045613": "无模板数据，请从左侧拖拽添加行布局",
      "P_YS_OA_XTMENHUCMS_0000045612": "请输入正整数",
      "P_YS_FED_EXAMP_0000020405": "生日",
      "P_YS_OA_XTMENHUCMS_0000045611": "返回提示!",
      "P_YS_OA_XTMENHUCMS_0000045610": "输入比例不对~",
      "P_YS_OA_XTMENHUCMS_0001537390": "导出",
      "P_YS_OA_XTMENHUCMS_0001537391": "编辑",
      "P_YS_OA_XTRIZHI_0000036750": "控件",
      "P_YS_OA_XTMENHUCMS_0000045609": "1:1:1布局",
      "P_YS_FED_UCFBASEDOC_0000025163": "预置",
      "P_YS_OA_XTMENHUCMS_0000045608": "变更模版布局",
      "P_YS_OA_XTMENHUCMS_0001537394": "您确定要移除",
      "P_YS_OA_XTMENHUCMS_0000045607": "确定要清空当前布局吗?",
      "P_YS_OA_XTMENHUCMS_0000045606": "添加行布局",
      "P_YS_OA_XTMENHUCMS_0000045605": "2:1布局",
      "P_YS_SD_UDHWN_0000177855": "吗？",
      "P_YS_OA_XTMENHUCMS_0001319032": "广告更新失败",
      "P_YS_OA_XTMENHUCMS_0001319031": "确定删除广告位吗?",
      "P_YS_OA_XTMENHUCMS_0001319030": "广告更新成功",
      "P_YS_OA_XTMENHUCMS_0000044991": "更多>颜色",
      "P_YS_OA_XTMENHUCMS_0001319029": "确定删除广告图吗?",
      "P_YS_OA_XTMENHUCMS_0000044990": "获取组件列表失败",
      "P_YS_OA_XTMENHUCMS_0001319028": "广告新增成功",
      "P_YS_PF_AUTHF_0000096106": "请选择身份类型",
      "P_YS_OA_XTMENHUCMS_0001319027": "广告新增失败",
      "P_YS_OA_XTMENHUCMS_0000044999": "模版名称重复",
      "P_YS_OA_XTMENHUCMS_0000045604": "个人门户设置成功!",
      "P_YS_OA_XTMENHUCMS_0000044998": "关联应用",
      "P_YS_OA_XTMENHUCMS_0000045603": "请输入自定义布局数字",
      "P_YS_OA_XTMENHUCMS_0000044997": "确定要启用吗？启用后重新登录生效",
      "P_YS_OA_XTMENHUCMS_0000045602": "另存为布局模板",
      "P_YS_OA_XTMENHUCMS_0001537389": "导入",
      "P_YS_OA_XTMENHUCMS_0000044996": "养老金事业部(2016)",
      "P_YS_OA_XTMENHUCMS_0000045601": "自定义布局添加",
      "P_YS_OA_XTMENHUCMS_0000044995": "虚菜单",
      "P_YS_OA_XTMENHUCMS_0000045600": "确定要删除这个模板吗?",
      "P_YS_OA_XTMENHUCMS_0000044994": "常用应用已达24个上限",
      "P_YS_OA_XTMENHUCMS_0000044993": "设置模板失败！请稍后再试",
      "P_YS_OA_XTMENHUCMS_0000044992": "个性控件",
      "P_YS_HR_HRJQ_0000031763": "早退",
      "P_YS_OA_XTMENHUCMS_0000044989": "停用门户",
      "P_YS_OA_XTMENHUCMS_0000044980": "元素库",
      "P_YS_FED_IMP_IOT0000005427": "处理状态",
      "P_YS_OA_XTMENHUCMS_0000044988": "吴德伦",
      "P_YS_OA_XTMENHUCMS_0000044987": "返回提示",
      "P_YS_OA_XTMENHUCMS_0000044986": "请求中请稍后重试!",
      "P_YS_PF_MES-FE_0001269119": "来源：",
      "P_YS_OA_XTMENHUCMS_0000044984": "保存到我的组件",
      "P_YS_OA_XTMENHUCMS_0000044983": "建议图标尺寸120*120px",
      "P_YS_OA_XTMENHUCMS_0000044982": "组织权限",
      "P_YS_OA_XTMENHUCMS_0000044981": "系统主题",
      "P_YS_OA_XTMENHUCMS_0001073711": "在线人数：",
      "P_YS_OA_XTMENHUCMS_0001073710": "用友网络科技股份有限公司 版权所有",
      "P_YS_OA_XTTMCENTER_0000035084": "已下架",
      "P_YS_HR_HRJQ_0000054657": "所属组织",
      "P_YS_SD_SDMAUF_0000152050": "添加图标",
      "P_YS_PF_GZTSYS_0000015860": "发文",
      "P_YS_PF_GZTSYS_0000012104": "启动图设置",
      "P_YS_OA_XTTMCENTER_0000035096": "请填写标题",
      "P_YS_OA_XTTMCENTER_0000035095": "已上架",
      "P_YS_OA_XTMENHUCMS_0001537350": "请输入内容",
      "P_YS_OA_XTMENHUCMS_0001537351": "删除",
      "P_YS_OA_XTMENHU_0000046561": "组件类型",
      "P_YS_FED_EXAMP_0000019871": "删除",
      "P_YS_OA_XTWENKU_0000035581": "个文件",
      "P_YS_OA_XTMENHUCMS_0001537343": "取消",
      "P_YS_OA_XTMENHUCMS_0001537344": "群简介",
      "P_YS_OA_XTMENHUCMS_0001537345": "请输入",
      "P_YS_OA_XTMENHUCMS_0001537346": "群名称不能为空",
      "P_YS_FED_EXAMP_0000020614": "删除成功",
      "P_YS_OA_XTMENHUCMS_0001537347": "修改成功",
      "P_YS_HR_HRXCF_0001116185": "按角色授权",
      "P_YS_OA_XTHUATI_0000046180": "单选",
      "P_YS_OA_XTMENHUCMS_0001537331": "部门/组织",
      "P_YS_OA_XTMENHUCMS_0001540743": "确定要批量删除${num}个吗？",
      "P_YS_OA_XTMENHUCMS_0000045222": "应用集合",
      "P_YS_OA_XTMENHUCMS_0000045221": "内容区设置帮助",
      "P_YS_PF_GZTSYS_0001010844": "系统管理员",
      "P_YS_OA_XTMENHUCMS_0000045220": "官方应用导航模版",
      "P_YS_OA_XTMENHUCMS_0000045229": "请输入自定义底部链接",
      "P_YS_OA_XTMENHUCMS_0000045228": "2018年用友集团内部控制体系建设工作组织与职责",
      "P_YS_OA_XTMENHUCMS_0001537323": "工作邮箱",
      "P_YS_OA_XTMENHUCMS_0000045227": "新云审批",
      "P_YS_OA_XTMENHUCMS_0000045226": "Tip:大小不超过20kb，尺寸122px*122px",
      "P_YS_OA_XTMENHUCMS_0001256468": "自定义底部高度",
      "P_YS_OA_XTMENHUCMS_0001537325": "添加成功",
      "P_YS_OA_XTMENHUCMS_0000045225": "导航设置帮助",
      "P_YS_OA_XTMENHUCMS_0000045224": "珍珠白",
      "P_YS_OA_XTMENHUCMS_0000045223": "请填写新建自定义应用名称",
      "P_YS_OA_XTMENHUCMS_0001537328": "名称",
      "P_YS_OA_XTMENHUCMS_0001537340": "新成员加入需要管理员审核",
      "P_YS_OA_XTMENHUCMS_0001537342": "保存",
      "P_YS_PF_MANAGER-FE_0001470878": "关联服务",
      "P_YS_OA_XTMENHUCMS_0000045211": "仅支持JPG/PNG格式图片，文件小于5M",
      "P_YS_OA_XTMENHUCMS_0001540739": "确定要批量删除",
      "P_YS_OA_XTMENHUCMS_0000045210": "添加分类",
      "P_YS_OA_XTMENHUCMS_0001314200": "工作台LOGO",
      "P_YS_OA_XTMENHUCMS_0001256471": "设为默认后，所有员工的默认工作台将变为此工作台，建议提前通知所有员工！",
      "P_YS_OA_XTMENHUCMS_0001314202": "请填写属性名称",
      "P_YS_OA_XTMENHUCMS_0001314201": "广告位删除成功",
      "P_YS_SD_UDHWN_0000121432": "请输入主题名称",
      "P_YS_OA_XTMENHUCMS_0001314204": "已读通知",
      "P_YS_OA_XTMENHUCMS_0001314203": "平台服务代码",
      "P_YS_OA_XTWENKU_0000035576": "服务器忙",
      "P_YS_OA_XTMENHUCMS_0000045219": "点击色块编辑",
      "P_YS_OA_XTMENHUCMS_0001314206": "未读通知",
      "P_YS_OA_XTMENHUCMS_0000045218": "从上到下",
      "P_YS_OA_XTMENHUCMS_0001314205": "js大小不超过3M",
      "P_YS_OA_XTMENHUCMS_0001537333": "上传成功",
      "P_YS_OA_XTMENHUCMS_0000045217": "请输入应用集合名称",
      "P_YS_OA_XTMENHUCMS_0001314208": "组件图片",
      "P_YS_OA_XTMENHUCMS_0001537334": "点击上传",
      "P_YS_OA_XTMENHUCMS_0000045216": "用友汽车公司云产品备案清单",
      "P_YS_OA_XTMENHUCMS_0001314207": "新增树结构",
      "P_YS_OA_XTMENHUCMS_0001537335": "仅支持JPG格式图片，文件小于5M",
      "P_YS_OA_XTMENHUCMS_0000045215": "吗?启用后重新登录生效!",
      "P_YS_OA_XTMENHUCMS_0001537336": "编辑内部群",
      "P_YS_OA_XTMENHUCMS_0000045214": "日志2.0",
      "P_YS_OA_XTMENHUCMS_0001314209": "广告位资源可在“组件设置-web广告图”中设置",
      "P_YS_OA_XTMENHUCMS_0001537337": "群头像",
      "P_YS_OA_XTMENHUCMS_0000045213": "袁三六",
      "P_YS_OA_XTMENHUCMS_0000045212": "系统控件",
      "P_YS_OA_XTMENHUCMS_0001537339": "管理员",
      "P_YS_OA_XTMENHUCMS_1417407282912165888": "是否授权给全员",
      "P_YS_OA_XTMENHUCMS_0000045209": "删除失败请稍后重试!",
      "P_YS_OA_XTMENHUCMS_0000045200": "名称不能为空!",
      "P_YS_OA_XTMENHUCMS_0001537309": "内部群名称",
      "P_YS_OA_XTMENHUCMS_0000045208": "参与人数",
      "P_YS_OA_XTMENHUCMS_0000045207": "导航块颜色",
      "P_YS_OA_XTMENHUCMS_0000045205": "启用自定义底部成功",
      "P_YS_OA_XTMENHUCMS_0000045204": "放大(80px)",
      "P_YS_OA_XTMENHUCMS_0000045203": "请至少选择其中一个使用",
      "P_YS_OA_XTMENHUCMS_0000045202": "启用横向导航",
      "P_YS_OA_XTMENHUCMS_0000045201": "官方自有主页",
      "P_YS_OA_XTMENHUCMS_1422527639852154883": "暂无场景化APP",
      "P_YS_OA_XTMENHUCMS_1422527639852154880": "请先选择场景化APP",
      "P_YS_OA_XTMENHUCMS_0001537320": "工作电话",
      "P_YS_OA_XTMENHUCMS_0001537318": "删除成功",
      "P_YS_PF_METADATA_0001038634": "版本版次规则使用",
      "P_YS_OA_XTMENHUCMS_0001537312": "内部群ID",
      "P_YS_OA_XTMENHUCMS_0001073709": " 京ICP备15057199号",
      "P_YS_OA_XTMENHUCMS_0001537314": "群主",
      "P_YS_OA_XTMENHUCMS_0001073708": "京公网安备 11010802020548号",
      "P_YS_OA_XTMENHUCMS_1422527639852154886": "已关联场景化APP",
      "P_YS_OA_XTLCZX_0000030299": "添加成功",
      "P_YS_PF_GZTTMP_0000078402": "所属分组",
      "P_YS_OA_XTMENHUCMS_0001314240": "文件大小最大为",
      "P_YS_OA_XTMENHUCMS_0000045024": "不能超过4个字哦",
      "P_YS_OA_XTMENHUCMS_0000045266": "建议0-10px",
      "P_YS_OA_XTMENHUCMS_0001314231": "组件回调函数名",
      "P_YS_OA_XTMENHUCMS_0000045023": "新建主题",
      "P_YS_OA_XTMENHUCMS_0000045265": "您确定要复制",
      "P_YS_OA_XTMENHUCMS_0001314230": "新建组件",
      "P_YS_OA_XTMENHUCMS_0000045022": "添加一级菜单",
      "P_YS_OA_XTMENHUCMS_0000045264": "1400像素固定宽度",
      "P_YS_PF_METADATA_0001038628": "版本版次规则",
      "P_YS_OA_XTMENHUCMS_0001314233": "请填写属性键值",
      "P_YS_OA_XTMENHUCMS_0000045021": "关联内容",
      "P_YS_OA_XTMENHUCMS_0000045263": "返回上一页",
      "P_YS_OA_XTMENHUCMS_0001314232": "删除工作台",
      "P_YS_OA_XTMENHUCMS_0000045020": "没有发现相关常用应用哟!",
      "P_YS_OA_XTMENHUCMS_0000045262": "累计金额(万元)",
      "P_YS_OA_XTMENHUCMS_0001314235": "从仪表板选择",
      "P_YS_OA_LCGL_0000037192": "个人统计",
      "P_YS_OA_XTMENHUCMS_0000045261": "请输入自定义底部高度",
      "P_YS_OA_XTMENHUCMS_0001314234": "工作台管理者",
      "P_YS_PF_GZTSYS_0000012730": "导航设置",
      "P_YS_OA_XTMENHUCMS_0000045260": "请大于1不超过12个字节的名称",
      "P_YS_OA_XTMENHUCMS_0001314237": "版心为核心信息放置区域",
      "P_YS_OA_XTMENHUCMS_0001314236": "允许员工设置默认工作台",
      "P_YS_OA_XTMENHUCMS_0001314239": "個人工作台",
      "P_YS_OA_XTMENHUCMS_0001314238": "恢复默认模板提示!",
      "P_YS_OA_XTMENHUCMS_0000045029": "当前数据为空",
      "P_YS_OA_XTMENHUCMS_0000045028": "本组织",
      "P_YS_OA_XTMENHUCMS_0000045027": "类别号",
      "P_YS_OA_XTMENHUCMS_0000045269": "上传文件中大小不得大于",
      "P_YS_OA_XTMENHUCMS_0000045026": "像素/px",
      "P_YS_OA_XTMENHUCMS_0000045268": "干部任命决定",
      "P_YS_OA_XTMENHUCMS_0000045025": "上传图标",
      "P_YS_OA_XTMENHUCMS_0000045267": "下午8点开会",
      "P_YS_OA_XTMENHUCMS_1420420752232939520": "编辑场景化APP",
      "OA_web_ad_000000003": "广告位名称",
      "P_YS_OA_XTMENHUCMS_0001314251": "更换仪表板",
      "P_YS_OA_XTMENHUCMS_0001314250": "变更模板布局",
      "P_YS_OA_XTMENHUCMS_0000045013": "Menu Name",
      "P_YS_OA_XTMENHUCMS_0000045255": "最多添加24个应用!",
      "P_YS_OA_XTMENHUCMS_0001314242": "头部背景图片",
      "P_YS_OA_XTMENHUCMS_0000045012": "请输入三位以内正整数",
      "P_YS_OA_XTMENHUCMS_0000045254": "点击使用该布局",
      "P_YS_OA_XTMENHUCMS_0001314241": "请选择组件类型",
      "P_YS_OA_XTMENHUCMS_0000045011": "径向",
      "P_YS_OA_XTMENHUCMS_0000045253": "翡翠绿",
      "P_YS_OA_XTMENHUCMS_0001314244": "建议50-100px",
      "P_YS_OA_XTMENHUCMS_0000045010": "应用显示名称",
      "P_YS_OA_XTMENHUCMS_0000045252": "请输入显示行数",
      "P_YS_OA_XTMENHUCMS_0001314243": "请输入组件回调函数名",
      "P_YS_OA_XTMENHUCMS_0000045251": "当前网络不可用,请检查你的网络",
      "P_YS_OA_XTMENHUCMS_0001314246": "点击确定恢复默认模板",
      "P_YS_OA_XTMENHUCMS_0000045250": "应用名称12个字节以内",
      "P_YS_OA_XTMENHUCMS_0001314245": "启用工作台",
      "P_YS_OA_XTMENHUCMS_0001314248": "新增企业自定义组件",
      "P_YS_OA_XTMENHUCMS_0001314247": "选择服务",
      "P_YS_OA_XTMENHUCMS_0001314249": "树结构选择",
      "P_YS_OA_XTMENHUCMS_0000045019": "我的组件库",
      "P_YS_OA_XTMENHUCMS_0000045018": "下午2点开会",
      "P_YS_OA_XTMENHUCMS_0000045017": "企业自有门户模版",
      "P_YS_OA_XTMENHUCMS_0000045259": "NC审批",
      "P_YS_OA_XTMENHUCMS_0000045016": "二级导航",
      "P_YS_OA_XTMENHUCMS_0000045258": "1.被限制的组织内成员，只能看到本组织的通讯录",
      "P_YS_OA_XTMENHUCMS_0000045015": "啊白测试",
      "P_YS_OA_XTMENHUCMS_0000045257": "保存并发布成功",
      "P_YS_OA_XTMENHUCMS_0000045014": "请输入主题名称12个字节以内",
      "P_YS_OA_XTMENHUCMS_0000045256": "下午7点开会",
      "OA_web_ad_000000004": "请选择要设置的广告位",
      "P_YS_OA_XTWENKU_0000035505": "刪除成功",
      "P_YS_OA_XTMENHUCMS_0000045002": "”相关内容",
      "P_YS_OA_XTMENHUCMS_0000045244": "组件已添加!",
      "P_YS_OA_XTMENHUCMS_0000045001": "对角",
      "P_YS_OA_XTMENHUCMS_0000045243": "工作台H5测试接口联调",
      "P_YS_OA_XTMENHUCMS_0000045000": "结束色",
      "P_YS_OA_XTMENHUCMS_0000045242": "集人字",
      "P_YS_OA_XTMENHUCMS_0001314211": "新增广告位",
      "P_YS_OA_XTMENHUCMS_0000045241": "工时排名TOP5",
      "P_YS_OA_XTMENHUCMS_0001314210": "工作台自适应样式",
      "P_YS_OA_XTMENHUCMS_0000045240": "添加主题成功!",
      "P_YS_OA_XTMENHUCMS_0001314213": "广告删除失败",
      "P_YS_OA_XTMENHUCMS_0001314212": "新增广告位成功",
      "P_YS_OA_XTMENHUCMS_0001314215": "组件信息",
      "P_YS_OA_XTMENHUCMS_0001314214": "确定要启用吗？",
      "P_YS_OA_XTMENHUCMS_0001314217": "请输入组件属性函数名",
      "P_YS_OA_XTMENHUCMS_0001438086": "该分组名称已存在，请输入其他名称！",
      "P_YS_OA_XTMENHUCMS_0000045009": "应用设置，拖动可以排序",
      "P_YS_OA_XTMENHUCMS_0001314216": "请输入工作台名称!",
      "P_YS_OA_XTMENHUCMS_0001438087": "请选择所属分组",
      "P_YS_PF_MANAGER-FE_1420076596501413894": "已选场景化APP",
      "P_YS_OA_XTMENHUCMS_0000045008": "自定义底部链接",
      "P_YS_OA_XTMENHUCMS_0001314219": "建议尺寸：230 x 190",
      "P_YS_OA_XTMENHUCMS_0001438088": "+ 新建分组",
      "P_YS_OA_XTMENHUCMS_0000045007": "业绩快报",
      "P_YS_OA_XTMENHUCMS_0000045249": "关于医疗公司业务整体合并到股份公司整合发展的通知",
      "P_YS_OA_XTMENHUCMS_0001314218": "广告删除成功",
      "P_YS_OA_XTMENHUCMS_0001438089": "请将该分组中的工作台移动到其他分组，否则无法执行删除操作！",
      "P_YS_OA_XTMENHUCMS_0000045006": "头部修饰颜色",
      "P_YS_OA_XTMENHUCMS_0000045248": "空白门户布局",
      "P_YS_OA_XTMENHUCMS_0000045005": "还未有项目哦!",
      "P_YS_OA_XTMENHUCMS_0000045247": "邮箱后缀",
      "P_YS_OA_XTMENHUCMS_0000045004": "企业组件库",
      "P_YS_OA_XTMENHUCMS_0000045246": "获取当前空间失败",
      "P_YS_OA_XTMENHUCMS_0000045003": "已出勤",
      "P_YS_OA_XTMENHUCMS_0000045245": "为保证移动端页面视觉效果，导航图标只能上传不超过20kb，尺寸是122px X 122px 的png图标。",
      "P_YS_OA_XTMENHUCMS_0001438085": "请选择分组!",
      "P_YS_OA_app_xtyyjm_0000035910": "web端",
      "P_YS_OA_XTMENHUCMS_0001009159": "添加水印",
      "P_YS_OA_XTMENHUCMS_0000045233": "纵向平铺",
      "P_YS_OA_XTMENHUCMS_0001314220": "版心",
      "P_YS_OA_XTMENHUCMS_0000045232": "中国红",
      "P_YS_OA_XTMENHUCMS_0000045231": "操作太快，稍等重试",
      "P_YS_OA_XTMENHUCMS_0001314222": "图片大小不超过500KB",
      "P_YS_OA_XTMENHUCMS_0000045230": "停用提示",
      "P_YS_OA_XTMENHUCMS_0001314221": "工作台使用者部门",
      "P_YS_OA_XTMENHUCMS_0001314224": "请填写广告位名称",
      "P_YS_OA_XTMENHUCMS_0001314223": "请选择关联应用",
      "P_YS_OA_XTMENHUCMS_0001314226": "广告位尺寸",
      "P_YS_OA_XTMENHUCMS_0001314225": "禁止员工设置默认工作台",
      "P_YS_OA_XTMENHUCMS_0001314228": "选择身份类型",
      "P_YS_OA_XTMENHUCMS_0001314227": "配置值",
      "P_YS_OA_XTMENHUCMS_0000045239": "刘豪-三十而立赢天下",
      "P_YS_OA_XTMENHUCMS_0000045238": "漏打卡",
      "P_YS_OA_XTMENHUCMS_0001314229": "请上传组件源文件!",
      "P_YS_OA_XTMENHUCMS_0000045237": "删除门户",
      "P_YS_OA_XTMENHUCMS_0001009162": "关闭成功",
      "P_YS_OA_XTMENHUCMS_0000045236": "色块导航",
      "P_YS_OA_XTMENHUCMS_0001009161": "开启成功",
      "P_YS_OA_XTMENHUCMS_0000045235": "创建门户",
      "P_YS_OA_XTMENHUCMS_0001009160": "动态分类管理",
      "P_YS_OA_XTMENHUCMS_0000045234": "网络错误请稍后再试!",
      "P_YS_OA_XTWENKU_0000035606": "项目文档",
      "P_YS_OA_XTMENHUCMS_0000045071": "设置保存成功!",
      "P_YS_OA_XTMENHUCMS_0000045070": "头部文字颜色",
      "P_YS_OA_XTMENHUCMS_0001314161": "法語",
      "P_YS_OA_XTMENHUCMS_0001314160": "日語",
      "P_YS_OA_XTMENHUCMS_0001314163": "树结构",
      "P_YS_OA_XTMENHUCMS_0001314162": "列表树结构",
      "P_YS_OA_XTMENHUCMS_0000045068": "自定义应用",
      "P_YS_OA_XTMENHUCMS_0001314154": "葡萄牙語",
      "P_YS_OA_XTMENHUCMS_0000045067": "重置成功",
      "P_YS_OA_XTMENHUCMS_0001314153": "【提示】若系統語言（<%=enterprise%>）內容未維護，系統將默認按當前語言（<%=current%>）內容賦值處理",
      "P_YS_OA_XTMENHUCMS_0000045066": "联络员",
      "P_YS_OA_XTMENHUCMS_0001314156": "研發建模服務-前端",
      "P_YS_OA_XTMENHUCMS_0000045065": "2.可以设置不受此规则限制的成员",
      "P_YS_OA_XTMENHUCMS_0001314155": "德語",
      "P_YS_OA_XTMENHUCMS_0000045064": "营员信息",
      "P_YS_OA_XTMENHUCMS_0001314158": "請輸入...",
      "P_YS_OA_XTMENHUCMS_0000045063": "三行图标时背景图尺寸为690×515 PX",
      "P_YS_OA_XTMENHUCMS_0001314157": "俄語",
      "P_YS_OA_XTMENHUCMS_0000045062": "同期金额(万元)",
      "P_YS_OA_XTMENHUCMS_0000045061": "建议70-100px",
      "P_YS_OA_XTMENHUCMS_0001314159": "當前語言",
      "P_YS_OA_XTMENHUCMS_0000045069": "自定义底部高度",
      "P_YS_OA_XTLCZX_0000030265": "加载中",
      "P_YS_OA_XTMENHUCMS_0000045060": "恢复成功",
      "P_YS_OA_XTMENHUCMS_0001314170": "分类下存在广告，不允许删除",
      "P_YS_OA_XTMENHUCMS_0001314172": "请输入组件名称",
      "P_YS_OA_XTMENHUCMS_0001314171": "关联工作台",
      "P_YS_OA_XTMENHUCMS_0001314174": "请填写接口地址",
      "P_YS_OA_XTMENHUCMS_0001314173": "组件属性函数名",
      "P_YS_OA_XTMENHUCMS_0000045057": "执行中",
      "P_YS_OA_XTMENHUCMS_0001314165": "新增单选类型",
      "P_YS_OA_XTMENHUCMS_0000045056": "高亮图标",
      "P_YS_OA_XTMENHUCMS_0001314164": "意大利語",
      "P_YS_OA_XTMENHUCMS_0001438090": "请输入工作台名称",
      "P_YS_OA_XTMENHUCMS_0000045055": "白宇宇fiona",
      "P_YS_OA_XTMENHUCMS_0000045297": "处罚决定",
      "P_YS_OA_XTMENHUCMS_0001314167": "空白工作台布局",
      "P_YS_OA_XTMENHUCMS_0000045054": "保存失败请稍后重试!",
      "P_YS_OA_XTMENHUCMS_0000045296": "确定删除这条限制规则吗？",
      "P_YS_OA_XTMENHUCMS_0001314166": "服务创建工作台",
      "P_YS_OA_XTMENHUCMS_0000045053": "保存失败请稍后重试",
      "P_YS_OA_XTMENHUCMS_0000045295": "(可拖动排序)",
      "P_YS_OA_XTMENHUCMS_0000045052": "自定义底部",
      "P_YS_OA_XTMENHUCMS_0000045294": "测试图片",
      "P_YS_OA_XTMENHUCMS_0001314168": "工作台名称不能超过20个字符",
      "P_YS_OA_XTMENHUCMS_0000045051": "普通图标",
      "P_YS_OA_XTMENHUCMS_0000045293": "新添日程",
      "P_YS_OA_XTMENHUCMS_0000045050": "”主题吗?",
      "P_YS_OA_XTMENHUCMS_0000045292": "修改一级菜单",
      "P_YS_OA_XTMENHUCMS_0001538941": "1.导入前，请您先下载模板，并按照指定格式编辑导入用户信息，然后导入数据；",
      "P_YS_OA_XTLCZX_0000030259": "关闭成功",
      "P_YS_OA_XTMENHUCMS_0001538943": "2.请您认真填写信息，否则可能导入失败；",
      "P_YS_OA_XTMENHUCMS_0000045059": "允许个人设计",
      "P_YS_OA_XTMENHUCMS_0001538945": "3.不建议导入同一个文件多次；",
      "P_YS_OA_XTMENHUCMS_0000045058": "自定义应用副本",
      "P_YS_FED_FW_0000022352": "布局",
      "P_YS_FED_FW_0000021022": "日",
      "P_YS_OA_XTMENHUCMS_0000045291": "从左到右",
      "P_YS_OA_XTMENHUCMS_0000045046": "新添任务",
      "P_YS_OA_XTMENHUCMS_0000045288": "起始色",
      "P_YS_OA_XTMENHUCMS_0000045045": "边框圆角值",
      "P_YS_OA_XTMENHUCMS_0000045044": "工作邮箱权限",
      "P_YS_OA_XTMENHUCMS_0000045286": "启用横向导航成功",
      "P_YS_OA_XTMENHUCMS_0000045043": "首屏",
      "P_YS_OA_XTMENHUCMS_0000045042": "确定要删除该导航吗",
      "P_YS_OA_XTMENHUCMS_0000045283": "个人详情页显示字段",
      "P_YS_OA_XTMENHUCMS_0000045040": "最多支持添加6个一级菜单！",
      "P_YS_OA_XTMENHUCMS_0000045282": "删除成功!",
      "P_YS_OA_XTMENHUCMS_0000045281": "分类LOGO",
      "P_YS_FED_FW_0000021499": "重置",
      "P_YS_FED_FW_0000021018": "四",
      "P_YS_OA_XTMENHUCMS_0000045049": "目前自由布局仅支持 Chrome 浏览器;请更换浏览器后重试!",
      "P_YS_OA_XTMENHUCMS_0000045048": "友空间工作台首页",
      "P_YS_OA_XTMENHUCMS_0000045047": "控件属性",
      "P_YS_OA_XTMENHUCMS_0000045289": "确定删除这个组件吗?",
      "P_YS_OA_XTMENHUCMS_0000045280": "移动端定制",
      "P_YS_OA_XTMENHUCMS_0001314150": "研发建模服务-前端",
      "P_YS_OA_XTMENHUCMS_0001314152": "系統語言",
      "P_YS_OA_XTMENHUCMS_0001314151": "韓語",
      "P_YS_OA_XTMENHUCMS_0000045035": "门户LOGO",
      "P_YS_OA_XTMENHUCMS_0000045277": "布拉特",
      "P_YS_OA_XTMENHUCMS_0001314143": "默認語種",
      "P_YS_OA_XTMENHUCMS_0000045034": "一次最多只允许上传",
      "P_YS_OA_XTMENHUCMS_0000045276": "关闭横向导航成功",
      "P_YS_OA_XTMENHUCMS_0000045033": "赵子健",
      "P_YS_OA_XTMENHUCMS_0000045275": "打开中...",
      "P_YS_OA_XTMENHUCMS_0001314145": "當前",
      "P_YS_OA_XTMENHUCMS_0000045032": "张c",
      "P_YS_OA_XTMENHUCMS_0000045274": "宝石青",
      "P_YS_OA_XTMENHUCMS_0001314144": "當前語種",
      "P_YS_OA_XTMENHUCMS_0000045031": "停用后重新登录生效",
      "P_YS_OA_XTMENHUCMS_0000045273": "链接页面",
      "P_YS_OA_XTMENHUCMS_0001314147": "默認",
      "P_YS_OA_XTMENHUCMS_0000045030": "海洋蓝",
      "P_YS_OA_XTMENHUCMS_0000045272": "(拖动可排序)",
      "P_YS_OA_XTMENHUCMS_0001314146": "【提示】若“系統語言”為空，則按照“當前語言”進行保存。",
      "P_YS_OA_XTMENHUCMS_0000045271": "部门统计-月",
      "P_YS_OA_XTMENHUCMS_0001314149": "簡體中文",
      "P_YS_OA_XTMENHUCMS_0000045270": "工作台后台管理接口联调测试",
      "P_YS_OA_XTMENHUCMS_0001314148": "多語言設置",
      "P_YS_OA_XTMENHUCMS_0000045039": "完成率",
      "P_YS_OA_XTMENHUCMS_0000045038": "没有发现相关文件哟",
      "P_YS_OA_XTMENHUCMS_0000045037": "自定义主题",
      "P_YS_OA_XTMENHUCMS_0000045279": "线性导航颜色",
      "P_YS_OA_XTMENHUCMS_0000045036": "集规划字",
      "P_YS_OA_XTMENHUCMS_0000045278": "预览效果",
      "P_YS_OA_XTMENHUCMS_0001314198": "组件属性配置",
      "P_YS_OA_XTMENHUCMS_0001314197": "创建工作台",
      "P_YS_OA_XTMENHUCMS_0001314199": "工作台使用者角色",
      "P_YS_OA_XTMENHU_0001025410": "自定义启动图",
      "P_YS_OA_XTMENHU_0001025411": "生效时间不能晚于失效时间",
      "P_YS_OA_XTLCZX_0000030460": "小时",
      "P_YS_FED_EXAMP_0000020173": "全部应用",
      "P_YS_OA_XTMENHUCMS_0001320648": "工作台设计",
      "P_YS_OA_XTMENHUCMS_0000045099": "关联功能",
      "P_YS_OA_XTMENHUCMS_0000045098": "故事数",
      "P_YS_OA_XTMENHUCMS_0000045097": "请输入正确格式的网址",
      "P_YS_OA_XTMENHU_0001025409": "上传失败，请上传300K以内的图片",
      "P_YS_OA_XTMENHUCMS_0000045096": "用友产业园公共会议室使用管理规定",
      "P_YS_OA_XTMENHUCMS_0000045095": "一级导航",
      "P_YS_OA_XTMENHU_0001025407": "默认启动图",
      "P_YS_OA_XTMENHUCMS_0000045094": "头部背景颜色",
      "P_YS_OA_XTMENHU_0001025408": "上传图片尺寸建议为1080*1920，大小不超过300KB",
      "P_YS_OA_XTMENHU_0001025405": "上传失败，请上传 jpg、jpeg、png 类型图片",
      "P_YS_OA_XTMENHU_0001025406": "编辑启动图",
      "P_YS_OA_XTMENHU_0001025403": "温馨提示：在未设置自定义启动图的时间段，移动端启动图将使用默认图",
      "P_YS_OA_XTMENHU_0001025404": "删除后无法恢复，请确认",
      "P_YS_OA_XTMENHU_0001025401": "请上传启动图",
      "P_YS_OA_XTMENHU_0001025402": "设定在企业纪念日、重大通知时展示的启动画面",
      "P_YS_OA_XTMENHU_0001025400": "点击修改",
      "P_YS_FED_FW_0000022154": "恢复",
      "P_YS_OA_XTMENHUCMS_0000045093": "逻辑排序",
      "P_YS_OA_XTMENHUCMS_0000045091": "签到数据测试结果反馈",
      "P_YS_OA_XTMENHUCMS_0001314181": "请输入平台服务代码(选填)",
      "P_YS_OA_XTMENHUCMS_0000045090": "确定要停用吗？停用后重新登录生效",
      "P_YS_SD_SDMAUF_0000151788": "页签1",
      "P_YS_OA_XTMENHUCMS_0001314183": "新增复选类型",
      "P_YS_OA_XTMENHUCMS_0001314182": "从已有工作台页面创建",
      "P_YS_OA_XTMENHUCMS_0001314185": "该类工作台，其启/停用状态和使用权均由对应的服务进行控制，此处不受控制",
      "P_YS_OA_XTMENHUCMS_0001314184": "请选择工作台使用者",
      "P_YS_OA_XTMENHUCMS_0001314176": "通过服务创建",
      "P_YS_OA_XTMENHUCMS_0000045089": "导航选中颜色",
      "P_YS_OA_XTMENHUCMS_0001314175": "请选择服务!",
      "P_YS_OA_XTMENHUCMS_0000045088": "门户使用者",
      "P_YS_OA_XTMENHUCMS_0001314178": "門戶",
      "P_YS_OA_XTMENHUCMS_0000045087": "您添加的内部群有些存在，已自动去重",
      "P_YS_OA_XTMENHUCMS_0001314177": "名称长度应小于等于20个字符",
      "P_YS_OA_XTMENHUCMS_0000045086": "链接企业自有门户",
      "P_YS_OA_XTMENHUCMS_0000045085": "上传图片只能是JPG/PNG格式",
      "P_YS_OA_XTMENHUCMS_0001314179": "输入比例错误~",
      "P_YS_OA_XTMENHUCMS_0000045084": "下午3点开会",
      "P_YS_OA_XTMENHUCMS_0000045083": "请选择所属机构",
      "P_YS_OA_XTMENHUCMS_0000045082": "取消自定义底部成功",
      "P_YS_OA_XTMENHUCMS_0001314190": "请选择要设置的资源尺寸",
      "P_YS_OA_XTMENHUCMS_0000045081": "请输入6个字符以内",
      "P_YS_OA_XTMENHUCMS_0000045080": "最多添加4个待办组件",
      "P_YS_OA_XTMENHUCMS_0001314192": "广告位删除失败",
      "P_YS_OA_XTMENHUCMS_0001314191": "頁簽1",
      "P_YS_OA_XTMENHUCMS_0001314194": "属性键值",
      "P_YS_OA_XTMENHUCMS_0001314193": "工作台名称不能为空",
      "P_YS_OA_XTMENHUCMS_0001314196": "%c加载资源为前端合包资源-portal",
      "P_YS_OA_XTMENHUCMS_0001314195": "新增下拉类型",
      "P_YS_OA_XTMENHUCMS_0001314187": "复制工作台",
      "P_YS_OA_XTMENHUCMS_0000045078": "图标修改仅对本控件有效",
      "P_YS_OA_XTMENHUCMS_0001314186": "搜索接口key值",
      "P_YS_OA_XTMENHUCMS_0000045077": "年假是申请15天",
      "P_YS_OA_XTMENHUCMS_0001314189": "请输入Url",
      "P_YS_OA_XTMENHUCMS_0000045076": "总体收款",
      "P_YS_OA_XTMENHUCMS_0001314188": "组件源文件",
      "P_YS_OA_XTMENHUCMS_0000045075": "个人设计",
      "P_YS_OA_XTMENHUCMS_0000045074": "热门话题",
      "P_YS_OA_XTMENHUCMS_0000045073": "夏令营集颜值",
      "P_YS_OA_XTMENHUCMS_0000045072": "账户类型",
      "P_YS_PF_GZTSYS_0000011913": "动态分类管理",
      "P_YS_OA_XTLCZX_0000030653": "常用",
      "P_YS_FED_FW_0000022521": "名称不能为空",
      "P_YS_IPAAS_UCG_0001156621": "下拉选择",
      "P_YS_SD_SDMA_0000037949": "菜单名称不能为空",
      "P_YS_OA_XTGONGGAO_0000045838": "请选择所属组织",
      "P_YS_OA_XTMENHUCMS_0001539173": "总导入人数",
      "P_YS_OA_XTMENHUCMS_0001539174": "失败人数",
      "P_YS_OA_XTMENHUCMS_0001539177": "成功人数",
      "P_YS_OA_XTMENHUCMS_0001539170": "返回",
      "P_YS_FED_UCFBASEDOC_0000025313": "停用成功",
      "P_YS_FED_FW_0000021412": "排名",
      "P_YS_FED_IMP-DFM-FE_0000020868": "操作",
      "P_YS_FED_EXAMP_0000020394": "全部",
      "P_YS_OA_XTHUIYI_0000046420": "待处理",
      "P_YS_OA_XTLCZX_0000030677": "拖拽可排序",
      "P_YS_FED_IMP-DFM-FE_0000020875": "编辑",
      "P_YS_FED_FW_0000021476": "山西",
      "P_YS_SD_SDMB_0000147720": "回调函数",
      "P_YS_FED_IMP_IOT0000006584": "刪除成功",
      "gwportal.erp.xiaoyou": "小友",
      "P_YS_OA_XTYUNHUIWU_0000162483": "组织",
      "P_YS_FED_FW_0000021003": "星期一",
      "P_YS_OA_app_xtywlj_0000037530": "暂无内容",
      "P_YS_YYGJFW_YBUC_0001302338": "组件图标",
      "P_YS_OA_XTMENHUCMS_0001095593": "中文最多4个字符，英文最多8个字符"
  }
}